import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import store from "./redux/store/index";
import Routes from "./routes/Routes";
import "react-datetime/css/react-datetime.css";
import { AlertaModal } from "./components/AlertaModal";
import menuStore, { ContextMenu } from "./redux/reducers/menuReducer";
import { ConfirmationModal } from "./components/ConfirmationModal";
import { SenhaModalV2 } from "./components/SenhaModalV2";

const App = () => {
  return (
    <Provider store={store}>
      <Provider store={menuStore} context={ContextMenu}>
        <Routes />
        <ReduxToastr
          timeOut={3000}
          newestOnTop={true}
          position="bottom-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          preventDuplicates
          progressBar
          closeOnToastrClick
        />
        <AlertaModal />
        <ConfirmationModal />
        <SenhaModalV2 />
      </Provider>
    </Provider>
  );
};

export default App;
