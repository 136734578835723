import React, { useState } from "react";
import {
  BotaoPesquisar,
  FiltroPeriodoDatas,
  IntegerFormInput,
  Table,
} from "../../../../../components";
import { ModalBase } from "../../../../../components/ModalBase";
import ManifEletronMDFeService from "../../../../../services/faturamento/ManifEletronMDFeService";
import { formatNumber } from "../../../../../coreUtils";
import { Row } from "reactstrap";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "numero",
    text: "Nº",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "data_emissao",
    text: "Emissão",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "tipo_doc",
    text: "Tipo",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_dest",
    text: "Destinatário",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "vlr_tot_carga",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const InclusaoRapidaDocsMDFeModal = ({
  isOpen,
  toggle,
  onConfirm,
  docsJaIncluidos,
}) => {
  const [docs, setDocs] = useState([]);
  const [selected, setSelected] = useState([]);
  const [dataIni, setDataIni] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    )
  );
  const [dataFim, setDataFim] = useState(new Date());
  const [numeroDoc, setNumeroDoc] = useState(null);

  const carregarDados = async () => {
    setLoading(true);
    try {
      const params = {
        data_ini: dataIni,
        data_fim: dataFim,
        numero_doc: numeroDoc,
      };

      const [ok, ret] = await ManifEletronMDFeService.listarDocsInclusaoRapida(
        params
      );
      if (ok) {
        const chavesDocsJaIncluidos = docsJaIncluidos.map(
          (e) => e.chave_acesso
        );
        setDocs(
          ret?.filter((e) => !chavesDocsJaIncluidos.includes(e.chave_acesso)) ??
            []
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDocs([]);
    setSelected([]);
    setDataIni(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      )
    );
    setDataFim(new Date());
    setNumeroDoc(null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? docs.map((e) => e) : []);
  };

  const handleSetSelected = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, row] : selected.filter((e) => e.id !== v)
    );
  };

  const handleConfirm = () => {
    onConfirm(selected);
    toggle();
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Inclusão Rápida de Docs."
      number="0042_4"
      autoFocus
      hideCancelButton
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      onConfirm={handleConfirm}
    >
      <Row>
        <FiltroPeriodoDatas defaultOption="7D" onChange={handleDate} />
        <IntegerFormInput
          label="Nº do Documento"
          md="2"
          onChange={setNumeroDoc}
        />
        <BotaoPesquisar onClick={carregarDados} loading={loading} />
      </Row>
      <Table
        columns={columns}
        data={docs}
        paginated={false}
        multiselect
        selected={selected.map((e) => e.id)}
        onSelect={handleSetSelected}
        onSelectAll={handleSelectAll}
        clearSelectionOnUpdate
      />
    </ModalBase>
  );
};
