import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
  LinkButton,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { GerenciamentoCobrancaBancariaService } from "../../../../services/financeiro/GerenciamentoCobrancaBancariaService";
import { GerencCobrancaSkillGrid } from "./components/GerencCobrancaSkillGrid";
import { GerarBoletosModal } from "../gerenc_cobranca_banc/components/GerarBoletosModal";
import { ImprimirBoletosModal } from "../boleto/components/ImprimirBoletosModal";
import { DetalhesCobrancaBancModal } from "../gerenc_cobranca_banc/components/DetalhesCobrancaBancModal";
import { EmailBoletosModal } from "../boleto/components/EmailBoletosModal";
import { ConfirmaCancelarBoletoModal } from "../gerenc_cobranca_banc/components/ConfirmaCancelarBoletoModal";
import { showConfirmation } from "../../../../components/ConfirmationModal";
import { formatarValor } from "../../../../coreUtils";
import { RadioItem } from "../../../../components/RadioGroup";
import { relInconsistenciasRoute } from "../../../../routes/modules/financeiro";

const filtrarPorOptions = [
  { label: "Vencimento", value: "VCT" },
  { label: "Emissão", value: "EMI" },
  { label: "Recebimento", value: "RCB" },
  { label: "Ocorrência Boleto", value: "OCB" },
];

export const SIGLA_MOSTRAR_PENDENTES = "PEN";
export const SIGLA_MOSTRAR_LIQUIDADOS = "LIQ";

const ocorrenciasOptions = [
  { label: "Criação do boleto", value: "REGIS" },
  { label: "Rejeição do boleto", value: "REJEI" },
  { label: "Atualização de dados do boleto", value: "ALTER" },
  { label: "Erro na atualização de dados do boleto", value: "ERRAL" },
  { label: "Boleto cancelado", value: "CANCL" },
  { label: "Erro no cancelamento do boleto", value: "ERRCA" },
  { label: "Pagamento do boleto", value: "PAGMT" },
  { label: "Estorno de pagamento do boleto", value: "ESTOR" },
  { label: "Erro no pagamento do boleto", value: "ERRPG" },
];

const __totaisInitialState = { vlr_receber: 0, vlr_recebido: 0 };

export const GerencCobrancaSkill = () => {
  /// Essa tela só estará disponível para o usuário do Thomas no sistema
  /// financeiro da Skillsoft.
  /// Essa tela não deverá ser disponibilizada para os clientes.
  const [idCliente, setIdCliente] = useState(undefined);
  const [idTipoCliente, setIdTipoCliente] = useState(undefined);
  const [nroBoleto, setNroBoleto] = useState(undefined);
  const [nroOrigem, setNroOrigem] = useState(undefined);
  const [filtrarPor, setFiltrarPor] = useState(filtrarPorOptions[0].value);
  const [mostrar, setMostrar] = useState(SIGLA_MOSTRAR_PENDENTES);
  const [ocorrenciaMostrar, setOcorrenciaMostrar] = useState(undefined);
  const [dataIni, setDataIni] = useState(
    new Date(new Date().setDate(new Date().getDate() - 3))
  );
  const [dataFim, setDataFim] = useState(new Date());

  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [lastSelectedLayout, setLastSelectedLayout] = useState(null);
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState(__totaisInitialState);

  const [loading, setLoading] = useState(false);
  const [loadingGerarNfseBoletos, setLoadingGerarNfseBoletos] = useState(false);
  const [loadingEnviarNfseBoletoViaWhats, setLoadingEnviarNfseBoletoViaWhats] =
    useState(false);
  const [loadingVerificarStatusBoletos, setLoadingVerificarStatusBoletos] =
    useState(false);
  const [loadingWhatsAtraso, setLoadingWhatsAtraso] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [cancelarOpen, setCancelarOpen] = useState(false);

  const handleDateInput = (dIni, dFim) => {
    setDataIni(dIni);
    setDataFim(dFim);
  };

  const carregarDados = async () => {
    setLoading(true);
    try {
      const params = {
        id_cliente: idCliente,
        id_tipo_cliente: idTipoCliente,
        nro_boleto: nroBoleto,
        nro_origem: nroOrigem,
        tipo_periodo: filtrarPor,
        data_ini: dataIni,
        data_fim: dataFim,
        mostrar: mostrar,
        tipo_ocorrencia: ocorrenciaMostrar,
      };

      const [ok, ret] = await GerenciamentoCobrancaBancariaService.listarSkill(
        params
      );
      if (ok) {
        setSelected([]);
        setDados(ret.titulos);
        setTotais(ret.totais);
      }
    } finally {
      setLoading(false);
    }
  };

  const limparDados = () => {
    setDados([]);
    setTotais(__totaisInitialState);
    setSelected([]);
  };

  useEffect(() => {
    limparDados();
    carregarDados();
  }, [mostrar]);

  useEffect(() => {
    if (filtrarPor !== "OCB") {
      setOcorrenciaMostrar(undefined);
    }
  }, [filtrarPor]);

  const handleSelect = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, row] : selected.filter((e) => e.id !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados : []);
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);
  const toggleCancelar = () => setCancelarOpen(!cancelarOpen);

  const mostrarDetalhes = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const cancelarBoleto = (id, layoutBoleto) => {
    setLastSelected(id);
    setLastSelectedLayout(layoutBoleto);
    setTimeout(() => {
      toggleCancelar();
    }, 1);
  };

  const gerarNfseBoletos = async () => {
    if (loadingGerarNfseBoletos) return;

    setLoadingGerarNfseBoletos(true);

    const gerarNfse = async (index) => {
      const [ok] =
        await GerenciamentoCobrancaBancariaService.gerarEmitirNfseBoleto(
          selected[index].id
        );
      if (!ok) {
        setLoadingGerarNfseBoletos(false);
        return;
      }

      processarTitulo(index + 1);
    };

    const processarTitulo = async (index) => {
      if (index > selected.length - 1) {
        // Terminou de processar a lista de títulos selecionados
        carregarDados();
        setLoadingGerarNfseBoletos(false);
        return;
      }

      // Verifica se o cliente gera NFS-e pelo boleto
      const [ok, ret] =
        await GerenciamentoCobrancaBancariaService.verificaClienteGeraNfseBoleto(
          selected[index].id_cliente
        );

      if (!ok) {
        setLoadingGerarNfseBoletos(false);
        return;
      }

      if (!ret.gera_nfse_na_geracao_boleto) {
        showConfirmation(
          `O cliente ${selected[index].nome_cliente} está definido para não ` +
            `gerar NFS-e através dos boletos. Deseja continuar?`,
          // Se clicar em sim, gera a NFS-e
          () => gerarNfse(index),
          // Se clicar em não, pula para o próximo item da lista
          () => processarTitulo(index + 1)
        );
        return;
      }

      await gerarNfse(index);
    };

    processarTitulo(0);
  };

  const enviarNfseBoletoViaWhats = async () => {
    setLoadingEnviarNfseBoletoViaWhats(true);
    try {
      for (const duplic of selected) {
        await GerenciamentoCobrancaBancariaService.enviarNfseBoletoViaWhats(
          duplic.id
        );
      }
    } finally {
      setLoadingEnviarNfseBoletoViaWhats(false);
    }
  };

  const verificarStatusBoletos = async () => {
    setLoadingVerificarStatusBoletos(true);
    try {
      for (const duplic of selected) {
        await GerenciamentoCobrancaBancariaService.verificarStatusBoleto(
          duplic.id
        );
      }
    } finally {
      setLoadingVerificarStatusBoletos(false);
      carregarDados();
    }
  };

  const enviarMensagemWhatsappAtraso = async () => {
    setLoadingWhatsAtraso(true);
    try {
      for (const duplic of selected) {
        await GerenciamentoCobrancaBancariaService.enviarAvisoAtrasoWhatsapp(
          duplic.id
        );
      }
    } finally {
      setLoadingWhatsAtraso(false);
      carregarDados();
    }
  };

  return (
    <PageContainer title="Gerenciamento de Cobrança Skill" number="9001">
      <Card body>
        <Row className="mb-2">
          <AsyncComboBox
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <AsyncComboBox
            md={2}
            label="Tipo de Cliente"
            isConcatField
            concatModelName="tipo_cliente"
            isSearchable
            onChange={(s) => setIdTipoCliente(s?.value)}
            defaultOptions
          />
          <TextInput
            label="Nº Boleto"
            value={nroBoleto}
            onChange={(e, v) => setNroBoleto(v)}
            md={2}
          />
          <IntegerFormInput
            label="Nº Origem"
            onChange={setNroOrigem}
            defaultValue={nroOrigem}
            md={2}
          />
          <RadioGroup label="Mostar" value={mostrar} onChange={setMostrar}>
            <RadioItem label="Pendentes" value={SIGLA_MOSTRAR_PENDENTES} />
            <RadioItem label="Liquidados" value={SIGLA_MOSTRAR_LIQUIDADOS} />
          </RadioGroup>
        </Row>
        <Row className="mb-2">
          <ComboBox
            label="Filtrar Por"
            md={2}
            onChange={(s) => setFiltrarPor(s?.value)}
            options={filtrarPorOptions}
            defaultValue={filtrarPor}
          />
          <FiltroPeriodoDatas onChange={handleDateInput} defaultOption="3D" />
          {filtrarPor === "OCB" && (
            <ComboBox
              label="Ocorrência"
              md="3"
              onChange={(s) => setOcorrenciaMostrar(s?.value)}
              options={ocorrenciasOptions}
              defaultValue={ocorrenciaMostrar}
              isClearable
            />
          )}
          <BotaoPesquisar loading={loading} onClick={carregarDados} />
          <LinkButton
            pathname={relInconsistenciasRoute.path}
            color="indigo"
            divClassName="ml-auto"
          >
            Inconsistências
          </LinkButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Quantidade | Valor a Receber"
          value={`${dados.length} | ${formatarValor(
            totais?.vlr_receber,
            2,
            2,
            true
          )}`}
          format={false}
        />
        <CardTotaisItem
          label="Valor Recebido"
          value={totais?.vlr_recebido}
          checkFloat
        />
        <CardTotaisItem
          label="Quantidade | Valor Selecionado"
          format={false}
          value={`${selected.length} | ${formatarValor(
            selected.reduce((p, c) => p + parseFloat(c.vlr_receber), 0),
            2,
            2,
            false
          )}`}
          checkFloat
        />
      </CardTotais>
      <Card body>
        <Row className="mb-2">
          <GerarBoletosModal selected={selected} notifyAction={carregarDados} />
          <FormButton
            disabled={selected.length === 0}
            color="indigo"
            onClick={verificarStatusBoletos}
            loading={loadingVerificarStatusBoletos}
            padded={false}
            divClassName="pr-0"
          >
            Verificar Status
          </FormButton>
          <ImprimirBoletosModal
            selected={selected}
            notifyAction={carregarDados}
            disabledButtons={selected.length === 0}
          />
          <EmailBoletosModal
            duplics={selected.map((e) => e.id)}
            notifyAction={carregarDados}
            disabledButtons={selected.length === 0}
            label="E-mail"
          />
          <FormButton
            disabled={selected.length === 0}
            color="primary"
            onClick={enviarNfseBoletoViaWhats}
            loading={loadingEnviarNfseBoletoViaWhats}
            padded={false}
            divClassName="pl-0"
          >
            Cobrança Whatsapp
          </FormButton>
          <FormButton
            disabled={selected.length === 0}
            color="warning"
            onClick={enviarMensagemWhatsappAtraso}
            loading={loadingWhatsAtraso}
            padded={false}
            divClassName="ml-auto"
          >
            Whats Atraso
          </FormButton>
          <FormButton
            divClassName="ml-auto pr-0"
            disabled={selected.length === 0}
            color="secondary"
            onClick={gerarNfseBoletos}
            loading={loadingGerarNfseBoletos}
            padded={false}
          >
            Gerar NFS-e
          </FormButton>
        </Row>
        <GerencCobrancaSkillGrid
          dados={dados}
          mostrar={mostrar}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          mostrarDetalhes={mostrarDetalhes}
          cancelarBoleto={cancelarBoleto}
        />
        <DetalhesCobrancaBancModal
          isOpen={detalhesOpen}
          toggle={toggleDetalhes}
          selected={lastSelected}
        />
        <ConfirmaCancelarBoletoModal
          isOpen={cancelarOpen}
          toggle={toggleCancelar}
          selected={lastSelected}
          selectedLayout={lastSelectedLayout}
          notifyAction={carregarDados}
        />
      </Card>
    </PageContainer>
  );
};
