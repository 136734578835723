import React, { useState } from "react";
import SolicitacaoRecargaService from "../../../../../services/pronta_entrega/SolicitacaoRecargaService";
import {
  FixedField,
  FormButton,
  ModalBase,
  Table,
  TableDelete,
} from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { Row } from "reactstrap";
import { LogsSolicRecargaModal } from "./LogsSolicRecargaModal";
import { showConfirmation } from "../../../../../components/ConfirmationModal";

export const DetalhesSolicRecargaModal = ({ isOpen, toggle, selected }) => {
  const [idRotaMob, setIdRotaMob] = useState(null);
  const [nomeRotaMob, setNomeRotaMob] = useState(null);
  const [expedidoJorn, setExpedidoJorn] = useState(null);
  const [dataHoraSolicitacao, setDataHoraSolicitacao] = useState(null);
  const [dataHoraExpedido, setDataHoraExpedido] = useState(null);
  const [itens, setItens] = useState([]);

  // Controle
  const [logsOpen, setLogsOpen] = useState(false);

  const columns = [
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
    },
    {
      dataField: "nome_produto",
      text: "nome",
      align: "left",
    },
    {
      dataField: "unidade_produto",
      text: "Unidade",
      align: "Center",
    },
    {
      dataField: "qtd_solic",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
    },
    {
      dataField: "act",
      dummyField: true,
      text: "",
      align: "center",
      formatter: (_, row) => (
        <TableDelete onClick={() => verifExcluirItem(row.id)} />
      ),
    },
  ];

  const verifExcluirItem = (idItem) => {
    showConfirmation(
      "Você tem certeza que deseja excluir esse item?",
      () => excluirItem(idItem),
      () => {}
    );
  };

  const excluirItem = async (idItem) => {
    const [ok] = await SolicitacaoRecargaService.excluirItem(idItem);
    if (ok) {
      setItens([...itens.filter((e) => e.id !== idItem)]);
    }
  };

  const limparDados = () => {
    setIdRotaMob(null);
    setNomeRotaMob(null);
    setExpedidoJorn(null);
    setDataHoraSolicitacao(null);
    setDataHoraExpedido(null);
    setItens([]);
  };

  const carregarDados = async () => {
    const [ok, ret] = await SolicitacaoRecargaService.buscar(selected);
    if (ok) {
      setIdRotaMob(ret.id_rota_mob);
      setExpedidoJorn(ret.expedido_jorn);
      setDataHoraSolicitacao(ret.data_hora_solicitacao);
      setDataHoraExpedido(ret.data_hora_expedido);
      setNomeRotaMob(ret.nome_rota_mob);
      setItens(ret.itens);
    } else {
      toggle();
    }
  };

  const toggleLogs = () => setLogsOpen(!logsOpen);

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title={`Detalhes da Solicitação de Recarga Nº ${selected}`}
        number="0104_2"
        onClosed={limparDados}
        onBeforeOpen={carregarDados}
        numberStyle={{ marginRight: "10px" }}
        footerActions={
          <FormButton
            color="primary"
            padded={false}
            divClassName="mr-auto"
            onClick={toggleLogs}
          >
            Logs
          </FormButton>
        }
      >
        <Row className="mb-2" style={{ justifyContent: "space-between" }}>
          <FixedField
            label="Rota"
            value={formatValueFromAPI(nomeRotaMob, idRotaMob)}
            horizontal
          />
          <FixedField
            label="Solicitado"
            value={dataHoraSolicitacao}
            horizontal
          />
          <FixedField
            label="Carregado"
            value={expedidoJorn ? "Sim" : "Não"}
            horizontal
          />
          <FixedField
            label="Data Hora Carregamento"
            value={dataHoraExpedido}
            horizontal
          />
        </Row>
        <Table data={itens} columns={columns} paginated={false} pageSize={10} />
      </ModalBase>
      <LogsSolicRecargaModal
        isOpen={logsOpen}
        toggle={toggleLogs}
        selected={selected}
      />
    </>
  );
};
