import React, { useEffect } from "react";
import { useState } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FormCheckbox,
  PageContainer,
} from "../../../../components";
import { GerencCredRotativoGrid } from "./components/GerencCredRotativoGrid";
import CredRotativoService from "../../../../services/financeiro/CredRotativoService";
import { AbrirContaCredRotativoModal } from "./components/AbrirContaCredRotativoModal";
// import { AlterarLimiteCredModal } from "./components/AlterarLimiteCredModal";
// import { IncluirCreditoModal } from "./components/IncluirCreditoModal";
// import { AlterarCreditoClienteModal } from "./components/AlterarCreditoClienteModal";
import { DetalhesContaModal } from "./components/DetalhesContaModal";

export const GerencCredRotativo = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [somenteComSaldoDevedor, setSomenteComSaldoDevedor] = useState(false);
  // const [somenteMovAberto, setSomenteMovAberto] = useState(true);
  const [dados, setDados] = useState([]);
  // const [detalhesConta, setDetalhesConta] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  // const [alterarLimiteCredOpen, setAlterarLimiteCredOpen] = useState(false);
  // const [incluirCreditoOpen, setIncluirCreditoOpen] = useState(false);
  // const [alterarCreditoOpen, setAlterarCreditoOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);

  const carregarDados = async () => {
    setLoading(true);

    const params = {
      id_cliente: idCliente,
      somente_com_saldo_devedor: somenteComSaldoDevedor,
    };

    const ret = await CredRotativoService.listarContas(params);
    setDados(ret ?? []);
    setLoading(false);
  };

  const handleSelect = (v) => {
    setSelected(v);
  };

  // const toggleAlterarLimiteCred = () => {
  //   setAlterarLimiteCredOpen(!alterarLimiteCredOpen);
  // };

  // const toggleIncluirCredito = () => {
  //   setIncluirCreditoOpen(!incluirCreditoOpen);
  // };

  // const toggleAlterarCredito = () => {
  //   setAlterarCreditoOpen(!alterarCreditoOpen);
  // };

  const toggleDetalhes = () => {
    setDetalhesOpen(!detalhesOpen);
  };

  // const alterarLimiteCred = (idCli) => {
  //   setSelected(idCli);
  //   toggleAlterarLimiteCred();
  // };
  // const incluirCredito = (idCli) => {
  //   setSelected(idCli);
  //   toggleIncluirCredito();
  // };

  // const alterarCredito = (idCli) => {
  //   setSelected(idCli);
  //   toggleAlterarCredito();
  // };

  const abrirDetalhesConta = (idCli) => {
    setSelected(idCli);
    toggleDetalhes();
  };

  const onChangeSelected = () => {
    if (selected) {
      setNomeCliente(
        dados.find((e) => e.id_cliente === selected)?.nome_cliente ?? ""
      );
    }
  };

  useEffect(onChangeSelected, [selected]);

  return (
    <PageContainer title="Gerenciamento de Crédito Rotativo" number="0068">
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <FormCheckbox
            label="Somente Clientes com Saldo Devedor"
            checked={somenteComSaldoDevedor}
            onChange={() => setSomenteComSaldoDevedor(!somenteComSaldoDevedor)}
          />
          {/* <FormCheckbox
            label="Somente Movimentos em Aberto"
            checked={somenteMovAberto}
            onChange={() => setSomenteMovAberto(!somenteMovAberto)}
          /> */}
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Row>
          <AbrirContaCredRotativoModal notifyEvent={carregarDados} />
        </Row>
      </Card>
      <Card body>
        <GerencCredRotativoGrid
          dados={dados}
          onSelect={handleSelect}
          // alterarLimiteCred={alterarLimiteCred}
          // incluirCredito={incluirCredito}
          // alterarCredito={alterarCredito}
          abrirDetalhesConta={abrirDetalhesConta}
        />
        {/* <AlterarLimiteCredModal
          isOpen={alterarLimiteCredOpen}
          toggle={toggleAlterarLimiteCred}
          notifyEvent={carregarDados}
          selected={selected}
          nomeCliente={nomeCliente}
        /> */}
        {/* <IncluirCreditoModal
          isOpen={incluirCreditoOpen}
          toggle={toggleIncluirCredito}
          notifyEvent={carregarDados}
          selected={selected}
          nomeCliente={nomeCliente}
        /> */}
        {/* <AlterarCreditoClienteModal
          isOpen={alterarCreditoOpen}
          toggle={toggleAlterarCredito}
          notifyEvent={carregarDados}
          selected={selected}
          nomeCliente={nomeCliente}
        /> */}
        <DetalhesContaModal
          isOpen={detalhesOpen}
          toggle={toggleDetalhes}
          notifyEvent={carregarDados}
          selected={selected}
          nomeCliente={nomeCliente}
        />
      </Card>
    </PageContainer>
  );
};
