import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Row } from "reactstrap";
import {
  AsyncComboBox,
  BotaoImprimir,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  PageContainer,
} from "../../../../components";
import { formatDateISO } from "../../../../coreUtils";
import { CentralPedInternoGrid } from "./components/CentralPedInternoGrid";
import { docPrintReport } from "../../../../pdf";
import PedidoInternoService from "../../../../services/pedidos/PedidoInternoService";
import { ImprimirPedInternoModal } from "./components/ImprimirPedInternoModal";
import { SenhaModal } from "../../../../components/SenhaModal";

const mostrarOptions = [
  { label: "Todos", value: "T" },
  { label: "Pendentes", value: "PEN" },
  { label: "Finalizados", value: "FIN" },
];

const filtrarPorOptions = [
  { label: "Emissão", value: "EMI" },
  { label: "Previsão de Entrega", value: "PRE" },
];

export const CentralPedInterno = ({ location }) => {
  const [dataIni, setDataIni] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 3
    )
  );
  const [dataFim, setDataFim] = useState(new Date());
  const [filtrarPor, setFiltrarPor] = useState(filtrarPorOptions[0].value);
  const [idCliente, setIdCliente] = useState(null);
  const [idVendedor, setIdVendedor] = useState(null);
  const [numeroPedido, setNumeroPedido] = useState(null);
  const [idCidade, setIdCidade] = useState(null);
  const [mostrar, setMostrar] = useState(mostrarOptions[0].value);
  const [dados, setDados] = useState([]);
  const [contagem, setContagem] = useState(0);
  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingImpListagem, setLoadingImpListagem] = useState(false);
  const [loadingImpFichaEntrega, setLoadingImpFichaEntrega] = useState(false);
  const [impListaSomenteSel, setImpListaSomenteSel] = useState(false);
  const [impListaItens, setImpListaItens] = useState(false);
  const [senhaEstornarOpen, setSenhaEstornarOpen] = useState(false);

  const limparDados = () => {
    setDados([]);
    setContagem(0);
    setTotal(0);
    setSelected([]);
    setLastSelected(null);
  };

  const handleSelectDate = async (_dataIni, _dataFim) => {
    setDataIni(_dataIni);
    setDataFim(_dataFim);
    limparDados();
  };

  const buscarDados = async (incluirItens, somenteSel) => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: idCliente,
      id_vendedor: idVendedor,
      numero_pedido: somenteSel ? selected : numeroPedido,
      mostrar: mostrar !== "T" ? mostrar : null,
      incluir_itens: incluirItens,
      filtrar_por: filtrarPor || "EMI",
      id_cidade: idCidade,
    };
    return await PedidoInternoService.central.listar(params);
  };

  const listarDados = async () => {
    setSelected([]);
    setLastSelected(null);
    const [ok, ret] = await buscarDados(false);

    if (ok) {
      setDados(ret.pedidos);
      setContagem(ret.qtd_pedidos);
      setTotal(ret.tot_pedidos);
    } else {
      setDados([]);
      setContagem(0);
      setTotal(0);
    }
  };

  const imprimirListagem = async () => {
    setLoadingImpListagem(true);
    const [ok, ret] = await buscarDados(impListaItens, impListaSomenteSel);

    if (ok) {
      await docPrintReport(
        "/relatorios/pedido_interno/listagem/",
        {
          data: ret,
          incluir_itens: impListaItens,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "0034_5"
      );
    }
    setLoadingImpListagem(false);
  };

  const imprimirFichaEntrega = async () => {
    setLoadingImpFichaEntrega(true);
    await PedidoInternoService.central.imprimirFicha(selected, 1);
    setLoadingImpFichaEntrega(false);
  };

  const notifyEvent = () => {
    listarDados();
  };

  const handleSearch = async () => {
    limparDados();
    setLoadingPesquisar(true);
    await listarDados();
    setLoadingPesquisar(false);
  };

  const handleSelect = (id, isSelect) => {
    setLastSelected(id);
    setSelected(
      isSelect ? [...selected, id] : selected.filter((e) => e !== id)
    );
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  useEffect(() => {
    if (location.state?.refresh) {
      handleSearch();
    }
  }, []);

  const toggleSenhaEstornar = () => setSenhaEstornarOpen(!senhaEstornarOpen);

  const solicitarSenhaEstornoVenda = (idPedido) => {
    setLastSelected(idPedido);
    setTimeout(() => {
      toggleSenhaEstornar();
    }, 1);
  };

  const estornarRecebimento = async (senha) => {
    const payload = { id_pedido: lastSelected, senha_estorno: senha };
    const [ok] = await PedidoInternoService.central.estornarRecebimento(
      payload
    );
    if (ok) {
      listarDados();
    }
    return ok;
  };

  return (
    <PageContainer title="Central de Pedidos Internos" number="0100" canGoBack>
      <Card body>
        <Row>
          <IntegerFormInput
            label="Nro. Pedido"
            md={2}
            onChange={(v) => setNumeroPedido(v)}
          />
          <AsyncComboBox
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={3}
            label="Vendedor"
            isConcatField
            concatModelName="colaborador"
            isSearchable
            isClearable
            onChange={(s) => setIdVendedor(s?.value)}
            hideShortcut
            defaultOptions
          />
          <AsyncComboBox
            md={3}
            label="Cidade"
            isConcatField
            concatModelName="cidade"
            isSearchable
            isClearable
            onChange={(s) => setIdCidade(s?.value)}
            hideShortcut
          />
        </Row>
        <Row>
          <ComboBox
            md={2}
            label="Mostrar"
            options={mostrarOptions}
            isClearable
            defaultValue={mostrar}
            onChange={(s) => setMostrar(s?.value)}
          />
          <ComboBox
            md={2}
            label="Filtrar Por"
            options={filtrarPorOptions}
            defaultValue={filtrarPor}
            onChange={(s) => setFiltrarPor(s?.value)}
          />
          <FiltroPeriodoDatas defaultOption="3D" onChange={handleSelectDate} />
        </Row>
        <Row>
          <BotaoPesquisar onClick={handleSearch} loading={loadingPesquisar} />
          <BotaoImprimir
            onClick={imprimirListagem}
            loading={loadingImpListagem}
            text="Imprimir Listagem"
          />
          <FormCheckbox
            label="Imprimir Listagem Somente Selecionados"
            divClassName="pl-0"
            checked={impListaSomenteSel}
            onChange={() => setImpListaSomenteSel(!impListaSomenteSel)}
          />
          <FormCheckbox
            label="Incluir Itens na Listagem"
            divClassName="pl-0"
            checked={impListaItens}
            onChange={() => setImpListaItens(!impListaItens)}
          />
          <ImprimirPedInternoModal selected={selected} />
          <BotaoImprimir
            onClick={imprimirFichaEntrega}
            loading={loadingImpFichaEntrega}
            text="Imprimir Ficha de Entrega"
            disabled={selected.length === 0}
          />
        </Row>
      </Card>
      <Card body>
        <CentralPedInternoGrid
          data={dados}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          selected={selected}
          notifyEvent={notifyEvent}
          estornarRecebimento={solicitarSenhaEstornoVenda}
        />
      </Card>
      <CardTotais md={4}>
        <CardTotaisItem
          label="Quantidade de Pedidos"
          value={contagem}
          checkFloat
        />
        <CardTotaisItem label="Valor Total" value={total} />
      </CardTotais>
      <SenhaModal
        isOpen={senhaEstornarOpen}
        toggle={toggleSenhaEstornar}
        title="Estorno de Recebimento"
        onConfirm={estornarRecebimento}
      />
    </PageContainer>
  );
};
