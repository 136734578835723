import React, { useState } from "react";
import { Table } from "../../../../components";
import { ModalBase } from "../../../../components/ModalBase";
import NotaFiscalService from "../../../../services/docs_eletron/NotaFiscalService";
import { formatValueFromAPI } from "../../../../coreUtils";

const alertasCol = [
  {
    dataField: "alerta",
    text: "Alerta",
    align: "left",
    headerAlign: "left",
    colWidth: "65%",
  },
  {
    dataField: "data_hora_alerta",
    text: "Data/Hora",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_colaborador",
    text: "Usuário",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
  },
];

export const AlertasNFeModal = ({ selected, isOpen, toggle }) => {
  const [alertas, setAlertas] = useState([]);

  const carregarDados = async () => {
    const [ok, ret] = await NotaFiscalService.gerenciamento.alertas(selected);
    if (ok) {
      setAlertas(ret);
    }
  };

  const limparDados = () => {
    setAlertas([]);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Alertas da NF-e"
      number="0055_9"
      autoFocus
      hideCancelButton
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Table
        columns={alertasCol}
        data={alertas}
        paginated={false}
        pageSize={10}
      />
    </ModalBase>
  );
};
