import React from "react";
import { Table, TableDelete } from "../../../../components";
import { formatNumber } from "../../../../coreUtils";

export const ProdutosNfEntradaGrid = ({
  dados,
  excluirItem,
  recalcularValoresGrade,
}) => {
  const columns = [
    {
      dataField: "item",
      text: "I",
      align: "center",
      fixedColWidth: true,
      colWidth: "35px",
    },
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
      fixedColWidth: true,
      colWidth: "60px",
    },
    { dataField: "nome_produto", text: "Nome do Produto", align: "left" },
    { dataField: "referencia", text: "Referência", align: "center" },
    {
      dataField: "sit_trib_icms",
      text: "CST",
      align: "center",
      fixedColWidth: true,
      colWidth: "60px",
      editable: true,
      editorMaxLength: 5,
      onChange: recalcularValoresGrade,
      alwaysShowEditor: true,
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      fixedColWidth: true,
      colWidth: "60px",
      editable: true,
      editorMaxLength: 5,
      onChange: recalcularValoresGrade,
      alwaysShowEditor: true,
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      fixedColWidth: true,
      colWidth: "50px",
    },
    {
      dataField: "quantidade",
      text: "Qtd",
      align: "center",
      formatter: (c, row) =>
        formatNumber(c, true, row.qtd_casas_decimais_qtd, true),
      fixedColWidth: true,
      colWidth: "80px",
      editable: true,
      onChange: recalcularValoresGrade,
      editorType: "number",
      decimalPlaces: (r) => r.qtd_casas_decimais_qtd ?? 2,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_unit",
      text: "Unitário",
      align: "right",
      formatter: (c, row) => formatNumber(c, true, row.qtd_casas_decimais_vlrs),
      fixedColWidth: true,
      colWidth: "80px",
      editable: true,
      onChange: recalcularValoresGrade,
      editorType: "number",
      decimalPlaces: (r) => r.qtd_casas_decimais_vlrs ?? 4,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_desc",
      text: "Desconto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      fixedColWidth: true,
      colWidth: "80px",
      editable: true,
      onChange: recalcularValoresGrade,
      editorType: "number",
      decimalPlaces: 2,
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_ipi",
      text: "IPI",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      fixedColWidth: true,
      colWidth: "80px",
    },
    {
      dataField: "vlr_icms",
      text: "ICMS",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      fixedColWidth: true,
      colWidth: "80px",
    },
    {
      dataField: "vlr_icms_st",
      text: "ICMS ST",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      fixedColWidth: true,
      colWidth: "80px",
    },
    {
      dataField: "vlr_tot_bruto",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      fixedColWidth: true,
      colWidth: "80px",
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      fixedColWidth: true,
      colWidth: "32px",
      formatter: (c, row) => (
        <TableDelete onClick={() => excluirItem(row.item)} />
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      pageSize={10}
      keyField="item"
    />
  );
};
