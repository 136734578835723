import React from "react";
import { Card, DropdownItem } from "reactstrap";
import { ColorField } from "../../../../components/ColorField";
import { MoreDropdown } from "../../../../components/MoreDropdown";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../coreUtils";
import { Table } from "../../../../components";
import { toastr } from "react-redux-toastr";

const coresStatusNFSe = [
  { value: "A", hint: "Aguardando Emissão", color: "#0080FF" },
  { value: "E", hint: "Emitida", color: "#00AA00" },
  { value: "R", hint: "Aguardando Confirmação", color: "#ff9151" },
  { value: "C", hint: "Cancelada", color: "#FF0D0D" },
];

export const NotaFiscalServicoGrid = ({
  data,
  selected,
  setSelected,
  onSelectAll,
  cancelar,
  consultar,
  alertas,
  alterar,
  descartarDigitacao,
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "numero",
      text: "Nº NFS-e",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "numero_rps",
      text: "Nº RPS",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      sortable: true,
      sortFunc: naturalSortDate,
    },
    {
      dataField: "nome_cli",
      text: "Destino",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "email",
      text: "E-Mail",
      align: "left",
    },
    {
      dataField: "vlr_total_nf",
      text: "Valor Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "status",
      text: "Sit",
      align: "center",
      formatter: (c) => <ColorField scheme={coresStatusNFSe} value={c} />,
      style: { verticalAlign: "middle", padding: 0 },
      colWidth: "35px",
      fixedColWidth: true,
      sortable: true,
      hideSortIcon: true,
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      selectOnClick: false,
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          {["A", "S"].includes(row.status) && (
            <>
              <DropdownItem onClick={() => alterar(row.id)}>
                Alterar
              </DropdownItem>
              <DropdownItem onClick={() => descartarDigitacao(row.id)}>
                Descartar Digitação
              </DropdownItem>
            </>
          )}
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              consultar(row.id);
            }}
          >
            Consultar
          </DropdownItem>
          <DropdownItem onClick={() => alertas(row.id)}>Alertas</DropdownItem>
          {row.status === "E" && (
            <DropdownItem onClick={() => cancelar(row.id)}>
              Cancelar NFS-e
            </DropdownItem>
          )}
        </MoreDropdown>
      ),
    },
  ];

  const onRowDoubleClick = (id, row) => {
    if (["A", "S"].includes(row.status)) {
      alterar(row.id);
    } else {
      toastr.warning(
        "Atenção",
        "Somente é possível alterar NFSe's Aguardando Emissão ou Salvas/Em Digitação"
      );
    }
  };

  return (
    <Card body>
      <Table
        data={data}
        columns={columns}
        onSelect={setSelected}
        paginated={false}
        multiselect
        selected={selected}
        onSelectAll={onSelectAll}
        sizePerPage={15}
        clearSelectionOnUpdate={true}
        showRegisterCount={false}
        onRowDoubleClick={onRowDoubleClick}
      />
    </Card>
  );
};
