import React, { useEffect, useState } from "react";
import {
  BotaoImprimir,
  BotaoPesquisar,
  FormCheckbox,
  IntegerFormInput,
  PageContainer,
  Table,
} from "../../../../../components";
import { Card, Row } from "reactstrap";
import { GerenciamentoCobrancaBancariaService } from "../../../../../services/financeiro/GerenciamentoCobrancaBancariaService";
import { docPrintReport } from "../../../../../pdf";

const RelInconsistenciasSkill = () => {
  const [somenteComInconsistencia, setSomenteComInconsistencia] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [dados, setDados] = useState([]);

  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "qtd_titulos",
      text: "Qtd. Títulos Geral",
      align: "center",
      sortable: true,
    },
    {
      dataField: "status_inconsist",
      text: "Status",
      align: "left",
      sortable: true,
    },
  ];

  const carregarDados = async () => {
    setLoading(true);
    setDados([]);
    try {
      const [ok, ret] =
        await GerenciamentoCobrancaBancariaService.relInconsistenciasSkill(
          ano,
          somenteComInconsistencia
        );
      if (ok) {
        setDados(ret);
      }
    } finally {
      setLoading(false);
    }
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] =
      await GerenciamentoCobrancaBancariaService.relInconsistenciasSkill(
        ano,
        somenteComInconsistencia
      );

    if (ok) {
      await docPrintReport(
        "/relatorios/cobranc_skill/inconsistencias/",
        { dados: ret },
        "9001",
        true
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    carregarDados();
  }, []);

  return (
    <PageContainer title="Inconsistências de Cobrança Skill" number="9001_1">
      <Card body>
        <Row>
          <FormCheckbox
            label="Somente com Inconsistências"
            checked={somenteComInconsistencia}
            onChange={() =>
              setSomenteComInconsistencia(!somenteComInconsistencia)
            }
          />
          <IntegerFormInput
            md={1}
            label="Ano"
            defaultValue={ano}
            onChange={setAno}
          />
          <BotaoPesquisar md="auto" loading={loading} onClick={carregarDados} />
          <BotaoImprimir md="auto" loading={loadingImprimir} onClick={imprimir} />
        </Row>
      </Card>
      <Card body>
        <Table
          columns={columns}
          data={dados}
          pageSize={20}
          paginated={false}
          clearSelectionOnUpdate
          multiselect
        />
      </Card>
    </PageContainer>
  );
};

export default RelInconsistenciasSkill;
