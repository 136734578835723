import React from "react";
import {
  Divider,
  MenuGroup,
  MenuGroupButton,
  PageContainer,
} from "../../components";
import { BsBasketFill, BsFiletypeXml } from "react-icons/bs";
import {
  confirmacaoPrecosRoute,
  entradaManualNfRoute,
  entradaNfXmlRoute,
  fracionamentoNfEntradaRoute,
  gerenciamentoComprasRoute,
  gerenciamentoNfEntradaRoute,
} from "../../routes/modules/compras";
import { LuSplit } from "react-icons/lu";
import { PiInvoice, PiNotePencil } from "react-icons/pi";
import { MdOutlinePriceCheck } from "react-icons/md";

export const Compras = () => {
  return (
    <PageContainer title="Compras">
      <Divider className="pl-3 pr-5">Entrada de Notas Fiscais</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Entrada Manual de NF"
          icon={PiNotePencil}
          pathname={entradaManualNfRoute.path}
          nroTela={entradaManualNfRoute.nroTela}
        />
        <MenuGroupButton
          label="Entrada via XML"
          icon={BsFiletypeXml}
          pathname={entradaNfXmlRoute.path}
          nroTela={entradaNfXmlRoute.nroTela}
        />
        <MenuGroupButton
          label="Fracionamento"
          icon={LuSplit}
          pathname={fracionamentoNfEntradaRoute.path}
          nroTela={fracionamentoNfEntradaRoute.nroTela}
        />
        <MenuGroupButton
          label="Gerenciamento NF Entrada"
          icon={PiInvoice}
          pathname={gerenciamentoNfEntradaRoute.path}
          nroTela={gerenciamentoNfEntradaRoute.nroTela}
        />
        <MenuGroupButton
          label="Confirmação de Preços"
          icon={MdOutlinePriceCheck}
          pathname={confirmacaoPrecosRoute.path}
          nroTela={confirmacaoPrecosRoute.nroTela}
        />
      </MenuGroup>
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento de Compras"
          icon={BsBasketFill}
          pathname={gerenciamentoComprasRoute.path}
          nroTela={gerenciamentoComprasRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
