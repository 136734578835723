import React, { useEffect, useRef, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormCheckbox,
  IntegerFormInput,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../../components";
import GerencContasPagarService from "../../../../services/financeiro/GerencContasPagarService";
import { Card, Row } from "reactstrap";
import { GerencContasPagarGrid } from "./components/GerencContasPagarGrid";
import { formatNumber } from "../../../../coreUtils";
import { DetalhesTituloPagarModal } from "./components/DetalhesTituloPagarModal";
import { IncluirTituloPagarModal } from "./components/IncluirTituloPagarModal";
import { LancamentosTituloPagarModal } from "./components/LancamentosTituloPagarModal";
import { apiGetV2 } from "../../../../apiV2";
import { AlterarTituloPagarModal } from "./components/AlterarTituloPagarModal";
import { showWarning } from "../../../../components/AlertaModal";
import { PagarTitulosModal } from "./components/PagarTitulosModal";
import ExcluirTitulosModal from "./components/ExcluirTitulosModal";
import { RadioItem } from "../../../../components/RadioGroup";

const periodoOptions = [
  { label: "Vencimento", value: "VCT" },
  { label: "Pagamento", value: "PGT" },
  { label: "Competência", value: "CMP" },
  { label: "Emissão", value: "EMI" },
];

export const GerencContasPagar = ({ location }) => {
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [idOrganizacao, setIdOrganizacao] = useState(null);
  const [numero, setNumero] = useState(null);
  const [idTitulo, setIdTitulo] = useState(null);
  const [valor, setValor] = useState(null);
  const [somenteCarteira, setSomenteCarteira] = useState(false);
  const [periodo, setPeriodo] = useState(periodoOptions[0].value);
  const [dataIni, setDataIni] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [dataFim, setDataFim] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [status, setStatus] = useState("PD");
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [lancamentosOpen, setLancamentosOpen] = useState(false);
  const [alterarOpen, setAlterarOpen] = useState(false);
  // Parâmetros da tela
  const [utilizaOrganizacao, setUtilizaOrganizacao] = useState(false);

  const filtroPeriodoRef = useRef();

  const titulosSelected = (dados ?? []).filter((e) => selected.includes(e.id));

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async (outrosParams) => {
    const params = {
      id_fornecedor: idFornecedor,
      id_organizacao: idOrganizacao,
      numero: numero,
      id_titulo: idTitulo,
      valor: (valor ?? 0) > 0 ? valor : null,
      mostrar: somenteCarteira ? "C" : "T",
      periodo: periodo,
      data_ini: dataIni,
      data_fim: dataFim,
      status: status,
      ...outrosParams,
    };
    setLoading(true);
    const [ok, ret] = await GerencContasPagarService.listar(params);
    if (ok) {
      setDados(ret.titulos);
      setTotais(ret.totais);
    } else {
      setDados([]);
      setTotais({});
    }
    setLoading(false);
  };

  const notifyEvent = () => carregarDados();

  const handleSelect = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, v] : selected.filter((e) => e !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetStatus = (v) => {
    setStatus(v);
    setPeriodo(v === "PG" ? "PGT" : "VCT");
    if (v !== status) {
      carregarDados({ status: v });
    }
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleLancamentos = () => setLancamentosOpen(!lancamentosOpen);

  const toggleAlterar = () => setAlterarOpen(!alterarOpen);

  const mostrarDetalhes = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 100);
  };

  const verificarAberto = async (id) => {
    const [ok, ret] = await GerencContasPagarService.buscarStatus(id);
    if (ok) {
      if (!ret.aberto) {
        showWarning(
          "O Título selecionado já foi pago e não pode ser alterado."
        );
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const lancamentos = async (id) => {
    if (!(await verificarAberto(id))) return;
    setLastSelected(id);
    setTimeout(() => {
      toggleLancamentos();
    }, 100);
  };

  const alterar = async (id) => {
    if (!(await verificarAberto(id))) return;
    setLastSelected(id);
    setTimeout(() => {
      toggleAlterar();
    }, 100);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/inc_titulo_pagar/");
    if (ok) {
      setUtilizaOrganizacao(ret.utiliza_organizacao);
    }
  };

  const onActivate = () => {
    carregarParametros();

    let outrosParams = {};
    const state = location?.state ?? {};
    if (Object.keys(state).length > 0) {
      if (state.data_ini !== undefined || state.data_fim !== undefined) {
        if (state.data_ini !== undefined) {
          outrosParams.data_ini = state.data_ini;
        }

        if (state.data_fim !== undefined) {
          outrosParams.data_fim = state.data_fim;
        }

        if (filtroPeriodoRef.current) {
          if (state.data_ini !== undefined && state.data_fim !== undefined) {
            filtroPeriodoRef.current.updateDataIniFim(
              state.data_ini,
              state.data_fim
            );
          } else if (state.data_ini !== undefined) {
            filtroPeriodoRef.current.updateDataIni(state.data_ini);
          } else {
            filtroPeriodoRef.current.updateDataFim(state.data_fim);
          }
        }
      }

      if (state.periodo !== undefined) {
        setPeriodo(state.periodo);
        outrosParams.periodo = state.periodo;
      }

      if (state.status !== undefined) {
        handleSetStatus(state.status);
        outrosParams.status = state.status;
      }

      window.history.replaceState({}, "");
    }

    carregarDados(outrosParams);
  };

  useEffect(onActivate, []);

  return (
    <PageContainer title="Gerenciamento de Contas a Pagar" number="0091">
      <Card body>
        <Row>
          <AsyncComboBox
            md={3}
            label="Fornecedor"
            isConcatField
            isClearable
            concatModelName="fornecedor"
            defaultValue={idFornecedor}
            onChange={(s) => setIdFornecedor(s?.value)}
          />
          {utilizaOrganizacao && (
            <AsyncComboBox
              md={2}
              label="Organização"
              isConcatField
              isClearable
              concatModelName="organizacao"
              defaultValue={idOrganizacao}
              onChange={(s) => setIdOrganizacao(s?.value)}
              defaultOptions
            />
          )}
          <TextInput
            md={2}
            label="Número"
            value={numero}
            onChange={(e, v) => setNumero(v)}
            maxLength={12}
          />
          <IntegerFormInput
            md={1}
            label="ID"
            defaultValue={idTitulo}
            onChange={setIdTitulo}
          />
          <NumberInput
            md={2}
            label="Valor Aproximado"
            value={valor}
            onChange={setValor}
          />
          <FormCheckbox
            label="Somente Carteira"
            value={somenteCarteira}
            onChange={() => setSomenteCarteira(!somenteCarteira)}
          />
        </Row>
        <Row className="mb-2">
          <ComboBox
            md={2}
            label="Periodo"
            options={periodoOptions}
            defaultValue={periodo}
            onChange={(s) => setPeriodo(s?.value)}
            isSearchable={false}
          />
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption={"MESATU"}
            ref={filtroPeriodoRef}
          />
          <RadioGroup
            label="Status"
            value={status}
            onChange={handleSetStatus}
            divClassName="mb-2"
          >
            <RadioItem label="Todos" value="TD" />
            <RadioItem label="Pendentes" value="PD" />
            <RadioItem label="Pagos" value="PG" />
          </RadioGroup>
        </Row>
        <Row>
          <BotaoPesquisar
            onClick={() => carregarDados()}
            loading={loading}
            padded={false}
          />
          <IncluirTituloPagarModal notifyEvent={notifyEvent} />
          <ExcluirTitulosModal
            titulosSelected={titulosSelected}
            notifyEvent={notifyEvent}
          />
          <DetalhesTituloPagarModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            selected={lastSelected}
            utilizaOrganizacao={utilizaOrganizacao}
          />
          <LancamentosTituloPagarModal
            isOpen={lancamentosOpen}
            toggle={toggleLancamentos}
            selected={lastSelected}
          />
          <AlterarTituloPagarModal
            isOpen={alterarOpen}
            toggle={toggleAlterar}
            selected={lastSelected}
            notifyEvent={notifyEvent}
          />
          <PagarTitulosModal
            titulosSelected={titulosSelected}
            notifyEvent={notifyEvent}
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Total a Pagar"
          value={
            formatNumber(totais?.qtd_tit_pagar ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_tit_pagar ?? 0, true, 2)
          }
          format={false}
        />
        <CardTotaisItem
          label="Total Pago"
          value={
            formatNumber(totais?.qtd_tit_pago ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_tit_pago ?? 0, true, 2)
          }
          format={false}
        />
        <CardTotaisItem
          label="Total Geral"
          value={
            formatNumber(totais?.qtd_geral ?? 0, true, 2, true) +
            " | " +
            formatNumber(totais?.vlr_geral ?? 0, true, 2)
          }
          format={false}
        />
      </CardTotais>
      <Card body>
        <GerencContasPagarGrid
          dados={dados}
          selected={selected}
          utilizaOrganizacao={utilizaOrganizacao}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
          mostrarDetalhes={mostrarDetalhes}
          lancamentos={lancamentos}
          alterar={alterar}
        />
      </Card>
    </PageContainer>
  );
};
