import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  LinkButton,
  PageContainer,
} from "../../../components";
import { Card, Row } from "reactstrap";
import { GerenciamentoComprasGrid } from "./components/GerenciamentoComprasGrid";
import {
  gerarSugestaoCompraRoute,
  incluirOrdemCompraRoute,
} from "../../../routes/modules/compras";
import { GerenciamentoComprasService } from "../../../services/compras/GerenciamentoComprasService";
import { EmailOrdemCompraModal } from "./components/EmailOrdemCompraModal";
import { FecharManualmenteOrdemCompraModal } from "./components/FecharManualmenteOrdemCompraModal";

export const GerenciamentoCompras = () => {
  const periodos = [
    { label: "Emissão", value: "EMI" },
    { label: "Última Atualização", value: "UTA" },
    { label: "Data de Entrega", value: "ENT" },
  ];
  const situacoes = [
    { label: "Sugestão de Compra Em Digitação", value: "SCD" },
    { label: "Ordem de Compra Em Digitação", value: "OCD" },
    { label: "Ordem de Compra Ag. Mercadoria", value: "OCA" },
  ];

  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [fornecedor, setFornecedor] = useState();
  const [periodo, setPeriodo] = useState(periodos.value);
  const [situacao, setSituacao] = useState(situacoes.value);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ordemCompraSelected, setOrdemCompraSelected] = useState(null);
  const [ordemCompraSelecStatus, setOrdemCompraSelecStatus] = useState(null);
  const [emailEnviarOrdemCompra, setEmailEnviarOrdemCompra] = useState("");
  const [emailOrdemCompraOpen, setEmailOrdemCompraOpen] = useState(false);
  const [fecharManualOrdemCompraOpen, setFecharManualOrdemCompraOpen] =
    useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setLoading(true);
    try {
      const [ok, ret] = await GerenciamentoComprasService.listar({
        data_ini: dataIni,
        data_fim: dataFim,
        id_fornecedor: fornecedor,
        situacao: situacao,
        periodo: periodo,
      });
      setDados(ok ? ret : []);
    } finally {
      setLoading(false);
    }
  };

  const toggleEmailOrdemCompra = () =>
    setEmailOrdemCompraOpen(!emailOrdemCompraOpen);

  const toggleFecharManualOrdemCompra = () =>
    setFecharManualOrdemCompraOpen(!fecharManualOrdemCompraOpen);

  const enviarEmailOrdemCompra = (idOrdemCompra, emailFornec) => {
    setOrdemCompraSelected(idOrdemCompra);
    setEmailEnviarOrdemCompra(emailFornec);
    setTimeout(() => {
      toggleEmailOrdemCompra();
    }, 1);
  };

  const onActivate = () => {
    carregarDados();
  };

  useEffect(onActivate, []);

  return (
    <PageContainer title="Gerenciamento de Compras" number="0016">
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Fornecedor"
            name="fornecedor"
            isConcatField
            concatModelName="fornecedor"
            isSearchable
            isClearable
            onChange={(s) => setFornecedor(s?.value)}
          />
          <ComboBox
            md={2}
            options={periodos}
            label="Período"
            onChange={(s) => setPeriodo(s?.value)}
            defaultValue={periodo}
          />
          <FiltroPeriodoDatas onChange={handleDate} />
        </Row>
        <Row>
          <ComboBox
            options={situacoes}
            md={3}
            label="Situação"
            onChange={(s) => setSituacao(s?.value)}
            defaultValue={situacao}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <LinkButton pathname={gerarSugestaoCompraRoute.path} color="info">
            Nova Sugestão
          </LinkButton>
          <LinkButton pathname={incluirOrdemCompraRoute.path} color="info">
            Nova Ordem de Compra
          </LinkButton>
          <FormButton
            color="success"
            onClick={toggleFecharManualOrdemCompra}
            disabled={ordemCompraSelecStatus !== "OCA"}
          >
            Fechar Ordem
          </FormButton>
          <EmailOrdemCompraModal
            isOpen={emailOrdemCompraOpen}
            toggle={toggleEmailOrdemCompra}
            ordemCompraSelected={ordemCompraSelected}
            emailEnviarOrdemCompra={emailEnviarOrdemCompra}
            setEmailEnviarOrdemCompra={setEmailEnviarOrdemCompra}
          />
          <FecharManualmenteOrdemCompraModal
            isOpen={fecharManualOrdemCompraOpen}
            toggle={toggleFecharManualOrdemCompra}
            ordemCompraSelected={ordemCompraSelected}
            notifyEvent={carregarDados}
          />
        </Row>
      </Card>
      <Card body>
        <GerenciamentoComprasGrid
          dados={dados}
          setSelected={(idRow, _, row) => {
            setOrdemCompraSelecStatus(row?.status);
            setOrdemCompraSelected(row?.id_ordem_compra);
          }}
          enviarEmailOrdemCompra={enviarEmailOrdemCompra}
        />
      </Card>
    </PageContainer>
  );
};
