import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  Divider,
  FiltroPeriodoDatas,
  FixedField,
  IconButton,
  ModalBase,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../components";
import GerencChequesClientesService from "../../../../../services/financeiro/GerencChequesClientesService";
import {
  formatNumber,
  formatValueFromAPI,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";
import { Row } from "reactstrap";
import { IoArrowDownOutline } from "react-icons/io5";

const periodoOptions = [
  { label: "Bom Para", value: "BOM" },
  { label: "Emissão", value: "EMI" },
];

const columns = [
  { dataField: "id", text: "#", align: "center", headerAlign: "center" },
  {
    dataField: "nome_banco",
    text: "Banco",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "nro_cheque",
    text: "Nº Cheque",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "bom_para",
    text: "Bom Para",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

export const IdentChequesPagamentoModal = ({
  isOpen,
  toggle,
  valorChequeRec,
  identificarCheques,
}) => {
  const [idCliente, setIdCliente] = useState(null);
  const [idBanco, setIdBanco] = useState(null);
  const [nroCheque, setNroCheque] = useState(null);
  const [periodo, setPeriodo] = useState(periodoOptions[0].value);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chequesAdd, setChequesAdd] = useState([]);

  const idsChequesAdd = chequesAdd.map((e) => e.id);
  const totalChequesAdd = sumDataField(chequesAdd, "valor");

  const limparDados = () => {
    setIdCliente(null);
    setIdBanco(null);
    setNroCheque(null);
    setPeriodo(periodoOptions[0].value);
    setDataIni(null);
    setDataFim(null);
    setDados([]);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_cliente: idCliente,
      id_banco: idBanco,
      nro_cheque: nroCheque,
      periodo: periodo,
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar_status: "A",
    };

    setLoading(true);
    const [ok, ret] = await GerencChequesClientesService.listar(params);
    if (ok) {
      setDados(ret.cheques);
    } else {
      setDados([]);
    }
    setLoading(false);
  };

  const handleAdd = (cheque) => {
    if (chequesAdd.some((e) => e.id === cheque.id)) {
      showWarning("Este cheque já foi incluído");
      return;
    }

    setChequesAdd([...chequesAdd, cheque]);
  };

  const handleDelete = (id) =>
    setChequesAdd(chequesAdd.filter((e) => e.id !== id));

  const handleSubmit = async () => {
    if (roundFloat(valorChequeRec) !== roundFloat(totalChequesAdd)) {
      showWarning(
        <>
          O Valor a Pagar em Cheques deve ser igual ao valor de Cheques
          Utilizados. <br />
          Valor a Pagar em Cheques: {formatNumber(valorChequeRec, true, 2)}
          <br />
          Valor Total dos Cheques: {formatNumber(totalChequesAdd, true, 2)}
          <br />
          Revise os valores lançados e tente novamente.
        </>
      );
      return;
    }

    identificarCheques(chequesAdd.map((e) => e.id));
    toggle();
  };

  const colunasAdd = [
    ...columns,
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <IconButton icon={IoArrowDownOutline} onClick={() => handleAdd(row)} />
      ),
    },
  ];

  const colunasDel = [
    ...columns,
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDelete(row.id)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Cheques Dispoíveis para Pagamento de Títulos"
      number="0131_5"
      onClosed={limparDados}
      onConfirm={handleSubmit}
    >
      <Row>
        <AsyncComboBox
          md={4}
          label="Banco"
          concatModelName="banco"
          defaultValue={idBanco}
          onChange={(s) => setIdBanco(s?.value)}
          hideShortcut
          defaultOptions
        />
        <TextInput
          label="Nº Cheque"
          md={2}
          value={nroCheque}
          onChange={(e, v) => setNroCheque(v)}
        />
        <AsyncComboBox
          md={6}
          label="Cliente"
          concatModelName="cliente"
          defaultValue={idCliente}
          onChange={(s) => setIdCliente(s?.value)}
        />
      </Row>
      <Row className="mb-2">
        <ComboBox
          options={periodoOptions}
          md={2}
          label="Período"
          onChange={(s) => setPeriodo(s?.value)}
          defaultValue={periodo}
        />
        <FiltroPeriodoDatas
          defaultOption={null}
          defaultStart={null}
          defaultEnd={true}
          onChange={handleDate}
        />
        <BotaoPesquisar onClick={carregarDados} loading={loading} />
      </Row>
      <Table
        data={dados.filter((e) => !idsChequesAdd.includes(e.id))}
        columns={colunasAdd}
        pageSize={5}
        showRegisterCount={false}
      />
      <Divider>Cheques a serem utilizados</Divider>
      <Table
        data={chequesAdd}
        columns={colunasDel}
        pageSize={5}
        showRegisterCount={false}
      />
      <Row>
        <FixedField
          label="Total a Pagar em Cheques"
          value={formatNumber(valorChequeRec, true, 2)}
          horizontal
        />
        <FixedField
          label="Total dos Cheques Utilizados"
          value={formatNumber(totalChequesAdd, true, 2)}
          horizontal
        />
      </Row>
    </ModalBase>
  );
};
