import { docPost } from "../../../../pdf";

export const imprimirEtqL39 = async (etiquetas) => {
  let etq = etiquetas.reduce(
    (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
    []
  );
  const res = await docPost("/etiquetas/layout_39/", etq, {
    errorMessage: "Falha ao gerar impressão de etiquetas",
  }, true);
  if (res) {
    return [true, res];
  } else {
    return [false, null];
  }
};
