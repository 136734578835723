import React from "react";
import {
  FixedField,
  MaskedInput,
  NumberInput,
  TabController,
  UnlockToEdit,
} from "../../../../../../components";
import { Row } from "reactstrap";
import { formatNumber } from "../../../../../../coreUtils";
import { TabBody } from "../../../../../../components/TabController";

const tradModBcICMS = (v) => {
  switch (v) {
    case "MVA":
      return "Margem Valor Agregado";
    case "PAV":
      return "Pauta (Valor)";
    case "PTM":
      return "Preço Tabelado Máximo";
    case "VOP":
      return "Valor da Operação";
    default:
      return "Não Especificado";
  }
};

const tradModBcICMSST = (v) => {
  switch (v) {
    case "TMS":
      return "Preço Tabelado ou Máximo Sugerido";
    case "NEG":
      return "Lista Negativa (Valor)";
    case "POS":
      return "Lista Positiva (Valor)";
    case "NEU":
      return "Lista Neutra (Valor)";
    case "PVA":
      return "Margem de Valor Agregado (%)";
    case "AUT":
      return "Pauta (Valor)";
    default:
      return "Não Definido";
  }
};

const format = (value, isPercentage) =>
  formatNumber(value ?? 0, true, 2) + (isPercentage ? "%" : "");

const rowClassName = "mb-2";
const labelClassName = "mb-0";

export const IncluirItemNFTributosCard = ({
  impostos,
  unidadeTributavel,
  setUnidadeTributavel,
  qtdTributavel,
  setQtdTributavel,
}) => {
  return (
    <TabController>
      <TabBody title="ICMS / ICMS ST">
        <Row className={rowClassName}>
          <FixedField
            labelClassName={labelClassName}
            md={4}
            label="CST - Modalidade"
            value={
              impostos.sit_trib_icms
                ? (impostos.sit_trib_icms ?? "") +
                  " - " +
                  tradModBcICMS(impostos.modal_bc_icms)
                : ""
            }
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Base de Cálculo"
            value={format(impostos.base_calc_icms)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Redutor B.C."
            value={format(impostos.perc_red_bc_icms, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. ICMS"
            value={format(impostos.perc_icms, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor ICMS"
            value={format(impostos.vlr_icms)}
          />
        </Row>
        <Row className={rowClassName}>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Mod. B.C. ICMS ST"
            value={tradModBcICMSST(impostos.modal_bc_icms_st)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Marg. Vlr. Adic."
            value={format(impostos.mva_icms_st, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Red. BC"
            value={format(impostos.perc_red_bc_icms_st, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Base de Cálculo"
            value={format(impostos.base_calc_icms_st)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. ICMS ST"
            value={format(impostos.perc_icms_st, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor ICMS ST"
            value={format(impostos.vlr_icms_st)}
          />
        </Row>
        <Row>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="ICMS Diferimento - Alíq."
            value={format(impostos.perc_difer_icms, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Vlr. Diferido"
            value={format(impostos.vlr_difer_icms)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Vlr. Dif. Operação"
            value={format(impostos.vlr_icms_oper)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="% ICMS Interestadual"
            value={format(impostos.aliq_icms_inter)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="% ICMS UF Dest."
            value={format(impostos.aliq_icms_uf_dest)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor ICMS DIFAL"
            value={format(impostos.vlr_icms_uf_dest)}
          />
        </Row>
      </TabBody>
      <TabBody title="PIS / COFINS">
        <Row className={rowClassName}>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="CST PIS"
            value={impostos.sit_trib_pis ?? ""}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Base de Cálculo"
            value={format(impostos.base_calc_pis)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. Ret."
            value={format(impostos.perc_reten_pis, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor Ret."
            value={format(impostos.vlr_reten_pis)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. PIS"
            value={format(impostos.perc_pis, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor PIS"
            value={format(impostos.vlr_pis)}
          />
        </Row>
        <Row>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="CST COFINS"
            value={impostos.sit_trib_cofins ?? ""}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Base de Cálculo"
            value={format(impostos.base_calc_cofins)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. Ret."
            value={format(impostos.perc_reten_cofins, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor Ret."
            value={format(impostos.vlr_reten_cofins)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. COFINS"
            value={format(impostos.perc_cofins, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor COFINS"
            value={format(impostos.vlr_cofins)}
          />
        </Row>
      </TabBody>
      <TabBody title="IPI / Crédito ICMS">
        <Row className={rowClassName}>
          <FixedField
            labelClassName={labelClassName}
            md={1}
            label="CST IPI"
            value={impostos.sit_trib_ipi ?? ""}
          />
          <FixedField
            labelClassName={labelClassName}
            md={1}
            label="Enquad."
            value={impostos.enquad_ipi ?? ""}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Base Cálc."
            value={format(impostos.base_calc_ipi)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. IPI"
            value={format(impostos.perc_ipi, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor IPI"
            value={format(impostos.vlr_ipi)}
          />
        </Row>
        <Row>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Cred. ICMS - Alíq."
            value={format(impostos.perc_cred_icms, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={4}
            label="Valor Crédito ICMS (Simples Nacional)"
            value={format(impostos.vlr_cred_icms)}
          />
        </Row>
      </TabBody>
      <TabBody title="Tributáveis / Repasse ST">
        <Row className={rowClassName}>
          <UnlockToEdit>
            <MaskedInput
              md={2}
              label="UN Tributável"
              value={unidadeTributavel}
              onChange={(e, v) => setUnidadeTributavel(v)}
              mask="***"
              maskChar={null}
            />
          </UnlockToEdit>
          <UnlockToEdit>
            <NumberInput
              md={3}
              label="Qtd. Tributável"
              value={qtdTributavel}
              onChange={setQtdTributavel}
            />
          </UnlockToEdit>
        </Row>
        <Row>
          <FixedField
            labelClassName={labelClassName}
            md={3}
            label="Repasse ST - Base Cálc."
            value={format(impostos.base_calc_st_ret)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq."
            value={format(impostos.perc_icms_st_ret, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor"
            value={format(impostos.vlr_icms_st_ret)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="ICMS Substituto"
            value={format(impostos.vlr_icms_substituto)}
          />
        </Row>
      </TabBody>
      <TabBody title="Outros">
        <Row className={rowClassName}>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Origem da Mercadoria"
            value={impostos.origem ?? ""}
          />
        </Row>
        <Row className={rowClassName}>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="BC Ret. IRPJ"
            value={format(impostos.bc_reten_irpj)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. Ret. IRPJ"
            value={format(impostos.perc_reten_irpj, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Vlr. Ret. IRPJ"
            value={format(impostos.vlr_reten_irpj)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Alíq. Ret. CSLL"
            value={format(impostos.perc_reten_csll, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Vlr. Ret. CSLL"
            value={format(impostos.vlr_reten_csll)}
          />
        </Row>
        <Row>
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="ICMS Efetivo - Red. BC"
            value={format(impostos.perc_red_bc_icms_efet)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Base Cálc."
            value={format(impostos.base_calc_icms_efet)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={1}
            label="Alíq."
            value={format(impostos.perc_icms_efet, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md={2}
            label="Valor ICMS Efetivo"
            value={format(impostos.vlr_icms_efet)}
          />
        </Row>
        <Row>
          <FixedField
            labelClassName={labelClassName}
            md="auto"
            label="Base Cálc. ICMS Monofásico Retido"
            value={format(impostos.qtd_bc_icms_mono_ret)}
          />
          <FixedField
            labelClassName={labelClassName}
            md="auto"
            label="Alíq. Ad Rem"
            value={format(impostos.aliq_ad_rem_icms_mono_ret, true)}
          />
          <FixedField
            labelClassName={labelClassName}
            md="auto"
            label="Valor ICMS Monofásico Retido"
            value={format(impostos.vlr_icms_mono_ret)}
          />
        </Row>
      </TabBody>
    </TabController>
  );
};
