import React from "react";
import { useState } from "react";
import {
  modalTitleTipoDocumento,
  routesBaseTipoDocumento,
} from "../TipoDocumento";
import { ModalCadastroV2 } from "../../../../../components/cadastro";
import { MODAL_ACTIONS } from "../../../../../coreUtils";
import {
  ComboBox,
  FormCheckbox,
  IntegerFormInput,
  RadioGroup,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { toastr } from "react-redux-toastr";
import { RadioItem } from "../../../../../components/RadioGroup";

const finalidadeNfeOptions = [
  { label: "Normal", value: "Normal" },
  { label: "Devolução", value: "Devolucao" },
  { label: "Ajuste ou Estorno", value: "Ajuste_Estorno" },
  { label: "Complementar", value: "Complementar" },
];

const finalidadeOperOptions = [
  { label: "Venda", value: "Venda" },
  { label: "Bonificação", value: "Bonificacao" },
  { label: "Remessa Saída", value: "Remessa_Saida" },
  { label: "Remessa Entrada", value: "Remessa_Entrada" },
  { label: "Devolução", value: "Devolucao" },
  { label: "Ajuste ou Estorno", value: "Ajuste_Estorno" },
  { label: "Complementar", value: "Complementar" },
  { label: "Transferência Entre Empresas", value: "Transfer_Entre_Empr" },
  { label: "Venda para Entrega Futura", value: "Venda_Entrega_Futura" },
];

export const CadastroTipoDocumentoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [ativo, setAtivo] = useState(true);
  const [nome, setNome] = useState("");
  const [serie, setSerie] = useState(null);
  const [exigeInfoFatura, setExigeInfoFatura] = useState(false);
  const [finalidadeNfe, setFinalidadeNfe] = useState(
    finalidadeNfeOptions[0].value
  );
  const [informaVlrPercLeiTranspFiscal, setInformaVlrPercLeiTranspFiscal] =
    useState(false);
  const [finalidadeOper, setFinalidadeOper] = useState(
    finalidadeOperOptions[0].value
  );
  const [seriesNfe, setSeriesNfe] = useState([]);
  const [tipoNf, setTipoNf] = useState("SAI");
  const [nfImportacao, setNfImportacao] = useState(false);
  const [formaTributacao, setFormaTributacao] = useState("Cfop");

  const limparDados = () => {
    setAtivo(true);
    setNome("");
    setSerie(null);
    setExigeInfoFatura(false);
    setFinalidadeNfe(finalidadeNfeOptions[0].value);
    setInformaVlrPercLeiTranspFiscal(false);
    setFinalidadeOper(finalidadeOperOptions[0].value);
    setSeriesNfe([]);
    setNfImportacao(false);
  };

  const fetchData = (data) => {
    setAtivo(data.ativo);
    setNome(data.nome);
    setSerie(data.serie);
    setExigeInfoFatura(data.exige_info_fatura);
    setInformaVlrPercLeiTranspFiscal(data.informa_vlr_perc_lei_transp_fiscal);
    setFinalidadeNfe(data.desc_finalidade_nfe);
    setFinalidadeOper(data.desc_fin_operacao);
    setTipoNf(data.tipo_nf);
    setNfImportacao(data.nf_importacao);
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(nome)) {
      toastr.warning("Atenção", "Por favor, informe o Nome");
      return false;
    }

    if ([null, undefined].includes(serie)) {
      toastr.warning("Atenção", "Por favor, informe a Série");
      return false;
    }

    const payload = {
      ativo: ativo,
      nome: nome,
      serie: serie,
      exige_info_fatura: exigeInfoFatura,
      finalidade_nfe: finalidadeNfe,
      informa_vlr_perc_lei_transp_fiscal: informaVlrPercLeiTranspFiscal,
      finalidade_oper: finalidadeOper,
      tipo_nf: tipoNf,
      nf_importacao: nfImportacao,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { id: selected, ...payload };
  };

  const buscarSeries = async () => {};

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleTipoDocumento}
      size="md"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      concatShortcut={concatShortcut}
      headerCheck={{
        value: ativo,
        toggle: () => setAtivo(!ativo),
      }}
      routesBase={routesBaseTipoDocumento}
      number="0086_1"
      selected={selected}
      notifyEvent={notifyEvent}
      onOpen={(_, params) => {
        buscarSeries();
        if (params) {
          setFormaTributacao(params.forma_tributacao);
        }
      }}
      paramsName="cad_tipo_documento"
      concatModelName="tipo_documento"
    >
      <Row className="mb-2">
        <TextInput
          md={12}
          label="Nome"
          value={nome}
          onChange={(e, v) => setNome(v)}
        />
      </Row>
      <Row className="mb-2">
        <IntegerFormInput
          md={3}
          label="Série"
          options={seriesNfe}
          defaultValue={serie}
          onChange={setSerie}
        />
        <RadioGroup
          label="Tipo de Nota Fiscal"
          value={tipoNf}
          onChange={setTipoNf}
        >
          <RadioItem label="Saída" value="SAI" />
          <RadioItem label="Entrada" value="ENT" />
        </RadioGroup>
      </Row>
      <Row className="mb-2">
        <ComboBox
          md={5}
          label="Finalidade da NFe"
          options={finalidadeNfeOptions}
          defaultValue={finalidadeNfe}
          onChange={(s) => setFinalidadeNfe(s?.value)}
          hint="Indica a Finalidade da Nota Fiscal Eletrônica"
        />
        {formaTributacao === "Regra_Tributacao" && (
          <ComboBox
            md={5}
            label="Finalidade da Operação"
            options={finalidadeOperOptions}
            defaultValue={finalidadeOper}
            onChange={(s) => setFinalidadeOper(s?.value)}
            hint={
              <>
                Define as regras de cálculo de impostos no modelo de{" "}
                <b>Regra Tributária</b>
              </>
            }
          />
        )}
      </Row>
      <Row className="mb-2">
        <FormCheckbox
          padded={false}
          label="Identificar Dados de Cobrança (Faturas) na emissão da nota fiscal eletrônica"
          checked={exigeInfoFatura}
          onChange={() => setExigeInfoFatura(!exigeInfoFatura)}
          className="fs-08rem"
        />
      </Row>
      <Row className="mb-2">
        <FormCheckbox
          padded={false}
          label="Destacar valor e percentual aproximados de tributos (lei de transparência fiscal) na NFe"
          checked={informaVlrPercLeiTranspFiscal}
          onChange={() =>
            setInformaVlrPercLeiTranspFiscal(!informaVlrPercLeiTranspFiscal)
          }
          className="fs-08rem"
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="As NF-es emitidas nesse tipo de documento, são notas de importação"
          checked={nfImportacao}
          onChange={() => setNfImportacao(!nfImportacao)}
          className="fs-08rem"
        />
      </Row>
    </ModalCadastroV2>
  );
};
