import React, { useState } from "react";
import { IntegerFormInput, ModalBase } from "../../../../components";
import { Row } from "reactstrap";
import { showWarning } from "../../../../components/AlertaModal";

export const PosicaoInicioModal = ({
  isOpen,
  toggle,
  maxLinhas,
  maxColunas,
  onConfirm,
}) => {
  const [linhaInicio, setLinhaInicio] = useState(1);
  const [colunaInicio, setColunaInicio] = useState(1);

  const limparDados = () => {
    setLinhaInicio(1);
    setColunaInicio(1);
  };

  const onConfirmInternal = () => {
    if (linhaInicio <= 0) {
      showWarning("A Linha de Início deve ser, no mínimo, 1");
      return false;
    } else if (maxLinhas && linhaInicio > maxLinhas) {
      showWarning(`A Linha de início não pode ser superior a ${maxLinhas}`);
      return;
    }

    if (colunaInicio <= 0) {
      showWarning("A Coluna de Início deve ser, no mínimo, 1");
      return;
    } else if (maxColunas && colunaInicio > maxColunas) {
      showWarning(`A Coluna de início não pode ser superior a ${maxColunas}`);
      return;
    }

    onConfirm(linhaInicio, colunaInicio);
    toggle();
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Impressão de Etiqueta"
      onClosed={limparDados}
      onConfirm={onConfirmInternal}
    >
      <Row className="mb-2">
        <IntegerFormInput
          colClassName="ml-auto"
          md={6}
          label="Linha de Início"
          defaultValue={linhaInicio}
          onChange={setLinhaInicio}
        />
        <IntegerFormInput
          md={6}
          label="Coluna de Início"
          defaultValue={colunaInicio}
          onChange={setColunaInicio}
        />
      </Row>
    </ModalBase>
  );
};
