import {
  Label,
  PrintDensity,
  PrintDensityName,
  Text,
  FontFamily,
  FontFamilyName,
  Spacing,
  Grid,
  Size,
  SizeType,
  Barcode,
  BarcodeType,
  BarcodeTypeName,
} from "jszpl";
import { wrapText, formatarValor } from "../../../../coreUtils";
import unidecode from "unidecode";

export const imprimirEtqL35 = (etiquetas) => {
  try {
    let etq = etiquetas.reduce(
      (acc, e) => [...acc, ...Array(e.qtd_etiquetas).fill(e)],
      []
    );

    const resZpl = etq.reduce((acc, row) => {
      const label = new Label();
      label.printDensity = new PrintDensity(PrintDensityName["8dpmm"]);
      label.width = 60;
      label.height = 40;

      const grid = new Grid();
      label.content.push(grid);
      grid.columns.push(new Size(1, SizeType.Relative));
      grid.rows.push(new Size(1, SizeType.Relative));
      grid.padding = new Spacing(40, 15, 0, 0);

      const nomeEmpr = new Text();
      grid.content.push(nomeEmpr);
      nomeEmpr.text = localStorage.getItem("nome_fant_empresa") ?? "";
      nomeEmpr.fontFamily = new FontFamily(FontFamilyName.B);
      nomeEmpr.characterHeight = new Size(30, SizeType.Absolute);
      nomeEmpr.characterWidth = new Size(20, SizeType.Absolute);
      nomeEmpr.grid.column = 0;
      nomeEmpr.top = new Size(0, SizeType.Absolute);

      const nomeWrap = wrapText(unidecode(row.nome), 30).split("\n");
      const nomeProd = new Text();
      grid.content.push(nomeProd);
      nomeProd.text = nomeWrap[0];
      nomeProd.fontFamily = new FontFamily(FontFamilyName.A);
      nomeProd.characterHeight = new Size(30, SizeType.Absolute);
      nomeProd.grid.column = 0;
      nomeProd.lineSpacing = 3;
      nomeProd.top = new Size(75, SizeType.Absolute);

      if (!["", "SEM GTIN"].includes(row.codigo_barras ?? "")) {
        const codBar = new Barcode();
        grid.content.push(codBar);
        codBar.data = row.codigo_barras;
        codBar.type = new BarcodeType(BarcodeTypeName.EAN13);
        codBar.left = new Size(15, SizeType.Absolute);
        codBar.height = new Size(50, SizeType.Absolute);
        codBar.grid.column = 0;
        codBar.top = new Size(210, SizeType.Absolute);
      }

      const cifrao = new Text();
      grid.content.push(cifrao);
      cifrao.text = "R$";
      cifrao.fontFamily = new FontFamily(FontFamilyName.B);
      cifrao.characterHeight = new Size(20, SizeType.Absolute);
      cifrao.grid.column = 0;
      cifrao.top = new Size(150, SizeType.Absolute);
      cifrao.left = new Size(0, SizeType.Absolute);

      const precoVenda = new Text();
      grid.content.push(precoVenda);
      precoVenda.text = formatarValor(row.preco_venda, 2, 2, true);
      precoVenda.fontFamily = new FontFamily(FontFamilyName.B);
      precoVenda.characterHeight = new Size(35, SizeType.Absolute);
      precoVenda.grid.column = 0;
      precoVenda.top = new Size(150, SizeType.Absolute);
      precoVenda.left = new Size(45, SizeType.Absolute);

      return [...acc, ...label.generateZPL().split("\n")];
    }, []);

    return [true, resZpl];
  } catch (err) {
    return [false, err.message];
  }
};
