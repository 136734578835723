import React, { useState } from "react";
import RoteirizacaoService from "../../../../../../services/cadastro/RoteirizacaoService";
import { FormButton } from "../../../../../../components";
import { ModalBase } from "../../../../../../components/ModalBase";

export const ExcluirRoteirizacaoModal = ({ selected, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async () => {
    setLoading(true);
    const payload = {
      id_roteirizacoes: selected,
    };
    const [ok] = await RoteirizacaoService.excluir(payload);
    if (ok) {
      toggle();
      notifyEvent();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        divClassName="pr-0"
        md="auto"
        color="danger"
        onClick={toggle}
        disabled={selected.length === 0}
        disabledHint="Selecione ao menos um Cliente"
      >
        Excluir da Roteirização
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Exclusão da Roteirização"
        number="0010_12"
        autoFocus
        confirmButtonText="Excluir"
        onConfirm={handleDelete}
        loadingConfirm={loading}
      >
        <p style={{ textAlign: "center" }}>
          Tem certeza de que deseja excluir {selected.length} cliente
          {selected.length !== 1 && "s"} da roteirização?
        </p>
      </ModalBase>
    </>
  );
};
