import React, { useEffect, useState, useRef } from "react";
import { Card, Row } from "reactstrap";
import { RadioGroup, RadioItem } from "../../../../../components/RadioGroup";
import RoteirizacaoService from "../../../../../services/cadastro/RoteirizacaoService";
import { RoteirizacaoGrid } from "./components/RoteirizacaoGrid";
import { AlterarOrdemModal } from "./components/AlterarOrdemModal";
import { ExcluirRoteirizacaoModal } from "./components/ExcluirRoteirizacaoModal";
import {
  AsyncComboBox,
  BotaoImprimir,
  ComboBox,
  FormButton,
  SearchInput,
  PageContainer,
} from "../../../../../components";
import {
  debounce,
  defaultDebounceTime,
  formatValueFromAPI,
} from "../../../../../coreUtils";
import { docPrintReport } from "../../../../../pdf";

const diasSemana = [
  { value: "SEG", label: "Segunda-Feira" },
  { value: "TER", label: "Terça-Feira" },
  { value: "QUA", label: "Quarta-Feira" },
  { value: "QUI", label: "Quinta-Feira" },
  { value: "SEX", label: "Sexta-Feira" },
  { value: "SAB", label: "Sábado" },
];

export const Roteirizacao = ({ location }) => {
  const idRota = location.state.id;
  const [nomeRota, setNomeRota] = useState("");
  const [dia, setDia] = useState(diasSemana[0].value);
  const [pista, setPista] = useState("");
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [idClienteAdd, setIdClienteAdd] = useState(null);
  const [diaAdd, setDiaAdd] = useState(diasSemana[0].value);
  const [loading, setLoading] = useState(true);
  const [loadingPista, setLoadingPista] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [alterarOrdemOpen, setAlterarOrdemOpen] = useState(false);
  const clienteAddRef = useRef();

  const buscarDados = async (p) => {
    const params = { pista: p };
    const [ok, ret] = await RoteirizacaoService.listarDia(idRota, dia, params);
    if (ok) {
      setClientes(ret.cliente);
      setNomeRota(ret.nome);
    } else {
      setClientes([]);
      setNomeRota("");
    }
  };

  const carregarDados = async (p) => {
    await buscarDados(pista);
    setLoading(false);
  };

  const handlePista = debounce(async (v) => {
    setPista(v);
    setLoadingPista(true);
    try {
      await buscarDados(v);
    } finally {
      setLoadingPista(false);
    }
  }, defaultDebounceTime);

  const handleSelect = (v, is) => {
    setSelected(is ? [...selected, v] : selected.filter((e) => e !== v));
    setLastSelected(is ? v : null);
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? clientes.map((e) => e.id) : []);
  };

  const toggleAlterarOrdem = () => setAlterarOrdemOpen(!alterarOrdemOpen);

  const handleAddCliente = async () => {
    const payload = {
      id_cliente: idClienteAdd,
      id_rota_cab: idRota,
      dia_semana: diaAdd,
      posicao: 0,
    };

    setLoadingAdd(true);

    const [ok] = await RoteirizacaoService.incluir(payload);
    if (ok) {
      carregarDados();
      setIdClienteAdd(null);
      clienteAddRef.current.clearValue();
    }

    setLoadingAdd(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    let data = [];
    for (let i = 0; i < diasSemana.length; i++) {
      const [ok, ret] = await RoteirizacaoService.listarDia(
        idRota,
        diasSemana[i].value
      );
      if (!ok) return false;

      data.push({
        dia: diasSemana[i].label,
        clientes: ret.cliente,
      });
    }

    await docPrintReport(
      "/relatorios/pronta_entrega/roteirizacao/",
      { nome_rota: formatValueFromAPI(nomeRota, idRota), dados: data },
      "0010_4"
    );

    setLoadingImprimir(false);
  };

  const alterarOrdem = (id) => {
    setLastSelected(id);
    setTimeout(() => {
      toggleAlterarOrdem();
    }, 1);
  };

  const onChangeDia = () => {
    carregarDados();
  };

  useEffect(onChangeDia, [dia]);

  return (
    <PageContainer
      title="Roteirização"
      number="0010_3"
      loading={loading}
      canGoBack
    >
      <Card body>
        <h4 className="mb-1">
          {nomeRota} ({idRota})
        </h4>
        <Row>
          <RadioGroup value={dia} onChange={setDia}>
            {diasSemana.map((e) => (
              <RadioItem label={e.label} value={e.value} />
            ))}
          </RadioGroup>
          <BotaoImprimir
            padded={false}
            loading={loadingImprimir}
            onClick={imprimir}
          />
        </Row>
      </Card>
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            onChange={(s) => setIdClienteAdd(s?.value ?? null)}
            isSearchable
            isClearable
            ref={clienteAddRef}
          />
          <ComboBox
            md={2}
            label="Dia da semana"
            options={diasSemana}
            onChange={(s) => setDiaAdd(s?.value)}
            defaultValue={diaAdd}
          />
          <FormButton
            md="auto"
            onClick={handleAddCliente}
            color="success"
            disabled={!diaAdd || !idClienteAdd}
            loading={loadingAdd}
            disabledHint="Informe um cliente e um dia da semana"
          >
            Adicionar
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <Row className="mb-2">
          <SearchInput
            label="Pesquisar na Lista Abaixo"
            md={5}
            loading={loadingPista}
            onChange={handlePista}
          />
          <AlterarOrdemModal
            isOpen={alterarOrdemOpen}
            toggle={toggleAlterarOrdem}
            selected={lastSelected}
            notifyEvent={carregarDados}
          />
          <ExcluirRoteirizacaoModal
            selected={selected}
            notifyEvent={carregarDados}
          />
        </Row>
        <RoteirizacaoGrid
          data={clientes}
          selected={selected}
          handleSelect={handleSelect}
          handleSelectAll={handleSelectAll}
          alterarOrdem={alterarOrdem}
        />
      </Card>
    </PageContainer>
  );
};
