import React from "react";
import {
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  naturalSortDate,
} from "../../../../coreUtils";
import { Table, TableCheck } from "../../../../components";
import { Card } from "reactstrap";

const columnsDetalhes = [
  {
    dataField: "id_produto",
    text: "Prod",
    align: "center",
  },
  {
    dataField: "nome_produto",
    text: "Nome",
    align: "left",
  },
  { dataField: "cfop", text: "CFOP", align: "center" },
  { dataField: "ncm", text: "NCM", align: "center" },
  { dataField: "unidade", text: "UN", align: "center" },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_custo_prod_antes",
    text: "Custo Antes",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_unit",
    text: "Unitário",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_tot_bruto",
    text: "Produtos",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_desconto",
    text: "Desconto",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "sit_trib_icms",
    text: "CST",
    align: "center",
  },
  {
    dataField: "vlr_icms",
    text: "ICMS",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_icms_st",
    text: "ICMS ST",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_ipi",
    text: "IPI",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "atu_preco_vda",
    text: "Atu Preços",
    align: "center",
    formatter: (c) => <TableCheck value={c} />,
  },
];

const columns = [
  {
    dataField: "numero",
    text: "Número",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "serie",
    text: "Série",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome_fornecedor",
    text: "Fornecedor",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
    sortable: true,
  },
  {
    dataField: "emissao",
    text: "Emissão",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "lancamento",
    text: "Lançamento",
    align: "center",
    sortable: true,
    sortFunc: naturalSortDate,
  },
  {
    dataField: "vlr_tot_prod",
    text: "Produtos",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_desconto",
    text: "Desconto",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_tot_icms",
    text: "ICMS",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_tot_icms_st",
    text: "ICMS ST",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_tot_ipi",
    text: "IPI",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_frete",
    text: "Frete",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const GerenciamentoNfEntradaGrid = ({ dados }) => {
  return (
    <Card body>
      <Table
        data={dados}
        columns={columns}
        canExpand
        paginated={false}
        showRegisterCount={false}
        expandedAreaBuilder={(row) => (
          <Table
            data={row.itens}
            columns={columnsDetalhes}
            paginated={false}
            pageSize={row.itens.length}
            showRegisterCount={false}
          />
        )}
      />
    </Card>
  );
};
