import moment from "moment";
import React from "react";
import { Card, Col } from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";
import {
  PageContainer,
  AsyncComboBox,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  MaskedInput,
  NumberInput,
  TextInput,
  DatePicker,
  Divider,
  FixedField,
} from "../../../../../components";
import {
  formatDateISO,
  formatValueFromAPI,
  MODAL_ACTIONS,
  roundFloat,
} from "../../../../../coreUtils";
import CentralPedidosService from "../../../../../services/pedidos/CentralPedidosService";
import UteisService from "../../../../../services/uteis/UteisService";
import { EntregarParcelasGrid } from "./components/EntregarParcelasGrid";
import { EntregarPedidosGrid } from "./components/EntregarPedidosGrid";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import { centralPedidosRoute } from "../../../../../routes/modules/pedidos";
import {
  TIPO_ITEM_BONIFICACAO,
  TIPO_ITEM_VENDA,
} from "../incluir_alterar/components/IncluirAlterarProdutoModal";
import { BoletoService } from "../../../../../services/bancario/BoletoService";
import { showWarning } from "../../../../../components/AlertaModal";
import { incluirAlterarClienteRoute } from "../../../../../routes/modules/cadastro";
import NotaFiscalConsumidorService from "../../../../../services/docs_eletron/NotaFiscalConsumidorService";

export const EntregarPedido = ({ location }) => {
  const history = useHistory();
  const idPedido = location.state.id;
  const [params, setParams] = useState({});
  const [infoPedido, setInfoPedido] = useState({});
  const [listaItens, setListaItens] = useState([]);
  const [transportadora, setTransportadora] = useState(null);
  const [qtdeVolume, setQtdeVolume] = useState(0);
  const [placa, setPlaca] = useState(null);
  const [ufVeiculo, setUfVeiculo] = useState(null);
  const [nroVolume, setNroVolume] = useState("");
  const [marca, setMarca] = useState("");
  const [especie, setEspecie] = useState("");
  const [obs, setObs] = useState("");
  const [dataEntrega, setDataEntrega] = useState(new Date());
  const [idContaBancFatBoleto, setIdContaBancFatBoleto] = useState(null);
  const [pagamento, setPagamento] = useState([]);
  const [emitirNFeAgora, setEmitirNFeAgora] = useState(false);
  const [mostrarDescontoNFe, setMostrarDescontoNFe] = useState(false);
  const [vlrEntrega, setVlrEntrega] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingParcelas, setLoadingParcelas] = useState(false);

  const infoPagamento = infoPedido?.pagamento ?? {};
  const condPag = infoPagamento?.id_cond_pag;

  const temVendaBonificacao = listaItens.some(
    (e) =>
      [TIPO_ITEM_BONIFICACAO, TIPO_ITEM_VENDA].includes(e.tipo) &&
      parseFloat(e.qt_entregar) > 0
  );

  const buscarParametros = async () => {
    const [ok, ret] = await CentralPedidosService.buscarParametros();
    if (ok) {
      setParams(ret);
    }
    return ok;
  };

  const carregarDados = async (carregarItens = true) => {
    const [ok, ret] = await CentralPedidosService.listarItensEntrega(idPedido);
    if (ok) {
      setInfoPedido(ret.pedido);
      setIdContaBancFatBoleto(
        ret.pedido?.pagamento?.id_conta_banc_fat_boleto ?? null
      );
      if (carregarItens) {
        setListaItens(ret.itens.map((e) => ({ ...e, qt_entregar: 0 })));
      }
    }
    return ok;
  };

  const verificaEntregaTotal = () =>
    !listaItens.some(
      (e) => parseFloat(e.qt_entregar) !== parseFloat(e.qt_saldo_dis)
    );

  const calcularParcelas = async () => {
    setLoadingParcelas(true);

    const aplicarDescSobreTotal = verificaEntregaTotal();

    const vlrDescSobreTotal = parseFloat(infoPedido.vlr_desc_sobre_total ?? 0);

    const vlrParcelar = aplicarDescSobreTotal
      ? vlrEntrega - vlrDescSobreTotal
      : vlrEntrega;

    const payload = {
      id_cond_pag: condPag,
      valor: vlrParcelar,
    };

    const [ok, ret] = await UteisService.calcularParcelasCondPag(payload);

    if (ok) {
      ret.forEach((item, index) => {
        item["parcela"] = index + 1;
        item.data_vcto = moment(item.data_vcto).format("DD/MM/YYYY");
        item.valor = parseFloat(item.valor);
      });
      setPagamento(ret);
    } else {
      setPagamento([]);
    }

    setLoadingParcelas(false);
  };

  const atualizarVlrEntrega = () => {
    setVlrEntrega(
      roundFloat(
        listaItens
          .filter((e) => e.tipo === "VDA")
          .reduce(
            (a, b) => a + parseFloat(b.qt_entregar) * parseFloat(b.vlr_final),
            0
          ),
        2
      )
    );
  };

  const entregarTudo = () => {
    listaItens.forEach((e) => (e.qt_entregar = parseFloat(e.qt_saldo_dis)));
    setListaItens([...listaItens]);
    atualizarVlrEntrega();
  };

  const zerarEntrega = () => {
    listaItens.forEach((e) => (e.qt_entregar = 0));
    setListaItens([...listaItens]);
    setVlrEntrega(0);
  };

  const alterarCadCliente = () => {
    const id = Math.floor(Math.random() * Date.now());
    const cadastro = window.open(incluirAlterarClienteRoute.path);

    cadastro.window.parameters = JSON.stringify({
      id: id,
      action: MODAL_ACTIONS.EDIT,
      selected: infoPedido.id_cliente,
    });

    const func = (event) => {
      if (event.origin !== window.location.origin && !event.data?.selected) {
        return;
      }

      if (event.data.id !== id) {
        return;
      }

      carregarDados(false);

      window.removeEventListener("message", func);
    };

    window.addEventListener("message", func);
  };

  const handleSubmit = async () => {
    if (loadingSubmit) return;

    if (!(dataEntrega instanceof Date)) {
      showWarning("Por favor, revise a Data de Entrega");
      return false;
    }

    setLoadingSubmit(true);
    try {
      const entregar = listaItens
        .filter((item) => item.qt_entregar > 0)
        .map((item) => ({
          id_item: item.id,
          qtd_entregar: item.qt_entregar,
        }));

      const temVendaBonifNaEntrega = listaItens.some(
        (e) =>
          [TIPO_ITEM_BONIFICACAO, TIPO_ITEM_VENDA].includes(e.tipo) &&
          parseFloat(e.qt_entregar) > 0
      );

      let payload = {
        id_pedido: idPedido,
        id_transportadora: transportadora,
        obs_entrega: obs,
        data_prev_entrega: dataEntrega.toISOString().split("T")[0],
        entregar: entregar,
        gerar_nfe_agora: emitirNFeAgora && temVendaBonifNaEntrega,
        id_conta_banc_fat_boleto: idContaBancFatBoleto,
        pagamento: pagamento.map((e) => ({
          ...e,
          data_vcto: formatDateISO(moment(e.data_vcto, "DD/MM/YYYY").toDate()),
        })),
        placa: placa,
        uf_veiculo: ufVeiculo,
        qtde_volume: qtdeVolume,
        nro_volume: nroVolume,
        marca: marca,
        especie: especie,
        mostrar_desconto_nfe: mostrarDescontoNFe && temVendaBonifNaEntrega,
      };

      const [ok, ret] = await CentralPedidosService.entregar(payload);
      if (ok) {
        let nfesEmitidas = true;
        if (ret?.nfes) {
          for (const nfe of ret.nfes) {
            if (nfe.nfe_emitida) {
              if (nfe.modelo_nf === "NFC") {
                await NotaFiscalConsumidorService.imprimir(nfe.id_nfe);
              } else {
                await NotaFiscalService.imprimir(nfe.id_nfe);
              }
            } else {
              nfesEmitidas = false;
            }
          }
        }
        if (ret?.titulos && nfesEmitidas && ret?.gerar_boletos) {
          for (const titulo of ret.titulos) {
            await BoletoService.imprimirBoleto(titulo.id_titulo);
          }
        }

        history.replace(centralPedidosRoute.path, { refresh: true });
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const iniciarTela = async () => {
    if (!(await buscarParametros())) {
      history.goBack();
      return;
    }

    if (!(await carregarDados())) {
      history.goBack();
      return;
    }
    setLoading(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const recalcularParcelas = () => {
    if (condPag) {
      calcularParcelas();
    }
  };

  useEffect(recalcularParcelas, [condPag, vlrEntrega]);

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  return (
    <PageContainer
      title={`Entrega do Pedido Nº ${idPedido}`}
      number="0034_1"
      canGoBack
      onKeyDown={onKeyDown}
      loading={loading}
    >
      <Card body>
        <Row className="mb-2">
          <FixedField
            label="Cliente"
            value={formatValueFromAPI(
              infoPedido.nome_cliente,
              infoPedido.id_cliente
            )}
          />
          <FixedField label="Fone" value={infoPedido.fone_cliente} />
          <FormButton
            md="auto"
            color="info"
            onClick={alterarCadCliente}
            padded={false}
            divStyle={{ paddingTop: "11px" }}
          >
            Cadastro Cliente
          </FormButton>
        </Row>
        <Row>
          <FixedField label="Endereço" value={infoPedido.endereco_cliente} />
          <FixedField label="Número" value={infoPedido.numero_cliente} />
          <FixedField label="Bairro" value={infoPedido.bairro_cliente} />
          <FixedField
            label="Cidade"
            value={`${infoPedido.cidade_cliente}-${infoPedido.uf_cidade_cliente}`}
          />
          <FixedField label="Emissão" value={infoPedido.data_emi} />
        </Row>
      </Card>
      <Card body>
        <Divider className="mt-1">Entrega</Divider>
        <Row className="mb-3">
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="transportadora"
            defaultOptions
            label="Transportadora"
            isSearchable
            name="transportadora"
            onChange={(s) => setTransportadora(s?.value ?? null)}
            defaultValue={transportadora}
            autoFocus
          />
          <MaskedInput
            mask="aaa-9*99"
            label="Placa"
            md={2}
            onChange={(e, v) => setPlaca(v)}
            value={placa}
            maskChar={null}
          />
          <MaskedInput
            mask="aa"
            md={2}
            label="UF Veíc."
            onChange={(e, v) => setUfVeiculo(v)}
            value={ufVeiculo}
            maskChar={null}
          />
          <IntegerFormInput
            md={2}
            label="Qtde. Volume"
            onChange={setQtdeVolume}
            defaultValue={qtdeVolume}
          />
          <TextInput
            label="Número"
            md={2}
            value={nroVolume}
            onChange={(e, v) => setNroVolume(v)}
            maxLength={12}
          />
        </Row>
        <Row className="mb-3">
          <TextInput
            label="Espécie"
            md={2}
            value={especie}
            onChange={(e, v) => setEspecie(v)}
            maxLength={12}
          />
          <TextInput
            label="Marca"
            md={2}
            value={marca}
            onChange={(e, v) => setMarca(v)}
            maxLength={12}
          />
          <DatePicker
            label="Data de Entrega"
            md="2"
            value={dataEntrega}
            onChange={(v) =>
              setDataEntrega(moment.isMoment(v) ? v.toDate() : v)
            }
          />
          <FormButton md="auto" color="light" onClick={entregarTudo}>
            Entregar Tudo
          </FormButton>
          <FormButton md="auto" color="light" onClick={zerarEntrega}>
            Zerar Entrega
          </FormButton>
        </Row>
        <EntregarPedidosGrid
          data={listaItens}
          atualizarVlrEntrega={atualizarVlrEntrega}
          identificaProdutoRevenda={params.identifica_produto_revenda}
        />
      </Card>
      <Card body>
        <Divider className="mt-1">Pagamento</Divider>
        <Row>
          <Col md={7}>
            <Row className="mb-2">
              <NumberInput
                md={3}
                label="Valor da Entrega"
                value={
                  verificaEntregaTotal()
                    ? vlrEntrega - (infoPedido.vlr_desc_sobre_total ?? 0)
                    : vlrEntrega
                }
                disabled
              />
              <NumberInput
                md={4}
                label="Desconto Sobre Total"
                hint="Considerado somente em caso de entrega total do pedido"
                value={parseFloat(infoPedido.vlr_desc_sobre_total ?? 0)}
                disabled
              />
            </Row>
            <Row className="mb-2">
              <FixedField
                label="Forma de Pagamento"
                value={infoPagamento?.moeda}
              />
              {infoPagamento?.moeda === "Boleto" && (
                <AsyncComboBox
                  md={5}
                  label="Conta Bancária"
                  concatModelName="conta_banc"
                  defaultOptions
                  onChange={(s) => setIdContaBancFatBoleto(s?.value ?? null)}
                  defaultValue={idContaBancFatBoleto}
                  disabled={
                    params.gerar_boleto_automatico === false ||
                    infoPagamento.cliente_gera_boleto_auto === false
                  }
                  disabledHint={
                    params.gerar_boleto_automatico === false
                      ? "O Sistema está parametrizado para NÃO gerar boletos " +
                        "automaticamente ao Entregar Pedidos Externos"
                      : infoPagamento.cliente_gera_boleto_auto === false
                      ? "O Cliente está marcado para NÃO gerar boletos " +
                        "automaticamente"
                      : undefined
                  }
                />
              )}
            </Row>
            {infoPagamento?.moeda === "Boleto" && (
              <Row>
                <FixedField
                  label="Condição de Pagamento"
                  value={infoPagamento?.nome_cond_pag}
                />
              </Row>
            )}
          </Col>
          {infoPagamento?.moeda === "Boleto" && (
            <EntregarParcelasGrid data={pagamento} loading={loadingParcelas} />
          )}
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            name="obs"
            type="textarea"
            label="Observação"
            md={5}
            value={obs}
            onChange={(e, v) => setObs(v)}
          />
          <FormCheckbox
            label="Mostrar Desconto na NF-e"
            value={mostrarDescontoNFe && temVendaBonificacao}
            name="mostrar_desconto_nfe"
            onChange={() => setMostrarDescontoNFe(!mostrarDescontoNFe)}
            disabled={!temVendaBonificacao}
            disabledHint="Nenhum item de venda ou bonificação identificado na entrega. Não é possível gerar NF-e desse pedido"
          />
          <FormCheckbox
            label={`Emitir Nota Fiscal agora ${
              infoPedido.usa_modelo_nfe_cli === true
                ? `(Modelo
                  ${infoPedido.modelo_nfe_cli === "NFE" ? "NF-e" : "NFC-e"})`
                : ""
            }`}
            value={emitirNFeAgora && temVendaBonificacao}
            name="emitir_nfe_agora"
            onChange={() => setEmitirNFeAgora(!emitirNFeAgora)}
            disabled={!temVendaBonificacao}
            disabledHint="Nenhum item de venda ou bonificação identificado na entrega. Não é possível gerar NF-e desse pedido"
          />
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            Confirmar
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
