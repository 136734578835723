import unidecode from "unidecode";
import {
  downloadFileFromBlob,
  formatValueFromAPI,
  limparNumero,
} from "../../../../coreUtils";

export const gerarFilizola = (produtos) => {
  try {
    for (const prod of produtos) {
      if (formatarCodBalanca(prod.cod_balanca) === "".padStart(6, "0")) {
        const msg =
          "O Produto: " +
          formatValueFromAPI(prod.nome, prod.id) +
          "Está com o código de balança zerado e não pode ser" +
          "considerado no arquivo de integração. " +
          "Por favor, revise este produto.";

        return [false, msg];
      }
    }

    const content = produtos.map((prod) => formatarProduto(prod)).join("");

    downloadFileFromBlob(
      new Blob([content], { type: "text/plain" }),
      "CADTXT.TXT"
    );
  } catch (err) {
    const msg = `Falha ao gerar exportação Filizola: ${err.message}`;
    return [false, msg];
  }

  return [true, null];
};

const formatarCodBalanca = (v) => v.padStart(6, "0");

const formatarProduto = (prod) => {
  const unidMed = prod.unidade === "KG" ? "P" : "U";

  const precoVenda = limparNumero(
    parseFloat(prod.preco_venda ?? 0).toFixed(2)
  ).padStart(7, "0");

  const nome = unidecode(prod.nome).substring(0, 22).padEnd(22, " ");

  return [
    formatarCodBalanca(prod.cod_balanca), // Código do Produto
    unidMed, // Unidade de Medida do Produto - P = Peso, U = Unidade
    nome, // Descritivo do Item – Primeira Linha
    precoVenda, // Preço de Venda
    // Prazo de Validade - 3 caracteres numéricos, em dias
    "\n",
  ].join("");
};
