import React, { useState } from "react";
import { ModalBase, TextInput } from "../../../../components";
import { Row } from "reactstrap";
import ManifEletronMDFeService from "../../../../services/faturamento/ManifEletronMDFeService";

export const VincularNfeManualmenteModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [chaveAcesso, setChaveAcesso] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setChaveAcesso("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      id_mdfe: selected,
      chave_acesso: chaveAcesso,
    };

    const [ok] = await ManifEletronMDFeService.vincularNfeManualmente(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Vincular NF-e de Remessa Manualmente"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={limparDados}
      number="0055_6"
    >
      <span>
        Informe a chave de acesso da NF-e de remessa abaixo e clique em
        Confirmar:
      </span>
      <Row>
        <TextInput value={chaveAcesso} onChange={(e, v) => setChaveAcesso(v)} />
      </Row>
    </ModalBase>
  );
};
