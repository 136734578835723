import React, { useEffect, useState } from "react";
import { Card, Row } from "reactstrap";
import XLSX from "xlsx";
import {
  AsyncComboBox,
  BotaoImprimir,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  ModalDetalhesIcone,
  PageContainer,
} from "../../../../components";
import { NfSaidaAgrupadoProdutoGrid } from "./components/NfSaidaAgrupadoProdutoGrid";
import {
  dateRangeAsTitle,
  formatDateISO,
  formatNumber,
  naturalSort,
} from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";
import { apiGetV2 } from "../../../../apiV2";
import { ModalDetalhesNotas } from "./components/ModalDetalhesNotas";
import { columns as columnsNotas } from "./components/ModalDetalhesNotas";
import { ModalMaisFiltros } from "./components/ModalMaisFiltros";

const modeloOptions = [
  { label: "Todos", value: "ALL" },
  { label: "Somente Modelo 55 (NF-e)", value: "NFE" },
  { label: "Somente Modelo 65 (NFC-e)", value: "NFC" },
];

export const NfSaidaAgrupadoProduto = () => {
  // Filtros
  const [idCliente, setIdCliente] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [idTipoDoc, setIdTipoDoc] = useState([]);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [modelo, setModelo] = useState(modeloOptions[0].value);
  const [cfops, setCfops] = useState(null);
  const [ncms, setNcms] = useState(null);

  // Dados do relatório
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [notasProdutoDetalhe, setNotasProdutoDetalhe] = useState([]);

  // Controle
  const [loading, setLoading] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [maisFiltrosOpen, setMaisFiltrosOpen] = useState(false);

  const limparDados = () => {
    setDados([]);
    setTotais({});
    setDetalhesOpen(false);
    setNotasProdutoDetalhe([]);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = () => {
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: idCliente,
      id_tipo_doc: idTipoDoc,
      modelo: modelo,
      id_produto: idProduto,
      cfops: cfops,
      ncms: ncms,
    };

    return apiGetV2(`/relatorios/nf_saida/agrupado_por_produto/`, params);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      setDados(ret.produtos);
      setTotais(ret.totais);
    }
    setLoading(false);
  };

  const imprimir = async () => {
    setLoadingImprimir(true);
    const [ok, ret] = await buscarDados();
    if (ok) {
      await docPrintReport(
        "/relatorios/nf_saida/agrupado_produto/",
        {
          dados: ret.produtos,
          totais: ret.totais,
          data_ini: formatDateISO(dataIni),
          data_fim: formatDateISO(dataFim),
        },
        "5043",
        true
      );
    }
    setLoadingImprimir(false);
  };

  useEffect(() => {
    limparDados();
  }, [dataIni, dataFim, idCliente, idTipoDoc]);

  const mostrarDetalhesProduto = (notasProduto) => {
    setNotasProdutoDetalhe(notasProduto);
    setTimeout(() => {
      setDetalhesOpen(true);
    }, 1);
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleMaisFiltros = () => setMaisFiltrosOpen(!maisFiltrosOpen);

  const columnsProdutos = [
    {
      dataField: "id_produto",
      text: "# Produto",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "descricao",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "qtd_prod",
      text: "Quantidade",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2, true),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "vlr_prod",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "det",
      text: "",
      align: "center",
      dummy: true,
      selectOnClick: false,
      formatter: (c, row) => (
        <ModalDetalhesIcone toggle={() => mostrarDetalhesProduto(row.notas)} />
      ),
    },
  ];

  const exportar = async () => {
    setLoadingExportar(true);
    setLoading(true);

    try {
      const [ok, ret] = await buscarDados();
      if (ok) {
        setDados(ret.produtos);
        setTotais(ret.totais);

        const data = [];

        data.push([...columnsProdutos, ...columnsNotas].map((col) => col.text));

        ret.produtos.forEach((row) => {
          const linhaProduto = columnsProdutos.map((col) =>
            col.formatter
              ? col.formatter(row[col.dataField])
              : row[col.dataField]
          );

          const itens = row.notas ?? [];

          itens.forEach((item) =>
            data.push([
              ...linhaProduto,
              ...columnsNotas.map((col) =>
                col.formatter
                  ? col.formatter(item[col.dataField])
                  : item[col.dataField]
              ),
            ])
          );
        });

        let ws = XLSX.utils.book_new();

        XLSX.utils.sheet_add_aoa(ws, data);
        const wb = { Sheets: { Relatório: ws }, SheetNames: ["Relatório"] };

        XLSX.writeFile(
          wb,
          `NF Saída Agrupado Por Produto - ${dateRangeAsTitle(
            dataIni,
            dataFim
          )}.xlsx`
        );
      }
      setLoading(false);
    } finally {
      setLoadingExportar(false);
      setLoading(false);
    }
  };

  return (
    <PageContainer
      title="NF Saída - Agrupado por Produto"
      number="5043"
      canGoBack
    >
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleDate} />
          <AsyncComboBox
            md={4}
            label="Cliente"
            concatModelName="cliente"
            onChange={(s) => setIdCliente(s?.value)}
            hideShortcut
          />
          <AsyncComboBox
            md={4}
            label="Produto"
            concatModelName="produto"
            onChange={(s) => setIdProduto(s?.value)}
            hideShortcut
          />
        </Row>
        <Row>
          <AsyncComboBox
            onChange={(s) => setIdTipoDoc(s?.map((e) => e.value) ?? [])}
            md={3}
            label="Tipo de Documento"
            concatModelName="tipo_documento"
            isMulti
            hideShortcut
            defaultOptions
          />
          <ComboBox
            options={modeloOptions}
            md={3}
            label="Modelo"
            onChange={(s) => setModelo(s?.value)}
            defaultValue={modelo}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoImprimir onClick={imprimir} loading={loadingImprimir} />
          <FormButton
            md="auto"
            color="indigo"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </FormButton>
          <FormButton
            md="auto"
            color="info"
            onClick={toggleMaisFiltros}
            divClassName="ml-auto"
          >
            + Filtros
          </FormButton>
        </Row>
      </Card>
      <CardTotais md={5}>
        <CardTotaisItem
          label="Quantidade"
          value={totais?.quantidade}
          checkFloat
        />
        <CardTotaisItem label="Valor" value={totais?.valor} />
      </CardTotais>
      <Card body>
        <NfSaidaAgrupadoProdutoGrid dados={dados} columns={columnsProdutos} />
      </Card>
      <ModalDetalhesNotas
        notasProduto={notasProdutoDetalhe}
        isOpen={detalhesOpen}
        toggle={toggleDetalhes}
      />
      <ModalMaisFiltros
        toggle={toggleMaisFiltros}
        isOpen={maisFiltrosOpen}
        cfops={cfops}
        ncms={ncms}
        setCfops={setCfops}
        setNcms={setNcms}
      />
    </PageContainer>
  );
};
