import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ColorField,
  ComboBox,
  FiltroPeriodoDatas,
  IntegerFormInput,
  ModalBase,
  Table,
} from "../../../../components";
import { Row } from "reactstrap";
import {
  buscarDadosEmpresaCookies,
  formatNumber,
  formatValueFromAPI,
  naturalSort,
  viewDownloadPDF,
} from "../../../../coreUtils";
import { docPost } from "../../../../pdf";
import moment from "moment";
import { showWarning } from "../../../../components/AlertaModal";
import NotaFiscalService from "../../../../services/docs_eletron/NotaFiscalService";
import { coresStatusNFe } from "../../../docs_eletron/nota_fiscal/components/NotaFiscalGrid";
import { PosicaoInicioModal } from "./PosicaoInicioModal";

const situacoes = [
  { label: "Todas as situações", value: "ALL" },
  { label: "Aguardando Emissão", value: "AGE" },
  { label: "Enviadas e Validadas SEFAZ", value: "EMI" },
  { label: "Canceladas", value: "CAN" },
  { label: "Inutilizadas", value: "INU" },
  { label: "Denegadas", value: "DEN" },
  { label: "Digitação Incompleta", value: "DIG" },
];

const columns = [
  { dataField: "numero", text: "Número", align: "center", sortable: true },
  { dataField: "serie", text: "S", align: "center", sortable: true },
  {
    dataField: "nome_cliente",
    text: "Cliente",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    sortable: true,
    fixedColWidth: true,
    colWidth: "350px",
  },
  { dataField: "emissao", text: "Emissão", align: "center", sortable: true },
  {
    dataField: "nome_transportadora",
    text: "Transportadora",
    align: "left",
    formatter: (c, row) =>
      row.id_transportadora ? formatValueFromAPI(c, row.id_transportadora) : "",
    sortable: true,
    fixedColWidth: true,
    colWidth: "310px",
  },
  {
    dataField: "qtd_volumes",
    text: "Volumes",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_tot_nf",
    text: "Valor",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "status",
    text: "Sit",
    align: "center",
    formatter: (c) => <ColorField scheme={coresStatusNFe} value={c} />,
    style: { verticalAlign: "middle", padding: 0 },
    colWidth: "35px",
    fixedColWidth: true,
    sortable: true,
    hideSortIcon: true,
  },
];

export const ImprimirLayout38Modal = ({ isOpen, toggle }) => {
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [idCliente, setIdCliente] = useState(null);
  const [numero, setNumero] = useState(null);
  const [serie, setSerie] = useState(null);
  const [idTransportadora, setIdTransportadora] = useState(null);
  const [idTipoDoc, setIdTipoDoc] = useState(null);
  const [situacao, setSituacao] = useState(situacoes[2].value);
  const [posicaoInicioOpen, setPosicaoInicioOpen] = useState(false);

  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);

  const limparDados = () => {
    setDataIni(new Date());
    setDataFim(new Date());
    setIdCliente(null);
    setNumero(null);
    setSerie(null);
    setIdTransportadora(null);
    setIdTipoDoc(null);
    setSituacao(situacoes[2].value);
    setDados([]);
    setSelected([]);
  };

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    setLoadingPesquisar(true);
    const params = {
      data_ini: dataIni,
      data_fim: dataFim,
      id_cliente: idCliente,
      id_transportadora: idTransportadora,
      id_tipo_doc: idTipoDoc,
      numero: numero,
      serie: serie,
      situacao: situacao,
    };
    const [ok, ret] = await NotaFiscalService.gerenciamento.listarImprEtiqueta(
      params
    );
    setDados(ok ? ret : []);
    setLoadingPesquisar(false);
  };

  const handleSelectAll = (is) => setSelected(is ? dados.map((e) => e.id) : []);

  const handleSetSelected = (v, is) =>
    setSelected(is ? [...selected, v] : selected.filter((e) => e !== v));

  const onConfirm = async () => {
    if (selected.length === 0) {
      showWarning("Por favor, selecione ao menos uma NFe");
      return;
    }

    togglePosicaoInicio();
  };

  const handleSubmit = async (linhaInicio, colunaInicio) => {
    setLoading(true);
    const payload = {
      layout: 38,
      dados_empresa: await buscarDadosEmpresaCookies(),
      linha_inicio: linhaInicio,
      coluna_inicio: colunaInicio,
      notas: dados
        .filter((e) => selected.includes(e.id))
        .map((e) => ({
          nome_cliente: e.nome_cliente,
          cidade_cliente: e.cidade_cliente,
          uf_cliente: e.uf_cliente,
          numero: e.numero,
          nome_transportadora: e.nome_transportadora,
          qtd_volumes: e.qtd_volumes,
        })),
    };

    const res = await docPost("/produto/etiqueta/", payload, {
      errorMessage: "Falha ao gerar impressão de etiquetas",
    });
    if (res) {
      viewDownloadPDF(
        res,
        `etq_${moment(new Date()).format("DDMMYYYY_hhmmss")}`
      );
    }

    setLoading(false);
  };

  const togglePosicaoInicio = () => setPosicaoInicioOpen(!posicaoInicioOpen);

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title="Impressão de Etiquetas"
        number="0073_3"
        onClosed={limparDados}
        onConfirm={onConfirm}
        loadingConfirm={loading}
      >
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            concatModelName="cliente"
            onChange={(s) => setIdCliente(s?.value)}
          />
          <IntegerFormInput
            md={2}
            label="Número NF-e"
            defaultValue={numero}
            onChange={setNumero}
          />
          <IntegerFormInput
            md={1}
            label="Série"
            defaultValue={serie}
            onChange={setSerie}
          />
          <AsyncComboBox
            md={4}
            label="Transportadora"
            concatModelName="transportadora"
            onChange={(s) => setIdTransportadora(s?.value)}
          />
          <AsyncComboBox
            md={4}
            label="Tipo Documento"
            concatModelName="tipo_documento"
            onChange={(s) => setIdTipoDoc(s?.value)}
          />
          <ComboBox
            options={situacoes}
            md={3}
            label="Situação"
            onChange={(s) => setSituacao(s?.value)}
            defaultValue={situacao}
          />
        </Row>
        <Row className="mb-2">
          <FiltroPeriodoDatas onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loadingPesquisar} />
        </Row>
        <Table
          data={dados}
          columns={columns}
          multiselect
          selected={selected}
          onSelect={handleSetSelected}
          onSelectAll={handleSelectAll}
          clearSelectionOnUpdate
          pageSize={15}
        />
      </ModalBase>
      <PosicaoInicioModal
        isOpen={posicaoInicioOpen}
        toggle={togglePosicaoInicio}
        maxLinhas={5}
        maxColunas={2}
        onConfirm={handleSubmit}
      />
    </>
  );
};
