import React, { useState } from "react";
import { Row } from "reactstrap";
import IntegerInput from "../../../../../../components/IntegerInput";
import { ModalBase } from "../../../../../../components/ModalBase";
import RoteirizacaoService from "../../../../../../services/cadastro/RoteirizacaoService";

export const AlterarOrdemModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [ordem, setOrdem] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (ordem && ordem !== null) {
      let data = {
        id: selected,
        nova_posicao: ordem,
      };

      setLoading(true);
      const [ok] = await RoteirizacaoService.alteraOrdem(data);
      if (ok) {
        toggle();
        notifyEvent();
        setOrdem(1);
      }
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Alterar Ordem"
      number="0010_11"
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row
        className="justify-content-center"
        style={{ marginTop: "-20px", marginBottom: "-20px" }}
      >
        <p className="mt-4">
          Selecione a nova posição do Cliente na Roteirização:
        </p>
        <IntegerInput
          md={2}
          name="ordem"
          min={1}
          onChange={(v) => setOrdem(parseInt(v))}
          autoFocus
        />
      </Row>
    </ModalBase>
  );
};
