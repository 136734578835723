import React, { useState } from "react";
import {
  ComboBox,
  DatePicker,
  Divider,
  FormButton,
  IntegerFormInput,
  MaskedInput,
  ModalBase,
  NumberInput,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";
import moment from "moment";
import { formatarValor, formatDateISO, ufs } from "../../../../../coreUtils";
import { showWarning } from "../../../../../components/AlertaModal";

const TIPO_VIA_TRANSP_OPTIONS = [
  { label: "Marítimo", value: "MARI" },
  { label: "Fluvial", value: "FLUV" },
  { label: "Lacustre", value: "LACU" },
  { label: "Aérea", value: "AERE" },
  { label: "Postal", value: "POST" },
  { label: "Ferroviária", value: "FERR" },
  { label: "Rodoviária", value: "RODO" },
  { label: "Conduto / Rede Transmissão", value: "CORE" },
  { label: "Meios Próprios", value: "MPRO" },
  { label: "Entrada / Saída Ficta", value: "ENFI" },
  { label: "Courier", value: "COUR" },
];

const TIPO_INTERMEDIARIO_OPTIONS = [
  { label: "Importação por Conta Própria", value: "PROP" },
  { label: "Importação Por Conta e Ordem", value: "ORDE" },
  { label: "Importação Por Encomenda", value: "ENCO" },
];

// Retira a opção Exterior
const UFS_DADOS_IMP = ufs.filter((e) => e.value !== "EX");

export const DadosImportacaoItemNfeModal = ({ isOpen, toggle, idItemNfe }) => {
  // Controle
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // Dados de Importação
  const [numeroDi, setNumeroDi] = useState("");
  const [dataRegDi, setDataRegDi] = useState(null);
  const [valorII, setValorII] = useState(0);
  const [localDesembaraco, setLocalDesembaraco] = useState("");
  const [ufDesembaraco, setUfDesembaraco] = useState("");
  const [dataDesembaraco, setDataDesembaraco] = useState(null);
  const [tipoViaTransp, setTipoViaTransp] = useState(
    TIPO_VIA_TRANSP_OPTIONS[0].value
  );
  const [vlrAfrmm, setVlrAfrmm] = useState(0);
  const [tipoIntermediario, setTipoIntermediario] = useState(
    TIPO_INTERMEDIARIO_OPTIONS[0].value
  );
  const [cnpjAdquirEncomend, setCnpjAdquirEncomend] = useState("");
  const [ufAdquirEncomend, setUfAdquirEncomend] = useState("");
  const [codExportador, setCodExportador] = useState("");

  // Adições
  const [nroAdicao, setNroAdicao] = useState("");
  const [codFabricanteAdicao, setCodFabricanteAdicao] = useState("");
  const [vlrDescDi, setVlrDescDi] = useState(0);
  const [nroDrawback, setNroDrawback] = useState("");
  const [adicoes, setAdicoes] = useState([]);

  const limparDados = () => {
    setNumeroDi("");
    setDataRegDi(null);
    setValorII(0);
    setLocalDesembaraco("");
    setUfDesembaraco("");
    setDataDesembaraco(null);
    setTipoViaTransp(null);
    setVlrAfrmm(0);
    setTipoIntermediario(null);
    setCnpjAdquirEncomend("");
    setUfAdquirEncomend("");
    setCodExportador("");
  };

  const columns = [
    {
      dataField: "seq_adicao",
      text: "#",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nro_adicao",
      text: "Número",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "cod_fabric_estrangeiro",
      text: "Código do Fabricante",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "vlr_desc_di",
      text: "Desconto da DI",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "nro_drawback",
      text: "Número Drawback",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      colHeaderStyle: { fontWeight: "normal" },
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <TableDelete onClick={() => removerAdicao(row.seq_adicao)} />
      ),
    },
  ];

  const adicionarAdicao = () => {
    if ([null, undefined].includes(nroAdicao)) {
      showWarning("Por favor, informe o número da adição");
      return;
    }

    if ([null, undefined].includes(nroDrawback)) {
      showWarning(
        "Por favor, revise o número do drawback. Informe 0 se não houver"
      );
      return;
    }

    setAdicoes([
      ...adicoes,
      {
        nro_adicao: nroAdicao,
        seq_adicao: adicoes.length + 1,
        cod_fabric_estrangeiro: codFabricanteAdicao,
        nro_drawback: nroDrawback,
        vlr_desc_di: vlrDescDi,
      },
    ]);

    setNroAdicao(null);
    setCodFabricanteAdicao("");
    setNroDrawback(null);
    setVlrDescDi(0);
  };

  const removerAdicao = (seqAdicaoRemover) => {
    const adicoesDepois = adicoes.filter(
      (e) => e.seq_adicao !== seqAdicaoRemover
    );

    setAdicoes(adicoesDepois.map((e, i) => ({ ...e, seq_adicao: i + 1 })));
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    try {
      if (!moment(dataRegDi).isValid()) {
        showWarning(
          "Por favor, revise a data de registro da DI",
          "ValidFront001",
          []
        );
        return;
      }
      if (!moment(dataDesembaraco).isValid()) {
        showWarning(
          "Por favor, revise a data de desembaraço aduaneiro",
          "ValidFront002",
          []
        );
        return;
      }

      const payload = {
        id_item_nfe: idItemNfe,
        numero_di: numeroDi || "",
        data_reg_di: formatDateISO(dataRegDi),
        vlr_ii: valorII || 0,
        local_desembaraco: localDesembaraco || "",
        uf_desembaraco: ufDesembaraco || "",
        data_desembaraco: formatDateISO(dataDesembaraco),
        tipo_via_transp: tipoViaTransp || "",
        vlr_afrmm: vlrAfrmm || 0,
        tipo_intermediario: tipoIntermediario || "",
        cnpj_adquir_encomend: cnpjAdquirEncomend || "",
        uf_adquir_encomend: ufAdquirEncomend || "",
        cod_exportador: codExportador || "",
        adicoes: adicoes,
      };

      const [ok] =
        await NotaFiscalService.inclusao.atualizarDadosImportacaoItem(payload);
      if (ok) {
        toggle();
      }
    } finally {
      setLoadingSubmit(false);
    }
  };

  const buscarDados = async () => {
    const [ok, ret] =
      await NotaFiscalService.inclusao.buscarDadosImportacaoItem(idItemNfe);
    if (ok) {
      setValorII(parseFloat(ret.vlr_ii));
      setNumeroDi(ret.nro_doc_imp);
      setDataRegDi(ret.data_reg_di);
      setLocalDesembaraco(ret.local_desembaraco);
      setUfDesembaraco(ret.uf_desembaraco);
      setDataDesembaraco(ret.data_desembaraco);
      if (ret.tipo_via_transp) setTipoViaTransp(ret.tipo_via_transp);
      setVlrAfrmm(parseFloat(ret.vlr_afrmm));
      if (ret.tipo_intermediario) setTipoIntermediario(ret.tipo_intermediario);
      setCnpjAdquirEncomend(ret.cnpj_adquir_encomend);
      setUfAdquirEncomend(ret.uf_adquir_encomend);
      setCodExportador(ret.cod_exportador);
      setAdicoes(ret.adicoes);
    }
  };

  return (
    <ModalBase
      title="Dados de Importação do Item"
      number="0055_13"
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      onBeforeOpen={buscarDados}
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loadingSubmit}
    >
      <Row className="mb-2">
        <TextInput
          label="Número da DI"
          value={numeroDi}
          onChange={(e, v) => setNumeroDi(v)}
          md={6}
          autoFocus
        />
        <DatePicker
          md={3}
          label="Registro da DI"
          value={dataRegDi}
          onChange={(v) => setDataRegDi(moment.isMoment(v) ? v.toDate() : v)}
          style={{ textAlign: "center" }}
        />
        <NumberInput
          label="Valor II"
          value={valorII}
          onChange={setValorII}
          disabled
          md={2}
        />
      </Row>
      <Row className="mb-2">
        <TextInput
          label="Local de Desembaraço Aduaneiro"
          value={localDesembaraco}
          onChange={(e, v) => setLocalDesembaraco(v)}
          md={6}
        />
        <ComboBox
          options={UFS_DADOS_IMP}
          md={2}
          label="UF Desembaraço"
          defaultOptions
          onChange={(selected) => setUfDesembaraco(selected?.value)}
          defaultValue={ufDesembaraco}
        />
        <DatePicker
          md={3}
          label="Data Desembaraço"
          value={dataDesembaraco}
          onChange={(v) =>
            setDataDesembaraco(moment.isMoment(v) ? v.toDate() : v)
          }
          style={{ textAlign: "center" }}
        />
      </Row>
      <Row className="mb-2">
        <ComboBox
          options={TIPO_VIA_TRANSP_OPTIONS}
          md={4}
          label="Tipo de Transporte Internacional"
          defaultOptions
          onChange={(selected) => setTipoViaTransp(selected?.value)}
          defaultValue={tipoViaTransp}
        />
        <NumberInput
          label="Valor de AFRMM"
          value={vlrAfrmm}
          onChange={setVlrAfrmm}
          md={2}
          hint="Adicional ao Frete para Renovação da Marinha Mercante"
        />
        <ComboBox
          options={TIPO_INTERMEDIARIO_OPTIONS}
          md={5}
          label="Forma de Importação Quanto a Intermediação"
          defaultOptions
          onChange={(selected) => setTipoIntermediario(selected?.value)}
          defaultValue={tipoIntermediario}
        />
      </Row>
      <Row className="mb-2">
        <MaskedInput
          mask={"99.999.999/9999-99"}
          name="cpf_cnpj"
          label={"CNPJ do Adquirente ou Encomendante"}
          md={4}
          value={cnpjAdquirEncomend}
          onChange={(e, v) => setCnpjAdquirEncomend(v)}
        />
        <ComboBox
          options={UFS_DADOS_IMP}
          md={4}
          label="UF do Adquirente ou Encomendante"
          defaultOptions
          onChange={(selected) => setUfAdquirEncomend(selected?.value)}
          defaultValue={ufAdquirEncomend}
        />
        <TextInput
          label="Código do Exportador"
          value={codExportador}
          onChange={(e, v) => setCodExportador(v)}
          md={3}
        />
      </Row>
      <Divider>Adições</Divider>
      <Row className="mb-2">
        <IntegerFormInput
          label="Número da Adição"
          defaultValue={nroAdicao}
          onChange={setNroAdicao}
          md={2}
        />
        <TextInput
          label="Código do Fabricante"
          value={codFabricanteAdicao}
          onChange={(e, v) => setCodFabricanteAdicao(v)}
          md={3}
        />
        <NumberInput
          label="Desconto da DI"
          value={vlrDescDi}
          onChange={setVlrDescDi}
          md={2}
        />
        <IntegerFormInput
          label="Número do Drawback"
          defaultValue={nroDrawback}
          onChange={setNroDrawback}
          md={3}
        />
        <FormButton onClick={adicionarAdicao} color="info">
          Incluir Adição
        </FormButton>
      </Row>
      <Table data={adicoes} columns={columns} pageSize={4} paginated={false} />
    </ModalBase>
  );
};
