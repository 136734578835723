import React, { useEffect, useRef } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
  LinkButton,
  MaskedInput,
  PageContainer,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import {
  historicoVeiculoRoute,
  incluirAlterarOSRoute,
  incluirAlterarOrcamentoOSRoute,
  manutencaoOSRoute,
} from "../../../../routes/modules/ordem_servico";
import OrdemServicoService from "../../../../services/ordem_servico/OrdemServicoService";
import { CentralOSGrid } from "./components/CentralOSGrid";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import XLSX from "xlsx";
import { apiGet } from "../../../../api";
import {
  ConcluirServOSButton,
  ConcluirServOSModal,
} from "./components/ConcluirServOSModal";
import {
  FinalizarOSButton,
  FinalizarOSModal,
} from "./components/FinalizarOSModal";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { CentralOSBoard } from "./components/CentralOSBoard";
import { CentralOSOpcoes } from "./components/CentralOSOpcoes";
import CadastroColaboradorService from "../../../../services/cadastro/CadastroColaboradorService";
import { DetalheOSModal } from "../../../financeiro/caixa_loja/components/DetalheOSModal";
import { apiGetV2 } from "../../../../apiV2";

const statusSimples = [
  {
    value: "A",
    label: "Aberto",
  },
  {
    value: "F",
    label: "Fechado",
  },
];

const statusEncerra = [
  {
    value: "A",
    label: "Aberto",
  },
  {
    value: "T",
    label: "Concluído",
  },
  {
    value: "F",
    label: "Finalizado",
  },
];

const statusMec = [
  {
    value: "X",
    label: "Todos",
  },
  {
    value: "A",
    label: "Somente Abertas",
  },
  {
    value: "F",
    label: "Somente Fechadas",
  },
];

const statusMecAvanc = [
  { value: "TNF", label: "Todas Não Finalizadas" },
  { value: "C", label: "Ag. Checklist, Em Recepção" },
  { value: "K", label: "Em Checklist/Orçamento" },
  { value: "P", label: "Pausada" },
  { value: "E", label: "Ag. Execução" },
  { value: "U", label: "Em Execução" },
  { value: "H", label: "Ag. Definição Chefe Mec." },
  { value: "T", label: "Concluídas" },
  { value: "F", label: "Finalizada" },
];

export const definirOpcoesStatus = (
  mecanica,
  utilizaStatusAdicConcServ,
  utilizaModuloAvancado
) =>
  utilizaStatusAdicConcServ
    ? utilizaModuloAvancado
      ? statusMecAvanc
      : statusEncerra
    : mecanica
    ? utilizaModuloAvancado
      ? statusMecAvanc
      : statusMec
    : statusSimples;

const filtrarPorOptions = [
  {
    value: "EMI",
    label: "Abertura",
  },
  {
    value: "FEC",
    label: "Fechamento",
  },
];

const filtrarPorOptionsEncerra = [
  {
    value: "EMI",
    label: "Abertura",
  },
  {
    value: "FEC",
    label: "Finalização",
  },
];

const filtrarPorOptionsMec = [
  {
    value: "EMI",
    label: "Abertura",
  },
  {
    value: "FEC",
    label: "Fechamento",
  },
  {
    value: "P",
    label: "Previsão de Entrega",
  },
];

export const definirOpcoesFiltrarPor = (mecanica, utilizaStatusAdicConcServ) =>
  utilizaStatusAdicConcServ
    ? filtrarPorOptionsEncerra
    : mecanica
    ? filtrarPorOptionsMec
    : filtrarPorOptions;

export const CentralOS = ({ location }) => {
  const history = useHistory();
  // Parâmetros
  const [tipoSist, setTipoSist] = useState("");
  const [utilizaStatusAdicConcServ, setUtilizaStatusAdicConcServ] =
    useState(false);
  const [identificaDataHoraAgendamento, setIdentificaDataHoraAgendamento] =
    useState(false);
  const [identificaTecnicoResp, setIdentificaTecnicoResp] = useState(false);
  const [imprFech, setImprFech] = useState(false);
  const [modoExib, setModoExib] = useState("P");
  const [utilizaModuloAvancado, setUtilizaModuloAvancado] = useState(false);
  const [
    perfilPermiteFinalizarOrdemServico,
    setPerfilPermiteFinalizarOrdemServico,
  ] = useState(true);
  // Controle
  const [concluirServOpen, setConcluirServOpen] = useState(false);
  const [finalizarOpen, setFinalizarOpen] = useState(false);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  // Filtros
  const [nroOS, setNroOS] = useState(null);
  const [placa, setPlaca] = useState("");
  const [idCliente, setIdCliente] = useState(null);
  const [status, setStatus] = useState(null);
  const [idResponsavel, setIdResponsavel] = useState(null);
  const [idVeiculo, setIdVeiculo] = useState(null);
  const [filtrarPor, setFiltrarPor] = useState("EMI");
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [dados, setDados] = useState([]);
  const [idSelecionado, setIdSelecionado] = useState(null);
  const [nomeCliSelected, setNomeCliSelected] = useState(null);
  const [placaSelected, setPlacaSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingEnviarExec, setLoadingEnviarExec] = useState(false);
  const [statusOS, setStatusOS] = useState(null);

  const filtroPeriodoRef = useRef();

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    // Não carrega os dados caso haja uma consulta em andamento
    if (loading) return;

    // Só carrega os dados caso haja o status base já tenha sido definido
    if (status === null) return;

    setLoading(true);

    let params = {
      id_cliente: idCliente,
      id_os: nroOS,
      id_tecnico: idResponsavel,
      id_veiculo: idVeiculo,
      placa: placa,
      data_ini: dataIni,
      data_fim: dataFim,
      periodo: filtrarPor,
      ...(modoExib === "P" && {
        status: status === "X" ? null : status,
      }),
    };

    const listFunc =
      modoExib === "P"
        ? OrdemServicoService.listar
        : OrdemServicoService.listarVisaoKanban;

    const ret = await listFunc(params);
    setDados(ret ?? []);
    setLoading(false);
  };

  const emailOS = async () => {
    setLoadingEmail(true);
    await OrdemServicoService.enviarEmail(idSelecionado);
    setLoadingEmail(false);
  };

  const imprimirOS = async (id) => {
    await OrdemServicoService.imprimirFechamento(id);
  };

  const exportarOS = async (id) => {
    const [ok, ret] = await apiGetV2(`/ordem_servico/impressao/buscar/${id}/`);
    const params = ret.params;
    if (params.layout_impr_emis === 10) {
      try {
        if (ok) {
          const os = ret.os;
          const data = [];
          const tempo = () =>
            data.push([
              "",
              "_/_/_",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
              ":",
            ]);
          data.push([`Ordem Serviço N°: `, os.id]);
          data.push([`Número da O.S.I: `]);
          data.push(["Abertura: ", os.data_emissao]);
          data.push(["Cliente: ", os.nome_cliente]);
          data.push(["Vendedor: "]);

          data.push(["Descrição dos Produtos: ", os.defeito]);

          data.push(["Solicitante: ", os.contato_serv]);
          data.push(["Serviço a Executar: ", os.observ]);
          data.push([""]);
          data.push(["Tempos: "]);
          data.push([
            "Projeto: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          data.push([""]);
          data.push([
            "Desmontagem: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          data.push([""]);
          data.push([
            "Torno: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          data.push(["Materiais:"]);
          data.push([""]);
          data.push([
            "Fresa: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          data.push(["Materiais:"]);
          data.push([""]);
          data.push([
            "Corte e Dobra: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          data.push(["Materiais:"]);
          data.push([""]);
          data.push([
            "Solda: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          data.push(["Materiais:"]);
          data.push([""]);
          data.push([
            "Montagem: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          data.push([""]);
          data.push([
            "Elétrica: ",
            "Entrega",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "Início",
            "Fim",
            "TOTAL",
          ]);
          tempo();
          const ws = XLSX.utils.aoa_to_sheet(data);

          // Adiciona as mesclagens das células
          ws["!merges"] = [
            { s: { r: 3, c: 1 }, e: { r: 3, c: 14 } }, // Mescla da linha de Cliente, de B2 até C2
            { s: { r: 4, c: 1 }, e: { r: 4, c: 14 } }, // Mescla da linha de Vendedor, de B3 até C3
            { s: { r: 6, c: 1 }, e: { r: 6, c: 14 } }, // Mescla da linha de Descrição, de B5 até C5
            { s: { r: 8, c: 0 }, e: { r: 8, c: 14 } },
            { s: { r: 12, c: 0 }, e: { r: 12, c: 14 } },
            { s: { r: 15, c: 0 }, e: { r: 15, c: 14 } },
            { s: { r: 18, c: 0 }, e: { r: 18, c: 14 } },
            { s: { r: 22, c: 0 }, e: { r: 22, c: 14 } },
          ];

          const wb = { Sheets: { OS: ws }, SheetNames: ["OS"] };

          let nomeArquivo = `Abertura de OS.xlsx`;

          XLSX.writeFile(wb, nomeArquivo);
        }
      } finally {
        setLoading(false);
      }
    } else {
      toastr.warning("Esta opção não está disponível para este layout");
    }
  };

  const carregarParametros = async () => {
    const ret = await apiGet("/tela/central_os/");
    if (ret) {
      setUtilizaStatusAdicConcServ(ret.utiliza_status_adic_conc_serv);
      setTipoSist(ret.tipo_sist);
      setIdentificaDataHoraAgendamento(
        ret.abrir_os_identifica_data_hora_agendamento
      );
      setIdentificaTecnicoResp(ret.abrir_os_identifica_tecnico_resp);
      setImprFech(ret.impr_fech_os);
      setModoExib(ret.visao_pad);
      setUtilizaModuloAvancado(ret.utiliza_modulo_avancado);
      setPerfilPermiteFinalizarOrdemServico(
        ret.perfil_permite_finalizar_ordem_servico
      );
      if (ret.utiliza_modulo_avancado && ret.tipo_sist === "MECANICA") {
        setStatus("TNF");
      } else {
        setStatus("A");
      }
    }
  };

  const notifyEvent = () => {
    carregarDados();
    setIdSelecionado(null);
    setNomeCliSelected(null);
    setPlacaSelected(null);
    setStatus("A");
  };

  const notifyFinalizar = async () => {
    if (["S", "P", "V"].includes(imprFech)) {
      await OrdemServicoService.imprimirFechamento(idSelecionado);
    }
    carregarDados();
    setIdSelecionado(null);
    setNomeCliSelected(null);
    setPlacaSelected(null);
  };

  const onActivate = () => {
    const iniciarTela = async () => {
      await carregarParametros();
      if (location.state?.refresh) {
        await carregarDados();
      }
    };
    iniciarTela();
  };

  useEffect(onActivate, []);

  const mecanica = tipoSist === "MECANICA";

  const handleSetStatus = (v) => {
    setStatus(v);
    setIdSelecionado(null);
    setIdVeiculo(null);
    setPlacaSelected(null);
    setStatusOS(null);
    setNomeCliSelected(null);
    if (filtroPeriodoRef.current) {
      if (["F", "X", null, undefined].includes(v)) {
        if (!(dataIni instanceof Date) || !(dataFim instanceof Date)) {
          filtroPeriodoRef.current.selecionarPeriodo("7D");
        }
      } else if (["A", "T"].includes(v)) {
        // Limpa filtro de data
        filtroPeriodoRef.current.selecionarPeriodo(null);
      }
    }
  };

  const onChangeStatus = () => {
    if (
      status !== "F" ||
      (dataIni instanceof Date && dataFim instanceof Date)
    ) {
      carregarDados();
    }
  };

  useEffect(onChangeStatus, [status]);

  const onChangeIdSelecionado = () => {
    if (modoExib === "P") {
      const s = dados?.find((e) => e.id === idSelecionado);
      setNomeCliSelected(s ? s.nome_cliente : "");
      setStatusOS(s ? s.status : "");
    }
  };

  useEffect(onChangeIdSelecionado, [idSelecionado]);

  const manutencaoOS = () => {
    if ([0, null, undefined].includes(idSelecionado)) {
      toastr.warning("Atenção", "Por favor, selecione uma O.S.");
      return false;
    }
    history.push(manutencaoOSRoute.path, { selected: idSelecionado });
  };

  const abrirOS = () => {
    history.push(incluirAlterarOSRoute.path, { action: MODAL_ACTIONS.ADD });
  };

  const enviarExec = async (id) => {
    setLoadingEnviarExec(true);
    if (await OrdemServicoService.enviarParaExecucao(id)) {
      carregarDados();
    }
    setLoadingEnviarExec(false);
  };

  const handleSetModoExib = (v) => {
    setModoExib(v);
    CadastroColaboradorService.preferencias.visaoPadraoCentralOs(v);
  };

  const reabrirConclusao = async (idOs) => {
    if (await OrdemServicoService.reabrirOsConcluida(idOs)) {
      carregarDados();
    }
  };

  const toggleConcluirServ = () => setConcluirServOpen(!concluirServOpen);

  const toggleFinalizar = () => setFinalizarOpen(!finalizarOpen);

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const mostrarDetalhes = (id) => {
    setIdSelecionado(id);
    toggleDetalhes();
  };

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F8") manutencaoOS();
      if (e.key === "F4") abrirOS();
    }
  };

  return (
    <PageContainer
      title="Central de Ordens de Serviço"
      number="0006"
      onKeyDown={onKeyDown}
      topOptions={<CentralOSOpcoes setModoExib={handleSetModoExib} />}
      canGoBack
    >
      <Card body>
        <Row>
          <IntegerFormInput
            md={2}
            label="Nº O.S."
            value={nroOS}
            onChange={setNroOS}
          />
          {mecanica && (
            <MaskedInput
              md={2}
              mask="aaa-9*99"
              label="Placa"
              value={placa}
              onChange={(e, v) => setPlaca(v)}
              maskChar={null}
            />
          )}
          <AsyncComboBox
            style={{ zIndex: 2 }}
            md={4}
            label="Cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
          />
          {modoExib === "P" && (
            <ComboBox
              md={2}
              label="Status"
              options={definirOpcoesStatus(
                mecanica,
                utilizaStatusAdicConcServ,
                utilizaModuloAvancado
              )}
              isSearchable={false}
              onChange={(s) => handleSetStatus(s?.value)}
              defaultValue={status}
            />
          )}
          {identificaTecnicoResp && (
            <AsyncComboBox
              style={{ zIndex: 2 }}
              md={3}
              label="Responsável"
              isConcatField
              concatModelName="tecnico"
              isSearchable
              isClearable
              onChange={(s) => setIdResponsavel(s?.value)}
            />
          )}
          {mecanica && (
            <AsyncComboBox
              style={{ zIndex: 2 }}
              md={2}
              label="Veíuclo"
              isConcatField
              concatModelName="veiculo"
              isSearchable
              isClearable
              onChange={(s) => setIdVeiculo(s?.value)}
            />
          )}
        </Row>
        <Row>
          <ComboBox
            md={2}
            label="Filtrar Por"
            options={definirOpcoesFiltrarPor(
              mecanica,
              utilizaStatusAdicConcServ
            )}
            onChange={(s) => setFiltrarPor(s?.value)}
            defaultValue={filtrarPor}
          />
          <FiltroPeriodoDatas
            defaultOption={null}
            defaultStart={null}
            defaultEnd={null}
            onChange={handleDate}
            ref={filtroPeriodoRef}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
        <Row>
          <FormButton md="auto" color="magenta" onClick={abrirOS}>
            F4 - Abrir O.S.
          </FormButton>
          {modoExib === "P" && (
            <>
              <FormButton
                md="auto"
                color="warning"
                onClick={manutencaoOS}
                disabled={
                  [0, null, undefined].includes(idSelecionado) ||
                  (dados instanceof Array ? dados : []).find(
                    (e) => e.id === idSelecionado
                  )?.status === "F"
                }
                disabledHint={
                  ![0, null, undefined].includes(idSelecionado)
                    ? "A O.S. selecionada já se encontra fechada"
                    : undefined
                }
              >
                F8 - Manutenção
              </FormButton>
              {utilizaStatusAdicConcServ && (
                <ConcluirServOSButton
                  onClick={toggleConcluirServ}
                  disabled={
                    (utilizaModuloAvancado
                      ? statusOS !== "H"
                      : statusOS !== "A") ||
                    [0, null, undefined].includes(idSelecionado)
                  }
                />
              )}
              {utilizaModuloAvancado && (
                <FormButton
                  md="auto"
                  color="indigo"
                  loading={loadingEnviarExec}
                  disabled={
                    [0, null, undefined].includes(idSelecionado) ||
                    ["F", "U"].includes(statusOS)
                  }
                  disabledHint={
                    ![0, null, undefined].includes(idSelecionado)
                      ? "A O.S. selecionada já se encontra Fechada ou Em Execução"
                      : undefined
                  }
                  onClick={() => enviarExec(idSelecionado)}
                >
                  Enviar para Execução
                </FormButton>
              )}
              <FinalizarOSButton
                onClick={toggleFinalizar}
                disabled={
                  statusOS === "F" ||
                  (utilizaStatusAdicConcServ
                    ? statusOS !== "T" ||
                      [0, null, undefined].includes(idSelecionado)
                    : [0, null, undefined].includes(idSelecionado))
                }
                padded
              />
              <FormButton
                onClick={emailOS}
                md="auto"
                color="secondary"
                disabled={[0, null, undefined].includes(idSelecionado)}
                loading={loadingEmail}
              >
                Enviar E-Mail
              </FormButton>
              {mecanica && (
                <LinkButton
                  md="auto"
                  color="primary"
                  pathname={historicoVeiculoRoute.path}
                  state={{ placa: placaSelected }}
                  disabled={["", null, undefined].includes(placaSelected)}
                >
                  Histórico do Veículo
                </LinkButton>
              )}
            </>
          )}
          {utilizaStatusAdicConcServ && (
            <ConcluirServOSModal
              isOpen={concluirServOpen}
              toggle={toggleConcluirServ}
              idOs={idSelecionado}
              notifyEvent={notifyEvent}
            />
          )}
          <FinalizarOSModal
            isOpen={finalizarOpen}
            toggle={toggleFinalizar}
            selected={idSelecionado}
            nomeClienteOS={nomeCliSelected}
            notifyEvent={notifyFinalizar}
          />
          <DetalheOSModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            idOS={idSelecionado}
          />
          <LinkButton
            pathname={incluirAlterarOrcamentoOSRoute.path}
            divClassName="ml-auto"
            md="auto"
            color="info"
          >
            Orçamento Rápido
          </LinkButton>
        </Row>
      </Card>
      <Card body>
        {modoExib === "P" ? (
          <CentralOSGrid
            dados={dados}
            setSelected={(s, _, row) => {
              setIdSelecionado(s ?? null);
              setNomeCliSelected(row?.nome_cliente ?? "");
              setStatusOS(row?.status ?? "");
              setPlacaSelected(row?.placa ?? "");
            }}
            mecanica={mecanica}
            utilizaStatusAdicConcServ={utilizaStatusAdicConcServ}
            identificaDataHoraAgendamento={identificaDataHoraAgendamento}
            identificaTecnicoResp={identificaTecnicoResp}
            utilizaModuloAvancado={utilizaModuloAvancado}
            imprimirOS={imprimirOS}
            exportarOS={exportarOS}
            reabrirConclusao={reabrirConclusao}
            mostrarDetalhes={mostrarDetalhes}
          />
        ) : (
          <CentralOSBoard
            dados={dados}
            mecanica={mecanica}
            utilizaStatusAdicConcServ={utilizaStatusAdicConcServ}
            identificaDataHoraAgendamento={identificaDataHoraAgendamento}
            concluirOS={(id) => {
              setIdSelecionado(id);
              toggleConcluirServ();
            }}
            finalizarOS={(id, nomeCliente) => {
              setIdSelecionado(id);
              setNomeCliSelected(nomeCliente);
              toggleFinalizar();
            }}
            imprimirOS={imprimirOS}
            exportarOS={exportarOS}
            emailOS={emailOS}
            utilizaModuloAvancado={utilizaModuloAvancado}
            enviarExec={enviarExec}
            mostrarDetalhes={mostrarDetalhes}
            reabrirConclusao={reabrirConclusao}
            perfilPermiteFinalizarOrdemServico={
              perfilPermiteFinalizarOrdemServico
            }
          />
        )}
      </Card>
    </PageContainer>
  );
};
