import React, { useState } from "react";
import NotaFiscalService from "../../../../services/docs_eletron/NotaFiscalService";
import { toastr } from "react-redux-toastr";
import { Row } from "reactstrap";
import { AsyncComboBox, FixedField, ModalBase } from "../../../../components";
import { dateFromLocaleString } from "../../../../coreUtils";

export const InutilizarNFeModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [numero, setNumero] = useState("");
  const [serie, setSerie] = useState("");
  const [ano, setAno] = useState("");
  const [idMotivo, setIdMotivo] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setNumero("");
    setSerie("");
    setAno("");
    setIdMotivo(null);
  };

  const carregarDados = async () => {
    const [ok, ret] = await NotaFiscalService.inclusao.buscarCab(selected);
    if (ok) {
      setNumero(ret.numero);
      setSerie(ret.serie);
      setAno(dateFromLocaleString(ret.emissao)?.getFullYear());
    }
  };

  const handleSubmit = async () => {
    if ([0, null, undefined].includes(idMotivo)) {
      toastr.warning("Atenção", "Por favor, informe o Motivo.");
      return false;
    }

    setLoading(true);
    const payload = {
      id_nfe: selected,
      id_motivo: idMotivo,
    };
    const [ok] = await NotaFiscalService.gerenciamento.inutilizar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Inutilização de Nota Fiscal"
      number="0055_8"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <FixedField label="Número" value={numero} />
        <FixedField label="Série" value={serie} />
        <FixedField label="Ano" value={ano} />
      </Row>
      <Row>
        <AsyncComboBox
          md={12}
          isConcatField
          concatModelName="motivo"
          defaultOptions
          label="Motivo"
          isSearchable
          onChange={(s) => setIdMotivo(s?.value)}
          defaultValue={idMotivo}
        />
      </Row>
    </ModalBase>
  );
};
