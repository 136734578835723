import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { Card, Col, Label, Row } from "reactstrap";
import {
  BotaoPesquisar,
  FormCheckbox,
  SearchInput,
  TextInput,
  PageContainer,
  BotaoConfirmarVoltar,
  FormButton,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  debounce,
  defaultDebounceTime,
} from "../../../../coreUtils";
import { ProdutosGrid } from "./components/ProdutosGrid";
import CadastroProdutosService from "../../../../services/cadastro/CadastroProdutosService";
import { ExportarProdutosModal } from "./components/ExportarProdutosModal";
import { ProdutosSimilaresGrid } from "./components/ProdutosSimilaresGrid";
import { incluirAlterarProdutoRoute } from "../../../../routes/modules/cadastro";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import {
  setPista,
  setMostrarInativos,
  setSomenteEmEstoque,
} from "./store/listagemProdutosSlice";
import { useHistory } from "react-router-dom";
import { BotaoIncluir, ModalExcluirV2 } from "../../../../components/cadastro";
import { AplicacoesGrid } from "./components/AplicacoesGrid";
import { LogProdutoModal } from "./components/LogProdutoModal";
import { ComprasVendasProdutoModal } from "../../../compras/entrada_nf_xml/produtos/components/ComprasVendasProdutoModal";

const ProdutoContainer = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.listagemProdutos);
  const history = useHistory();
  const [produtos, setProdutos] = useState([]);
  const [selected, setSelected] = useState(null);
  const [similares, setSimilares] = useState([]);
  const [aplicacoes, setAplicacoes] = useState([]);
  const [selectedObserv, setSelectedObserv] = useState("");
  const [selectedRefAux1, setSelectedRefAux1] = useState("");
  const [selectedRefAux2, setSelectedRefAux2] = useState("");
  const [selectedRefAux3, setSelectedRefAux3] = useState("");
  const [selectedRefAux4, setSelectedRefAux4] = useState("");
  const [selectedLocal, setSelectedLocal] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingPista, setLoadingPista] = useState(false);
  const [tipoSist, setTipoSist] = useState(null);
  const [usaCorProd, setUsaCorProd] = useState(null);
  const [usaTamanhoProd, setUsaTamanhoProd] = useState(null);
  const [usaRegraTributaria, setUsaRegraTributaria] = useState(false);
  const [usaPrecoAtacado, setUsaPrecoAtacado] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [comprasVendasOpen, setComprasVendasOpen] = useState(false);

  const sistAutomotivo = useMemo(() => tipoSist === "MECANICA", [tipoSist]);

  const buscarDados = async (pista) => {
    const params = {
      pista: pista,
      mostrar_inativos: store.mostrarInativos,
      somente_em_estoque: store.somenteEmEstoque,
    };
    const getFunc = sistAutomotivo
      ? CadastroProdutosService.listarAutomotivo
      : CadastroProdutosService.listar;

    const [ok, ret] = await getFunc(params);

    setProdutos(ok ? ret : []);
    if (sistAutomotivo) {
      setSimilares([]);
    }
  };

  const handlePista = useCallback(
    debounce(async (v) => {
      dispatch(setPista(v));
      setLoadingPista(true);
      await buscarDados(v);
      setLoadingPista(false);
    }, defaultDebounceTime),
    [tipoSist]
  );

  const carregarDados = async () => {
    setLoading(true);
    setSelected(null);
    await buscarDados(store.pista);
    setLoading(false);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await CadastroProdutosService.buscarParametros();

    if (ok) {
      setTipoSist(ret?.tipo_sist ?? "PADRAO");
      setUsaCorProd(ret?.utiliza_cor_prod ?? false);
      setUsaTamanhoProd(ret?.utiliza_tamanho_prod ?? false);
      setUsaRegraTributaria(ret?.usa_regra_tributaria ?? false);
      setUsaPrecoAtacado(ret?.usa_preco_atacado ?? false);
    }
    return ok;
  };

  const notifyDelete = () => {
    setSelected(null);
    carregarDados();
  };

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const toggleLogs = () => setLogsOpen(!logsOpen);

  const incluir = () => {
    history.push(incluirAlterarProdutoRoute.path, {
      action: MODAL_ACTIONS.ADD,
    });
  };

  const alterar = (id) => {
    history.push(incluirAlterarProdutoRoute.path, {
      action: MODAL_ACTIONS.EDIT,
      selected: id,
    });
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  const mostrarLogs = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleLogs();
    }, 1);
  };

  useEffect(() => {
    carregarParametros();
  }, []);

  useEffect(() => {
    if (tipoSist) {
      carregarDados();
    }
  }, [tipoSist]);

  const handleSelectTable = (value, checked, row) => {
    if (checked) {
      setSelected(value);
      setSimilares(row?.similares ?? []);
      setAplicacoes(row?.aplicacoes ?? []);
      setSelectedObserv(row?.observ);
      setSelectedRefAux1(row?.refer_aux_1);
      setSelectedRefAux2(row?.refer_aux_2);
      setSelectedRefAux3(row?.refer_aux_3);
      setSelectedRefAux4(row?.refer_aux_4);
      setSelectedLocal(row?.local);
    } else {
      setSelected(null);
      setSimilares([]);
      setAplicacoes([]);
      setSelectedObserv("");
      setSelectedRefAux1("");
      setSelectedRefAux2("");
      setSelectedRefAux3("");
      setSelectedRefAux4("");
      setSelectedLocal("");
    }
  };

  return (
    <>
      <Card body>
        <Row>
          <SearchInput
            md={5}
            value={store.pista}
            onChange={handlePista}
            loading={loadingPista}
            placeholder="Pesquisa ID, Referência, Código de Barras, Nome, Fabricante ou Grupo"
            hideLabel
          />
          <FormCheckbox
            label="Mostrar Inativos"
            checked={store.mostrarInativos}
            onChange={() =>
              dispatch(setMostrarInativos(!store.mostrarInativos))
            }
            padded={false}
          />
          <FormCheckbox
            label="Com Estoque"
            checked={store.somenteEmEstoque}
            onChange={() =>
              dispatch(setSomenteEmEstoque(!store.somenteEmEstoque))
            }
            padded={false}
          />
          <BotaoPesquisar
            onClick={carregarDados}
            loading={loading}
            padded={false}
          />
          <BotaoIncluir toggle={incluir} padded={false} />
          <ModalExcluirV2
            title="Produto"
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            number="0029_2"
            selected={selected}
            routeBase={CadastroProdutosService.urlBase}
            notifyEvent={notifyDelete}
          />
          <ExportarProdutosModal usaRegraTributaria={usaRegraTributaria} />
          <LogProdutoModal
            isOpen={logsOpen}
            toggle={toggleLogs}
            selected={selected}
          />
          <BotaoConfirmarVoltar
            selected={selected}
            carregarDados={carregarDados}
          />
        </Row>
      </Card>
      <ProdutosGrid
        data={produtos}
        setSelected={handleSelectTable}
        tipoSist={tipoSist}
        alterar={alterar}
        excluir={excluir}
        sistAutomotivo={sistAutomotivo}
        utilizaCorProd={usaCorProd}
        utilizaTamanhoProd={usaTamanhoProd}
        usaPrecoAtacado={usaPrecoAtacado}
        mostrarLogs={mostrarLogs}
      />
      <Card body>
        {sistAutomotivo && (
          <>
            <ProdutosSimilaresGrid data={similares} />
            <Col className="mt-2">
              <Row>
                <Col
                  md="auto"
                  className="pl-0 pr-0"
                  style={{ paddingTop: "5px" }}
                >
                  <Label>Referências</Label>
                </Col>
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selectedRefAux1}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selectedRefAux2}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-1"
                  disabled
                  className="text-center"
                  value={selectedRefAux3}
                />
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-0"
                  disabled
                  className="text-center"
                  value={selectedRefAux4}
                />
                <Col
                  md="auto"
                  className="pl-0 ml-auto pr-0"
                  style={{ paddingTop: "5px" }}
                >
                  <Label>Local</Label>
                </Col>
                <TextInput
                  md={2}
                  type="text"
                  inline
                  hiddenLabel
                  colClassName="pl-1 pr-0"
                  disabled
                  className="text-center"
                  value={selectedLocal}
                />
              </Row>
            </Col>
          </>
        )}
        <Row>
          <TextInput
            md={7}
            type="textarea"
            value={selectedObserv}
            rows={5}
            hiddenLabel
            disabled
          />
          <Col md={2} className="no-gutters">
            <FormButton
              block={false}
              padded={false}
              md={null}
              color="primary"
              onClick={() => setComprasVendasOpen(!comprasVendasOpen)}
              disabled={[0, null, undefined].includes(selected)}
              divClassName="mb-2"
            >
              Compras & Vendas
            </FormButton>
            <ComprasVendasProdutoModal
              isOpen={comprasVendasOpen}
              toggle={() => setComprasVendasOpen(!comprasVendasOpen)}
              selected={selected}
            />
            {!sistAutomotivo && (
              <TextInput
                label="Local"
                md={12}
                type="text"
                colClassName="pr-0"
                disabled
                className="text-center"
                value={selectedLocal}
              />
            )}
          </Col>
          {sistAutomotivo && (
            <Col md={5}>
              <Label>Aplicações</Label>
              <AplicacoesGrid aplicacoes={aplicacoes} />
            </Col>
          )}
        </Row>
      </Card>
    </>
  );
};

export const Produto = () => {
  return (
    <PageContainer title="Cadastro de Produtos" number="0029" canGoBack>
      <Provider store={store}>
        <ProdutoContainer />
      </Provider>
    </PageContainer>
  );
};
