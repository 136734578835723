import React from "react";
import { MoreDropdown, Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";

export const OrdemCompraGrid = ({
  dados,
  excluirItem,
  recalcularValoresGrade,
  atualizarDadosItem,
}) => {
  const columns = [
    {
      dataField: "id_produto",
      text: "Código",
      align: "center",
    },
    {
      dataField: "nome_produto",
      text: "Descrição",
      align: "left",
    },
    {
      dataField: "unidade_produto",
      text: "UN",
      align: "center",
    },
    {
      dataField: "quantidade",
      text: "Qtd.",
      align: "center",
      formatter: (c, row) =>
        formatNumber(c, true, row.qtd_casas_decimais_qtd, true),
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: (row) => row.qtd_casas_decimais_qtd,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_item_compra",
      text: "Custo do Produto",
      align: "right",
      formatter: (c, row) => formatNumber(c, true, row.qtd_casas_decimais_vlrs),
      editable: true,
      onChange: recalcularValoresGrade,
      onBlur: atualizarDadosItem,
      editorType: "number",
      decimalPlaces: (row) => row.qtd_casas_decimais_vlrs,
      colWidth: "75px",
      alwaysShowEditor: true,
    },
    {
      dataField: "vlr_total_compra",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      colWidth: "75px",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => excluirItem(row.id)}>
            Excluir
          </DropdownItem>
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table data={dados} columns={columns} paginated={false} pageSize={10} />
  );
};
