import React, { useRef, useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  FormButton,
  Table,
  TableDelete,
} from "../../../../../components";
import { toastr } from "react-redux-toastr";
import { naturalSort } from "../../../../../coreUtils";

export const TomadoresMDFe = ({ tomadores, addTomador, deleteTomador }) => {
  const [idTomador, setIdTomador] = useState(null);
  const [nomeTomador, setNomeTomador] = useState("");
  const [cpfCnpjTomador, setCpfCnpjTomador] = useState("");
  const tomadorRef = useRef();

  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome",
      text: "Tomador",
      align: "left",
      sortable: true,
    },
    {
      dataField: "cpf_cnpj",
      text: "CPF/CNPJ",
      align: "center",
    },
    {
      dataField: "delete_action",
      isDummyField: true,
      text: "",
      align: "center",
      formatter: (_, row) => (
        <TableDelete onClick={() => deleteTomador(row.id)} />
      ),
    },
  ];

  const handleAddTomador = () => {
    const payload = {
      id: idTomador,
      nome: nomeTomador,
      cpf_cnpj: cpfCnpjTomador,
    };
    addTomador(payload);

    setIdTomador(null);
    setNomeTomador("");
    setCpfCnpjTomador("");
    tomadorRef.current.clearValue();
  };

  const handleSelectTomador = (s) => {
    if (s) {
      if (tomadores.some((e) => e.id === s?.value)) {
        toastr.warning("Atenção", "O Tomador selecionado já foi informado.");

        setTimeout(() => {
          tomadorRef.current.clearValue();
        }, 2);

        return false;
      }
    }

    setIdTomador(s?.value);
    setNomeTomador(s?.label);
    setCpfCnpjTomador(s?.cpf_cnpj);
  };

  return (
    <>
      <Row className="mb-3">
        <AsyncComboBox
          md={3}
          isConcatField
          concatModelName="tomador_mdfe"
          label="Tomador"
          isSearchable
          isClearable
          defaultOptions
          onChange={handleSelectTomador}
          ref={tomadorRef}
        />
        <FormButton
          onClick={handleAddTomador}
          color="success"
          md="auto"
          disabled={!idTomador}
          disabledHint="Selecione um tomador no campo à esquerda"
        >
          Adicionar Tomador
        </FormButton>
      </Row>
      <Table
        columns={columns}
        data={tomadores}
        pageSize={5}
        fixedSize={false}
      />
    </>
  );
};
