import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ChevronLeft, ChevronRight, Plus } from "react-feather";
import { Row } from "reactstrap";
import ImgsViewer from "react-images-viewer";
import { useEffect } from "react";
import { usePrevious } from "../../../../../../coreUtils";
import {
  IconButton,
  ModalBase,
  TableDelete,
  TextInput,
} from "../../../../../../components";
import { toastr } from "react-redux-toastr";

const resizeImage = (base64Str, maxWidth = 700, maxHeight = 700) => {
  return new Promise((resolve) => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
};

const DragDropFotos = ({ onDrop, width, height }) => {
  const dropzoneOptions = { multiple: false, maxFiles: 1, accept: "image/*" };

  const _onDrop = useCallback(
    (acceptedFiles) => {
      if (onDrop) {
        acceptedFiles.forEach((file) => onDrop(file));
      }
    },
    [onDrop]
  );

  dropzoneOptions["onDrop"] = _onDrop;

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  return (
    <div
      style={{
        width: width,
        height: height,
      }}
    >
      <div
        {...getRootProps({
          className: "dropzone",
          style: {
            cursor: "pointer",
            justifyContent: "center",
            height: "100%",
          },
        })}
      >
        <input {...getInputProps({ multiple: false })} />
        <p style={{ marginTop: "revert" }}>Adicionar Foto</p>
      </div>
    </div>
  );
};

const imgCanvasStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#eee",
  border: "1px solid #dee2e6",
};

const AddFotoModal = ({ isOpen, toggle, onAdd }) => {
  const [previaImg, setPreviaImg] = useState(null);
  const [previaObserv, setPreviaObserv] = useState("");
  const obsRef = useRef();

  const limparDados = () => {
    setPreviaImg(null);
    setPreviaObserv("");
  };

  const onLoadImage = async (file) => {
    var reader = new FileReader();

    reader.addEventListener("load", async function (e) {
      const imgResize = await resizeImage(e.target.result);
      setPreviaImg(imgResize);
      obsRef.current.focus();
    });

    reader.readAsDataURL(file);
  };

  const onConfirmInternal = () => {
    if (!previaImg) {
      toastr.warning("Atenção", "Por favor, selecione a imagem.");
      return false;
    }
    onAdd({ base64: previaImg, observ: previaObserv });
    toggle();
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      onClosed={limparDados}
      size="md"
      onConfirm={onConfirmInternal}
      title="Adicionar Foto da O.S."
    >
      {!previaImg ? (
        <DragDropFotos onDrop={onLoadImage} height="300px" />
      ) : (
        <>
          <div style={{ ...imgCanvasStyle, height: "300px" }}>
            <img
              src={previaImg}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              alt=""
            />
          </div>
        </>
      )}
      <Row>
        <TextInput
          label="Observação"
          value={previaObserv}
          onChange={(e, v) => setPreviaObserv(v)}
          ref={obsRef}
        />
      </Row>
    </ModalBase>
  );
};

export const CarrosselFotosOS = ({ fotos = [], onAdd, onDelete }) => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const qtdFotosAntes = usePrevious(fotos?.length);

  const lado = "230px";

  const onChangeFotos = () => {
    if (
      fotos.length > 0 &&
      qtdFotosAntes > 0 &&
      (index > fotos.length - 1 || fotos?.length > qtdFotosAntes)
    ) {
      setIndex(fotos.length - 1);
    }
  };

  useEffect(onChangeFotos, [fotos]);

  const toggleAddModal = () => {
    setAddModalOpen(!addModalOpen);
  };

  const temFotos = fotos.length > 0;

  return (
    <div
      style={{
        width: lado,
        height: lado,
        marginBottom: "-10px",
      }}
    >
      <div
        style={{
          ...imgCanvasStyle,
          marginLeft: "auto",
          height: "88%",
          width: "95%",
          cursor: !temFotos ? "pointer" : "default",
        }}
        className="mb-2"
        onClick={() => !temFotos && toggleAddModal()}
      >
        {!temFotos ? (
          <span>Adicionar Fotos</span>
        ) : (
          <img
            src={fotos[index]?.base64}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              cursor: "zoom-in",
            }}
            alt=""
            onClick={() => setIsViewerOpen(true)}
          />
        )}
      </div>
      {temFotos && (
        <span
          style={{
            width: "230px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
          }}
        >
          {fotos[index]?.observ}
        </span>
      )}
      {temFotos && (
        <>
          <br />
          <div
            className="ml-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex" }}>
              <ChevronLeft
                size={20}
                onClick={() => index > 0 && setIndex(index - 1)}
                style={index > 0 ? { cursor: "pointer" } : { color: "#DDD" }}
              />
              <label style={{ marginInline: "1rem" }}>
                {index + 1} / {fotos.length}
              </label>
              <ChevronRight
                size={20}
                onClick={() => index < fotos.length - 1 && setIndex(index + 1)}
                style={
                  index < fotos.length - 1
                    ? { cursor: "pointer" }
                    : { color: "#DDD" }
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                size={20}
                icon={Plus}
                color="green"
                onClick={toggleAddModal}
              />
              <TableDelete
                className="ml-3"
                onClick={() => {
                  onDelete(index);
                }}
              />
            </div>
          </div>

          <ImgsViewer
            imgs={fotos.map((e) => ({
              src: e.base64,
              caption: e.observ,
            }))}
            isOpen={isViewerOpen}
            currImg={index}
            backdropCloseable={true}
            onClose={() => setIsViewerOpen(false)}
            onClickNext={() => setIndex(index + 1)}
            onClickPrev={() => setIndex(index - 1)}
            spinnerDisabled
            width={700}
            backgroundStyle={{ backgroundColor: "#00000085" }}
          />
        </>
      )}
      <AddFotoModal
        isOpen={addModalOpen}
        toggle={toggleAddModal}
        onAdd={onAdd}
      />
    </div>
  );
};
