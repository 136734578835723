import { apiDeleteV2, apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/jornada/carga_prod";

const SolicitacaoRecargaService = {
  buscar: async (idSolicitacao) =>
    await apiGetV2(`${URL_BASE}/buscar/${idSolicitacao}/`),
  buscarLogs: async (idSolicitacao) =>
    await apiGetV2(`${URL_BASE}/buscar_logs/${idSolicitacao}/`),
  cancelarSolicitacao: async (idSolicitacao) =>
    await apiDeleteV2(`${URL_BASE}/cancelar_solicitacao/${idSolicitacao}/`),
  excluirItem: async (idItem) =>
    await apiDeleteV2(`${URL_BASE}/excluir_item/${idItem}/`),
  expedirRecargaInstantaneaAdm: async (idSolicitacao) =>
    await apiPostV2(`${URL_BASE}/expedir_recarga_instantanea_adm/`, {
      id_solicitacao: idSolicitacao,
    }),
  listar: async (params) => await apiGetV2(`${URL_BASE}/listar/`, params),
};

export default SolicitacaoRecargaService;
