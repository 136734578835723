import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/nfe_entrada/confirmacao_precos";

const ConfirmacaoPrecosService = {
  alterarDadosItem: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_dados_item/`, payload, {
      successMessage: false,
    }),
  alterarValoresNf: (payload) =>
    apiPutV2(`${URL_BASE}/alterar_valores_nf/`, payload, {
      successMessage: false,
    }),
  buscarDadosNf: (idNfEntrada) =>
    apiGetV2(`${URL_BASE}/buscar_dados_nf/${idNfEntrada}/`),
  confirmar: (payload) => apiPostV2(`${URL_BASE}/confirmar/`, payload),
  listarNfs: (params) => apiGetV2(`${URL_BASE}/listar_nfs/`, params),
  gerarDadosNf: (payload) =>
    apiPostV2(`${URL_BASE}/gerar_dados_nf/`, payload, {
      successMessage: false,
    }),
};

export default ConfirmacaoPrecosService;
