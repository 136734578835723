import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  IntegerFormInput,
  LabelButton,
  MaskedInput,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Col, Row } from "reactstrap";
import {
  definirOpcoesFiltrarPor,
  definirOpcoesStatus,
} from "../../atendimento/central_os/CentralOS";
import { apiGetV2 } from "../../../../apiV2";
import { GerenciamentoOSGrid } from "./components/GerenciamentoOSGrid";
import GerenciamentoOSService from "../../../../services/ordem_servico/GerenciamentoOSSservice";
import {
  exportDataToSheet,
  formatNumber,
  getFirstDayOfWeek,
  getLastDayOfWeek,
} from "../../../../coreUtils";
import { ItensGerenciamentoOSGrid } from "./components/ItensGerenciamentoOSGrid";
import OrdemServicoService from "../../../../services/ordem_servico/OrdemServicoService";
import { DetalheOSModal } from "../../../financeiro/caixa_loja/components/DetalheOSModal";
import { FormasRecOSGrid } from "./components/FormasRecOSGrid";
import { ConfReabrirOSModal } from "./components/ConfReabrirOSModal";
import { LogsOSModal } from "./components/LogsOSModal";
import { SenhaModal } from "../../../../components/SenhaModal";
import { ExcluirOSModal } from "./components/ExcluirOSModal";

export const GerenciamentoOS = () => {
  // Parâmetros
  const [tipoSist, setTipoSist] = useState("");
  const [utilizaStatusAdicConcServ, setUtilizaStatusAdicConcServ] =
    useState(false);
  const [utilizaModuloAvancado, setUtilizaModuloAvancado] = useState(false);
  const [idOS, setIdOS] = useState(null);
  const [placa, setPlaca] = useState("");
  const [idCliente, setIdCliente] = useState(null);
  const [status, setStatus] = useState(null);
  const [idProduto, setIdProduto] = useState(null);
  const [idServico, setIdServico] = useState(null);
  const [idVeiculo, setIdVeiculo] = useState(null);
  const [idTerceirizacao, setIdTerceirizacao] = useState(null);
  const [chassi, setChassi] = useState(null);
  const [periodo, setPeriodo] = useState("EMI");
  const [dataIni, setDataIni] = useState(getFirstDayOfWeek(new Date()));
  const [dataFim, setDataFim] = useState(getLastDayOfWeek(new Date()));
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [selected, setSelected] = useState(null);
  const [nomeCliSelected, setNomeCliSelected] = useState(null);
  const [selectedItens, setSelectedItens] = useState([]);
  const [selectedFormasRec, setSelectedFormasRec] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);
  // Controle
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [reabrirOpen, setReabrirOpen] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [senhaEstornarOpen, setSenhaEstornarOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const mecanica = tipoSist === "MECANICA";

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const buscarDados = (incluirItens = false) => {
    const params = {
      id_os: idOS,
      placa: placa,
      id_cliente: idCliente,
      status: status,
      id_produto: idProduto,
      id_servico: idServico,
      id_veiculo: idVeiculo,
      id_terceirizacao: idTerceirizacao,
      chassi: chassi,
      periodo: periodo,
      data_ini: dataIni,
      data_fim: dataFim,
      incluir_itens: incluirItens,
    };
    return GerenciamentoOSService.listar(params);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados(true);
    if (ok) {
      const dados = ret.ordens_servico.reduce((acc, cab) => {
        if (cab.itens.length === 0) {
          cab.itens = [{}];
        }

        return [
          ...acc,
          ...cab.itens.map((item) => ({
            "Nro. OS": cab.id,
            Cliente: cab.nome_cliente,
            Placa: cab.placa,
            Veículo: cab.nome_veic,
            Modelo: cab.modelo_veic,
            Ano: cab.ano_veic,
            Chassi: cab.chassi_veic,
            KM: cab.km,
            Problema: cab.defeito1,
            "Obs. Abertura": cab.obs_emi1,
            "Situação OS": cab.desc_status,
            Abertura: cab.data_abertura,
            Fechamento: cab.data_fechamento,
            "Obs. Fechamento": cab.obs_fec1,
            Item: item.item,
            Tipo: item.tipo,
            "ID Item": item.id_item,
            Descrição: item.descricao,
            Referência: item.referencia,
            UN: item.unidade,
            Quantidade: parseFloat(item.quantidade ?? 0),
            "Vlr. Item": parseFloat(item.vlr_item ?? 0),
            Total: parseFloat(item.vlr_total ?? 0),
          })),
        ];
      }, []);
      exportDataToSheet(dados, "Ordens de Serviço", {
        dataIni: dataIni,
        dataFim: dataFim,
      });
    }
    setLoadingExportar(false);
  };

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados(false);
    setDados(ok ? ret.ordens_servico : []);
    setTotais(ok ? ret.totais : {});
    if (ok && ret.ordens_servico.length > 0) {
      if (![0, null, undefined].includes(selected)) {
        await carregarItensSelected();
      } else {
        setSelected(ret.ordens_servico[0].id);
      }
    } else {
      setSelected(null);
    }
    setLoading(false);
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/central_os/");
    if (ok) {
      setUtilizaStatusAdicConcServ(ret.utiliza_status_adic_conc_serv);
      setTipoSist(ret.tipo_sist);
      setUtilizaModuloAvancado(ret.utiliza_modulo_avancado);
    }
  };

  const carregarItensSelected = async () => {
    if (![0, null, undefined].includes(selected)) {
      const [ok, ret] = await OrdemServicoService.itens.resumo(selected);
      setSelectedItens(ok ? ret : []);
      const [ok2, ret2] = await OrdemServicoService.buscarFormasRec(selected);
      setSelectedFormasRec(ok2 ? ret2 : []);
    } else {
      setSelectedItens([]);
      setSelectedFormasRec([]);
    }
  };

  const reabrir = async (id) => {
    setSelected(id);
    const [ok] = await OrdemServicoService.verificarPodeReabrir(id);
    if (!ok) {
      return;
    }
    setTimeout(() => {
      toggleReabrir();
    }, 1);
  };

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleReabrir = () => setReabrirOpen(!reabrirOpen);

  const toggleLogs = () => setLogsOpen(!logsOpen);

  const toggleSenhaEstornar = () => setSenhaEstornarOpen(!senhaEstornarOpen);

  const toggleExclusao = () => setExcluirOpen(!excluirOpen);

  const mostrarDetalhes = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const mostrarLogs = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleLogs();
    }, 1);
  };

  const solicitarSenhaEstornoOS = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleSenhaEstornar();
    }, 1);
  };

  const estornarOS = async (senha) => {
    const payload = { id_os: selected, senha_estorno: senha };
    const [ok] = await OrdemServicoService.estornarRecCaixa(payload);
    if (ok) {
      carregarDados();
    }
    return ok;
  };

  const excluirOS = (id, nomeCliente) => {
    setSelected(id);
    setNomeCliSelected(nomeCliente);
    toggleExclusao();
  };

  const onChangeSelected = () => {
    carregarItensSelected();
  };

  useEffect(onChangeSelected, [selected]);

  const onActivate = () => {
    carregarParametros();
  };

  useEffect(onActivate, []);

  return (
    <PageContainer title="Gerenciamento de Ordens de Serviço" number="0107">
      <Card body>
        <Row>
          <IntegerFormInput
            md={2}
            label="Nº O.S."
            value={idOS}
            onChange={setIdOS}
          />
          {mecanica && (
            <MaskedInput
              md={2}
              mask="aaa-9*99"
              label="Placa"
              value={placa}
              onChange={(e, v) => setPlaca(v)}
              maskChar={null}
            />
          )}
          <AsyncComboBox
            md={4}
            label="Cliente"
            concatModelName="cliente"
            isSearchable
            isClearable
            onChange={(s) => setIdCliente(s?.value)}
          />
          <ComboBox
            md={2}
            label="Status"
            options={definirOpcoesStatus(
              mecanica,
              utilizaStatusAdicConcServ,
              utilizaModuloAvancado
            )}
            isSearchable={false}
            isClearable
            onChange={(s) => setStatus(s?.value)}
            defaultValue={status}
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={5}
            label="Produto"
            concatModelName="produto"
            isSearchable
            isClearable
            onChange={(s) => setIdProduto(s?.value)}
          />
          <AsyncComboBox
            md={4}
            label="Serviço"
            concatModelName="servico"
            isSearchable
            isClearable
            onChange={(s) => setIdServico(s?.value)}
          />
          <AsyncComboBox
            md={3}
            label="Terceirização"
            concatModelName="terceirizacao"
            isSearchable
            isClearable
            onChange={(s) => setIdTerceirizacao(s?.value)}
          />
        </Row>
        {mecanica && (
          <Row>
            <AsyncComboBox
              md={4}
              label="Veículo"
              concatModelName="veiculo"
              isSearchable
              isClearable
              onChange={(s) => setIdVeiculo(s?.value)}
            />
            <TextInput
              md={3}
              label="Chassi"
              value={chassi}
              onChange={(e, v) => setChassi(v)}
            />
          </Row>
        )}
        <Row>
          <ComboBox
            md={2}
            label="Filtrar Por"
            options={definirOpcoesFiltrarPor(
              mecanica,
              utilizaStatusAdicConcServ
            )}
            onChange={(s) => setPeriodo(s?.value)}
            defaultValue={periodo}
          />
          <FiltroPeriodoDatas
            defaultOption={null}
            defaultStart={dataIni}
            defaultEnd={dataFim}
            onChange={handleDate}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <LabelButton
            divClassName="ml-auto"
            onClick={exportar}
            loading={loadingExportar}
          >
            Exportar
          </LabelButton>
          <DetalheOSModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            idOS={selected}
          />
          <ExcluirOSModal
            isOpen={excluirOpen}
            toggle={toggleExclusao}
            selected={selected}
            nomeClienteOS={nomeCliSelected}
            notifyEvent={carregarDados}
          />
          <ConfReabrirOSModal
            isOpen={reabrirOpen}
            toggle={toggleReabrir}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <LogsOSModal
            isOpen={logsOpen}
            toggle={toggleLogs}
            selected={selected}
          />
        </Row>
      </Card>
      <CardTotais cardClassName="py-1">
        <CardTotaisItem label="Produtos" value={totais?.produtos} />
        <CardTotaisItem label="Serviços" value={totais?.servicos} />
        <CardTotaisItem
          label="Terceirização"
          style={{ flexBasis: "80%" }}
          value={
            `${formatNumber(
              totais?.custo_terceiriz ?? 0,
              true,
              2
            )} (custo) - ` +
            `${formatNumber(totais?.terceiriz ?? 0, true, 2)} (venda) = ` +
            `${formatNumber(totais?.result_terceiriz ?? 0, true, 2)} ` +
            `(${formatNumber(
              totais?.perc_result_terceiriz ?? 0,
              true,
              2,
              true
            )}%) `
          }
          format={false}
        />
        <CardTotaisItem label="Total Geral" value={totais?.total_geral} />
      </CardTotais>
      <Card body>
        <GerenciamentoOSGrid
          dados={dados}
          mecanica={mecanica}
          onSelect={setSelected}
          selected={selected}
          mostrarDetalhes={mostrarDetalhes}
          reabrir={reabrir}
          mostrarLogs={mostrarLogs}
          estornarRecebimento={solicitarSenhaEstornoOS}
          excluirOS={excluirOS}
        />
      </Card>
      <Card body>
        <Row>
          <Col md={8}>
            <ItensGerenciamentoOSGrid
              dados={selectedItens}
              mecanica={mecanica}
            />
          </Col>
          <Col md={4}>
            <FormasRecOSGrid dados={selectedFormasRec} />
          </Col>
        </Row>
      </Card>
      <SenhaModal
        isOpen={senhaEstornarOpen}
        toggle={toggleSenhaEstornar}
        title="Estorno de Ordem de Servico"
        onConfirm={estornarOS}
        hint="Este processo irá reabrir a O.S. no Caixa Loja e excluir os recebimentos
          vinculados a ela."
      />
    </PageContainer>
  );
};
