import { apiGetV2, apiPostV2 } from "../../apiV2";

const URL_BASE = "/estoque_principal/gerenciamento";
const ManutencaoEstoqueService = {
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  buscarMovimentacoes: (idProd, params) =>
    apiGetV2(`${URL_BASE}/buscar_movimentacoes/${idProd}/`, params),
  incluirAjuste: (payload) => apiPostV2(`${URL_BASE}/incluir_ajuste/`, payload),
};

export default ManutencaoEstoqueService;
