import React, { useEffect, useRef, useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  Divider,
  FixedField,
  FormButton,
  Loader,
  NumberInput,
  PageContainer,
  TabController,
  TextInput,
} from "../../../components";
import { Card, Col, Row } from "reactstrap";
import moment from "moment";
import IncluirProdutoNfEntradaForm from "./components/IncluirProdutoNfEntradaForm";
import { ProdutosNfEntradaGrid } from "./components/ProdutosNfEntradaGrid";
import {
  dateFromLocaleString,
  formatDateISO,
  formatDateLocal,
  formatNumber,
  roundFloat,
  sumDataField,
} from "../../../coreUtils";
import UteisService from "../../../services/uteis/UteisService";
import { FaturasNfEntradaGrid } from "./components/FaturasNfEntradaGrid";
import { showWarning } from "../../../components/AlertaModal";
import { apiGetV2, apiPostV2 } from "../../../apiV2";
import store from "./store";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  initialState,
  setDataEmi,
  setFaturas,
  setIdCondPag,
  setIdFornecedor,
  setIdTipoDoc,
  setItens,
  setNumero,
  setSerie,
  setup,
  setVlrFrete,
  setVlrOutros,
  setVlrSeguro,
} from "./store/entradaManualNfSlice";
import { useHistory } from "react-router-dom";
import { showConfirmation } from "../../../components/ConfirmationModal";
import { TabBody } from "../../../components/TabController";
import { confirmarPrecosNfRoute } from "../../../routes/modules/compras";

const definirSeqItens = (itens) => itens.map((e, i) => ({ item: i + 1, ...e }));

const styleTotais = { textAlign: "right" };

const EntradaManualNfContainer = () => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state.entradaManualNf);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingParcelas, setLoadingParcelas] = useState(false);
  /// Parâmetros
  const [
    permiteDifPlanoPagVlrTotNfManual,
    setPermiteDifPlanoPagVlrTotNfManual,
  ] = useState(false);

  const refIdFornecedor = useRef();
  const refIdTipoDoc = useRef();
  const refIdCondPag = useRef();

  const vlrTotDesc = sumDataField(store.itens, "vlr_desc");
  const vlrTotIpi = sumDataField(store.itens, "vlr_ipi");
  const vlrTotIcms = sumDataField(store.itens, "vlr_icms");
  const vlrTotIcmsSt = sumDataField(store.itens, "vlr_icms_st");
  const vlrTotProd = store.itens.reduce(
    (acc, e) => acc + e.vlr_unit * e.quantidade,
    0
  );
  const vlrTotNf =
    vlrTotProd +
    vlrTotIcmsSt +
    vlrTotIpi +
    store.vlrFrete +
    store.vlrSeguro +
    store.vlrOutros -
    vlrTotDesc;

  const limparDados = () => {
    dispatch(setup(initialState));
    if (refIdFornecedor.current) {
      refIdFornecedor.current.clearValue();
    }
    if (refIdTipoDoc.current) {
      refIdTipoDoc.current.clearValue();
    }
    if (refIdCondPag.current) {
      refIdCondPag.current.clearValue();
    }
  };

  const buscarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/entrada_nf_manual/");
    if (ok) {
      setPermiteDifPlanoPagVlrTotNfManual(
        ret.permite_dif_plano_pag_vlr_tot_nf_manual
      );
    } else {
      history.goBack();
    }
    setLoading(false);
  };

  const incluirItem = (payload) => {
    const it = definirSeqItens([...store.itens, payload]);
    dispatch(setItens(it));
    return true;
  };

  const excluirItem = (item) => {
    const it = definirSeqItens(store.itens.filter((e) => e.item !== item));
    dispatch(setItens(it));
  };

  const recalcularValoresGrade = (coluna, novoValor, row) => {
    // Se o valor não for alterado, não recalcula nada
    const alterouValorNumerico = !["sit_trib_icms", "cfop"].includes(coluna);
    let novoValorTest;
    let valorAntesTest;
    if (alterouValorNumerico) {
      const casasDecTest =
        coluna === "quantidade"
          ? row.qtd_casas_decimais_qtd
          : coluna === "vlr_unit"
          ? row.qtd_casas_decimais_vlrs
          : 2;
      novoValorTest = roundFloat(parseFloat(novoValor), casasDecTest);
      valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTest);
    } else {
      novoValorTest = novoValor;
      valorAntesTest = row[coluna];
    }

    if (novoValorTest === valorAntesTest) {
      return false;
    }

    if (alterouValorNumerico) {
      // 1 - Cálculos por Valor
      // 2 - Cálculos por Percentual
      const tipoCalc = [
        "perc_desc",
        "perc_ipi",
        "perc_icms",
        "perc_icms_st",
      ].includes(coluna)
        ? 2
        : 1;

      let vDesc = 0;
      let vIpi = 0;
      let vBaseCalcIcms = 0;
      let vIcms = 0;
      let vIcmsSt = 0;
      let pDesc = 0;
      let pIpi = 0;
      let pIcms = 0;
      let pIcmsSt = 0;

      let qtd = coluna === "quantidade" ? novoValor : row.quantidade;
      let unit = coluna === "vlr_unit" ? novoValor : row.vlr_unit;
      let valor = qtd * unit;

      if (tipoCalc === 1) {
        vDesc = coluna === "vlr_desc" ? novoValor : row.vlr_desc;
        vIpi = coluna === "vlr_ipi" ? novoValor : row.vlr_ipi;
        vIcms = coluna === "vlr_icms" ? novoValor : row.vlr_icms;
        vIcmsSt = coluna === "vlr_icms_st" ? novoValor : row.vlr_icms_st;

        if (valor > 0) {
          if (vDesc !== 0) {
            pDesc = (vDesc / valor) * 100;
          }
          if (vIpi !== 0) {
            pIpi = (vIpi / valor) * 100;
          }

          vBaseCalcIcms = valor;

          if (vIcms !== 0) {
            pIcms = (vIcms / vBaseCalcIcms) * 100;
          }
          if (vIcmsSt !== 0) {
            pIcmsSt = (vIcmsSt / vBaseCalcIcms) * 100;
          }
        }

        row.perc_desc = roundFloat(pDesc, 2);
        row.perc_ipi = roundFloat(pIpi, 2);
        row.perc_icms = roundFloat(pIcms, 2);
        row.perc_icms_st = roundFloat(pIcmsSt, 2);
      } else if (tipoCalc === 2) {
        pDesc = coluna === "perc_desc" ? novoValor : row.perc_desc;
        pIpi = coluna === "perc_ipi" ? novoValor : row.perc_ipi;
        pIcms = coluna === "perc_icms" ? novoValor : row.perc_icms;
        pIcmsSt = coluna === "perc_icms_st" ? novoValor : row.perc_icms_st;

        if (pDesc !== 0) {
          vDesc = valor * (pDesc / 100);
        }

        if (pIpi !== 0) {
          vIpi = valor * (pIpi / 100);
        }

        vBaseCalcIcms = valor;

        if (pIcms !== 0) {
          vIcms = vBaseCalcIcms * (pIcms / 100);
        }
        if (pIcmsSt !== 0) {
          vIcmsSt = vBaseCalcIcms * (pIcmsSt / 100);
        }

        row.vlr_desc = roundFloat(vDesc, 2);
        row.vlr_ipi = roundFloat(vIpi, 2);
        row.vlr_icms = roundFloat(vIcms, 2);
        row.vlr_icms_st = roundFloat(vIcmsSt, 2);
      }
      let vTotBruto = roundFloat(valor + vIpi + vIcmsSt - vDesc, 2);
      if (vTotBruto < 0) {
        vTotBruto = 0;
      }
      row.vlr_tot_bruto = vTotBruto;
    }

    row[coluna] = novoValor;

    dispatch(
      setItens(
        store.itens.map((element) => {
          if (element.item === row.item) {
            return { ...row };
          } else {
            return element;
          }
        })
      )
    );
    return true;
  };

  const calcularParcelasFatura = async () => {
    setLoadingParcelas(true);
    try {
      const payload = {
        id_cond_pag: store.idCondPag,
        valor: vlrTotNf,
        data_base: formatDateISO(store.dataEmi),
      };

      const [ok, ret] = await UteisService.calcularParcelasCondPag(payload);
      if (ok) {
        dispatch(
          setFaturas(
            ret.map((e, index) => ({
              ...e,
              numero: store.numero,
              data_vcto: formatDateLocal(e.data_vcto),
              parcela: index + 1,
              valor: parseFloat(e.valor),
            }))
          )
        );
      }
    } finally {
      setLoadingParcelas(false);
    }
  };

  const handleEditFatura = (coluna, novoValor, row) => {
    if (coluna === "numero") {
      row.numero = novoValor;
    } else if (coluna === "valor") {
      row.valor = parseFloat(novoValor);
    } else if (coluna === "data_vcto") {
      if (moment.isMoment(novoValor)) {
        row.data_vcto = formatDateLocal(novoValor);
      } else {
        return false;
      }
    }

    dispatch(
      setFaturas(
        store.faturas.map((e) => (e.parcela === row.parcela ? row : e))
      )
    );
  };

  const handleSubmit = async (faseValid = 0) => {
    if ([0, null, undefined].includes(store.idFornecedor)) {
      showWarning("Por favor, informe o Fornecedor");
      return;
    }

    if (!(store.dataEmi instanceof Date)) {
      showWarning("Por favor, revise a Data de Emissão");
      return;
    }

    if ([0, null, undefined].includes(store.idTipoDoc)) {
      showWarning("Por favor, informe o Tipo de Documento");
      return;
    }

    if (["", null, undefined].includes(store.numero)) {
      showWarning("Por favor, informe o Número da NF");
      return;
    }

    if (store.itens.length === 0) {
      showWarning("Por favor, informe ao menos 1 item");
      return;
    }

    if (store.faturas.length === 0) {
      showWarning("Por favor, informe o Plano de Pagamento da NF");
      return;
    }

    if (faseValid < 1) {
      if (permiteDifPlanoPagVlrTotNfManual) {
        const vlrTotFaturas = roundFloat(
          sumDataField(store.faturas, "valor"),
          2
        );
        if (vlrTotFaturas !== roundFloat(vlrTotNf, 2)) {
          showConfirmation(
            <>
              O Valor Total do Plano de Pagamento é diferente do Valor Total da
              NF.
              <br />
              Total Plano Pag.: {formatNumber(vlrTotFaturas, true, 2)} | Total
              NF: {formatNumber(roundFloat(vlrTotNf, 2), true, 2)}
              <br />
              Deseja continuar?
            </>,
            () => handleSubmit(1)
          );
          return;
        }
      }
    }

    setLoadingSubmit(true);
    const payload = {
      id_fornecedor: store.idFornecedor,
      data_emi: formatDateISO(store.dataEmi),
      id_tipo_doc: store.idTipoDoc,
      numero: store.numero,
      serie: store.serie,
      vlr_frete: store.vlrFrete,
      vlr_seguro: store.vlrSeguro,
      vlr_outros: store.vlrOutros,
      id_cond_pag: store.idCondPag,
      faturas: store.faturas.map((e) => ({
        parcela: e.parcela,
        numero: e.numero,
        data_vcto: formatDateISO(dateFromLocaleString(e.data_vcto)),
        valor: e.valor,
      })),
      itens: store.itens.map((e) => ({
        item: e.item,
        id_produto: e.id_produto,
        nome_produto: e.nome_produto,
        unidade: e.unidade,
        cfop: e.cfop,
        quantidade: e.quantidade,
        vlr_unit: e.vlr_unit,
        perc_desc: e.perc_desc,
        vlr_desc: e.vlr_desc,
        vlr_tot_bruto: e.vlr_tot_bruto,
        perc_ipi: e.perc_ipi,
        vlr_ipi: e.vlr_ipi,
        sit_trib_icms: e.sit_trib_icms,
        perc_icms: e.perc_icms,
        vlr_icms: e.vlr_icms,
        perc_icms_st: e.perc_icms_st,
        vlr_icms_st: e.vlr_icms_st,
      })),
    };

    const [ok, ret] = await apiPostV2(
      "/nfe_entrada/entrada_manual/dar_entrada/",
      payload
    );
    if (ok) {
      limparDados();

      const confirmarPrecosNf = () =>
        history.push(confirmarPrecosNfRoute.path, {
          selected: ret.id_nf_entrada_cab,
        });

      showConfirmation(
        "Deseja confirmar os preços da Nota Fiscal agora?",
        confirmarPrecosNf
      );
    }

    setLoadingSubmit(false);
  };

  const onActivate = () => {
    buscarParametros();
  };

  useEffect(onActivate, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Card body>
        <TabController>
          <TabBody title="Dados da Nota">
            <Row>
              <AsyncComboBox
                md={5}
                label="Fornecedor"
                concatModelName="fornecedor"
                defaultValue={store.idFornecedor}
                onChange={(s) => dispatch(setIdFornecedor(s?.value))}
                ref={refIdFornecedor}
              />
              <DatePicker
                md={2}
                label="Emissão"
                value={store.dataEmi}
                onChange={(v) =>
                  dispatch(setDataEmi(moment.isMoment(v) ? v.toDate() : v))
                }
              />
            </Row>
            <Row>
              <AsyncComboBox
                md={5}
                label="Tipo de Documento"
                concatModelName="tipo_documento"
                defaultValue={store.idTipoDoc}
                onChange={(s) => dispatch(setIdTipoDoc(s?.value))}
                ref={refIdTipoDoc}
                defaultOptions
              />
              <TextInput
                md={2}
                label="Número"
                value={store.numero}
                onChange={(e, v) => dispatch(setNumero(v))}
              />
              <TextInput
                md={1}
                label="Série"
                value={store.serie}
                onChange={(e, v) => dispatch(setSerie(v))}
              />
            </Row>
            <Divider>Produtos</Divider>
            <IncluirProdutoNfEntradaForm
              incluirItem={incluirItem}
              itens={store.itens}
            />
            <ProdutosNfEntradaGrid
              dados={store.itens}
              excluirItem={excluirItem}
              recalcularValoresGrade={recalcularValoresGrade}
            />
            <Divider>Totais da NF</Divider>
            <Row>
              <FixedField
                label="ICMS"
                value={formatNumber(vlrTotIcms, true, 2)}
                labelStyle={styleTotais}
                textStyle={styleTotais}
              />
              <FixedField
                label="ICMS ST"
                value={formatNumber(vlrTotIcmsSt, true, 2)}
                labelStyle={styleTotais}
                textStyle={styleTotais}
              />
              <FixedField
                label="IPI"
                value={formatNumber(vlrTotIpi, true, 2)}
                labelStyle={styleTotais}
                textStyle={styleTotais}
              />
              <FixedField
                label="Desconto"
                value={formatNumber(vlrTotDesc, true, 2)}
                labelStyle={styleTotais}
                textStyle={styleTotais}
              />
              <FixedField
                label="Produtos"
                value={formatNumber(vlrTotProd, true, 2)}
                labelStyle={styleTotais}
                textStyle={styleTotais}
              />
              <NumberInput
                md={2}
                label="Frete"
                value={store.vlrFrete}
                onChange={(v) => dispatch(setVlrFrete(v))}
              />
              <NumberInput
                md={2}
                label="Seguro"
                value={store.vlrSeguro}
                onChange={(v) => dispatch(setVlrSeguro(v))}
              />
              <NumberInput
                md={2}
                label="Outros"
                value={store.vlrOutros}
                onChange={(v) => dispatch(setVlrOutros(v))}
              />
              <FixedField
                label="Total da Nota Fiscal"
                value={formatNumber(vlrTotNf, true, 2)}
                labelStyle={{ color: "black", ...styleTotais }}
                textStyle={{
                  ...styleTotais,
                  fontSize: "1rem",
                  color: "black",
                  fontWeight: "bold",
                }}
              />
            </Row>
          </TabBody>
          <TabBody title="Plano de Pagamento">
            <Row className="mb-2">
              <AsyncComboBox
                md={4}
                label="Condição de Pagamento"
                concatModelName="cond_pag"
                defaultValue={store.idCondPag}
                onChange={(s) => dispatch(setIdCondPag(s?.value))}
                className="pr-0"
                ref={refIdCondPag}
              />
              <FormButton
                onClick={calcularParcelasFatura}
                loading={loadingParcelas}
                color="primary"
                disabled={
                  [0, null, undefined].includes(store.idCondPag) ||
                  !(store.dataEmi instanceof Date) ||
                  ["", null, undefined].includes(store.numero)
                }
                disabledHint={
                  !(store.dataEmi instanceof Date)
                    ? "Revise a Data de Emissão da NF"
                    : ["", null, undefined].includes(store.numero)
                    ? "Informe o Número da NF"
                    : "Informe a Condição de Pagamento"
                }
              >
                Calcular Sugestão
              </FormButton>
            </Row>
            <Col md={4} className="no-gutters">
              <FaturasNfEntradaGrid
                faturas={store.faturas}
                handleEditFatura={handleEditFatura}
              />
            </Col>
          </TabBody>
        </TabController>
        <Row>
          <FormButton
            padded={false}
            color="success"
            onClick={() => handleSubmit(0)}
            loading={loadingSubmit}
            divClassName="ml-auto"
          >
            F9 - Finalizar
          </FormButton>
        </Row>
      </Card>
    </>
  );
};

export const EntradaManualNf = () => {
  return (
    <PageContainer
      title="Entrada Manual de Nota Fiscal"
      number="0123"
      canGoBack
    >
      <Provider store={store}>
        <EntradaManualNfContainer />
      </Provider>
    </PageContainer>
  );
};
