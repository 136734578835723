import React, { useEffect, useState } from "react";
import {
  FixedField,
  FormButton,
  LabelButton,
  NumberInput,
  PageContainer,
} from "../../../../components";
import { Card, Col, Row } from "reactstrap";
import {
  dateFromLocaleString,
  formatNumber,
  formatValueFromAPI,
  roundFloat,
} from "../../../../coreUtils";
import { ComprasVendasProdutoModal } from "../../entrada_nf_xml/produtos/components/ComprasVendasProdutoModal";
import { useHistory } from "react-router-dom";
import { HistComprasProdutoGrid } from "./components/HistComprasProdutoGrid";
import ConfirmacaoPrecosService from "../../../../services/compras/ConfirmacaoPrecosService";
import { v4 as uuidv4 } from "uuid";
import { showConfirmation } from "../../../../components/ConfirmationModal";
import moment from "moment";
import { apiGetV2 } from "../../../../apiV2";
import { ProdutoConfirmarPrecosGrid } from "./components/ProdutoConfirmarPrecosGrid";

export const ConfirmarPrecosNf = ({ location }) => {
  const selected = location?.state?.selected;
  // Parâmetros
  const [usaPrecoAtacado, setUsaPrecoAtacado] = useState(false);

  // Dados da NF
  const [numero, setNumero] = useState(null);
  const [serie, setSerie] = useState(null);
  const [dataEmi, setDataEmi] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState("");
  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [fornecedorSimplesNacional, setFornecedorSimplesNacional] =
    useState(false);
  const [vlrOutrosForaNf, setVlrOutrosForaNf] = useState(0);
  const [itens, setItens] = useState([]);

  // Dados do produto selecionado
  const [selectedDados, setSelectedDados] = useState({});
  const [selectedIdProduto, setSelectedIdProduto] = useState(null);
  const [selectedHistCompra, setSelectedHistCompra] = useState([]);

  // Variáveis de Controle
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [atualizarValoresItens, setAtualizarValoresItens] = useState(false);
  const [comprasVendasOpen, setComprasVendasOpen] = useState(false);
  const [todosItensMarcados, setTodosItensMarcados] = useState(false);
  const history = useHistory();

  const carregarDados = async () => {
    const [ok, ret] = await ConfirmacaoPrecosService.buscarDadosNf(selected);
    if (ok) {
      setNumero(ret.numero);
      setSerie(ret.serie);
      setDataEmi(ret.emissao);
      setIdFornecedor(ret.id_fornecedor);
      setNomeFornecedor(ret.nome_fornecedor);
      setFornecedorSimplesNacional(ret.fornecedor_simples_nacional);
      setVlrOutrosForaNf(parseFloat(ret.vlr_outros_fora_nf));
      setItens(
        ret.itens.map((e) => ({
          ...e,
          uuid: uuidv4(),
          vlr_unit_prod: parseFloat(e.vlr_unit_prod),
          perc_lucro: parseFloat(e.perc_lucro),
          preco_venda: parseFloat(e.preco_venda),
          perc_luctro_atacado: parseFloat(e.perc_luctro_atacado),
          preco_atacado: parseFloat(e.preco_atacado),
          vlr_custo_unit: parseFloat(e.vlr_custo_unit),
          vlr_outros_fora_nf: parseFloat(e.vlr_outros_fora_nf),
        }))
      );
    }
    return ok;
  };

  const gerarDadosNf = async () => {
    const payload = { id_nf_entrada_cab: selected };
    const [ok] = await ConfirmacaoPrecosService.gerarDadosNf(payload);
    return ok;
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("/tela/confirmacao_precos/");
    if (ok) {
      setUsaPrecoAtacado(ret.usa_preco_atacado);
    }
    return ok;
  };

  const recalcularValoresGrade = (coluna, novoValor, row) => {
    const valorAnterior = roundFloat(parseFloat(row[coluna]), 2);
    const novoValorTest = roundFloat(parseFloat(novoValor), 2);
    if (novoValorTest === valorAnterior) {
      return false;
    }

    if (coluna === "preco_venda") {
      row.preco_venda = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.perc_lucro = roundFloat(
          (row.preco_venda * 100) / row.vlr_custo_unit - 100,
          2
        );
      }
    } else if (coluna === "perc_lucro") {
      row.perc_lucro = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.preco_venda = roundFloat(
          row.vlr_custo_unit + row.vlr_custo_unit * (row.perc_lucro / 100),
          2
        );
      }
    } else if (coluna === "preco_atacado") {
      row.preco_atacado = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.perc_lucro_atacado = roundFloat(
          (row.preco_atacado * 100) / row.vlr_custo_unit - 100,
          2
        );
      }
    } else if (coluna === "perc_lucro_atacado") {
      row.perc_lucro_atacado = parseFloat(novoValor);

      if (row.vlr_custo_unit > 0) {
        row.preco_atacado = roundFloat(
          row.vlr_custo_unit +
            row.vlr_custo_unit * (row.perc_lucro_atacado / 100),
          2
        );
      }
    } else if (coluna === "vlr_outros_fora_nf") {
      row.vlr_outros_fora_nf = parseFloat(novoValor);

      const vlrOutrosForaUnit = roundFloat(
        row.vlr_outros_fora_nf / row.qtd_prod_entrada,
        2
      );
      row.vlr_custo_unit = roundFloat(row.vlr_unit_prod + vlrOutrosForaUnit, 2);

      if (row.vlr_custo_unit > 0) {
        if (row.preco_venda > 0) {
          row.perc_lucro = roundFloat(
            (row.preco_venda * 100) / row.vlr_custo_unit - 100,
            2
          );
        }

        if (usaPrecoAtacado && row.preco_atacado > 0) {
          row.perc_lucro_atacado = roundFloat(
            (row.preco_atacado * 100) / row.vlr_custo_unit - 100,
            2
          );
        }
      }
    }

    row.atualiza_preco_venda = true;

    setAtualizarValoresItens(true);
    setItens(itens.map((e) => (e.uuid === row.uuid ? row : e)));
  };

  const marcarDesmarcarItem = (row, buscarDados = true) => {
    setAtualizarValoresItens(true);

    const payload = {
      ...row,
      atualiza_preco_venda: !row.atualiza_preco_venda,
    };
    atualizarDadosItem(payload, true, buscarDados);
  };

  const toggleMarcarTodos = () => {
    itens.forEach((e) => {
      if (todosItensMarcados) {
        if (e.atualiza_preco_venda) marcarDesmarcarItem(e, false);
      } else {
        if (!e.atualiza_preco_venda) marcarDesmarcarItem(e, false);
      }
    });

    carregarDados();
  };

  const onChangeItens = () => {
    const todosMarcados = itens.every((e) => e.atualiza_preco_venda);

    setTodosItensMarcados(todosMarcados);
  };

  useEffect(onChangeItens, [itens]);

  const atualizarDadosItem = async (row, forcarAtu, buscarDados = true) => {
    if ((atualizarValoresItens || forcarAtu) && row.id > 0) {
      const payload = {
        id_nf_entrada_item: row.id,
        perc_lucro: parseFloat(row.perc_lucro),
        preco_venda: parseFloat(row.preco_venda),
        perc_lucro_atacado: parseFloat(row.perc_lucro_atacado),
        preco_atacado: parseFloat(row.preco_atacado),
        atualiza_preco_venda: row.atualiza_preco_venda,
        vlr_outros_fora_nf: parseFloat(row.vlr_outros_fora_nf),
        vlr_custo_unit: parseFloat(row.vlr_custo_unit),
      };

      await ConfirmacaoPrecosService.alterarDadosItem(payload);

      if (buscarDados) carregarDados();
    }
  };

  const atualizarValoresNf = async (buscarDados = true, considerarDesc) => {
    const payload = {
      id_nf_entrada_cab: selected,
      vlr_outros_fora_nf: vlrOutrosForaNf,
      considerar_desconto: considerarDesc,
    };

    await ConfirmacaoPrecosService.alterarValoresNf(payload);

    if (buscarDados) carregarDados();
  };

  const handleSelect = (uuid, is, row) => {
    setSelectedDados(is ? row : {});
    if (is) {
      setSelectedIdProduto(row.id_produto);
      setSelectedHistCompra(row.hist_compra);
    } else {
      setSelectedIdProduto(null);
      setSelectedHistCompra([]);
    }
  };

  const toggleComprasVendas = () => setComprasVendasOpen(!comprasVendasOpen);

  const comprasVendasProd = (idProd) => {
    setSelectedIdProduto(idProd);
    setTimeout(() => {
      toggleComprasVendas();
    }, 1);
  };

  const continuar = () => {
    if (!itens.some((e) => e.atualiza_preco_venda)) {
      showConfirmation(
        <>
          <h5 style={{ textAlign: "center" }}>
            Nenhum produto foi marcado para atualizar preços de custo e venda.
          </h5>
          <h5 style={{ textAlign: "center" }}>
            Deseja continuar sem atualizar os preços?
          </h5>
        </>,
        confirmarContinuar
      );
      return;
    }

    confirmarContinuar();
  };

  const confirmarContinuar = async () => {
    setLoadingSubmit(true);
    const payload = { id_nf_entrada_cab: selected };
    const [ok] = await ConfirmacaoPrecosService.confirmar(payload);
    if (ok) {
      history.goBack();
    }
    setLoadingSubmit(false);
  };

  const iniciarTela = async () => {
    if (!(await buscarParams())) {
      history.goBack();
    }

    if (!(await gerarDadosNf())) {
      history.goBack();
    }

    if (!(await carregarDados())) {
      history.goBack();
    }
    setLoading(false);
  };

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      continuar();
    }
  };

  return (
    <PageContainer
      title="Confirmar Preços da Nota Fiscal de Entrada"
      number="0130_1"
      loading={loading}
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row className="mb-2">
          <FixedField horizontal label="Número" value={numero} />
          <FixedField horizontal label="Série" value={serie} />
          <FixedField horizontal label="Emissão" value={dataEmi} />
        </Row>
        <Row className="mb-2">
          <FixedField
            horizontal
            label="Fornecedor"
            value={
              <>
                {formatValueFromAPI(nomeFornecedor, idFornecedor)}
                {fornecedorSimplesNacional && (
                  <span
                    style={{
                      marginLeft: "0.5rem",
                      background: "rgb(67, 201, 144)",
                      paddingInline: "0.5rem",
                      borderRadius: "0.3rem",
                      color: "white",
                    }}
                  >
                    Simples Nacional
                  </span>
                )}
              </>
            }
          />
        </Row>
        <ComprasVendasProdutoModal
          isOpen={comprasVendasOpen}
          toggle={toggleComprasVendas}
          selected={selectedIdProduto}
        />
        <Row>
          <FormButton
            color="primary"
            onClick={toggleComprasVendas}
            disabled={[0, null, undefined].includes(selectedIdProduto)}
            disabledHint="Selecione um produto"
          >
            Compras & Vendas
          </FormButton>
          <NumberInput
            md={2}
            label="Outras Desp. Fora NF"
            value={vlrOutrosForaNf}
            onChange={setVlrOutrosForaNf}
            onBlur={() =>
              setTimeout(async () => {
                await atualizarValoresNf(true);
              }, 1)
            }
            hint="Este Valor será rateado sobre o custo unitário dos produtos"
          />
        </Row>
      </Card>
      <Card body>
        <ProdutoConfirmarPrecosGrid
          usaPrecoAtacado={usaPrecoAtacado}
          dados={itens}
          onSelect={handleSelect}
          comprasVendasProd={comprasVendasProd}
          handleEditItem={recalcularValoresGrade}
          atualizarValoresItens={(row) => atualizarDadosItem(row, false)}
          marcarDesmarcarItem={marcarDesmarcarItem}
        />
      </Card>
      <Card body>
        <Row className="pr-0">
          <Col md={8} className="pr-0">
            <Row>
              <Col className="pr-0">
                <HistComprasProdutoGrid
                  dados={selectedHistCompra}
                  usaPrecoAtacado={usaPrecoAtacado}
                />
              </Col>
            </Row>
          </Col>
          <Col md={4} className="pr-0">
            <Row className="mb-2">
              <FixedField
                md={4}
                label="Preço Venda Atual"
                textStyle={{ display: "flex", justifyContent: "space-between" }}
                value={
                  <>
                    <span>
                      {formatNumber(
                        selectedDados?.perc_lucro_prod ?? 0,
                        true,
                        2,
                        true
                      )}
                      %
                    </span>
                    <span>
                      R${" "}
                      {formatNumber(
                        selectedDados?.preco_venda_prod ?? 0,
                        true,
                        2
                      )}
                    </span>
                  </>
                }
                divStyle={{
                  borderRightColor: "#ccc",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                }}
              />
              {usaPrecoAtacado && (
                <FixedField
                  md={4}
                  label="Preço Atacado"
                  textStyle={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  value={
                    <>
                      <span>
                        {formatNumber(
                          selectedDados?.perc_lucro_atacado_prod ?? 0,
                          true,
                          2,
                          true
                        )}
                        %
                      </span>
                      <span>
                        R${" "}
                        {formatNumber(
                          selectedDados?.preco_atacado_prod ?? 0,
                          true,
                          2
                        )}
                      </span>
                    </>
                  }
                  divStyle={{
                    borderRightColor: "#ccc",
                    borderRightStyle: "solid",
                    borderRightWidth: "1px",
                  }}
                />
              )}
              <FixedField
                md={4}
                label="Estoque"
                value={formatNumber(
                  selectedDados?.quantidade_prod ?? 0,
                  true,
                  2,
                  true
                )}
                divClassName="pr-0"
              />
            </Row>
            <Row
              className="mb-2 pt-2"
              style={{
                borderTopColor: "#ccc",
                borderTopStyle: "solid",
                borderTopWidth: "1px",
              }}
            >
              <FixedField
                md={4}
                label="Custo Médio"
                value={
                  "R$ " +
                  formatNumber(selectedDados?.custo_med_prod ?? 0, true, 2)
                }
                divClassName="pr-0"
                divStyle={{
                  borderRightColor: "#ccc",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                }}
              />
              <FixedField
                md={4}
                label="Custo Máx."
                value={
                  "R$ " +
                  formatNumber(selectedDados?.custo_max_prod ?? 0, true, 2)
                }
                divClassName="pr-0"
                divStyle={{
                  borderRightColor: "#ccc",
                  borderRightStyle: "solid",
                  borderRightWidth: "1px",
                }}
              />
              <FixedField
                md={4}
                label="Última Entrada"
                textStyle={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                value={
                  <>
                    {selectedDados?.data_ue_prod && (
                      <>
                        <span>
                          {moment(
                            dateFromLocaleString(selectedDados?.data_ue_prod)
                          ).format("DD/MM/YY")}
                        </span>
                        <span>
                          R${" "}
                          {formatNumber(
                            selectedDados?.custo_ue_prod ?? 0,
                            true,
                            2
                          )}
                        </span>
                      </>
                    )}
                  </>
                }
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <LabelButton onClick={toggleMarcarTodos}>
            {!todosItensMarcados ? "" : "Não "}Atualizar Todos
          </LabelButton>
          <FormButton
            padded={false}
            color="success"
            divClassName="ml-auto pr-0"
            onClick={continuar}
            loading={loadingSubmit}
          >
            F9 - Confirmar Preços
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
