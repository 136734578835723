import React from "react";
import { Edit2 } from "react-feather";
import { formatarValor } from "../../../../coreUtils";
import { Table } from "../../../../components";

export const FaturasNfEntradaGrid = ({ faturas, handleEditFatura }) => {
  const columns = [
    {
      dataField: "numero",
      text: "Número",
      align: "center",
      onChange: handleEditFatura,
      editable: true,
      alwaysShowEditor: true,
    },
    {
      dataField: "parcela",
      text: "P",
      align: "center",
      fixedColWidth: true,
      colWidth: "10%",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      fixedColWidth: true,
      colWidth: "25%",
      editable: true,
      editorType: "date",
      onChange: handleEditFatura,
      formatter: (c) => (
        <>
          {c}
          <Edit2 size={12} className="ml-1" style={{ marginBottom: "3px" }} />
        </>
      ),
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatarValor(c, 2),
      fixedColWidth: true,
      colWidth: "25%",
      onChange: handleEditFatura,
      editable: true,
      editorType: "number",
      alwaysShowEditor: true,
    },
  ];

  return (
    <Table
      data={faturas}
      columns={columns}
      pageSize={10}
      keyField="parcela"
      showRegisterCount={false}
      paginated={false}
      fixedSize={false}
    />
  );
};
