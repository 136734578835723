import React, { useState } from "react";
import {
  FixedField,
  IconButton,
  ModalBase,
  Table,
} from "../../../../components";
import {
  formatNumber,
  formatValueFromAPI,
  formatarValor,
} from "../../../../coreUtils";
import NotaFiscalConsumidorService from "../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { BiCopy } from "react-icons/bi";
import { toastr } from "react-redux-toastr";
import { Row, UncontrolledTooltip } from "reactstrap";

const columns = [
  {
    dataField: "item",
    text: "I",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "cod_prod",
    text: "Prod",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "nome_prod",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
  },
  {
    dataField: "cfop",
    text: "CFOP",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "ncm",
    text: "NCM",
    align: "center",
    headerAlign: "center",
    formatter: (c, row) =>
      row.ncm_valido ? (
        c
      ) : (
        <>
          <span style={{ color: "red" }} id={`ncm-inv-${row.id}`}>
            {c}
          </span>
          <UncontrolledTooltip target={`ncm-inv-${row.id}`} placement="left">
            NCM Inválida
          </UncontrolledTooltip>
        </>
      ),
  },
  {
    dataField: "sit_trib_icms",
    text: "CST",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "ean",
    text: "EAN",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "unidade",
    text: "UN",
    align: "center",
    headerAlign: "center",
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    headerAlign: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_unit",
    text: "Unitário",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_item",
    text: "Item",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    formatter: (c) => formatNumber(c, true, 2),
  },
];

const columnsFormasPag = [
  {
    dataField: "desc_forma_pag",
    text: "Forma Pagamento",
    align: "left",
  },
  {
    dataField: "valor",
    text: "Valor",
    align: "right",
    formatter: (c) => formatarValor(c, 2, 2, true),
  },
];

export const DetalhesNFCeModal = ({ isOpen, toggle, selected }) => {
  const [dados, setDados] = useState({});

  const limparDados = () => {
    setDados({});
  };

  const carregarDados = async () => {
    const [ok, ret] = await NotaFiscalConsumidorService.buscar(selected);
    if (ok) {
      setDados(ret);
    } else {
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Detalhes da NFC-e"
      number="0066_2"
      autoFocus
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Row
        className="mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <FixedField label="Número" value={dados.numero} horizontal />
        <FixedField label="Série" value={dados.serie} horizontal />
        <FixedField label="Emissão" value={dados.emissao} horizontal />
        <FixedField
          label="Inclusão"
          value={`${dados.dt_inclusao} ${dados.hr_inclusao}`}
          horizontal
          divClassName="pr-0"
        />
      </Row>
      <Row
        className="mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <FixedField
          label="Tipo de Documento"
          value={dados.desc_tipo_doc}
          horizontal
        />
        <FixedField
          label="Origem"
          value={`${dados.desc_origem} - ${dados.nro_origem}`}
          horizontal
          divClassName="pr-0"
        />
      </Row>
      <Row
        className="mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <FixedField
          label="Chave de Acesso"
          value={
            <>
              {dados.chave_nfce}{" "}
              {(dados.chave_nfce ?? "").length > 0 && (
                <IconButton
                  size={12}
                  icon={BiCopy}
                  onClick={() => {
                    navigator.clipboard.writeText(dados.chave_nfce);
                    toastr.info("", "Chave de Acesso copiada.");
                  }}
                />
              )}
            </>
          }
          horizontal
        />
        <FixedField
          label="Protoc. Autorização"
          value={dados.protoc_emi_nfce}
          horizontal
          divClassName="pr-0"
        />
      </Row>
      <Row
        className="mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <FixedField
          label="Cliente"
          value={formatValueFromAPI(dados.nome_cli, dados.cod_cli)}
          horizontal
        />
        <FixedField
          label="CPF/CNPJ"
          value={dados.cpf_cnpj_cli || "Não Informado"}
          horizontal
          divClassName="pr-0"
        />
      </Row>
      <Row
        className="mb-2"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <FixedField
          label="Base Cálc. ICMS"
          value={formatNumber(dados.base_calc_icms ?? 0, true, 2)}
          horizontal
        />
        <FixedField
          label="ICMS"
          value={formatNumber(dados.vlr_tot_icms ?? 0, true, 2)}
          horizontal
        />
        <FixedField
          label="Total Produtos"
          value={formatNumber(dados.vlr_tot_prod ?? 0, true, 2)}
          horizontal
        />
        <FixedField
          label="Total da Nota"
          value={formatNumber(dados.vlr_tot_nf ?? 0, true, 2)}
          horizontal
          divClassName="pr-0"
        />
      </Row>
      <Table
        data={dados.itens ?? []}
        columns={columns}
        pageSize={8}
        paginated={false}
        bgStyle={{ marginBottom: "1rem" }}
      />
      <Table
        keyField="forma_pag"
        data={dados.pagamento ?? []}
        columns={columnsFormasPag}
        paginated={false}
        showRegisterCount={false}
        pageSize={3}
        growIntoPageSize
      />
    </ModalBase>
  );
};
