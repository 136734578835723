import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import { reducer as gerenciamentoVendasMobile } from "./frente_venda_mobile/gerenciamentoVendasReducer";
import alerts from "./alertsReducer";
import confirmation from "./confirmationReducer";
import senha from "./senhaReducer";
import page from "./pageReducer";
import auth from "./authReducer";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  layout,
  theme,
  toastr,
  gerenciamentoVendasMobile,
  alerts,
  confirmation,
  senha,
  page,
  auth,
});
