import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  descricao: "",
  dentroEstabelecimento: null,
  finalidadeOper: "",
  ufOrigem: localStorage.getItem("uf_empresa"),
  ufDestino: "",
  tipoContrib: "",
  modeloNfe: "",
  cfop: "",
  cobrarNaFatura: false,
  mensagAdicNfe: "",
  sitTribIcms: "",
  modBcIcms: "",
  percIcms: 0,
  tipoRedBaseIcms: "",
  percRedBaseIcmsEm: 0,
  percRedBaseIcmsPara: 0,
  somaVlrFreteBcIcms: false,
  modBcIcmsSt: "",
  margValorAdicIcmsSt: 0,
  percIcmsSt: 0,
  percReduIcmsSt: 0,
  sitTribIpi: "",
  percIpi: 0,
  enquadIpi: "",
  somaVlrIpiBcIcmsSt: false,
  somaVlrIpiBcIcms: false,
  somaVlrFreteBcIpi: false,
  somaVlrOutrosBcIcms: false,
  sitTribPis: "",
  percPis: 0,
  percRetenPis: 0,
  sitTribCofins: "",
  percCofins: 0,
  percRetenCofins: 0,
  geraCredIcms: false,
  aliqCredIcms: false,
  percDesonerIcms: 0,
  motivoDesonerIcms: "",
  percRetenCsll: 0,
  percRetenIrpj: 0,
  percIcmsDifer: 0,
  deducVlrIcmsBcPisCofins: false,
  aliqAdRem: 0,
  cBenef: "",
  ativo: true,
  produtos: [],
  cstsIcms: [],
  camposAtivosIcms: {
    mod_bc_icms_ativo: true,
    aliq_icms_ativo: true,
    tipo_red_bc_icms_ativo: true,
    perc_red_em_bc_icms_ativo: true,
    perc_red_para_bc_icms_ativo: true,
    perc_desoner_icms_ativo: true,
    motivo_desoner_icms_ativo: true,
    mod_bc_icms_st_ativo: true,
    perc_red_bc_icms_st_ativo: true,
    perc_mva_bc_icms_st_ativo: true,
    aliq_icms_st_ativo: true,
    credito_icms_simples_ativo: true,
  },
};

export const cadRegraTribuacaoSlice = createSlice({
  name: "cad_regra_tribuacao",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setDescricao: (state, action) => {
      state.descricao = action.payload;
    },
    setDentroEstabelecimento: (state, action) => {
      state.dentroEstabelecimento = action.payload;
    },
    setFinalidadeOper: (state, action) => {
      state.finalidadeOper = action.payload;
    },
    setUfOrigem: (state, action) => {
      state.ufOrigem = action.payload;
    },
    setUfDestino: (state, action) => {
      state.ufDestino = action.payload;
    },
    setTipoContrib: (state, action) => {
      state.tipoContrib = action.payload;
    },
    setModeloNfe: (state, action) => {
      state.modeloNfe = action.payload;
    },
    setCfop: (state, action) => {
      state.cfop = action.payload;
    },
    setCobrarNaFatura: (state, action) => {
      state.cobrarNaFatura = action.payload;
    },
    setMensagAdicNfe: (state, action) => {
      state.mensagAdicNfe = action.payload;
    },
    setCamposAtivosIcms: (state, action) => {
      state.camposAtivosIcms = action.payload;
    },
    setSitTribIcms: (state, action) => {
      state.sitTribIcms = action.payload;
    },
    setModBcIcms: (state, action) => {
      state.modBcIcms = action.payload;
    },
    setPercIcms: (state, action) => {
      state.percIcms = action.payload;
    },
    setTipoRedBaseIcms: (state, action) => {
      state.tipoRedBaseIcms = action.payload;
    },
    setPercRedBaseIcmsEm: (state, action) => {
      state.percRedBaseIcmsEm = action.payload;
    },
    setPercRedBaseIcmsPara: (state, action) => {
      state.percRedBaseIcmsPara = action.payload;
    },
    setSomaVlrFreteBcIcms: (state, action) => {
      state.somaVlrFreteBcIcms = action.payload;
    },
    setModBcIcmsSt: (state, action) => {
      state.modBcIcmsSt = action.payload;
    },
    setMargValorAdicIcmsSt: (state, action) => {
      state.margValorAdicIcmsSt = action.payload;
    },
    setPercIcmsSt: (state, action) => {
      state.percIcmsSt = action.payload;
    },
    setPercReduIcmsSt: (state, action) => {
      state.percReduIcmsSt = action.payload;
    },
    setSitTribIpi: (state, action) => {
      state.sitTribIpi = action.payload;
    },
    setPercIpi: (state, action) => {
      state.percIpi = action.payload;
    },
    setEnquadIpi: (state, action) => {
      state.enquadIpi = action.payload;
    },
    setSomaVlrIpiBcIcmsSt: (state, action) => {
      state.somaVlrIpiBcIcmsSt = action.payload;
    },
    setSomaVlrIpiBcIcms: (state, action) => {
      state.somaVlrIpiBcIcms = action.payload;
    },
    setSomaVlrFreteBcIpi: (state, action) => {
      state.somaVlrFreteBcIpi = action.payload;
    },
    setSomaVlrOutrosBcIcms: (state, action) => {
      state.somaVlrOutrosBcIcms = action.payload;
    },
    setSitTribPis: (state, action) => {
      state.sitTribPis = action.payload;
    },
    setPercPis: (state, action) => {
      state.percPis = action.payload;
    },
    setSitTribCofins: (state, action) => {
      state.sitTribCofins = action.payload;
    },
    setPercCofins: (state, action) => {
      state.percCofins = action.payload;
    },
    setGeraCredIcms: (state, action) => {
      state.geraCredIcms = action.payload;
    },
    setAliqCredIcms: (state, action) => {
      state.aliqCredIcms = action.payload;
    },
    setPercDesonerIcms: (state, action) => {
      state.percDesonerIcms = action.payload;
    },
    setMotivoDesonerIcms: (state, action) => {
      state.motivoDesonerIcms = action.payload;
    },
    setPercRetenCsll: (state, action) => {
      state.percRetenCsll = action.payload;
    },
    setPercRetenIrpj: (state, action) => {
      state.percRetenIrpj = action.payload;
    },
    setPercIcmsDifer: (state, action) => {
      state.percIcmsDifer = action.payload;
    },
    setDeducVlrIcmsBcPisCofins: (state, action) => {
      state.deducVlrIcmsBcPisCofins = action.payload;
    },
    setCBenef: (state, action) => {
      state.cBenef = action.payload;
    },
    setAliqAdRem: (state, action) => {
      state.aliqAdRem = action.payload;
    },
    setAtivo: (state, action) => {
      state.ativo = action.payload;
    },
    setProdutos: (state, action) => {
      state.produtos = action.payload;
    },
    setCstsIcms: (state, action) => {
      state.cstsIcms = action.payload;
    },
    addProduto: (state, action) => {
      state.produtos.push(action.payload);
    },
    deleteProduto: (state, action) => {
      state.produtos = state.produtos.filter((p) => {
        return p.id !== action.payload;
      });
    },
  },
});

export const {
  init,
  setup,
  setDescricao,
  setDentroEstabelecimento,
  setFinalidadeOper,
  setUfOrigem,
  setUfDestino,
  setTipoContrib,
  setModeloNfe,
  setCfop,
  setCobrarNaFatura,
  setMensagAdicNfe,
  setCamposAtivosIcms,
  setSitTribIcms,
  setModBcIcms,
  setPercIcms,
  setTipoRedBaseIcms,
  setPercRedBaseIcmsEm,
  setPercRedBaseIcmsPara,
  setSomaVlrFreteBcIcms,
  setModBcIcmsSt,
  setMargValorAdicIcmsSt,
  setPercIcmsSt,
  setPercReduIcmsSt,
  setSitTribIpi,
  setPercIpi,
  setEnquadIpi,
  setSomaVlrIpiBcIcmsSt,
  setSomaVlrIpiBcIcms,
  setSomaVlrFreteBcIpi,
  setSomaVlrOutrosBcIcms,
  setSitTribPis,
  setPercPis,
  setSitTribCofins,
  setPercCofins,
  setGeraCredIcms,
  setAliqCredIcms,
  setPercDesonerIcms,
  setMotivoDesonerIcms,
  setPercRetenCsll,
  setPercRetenIrpj,
  setPercIcmsDifer,
  setDeducVlrIcmsBcPisCofins,
  setCBenef,
  setAliqAdRem,
  setAtivo,
  setProdutos,
  setCstsIcms,
  addProduto,
  deleteProduto,
} = cadRegraTribuacaoSlice.actions;

export default cadRegraTribuacaoSlice.reducer;
