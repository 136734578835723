import React, { useRef, useState } from "react";
import { ModalBase } from "./ModalBase";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_SENHA_MODAL, SHOW_SENHA_MODAL } from "../redux/constants";
import outsideStore from "../redux/store";
import { PasswordInput } from "./PasswordInput";
import { showWarning } from "./AlertaModal";

export const showSenha = ({ title, onConfirm, hint }) => {
  outsideStore.dispatch({
    type: SHOW_SENHA_MODAL,
    title: title,
    hint: hint,
    onConfirm: onConfirm,
  });
};

export const SenhaModalV2 = () => {
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => state.senha);
  const inputRef = useRef();

  const limparDados = () => setSenha("");

  const toggle = () => {
    if (store.isOpen) {
      dispatch({ type: CLOSE_SENHA_MODAL });
    }
  };

  const onConfirmInternal = async () => {
    if (senha.length === 0) {
      showWarning(`Por favor, informe a Senha de ${store.title}`);
      return false;
    }
    setLoading(true);
    try {
      if (await store.onConfirm(senha)) {
        toggle();
      } else {
        limparDados();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={store.isOpen}
      toggle={toggle}
      size="sm"
      title={`Informe a Senha de ${store.title}`}
      onConfirm={onConfirmInternal}
      loadingConfirm={loading}
      onClosed={limparDados}
    >
      {store.hint && <p>{store.hint}</p>}
      <PasswordInput value={senha} onChange={setSenha} ref={inputRef} />
    </ModalBase>
  );
};
