import React, { useEffect } from "react";
import { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Card, Row } from "reactstrap";
import {
  PageContainer,
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  FormButton,
  IntegerFormInput,
  LinkButton,
} from "../../../components";
import { MODAL_ACTIONS, viewDownloadPDF } from "../../../coreUtils";
import NotaFiscalServicoService from "../../../services/docs_eletron/NotaFiscalServicoService";
import { NotaFiscalServicoGrid } from "./components/NotaFiscalServicoGrid";
import { incluirAlterarNFServicoRoute } from "../../../routes/modules/docsEletron";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AlertasNFSeModal } from "./components/AlertasNFSeModal";
import { CancelarNFSeModal } from "./components/CancelarNFSeModal";
import { DescartarDigitacaoNFSeModal } from "./components/DescartarDigitacaoModal";
import { apiGetV2 } from "../../../apiV2";
import { showWarning } from "../../../components/AlertaModal";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  setDataFim,
  setDataIni,
  setCliente,
  setNumeroNF,
  setSituacao,
} from "./store/nfServicoSlice";
import store from "./store";

const situacoes = [
  { label: "Todas", value: "T" },
  { label: "Aguardando Emissão", value: "A" },
  { label: "Salvas/Em digitação", value: "S" },
  { label: "Emitidas", value: "E" },
  { label: "Aguardando Confirmação", value: "R" },
  { label: "Canceladas", value: "C" },
];

const NotaFiscalServicoContainer = ({ location }) => {
  const store = useSelector((state) => state.nfServico);
  const dispatch = useDispatch();

  const history = useHistory();
  const [totais, setTotais] = useState({});
  const [dados, setDados] = useState([]);
  const [selected, setSelected] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEmitir, setLoadingEmitir] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingImprimir, setLoadingImprimir] = useState(false);

  const [alertasOpen, setAlertasOpen] = useState(false);
  const [cancelarNFSeOpen, setCancelarNFSeOpen] = useState(false);
  const [descarteDigitacaoOpen, setDescarteDigitacaoOpen] = useState(false);

  const clearSelection = () => {
    setSelected([]);
    setLastSelected(null);
  };

  const buscarParametros = async () => {
    const [ok, ret] = await apiGetV2(`/tela/nf_servico/`);
    if (ok) {
      if (ret.regime_tribut === "MEI") {
        showWarning(
          "Empresa definida como MEI, não é possível emissão de " +
            "documentos eletrônicos através do sistema. Empresa utiliza " +
            "emissão através do sistema da NFS-e Nacional"
        );
        history.goBack();
      }
    }
  };

  const inicializarTela = () => {
    buscarParametros();
  };

  useEffect(inicializarTela, []);

  const carregarDados = async () => {
    setLoading(true);
    const params = {
      id_cliente: store.cliente,
      numero_nf: store.numeroNF,
      situacao: store.situacao,
      data_ini: store.dataIni,
      data_fim: store.dataFim,
    };

    const [ok, ret] = await NotaFiscalServicoService.listar(params);

    if (ok) {
      clearSelection();
      setDados(ret.notas ?? []);
      setTotais(ret.totais ?? {});
    } else {
      setDados([]);
      setTotais({});
    }
    setLoading(false);
  };

  const handleDate = (di, df) => {
    dispatch(setDataIni(di));
    dispatch(setDataFim(df));
  };

  const handleSelectAll = (isSelected) => {
    setSelected(isSelected ? dados.map((e) => e.id) : []);
  };

  const handleSetSelected = (v, isSelected, row) => {
    setSelected(
      isSelected ? [...selected, v] : selected.filter((e) => e !== v)
    );
    setLastSelected(isSelected ? v : null);
  };

  const imprimirNFSe = async (id) => {
    const [blob, nomeArq] = await NotaFiscalServicoService.imprimirNFSe(id);
    if (blob) {
      viewDownloadPDF(blob, nomeArq);
    }
  };

  const enviarEmailNFSe = async (id) => {
    await NotaFiscalServicoService.enviarEmail(id);
  };

  const emitirNFSes = async () => {
    setLoadingEmitir(true);
    for (let i = 0; i < dados.length; i++) {
      if (selected.includes(dados[i].id)) {
        try {
          const [ok] = await NotaFiscalServicoService.emitirNFSe(dados[i].id);
          if (ok) {
            await imprimirNFSe(dados[i].id);
          }
        } catch (error) {
          toastr.error("Erro", error.message);
        }
      }
    }
    await carregarDados();
    setLoadingEmitir(false);
  };

  const enviarEmails = async () => {
    setLoadingEmail(true);
    const sel = dados.filter((e) => selected.includes(e.id)).map((e) => e.id);
    for (let i = 0; i < sel.length; i++) {
      try {
        await enviarEmailNFSe(sel[i]);
      } catch (error) {
        toastr.error("Erro", error.message);
      }
    }
    setLoadingEmail(false);
  };

  const imprimirNFSes = async () => {
    setLoadingImprimir(true);
    const sel = dados.filter((e) => selected.includes(e.id)).map((e) => e.id);
    for (let i = 0; i < sel.length; i++) {
      try {
        await imprimirNFSe(sel[i]);
      } catch (error) {
        toastr.error("Erro", error.message);
      }
    }
    setLoadingImprimir(false);
  };

  const cancelarNFSe = async (id) => {
    setLastSelected(id);
    toggleCancelarNFSe();
  };

  const toggleCancelarNFSe = () => setCancelarNFSeOpen(!cancelarNFSeOpen);

  const toggleAlertas = () => setAlertasOpen(!alertasOpen);

  const toggleDescarteDigitacao = () =>
    setDescarteDigitacaoOpen(!descarteDigitacaoOpen);

  const consultarNFSe = async (id) => {};

  const alertasNFSe = async (id) => {
    setLastSelected(id);
    toggleAlertas();
  };

  const alterarNFSe = (id) => {
    history.push(incluirAlterarNFServicoRoute.path, {
      selected: id,
      action: MODAL_ACTIONS.EDIT,
    });
  };

  const descartarDigitacao = (id) => {
    setLastSelected(id);
    toggleDescarteDigitacao();
  };

  const carregarDadosOnRefresh = () => {
    if (location.state?.refresh) {
      carregarDados();
    }
  };

  useEffect(carregarDadosOnRefresh, []);

  return (
    <>
      <Card body>
        <Row>
          <AsyncComboBox
            md={5}
            label="Cliente"
            name="cliente"
            isConcatField
            concatModelName="cliente"
            isSearchable
            isClearable
            defaultValue={store.cliente}
            onChange={(s) => dispatch(setCliente(s?.value))}
          />
          <IntegerFormInput
            md={2}
            label="Número NF"
            onChange={(v) => dispatch(setNumeroNF(v))}
            defaultValue={store.numeroNF}
          />
          <ComboBox
            md={3}
            label="Situação"
            options={situacoes}
            onChange={(s) => dispatch(setSituacao(s?.value))}
            isClearable={false}
            isSearchable={false}
            defaultValue={store.situacao}
          />
        </Row>
        <Row>
          <FiltroPeriodoDatas
            onChange={handleDate}
            defaultOption={null}
            defaultStart={store.dataIni}
            defaultEnd={store.dataFim}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <LinkButton
            md="auto"
            color="info"
            pathname={incluirAlterarNFServicoRoute.path}
            state={{ selected: lastSelected, action: MODAL_ACTIONS.ADD }}
            divClassName="ml-5"
          >
            Incluir
          </LinkButton>
          <FormButton
            color="success"
            md="auto"
            onClick={emitirNFSes}
            disabled={
              selected?.length === 0 ||
              dados.find((e) => selected.includes(e.id) && e.status !== "A")
            }
            hint="Emite e envia NFS-e por e-mail"
            disabledHint={
              selected?.length === 0
                ? "Selecione ao menos uma NFSe da lista."
                : dados.find((e) => selected.includes(e.id) && e.status !== "A")
                ? "Selecione somente NFSe's que não tenham sido emitidas."
                : ""
            }
            loading={loadingEmitir}
          >
            Emitir NFS-e
          </FormButton>
          <FormButton
            md="auto"
            color="secondary"
            onClick={enviarEmails}
            loading={loadingEmail}
            disabled={
              selected?.length === 0 ||
              dados.find((e) => selected.includes(e.id) && e.status !== "E")
            }
            disabledHint={
              selected?.length === 0
                ? "Selecione ao menos uma NFSe da lista."
                : dados.find((e) => selected.includes(e.id) && e.status !== "E")
                ? "Selecione somente NFSe's emitidas."
                : ""
            }
          >
            Enviar E-Mail
          </FormButton>
          <FormButton
            md="auto"
            color="primary"
            onClick={imprimirNFSes}
            loading={loadingImprimir}
            disabled={
              selected?.length === 0 ||
              dados.find(
                (e) => selected.includes(e.id) && !["E", "C"].includes(e.status)
              )
            }
            disabledHint={
              selected?.length === 0
                ? "Selecione ao menos uma NFSe da lista."
                : dados.find(
                    (e) =>
                      selected.includes(e.id) && !["E", "C"].includes(e.status)
                  )
                ? "Selecione somente NFSe's emitidas ou canceladas."
                : ""
            }
          >
            Imprimir
          </FormButton>
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Quantidade"
          value={(dados ?? []).length}
          checkFloat={true}
          className="col-md-2 ml-auto"
        />
        <CardTotaisItem
          label="Valor Total"
          value={totais?.valor_total}
          className="col-md-2"
        />
      </CardTotais>
      <NotaFiscalServicoGrid
        data={dados}
        onSelectAll={handleSelectAll}
        setSelected={handleSetSelected}
        cancelar={cancelarNFSe}
        consultar={consultarNFSe}
        alertas={alertasNFSe}
        selected={selected}
        alterar={alterarNFSe}
        descartarDigitacao={descartarDigitacao}
      />
      <AlertasNFSeModal
        isOpen={alertasOpen}
        toggle={toggleAlertas}
        selected={lastSelected}
      />
      <CancelarNFSeModal
        isOpen={cancelarNFSeOpen}
        toggle={toggleCancelarNFSe}
        selected={lastSelected}
        notify={carregarDados}
      />
      <DescartarDigitacaoNFSeModal
        isOpen={descarteDigitacaoOpen}
        toggle={toggleDescarteDigitacao}
        selected={lastSelected}
        notify={carregarDados}
      />
    </>
  );
};

export const NotaFiscalServico = ({ location }) => {
  return (
    <PageContainer title="Nota Fiscal de Serviço" number="0046" canGoBack>
      <Provider store={store}>
        <NotaFiscalServicoContainer location={location} />
      </Provider>
    </PageContainer>
  );
};
