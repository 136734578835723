import React from "react";
import { Card } from "reactstrap";
import { PageContainer } from "../../../components/PageContainer";
import { TabBody, TabController } from "../../../components/TabController";
import { ImpressaoEtiquetaEstoque } from "./components/ImpressaoEtiquetaEstoque";
import { ImpressaoOrcamento } from "./components/ImpressaoOrcamento";
import { ImpressaoVenda } from "./components/ImpressaoVenda";
import { ImpressaoFechOS } from "./components/ImpressaoFechOS";
import { ImpressaoEmiOS } from "./components/ImpressaoEmiOS";
import { ImpressaoPedidoExt } from "./components/ImpressaoPedidoExt";
import { ImpressaoPedidoInt } from "./components/ImpressaoPedidoInt";
import { ImpressaoRecibo } from "./components/ImpressaoRecibo";
import { ImpressaoCarne } from "./components/ImpressaoCarne";

export const Impressao = () => {
  return (
    <PageContainer title="Definições de Impressão" number="conf_05">
      <Card body>
        <TabController>
          <TabBody title="Venda">
            <ImpressaoVenda />
          </TabBody>
          <TabBody title="Orçamento">
            <ImpressaoOrcamento />
          </TabBody>
          <TabBody title="Etiqueta de Estoque">
            <ImpressaoEtiquetaEstoque />
          </TabBody>
          <TabBody title="O.S. - Abertura">
            <ImpressaoEmiOS />
          </TabBody>
          <TabBody title="O.S. - Fechamento">
            <ImpressaoFechOS />
          </TabBody>
          <TabBody title="Pedido Externo">
            <ImpressaoPedidoExt />
          </TabBody>
          <TabBody title="Pedido Interno">
            <ImpressaoPedidoInt />
          </TabBody>
          <TabBody title="Recibo">
            <ImpressaoRecibo />
          </TabBody>
          <TabBody title="Carnê">
            <ImpressaoCarne />
          </TabBody>
        </TabController>
      </Card>
    </PageContainer>
  );
};
