import React from "react";
import { TableCheck } from "../../../../../../components/TableCheck";
import { Table } from "../../../../../../components";
import { naturalSort } from "../../../../../../coreUtils";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../../components/cadastro";

export const CondutorGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      sortable: true,
    },
    {
      dataField: "cpf",
      text: "CPF",
      align: "center",
    },
    {
      dataField: "status",
      text: "Ativo",
      align: "center",
      formatter: (c) => <TableCheck value={c} />,
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];
  return <Table columns={columns} data={data} onSelect={setSelected} />;
};
