import React from "react";
import { ModalBase } from "../../../../components/ModalBase";
import NotaFiscalServicoService from "../../../../services/docs_eletron/NotaFiscalServicoService";

export const DescartarDigitacaoNFSeModal = ({
  isOpen,
  toggle,
  selected,
  notify,
}) => {
  const onConfirm = async () => {
    const [ok] = await NotaFiscalServicoService.descartarDigitacao(selected);
    if (ok) {
      notify();
      toggle();
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Descartar Digitação NFSe"
      number="0046_4"
      autoFocus
      onConfirm={onConfirm}
      confirmButtonText="Sim"
      cancelButtonText="Não"
    >
      Você tem certeza que deseja descartar a digitação da NF-e #{selected}?
    </ModalBase>
  );
};
