import React from "react";
import { ModalBase } from "../../../../../../components";

export const ConfirmarRepetirItemNfModal = ({ isOpen, toggle, onConfirm }) => {
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Inclusão de Item em NFe"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      onConfirm={() => {
        onConfirm();
        toggle();
      }}
    >
      <p style={{ textAlign: "center", width: "100%" }}>
        O Produto já foi incluido na Nota Fiscal.
        <br />
        Deseja incluí-lo novamente?
      </p>
    </ModalBase>
  );
};
