import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  Divider,
  FixedField,
  FormButton,
  IconButton,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { Label, Row } from "reactstrap";
import { BsMagic } from "react-icons/bs";
import {
  formatDateISO,
  formatNumber,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import moment from "moment";
import { showWarning } from "../../../../../components/AlertaModal";
import GerencContasPagarService from "../../../../../services/financeiro/GerencContasPagarService";
import { showConfirmation } from "../../../../../components/ConfirmationModal";
import { IdentChequesPagamentoModal } from "./IdentChequesPagamentoModal";

const LinhaValor = ({ label, value, onChange, preencAuto }) => (
  <Row style={{ justifyContent: "center" }}>
    <NumberInput md={6} label={label} value={value} onChange={onChange} />
    <IconButton
      icon={BsMagic}
      onClick={() => preencAuto(onChange)}
      className="mt-4"
    />
  </Row>
);

export const PagarTitulosModal = ({ titulosSelected, notifyEvent }) => {
  const idColab = parseInt(localStorage.getItem("id_colaborador"));
  const idEmpresa = parseInt(localStorage.getItem("id_empresa"));
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [identChequesOpen, setIdentChequesOpen] = useState(false);

  // Conta Bancária
  const [idContaContaBanc, setIdContaContaBanc] = useState(null);
  const [historContaBanc, setHistorContaBanc] = useState("");
  const [dataPagContaBanc, setDataPagContaBanc] = useState(null);
  const [valorContaBanc, setValorContaBanc] = useState(0);

  // Caixa ADM
  const [historCaixaAdm, setHistorCaixaAdm] = useState("");
  const [dataPagCaixaAdm, setDataPagCaixaAdm] = useState(null);
  const [valorCaixaAdm, setValorCaixaAdm] = useState(0);

  // Cheque de Cliente
  const [valorChequeRec, setValorChequeRec] = useState(0);
  const [idChequesRec, setIdChequesRec] = useState([]);

  const selecionouTituloPago = titulosSelected.some((e) => e.aberto === false);
  const vlrPagar = roundFloat(sumDataField(titulosSelected, "vlr_pagar"), 2);
  const totalLancado = roundFloat(
    valorContaBanc + valorCaixaAdm + valorChequeRec,
    2
  );
  const totalLancar = roundFloat(vlrPagar - totalLancado, 2);
  const multiplosTitulos = titulosSelected.length > 1;

  const limparValores = () => {
    setValorContaBanc(0);
    setValorCaixaAdm(0);
    setValorChequeRec(0);
  };

  const limparDados = () => {
    limparValores();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const preencAuto = (funcao) => {
    limparValores();
    funcao(parseFloat(vlrPagar));
  };

  const verificarValoresRecursos = () => {
    if (valorContaBanc === 0) {
      setIdContaContaBanc(null);
      setHistorContaBanc("");
      setDataPagContaBanc(null);
    }

    if (valorCaixaAdm === 0) {
      setHistorCaixaAdm("");
      setDataPagCaixaAdm(null);
    }
  };

  const onChangeValores = () => {
    verificarValoresRecursos();
  };

  useEffect(onChangeValores, [valorCaixaAdm, valorContaBanc, valorChequeRec]);

  const onChangeValorChequeRec = () => {
    setIdChequesRec([]);
  };

  useEffect(onChangeValorChequeRec, [valorChequeRec]);

  const toggleIdentCheques = () => setIdentChequesOpen(!identChequesOpen);

  const identificarCheques = (idCheques) => {
    setIdChequesRec(idCheques);
    setTimeout(() => {
      handleSubmit(true, idCheques);
    }, 1);
  };

  const handleSubmit = async (verificarPagtoParcial, idCheques) => {
    let recursos = [];
    let pagtoParcial = false;
    if (totalLancado === 0) {
      showWarning("Por favor, informe ao menos um Recurso de Pagamento.");
      return;
    } else if (totalLancado > vlrPagar) {
      showWarning(`A soma dos recursos utilizados para o pagamento é MAIOR
          que valor total dos títulos selecionados.\n
          Por Favor, verifique os valores informados.`);
      return;
    } else if (totalLancado < vlrPagar) {
      if (multiplosTitulos) {
        showWarning(`A soma dos recursos utilizados para o pagamento é MENOR
          que valor total dos títulos selecionados.\n
          Por Favor, verifique os valores informados.`);
        return;
      } else {
        pagtoParcial = true;
      }
    }

    if (valorContaBanc > 0) {
      if ([0, null, undefined].includes(idContaContaBanc)) {
        showWarning("Por favor, informe a Conta Bancária.");
        return;
      }
      if (!(dataPagContaBanc instanceof Date)) {
        showWarning("Por favor, revise a Data do Pagamento em Conta Bancária.");
        return;
      }

      recursos.push({
        conta_banc: {
          id_conta: idContaContaBanc,
          histor: historContaBanc,
          data_pag: formatDateISO(dataPagContaBanc),
          valor: valorContaBanc,
        },
      });
    }

    if (valorCaixaAdm > 0) {
      if (!(dataPagCaixaAdm instanceof Date)) {
        showWarning(
          "Por favor, revise a Data do Pagamento em Caixa Administrativo."
        );
        return;
      }

      recursos.push({
        caixa_adm: {
          empresa: idEmpresa,
          histor: historCaixaAdm,
          data_pag: formatDateISO(dataPagCaixaAdm),
          valor: valorCaixaAdm,
        },
      });
    }

    if (valorChequeRec > 0) {
      if (idCheques.length === 0) {
        toggleIdentCheques();
        return;
      }

      recursos.push({
        cheque_rec: {
          valor: valorChequeRec,
          id_cheques_rec: idCheques,
        },
      });
    }

    if (verificarPagtoParcial && pagtoParcial) {
      const labelStyle = { fontSize: "0.9rem" };
      const rowClassName = "mx-0";

      showConfirmation(
        <>
          <Row className={rowClassName}>
            <Label style={{ ...labelStyle, fontWeight: "bold" }}>
              Deseja confirmar o pagamento PARCIAL do título?
            </Label>
          </Row>
          <Row className={rowClassName}>
            <Label style={labelStyle}>
              Valor do Título:{" "}
              <strong>{formatNumber(vlrPagar, true, 2)}</strong>
            </Label>
          </Row>
          <Row className={rowClassName}>
            <Label style={labelStyle}>
              Valor dos Recursos:{" "}
              <strong>{formatNumber(totalLancado, true, 2)}</strong>
            </Label>
          </Row>
        </>,
        () => handleSubmit(false, idChequesRec)
      );
      return;
    }

    const payload = {
      id_colab: idColab,
      titulos_pag: titulosSelected.map((e) => ({
        id_duplic_pag_mov: e.id,
        vlr_pagar: multiplosTitulos ? parseFloat(e.vlr_pagar) : totalLancado,
      })),
      recursos: recursos,
    };
    setLoading(true);
    const [ok] = await GerencContasPagarService.liquidar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <>
      <FormButton
        md="auto"
        color="success"
        onClick={toggle}
        disabled={selecionouTituloPago || titulosSelected.length === 0}
        disabledHint={
          selecionouTituloPago
            ? "Selecione somete títulos abertos"
            : "Selecione ao menos um título da lista"
        }
        padded={false}
      >
        Efetuar Pagamento
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Efetuar Pagamento de Títulos"
        number="0091_6"
        onClosed={limparDados}
        onConfirm={() => handleSubmit(true, idChequesRec)}
        loadingConfirm={loading}
      >
        <Row style={{ justifyContent: "center" }} className="mb-2">
          <FixedField
            label="Valor Total a Pagar"
            value={formatNumber(vlrPagar, true, 2)}
            labelStyle={{ fontSize: "1rem" }}
            textStyle={{ fontSize: "1rem" }}
            horizontal
          />
        </Row>
        <LinhaValor
          label="Conta Bancária"
          value={valorContaBanc}
          onChange={setValorContaBanc}
          preencAuto={preencAuto}
        />
        <LinhaValor
          label="Caixa Administrativo"
          value={valorCaixaAdm}
          onChange={setValorCaixaAdm}
          preencAuto={preencAuto}
        />
        <LinhaValor
          label="Cheque de Cliente"
          value={valorChequeRec}
          onChange={setValorChequeRec}
          preencAuto={preencAuto}
        />
        <Row style={{ justifyContent: "space-between" }} className="mt-2">
          <FixedField
            label={multiplosTitulos ? "Total a Lançar" : "Restante a Pagar"}
            value={formatNumber(totalLancar, true, 2)}
            horizontal
          />
          <FixedField
            label="Total Lançado"
            value={formatNumber(totalLancado, true, 2)}
            horizontal
          />
        </Row>
        <IdentChequesPagamentoModal
          isOpen={identChequesOpen}
          toggle={toggleIdentCheques}
          valorChequeRec={valorChequeRec}
          identificarCheques={identificarCheques}
        />
        {valorContaBanc > 0 && (
          <>
            <Divider>Informações Conta Bancária</Divider>
            <Row>
              <AsyncComboBox
                md={12}
                label="Conta"
                isSearchable
                isConcatField
                defaultOptions
                concatModelName="conta_banc"
                defaultValue={idContaContaBanc}
                onChange={(s) => setIdContaContaBanc(s?.value ?? null)}
              />
            </Row>
            <Row>
              <TextInput
                md={12}
                label="Histórico"
                value={historContaBanc}
                onChange={(e, v) => setHistorContaBanc(v)}
              />
            </Row>
            <Row>
              <DatePicker
                md={6}
                label="Data"
                value={dataPagContaBanc}
                onChange={(v) =>
                  setDataPagContaBanc(moment.isMoment(v) ? v.toDate() : v)
                }
              />
            </Row>
          </>
        )}
        {valorCaixaAdm > 0 && (
          <>
            <Divider>Informações Caixa Administrativo</Divider>
            <Row>
              <AsyncComboBox
                md={12}
                label="Empresa"
                isSearchable
                isConcatField
                defaultOptions
                concatModelName="empresa"
                defaultValue={idEmpresa}
                disabled
              />
            </Row>
            <Row>
              <TextInput
                md={12}
                label="Histórico"
                value={historCaixaAdm}
                onChange={(e, v) => setHistorCaixaAdm(v)}
              />
            </Row>
            <Row>
              <DatePicker
                md={6}
                label="Data"
                value={dataPagCaixaAdm}
                onChange={(v) =>
                  setDataPagCaixaAdm(moment.isMoment(v) ? v.toDate() : v)
                }
              />
            </Row>
          </>
        )}
      </ModalBase>
    </>
  );
};
