import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { formasPagNfe } from "../../../../../utils/flags";
import {
  ComboBox,
  FixedField,
  FormButton,
  NumberInput,
  Table,
  TableDelete,
} from "../../../../../components";
import { FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { formatarValor, sumDataField } from "../../../../../coreUtils";
import { toastr } from "react-redux-toastr";
import { setFormasPag } from "../store/cabNFSlice";

const FormasPagNfForm = () => {
  const stateCab = useSelector((state) => state.cabNF);
  const valorNFe = stateCab.totaisNfe.vlrTotFatura;
  const dispatch = useDispatch();

  const [descFormaPag, setDescFormaPag] = useState("");
  const [formaPag, setFormaPag] = useState(null);
  const [valor, setValor] = useState(0);

  const totalLancado = sumDataField(stateCab.formasPag, "valor");

  const handleAdd = () => {
    if (stateCab.formasPag.some((e) => e.forma_pag === formaPag)) {
      toastr.warning(
        "Atenção",
        "A forma de pagamento já foi incluída. Se quiser " +
          "adicionar mais valor, altere o registro na grade ao lado"
      );
      return;
    }

    dispatch(
      setFormasPag([
        ...stateCab.formasPag,
        { desc_forma_pag: descFormaPag, forma_pag: formaPag, valor: valor },
      ])
    );
    setFormaPag(null);
    setValor(0);
  };

  const removerFormaPag = (formaPagRemover) => {
    dispatch(
      setFormasPag([
        ...stateCab.formasPag.filter((e) => e.forma_pag !== formaPagRemover),
      ])
    );
  };

  const atualizarFormaPag = (_, novoValor, dadosAntigosFormaPag) => {
    dadosAntigosFormaPag.valor = novoValor;

    dispatch(
      setFormasPag([
        ...stateCab.formasPag.map((e) =>
          e.forma_pag === dadosAntigosFormaPag.forma_pag
            ? dadosAntigosFormaPag
            : e
        ),
      ])
    );
  };

  const columnsFormasPag = [
    {
      dataField: "desc_forma_pag",
      text: "Forma Pagamento",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatarValor(c, 2, 2, true),
      fixedColWidth: true,
      colWidth: "80px",
      editable: true,
      onChange: atualizarFormaPag,
      editorType: "number",
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      headerAlign: "center",
      colHeaderStyle: { fontWeight: "normal" },
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <TableDelete onClick={() => removerFormaPag(row.forma_pag)} />
      ),
    },
  ];

  return (
    <Row>
      <Col md={5} className="px-0">
        <ComboBox
          md={12}
          label="Forma de Pagamento"
          options={formasPagNfe}
          isClearable
          isSearchable={false}
          menuPlacement="top"
          defaultValue={formaPag}
          onChange={(s) => {
            setFormaPag(s?.value);
            setDescFormaPag(s?.label);
          }}
        />
        <Row className="pl-3">
          <NumberInput
            md={8}
            label="Valor"
            value={valor}
            onChange={(v) => setValor(v)}
          />
          <FormButton
            md={4}
            color="indigo"
            disabled={
              valor === 0 ||
              !formasPagNfe.map((e) => e.value).includes(formaPag)
            }
            onClick={handleAdd}
          >
            <FaArrowRight />
          </FormButton>
        </Row>
      </Col>
      <Col md={7}>
        <Table
          keyField="forma_pag"
          data={stateCab.formasPag}
          columns={columnsFormasPag}
          paginated={false}
          showRegisterCount={false}
          pageSize={3}
        />
      </Col>
      <Col md={{ size: 7, offset: 5 }} className="pr-0">
        <Row className="px-3 pr-0" style={{ textAlign: "right" }}>
          <FixedField
            md={4}
            label="Total a Lançar"
            value={formatarValor(valorNFe, 2, 2, false)}
            disabled
            divClassName="pl-0"
          />
          <FixedField
            md={4}
            label="A Lançar"
            value={formatarValor(valorNFe - totalLancado, 2, 2, false)}
            disabled
          />
          <FixedField
            md={4}
            label="Lançado"
            value={formatarValor(totalLancado, 2, 2, false)}
            disabled
            divClassName="pr-0"
          />
        </Row>
      </Col>
    </Row>
  );
};

export default FormasPagNfForm;
