import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pista: "",
  finalidade: null,
  idProduto: null,
  cfop: null,
  modelo: null,
  ufDestino: null,
  tipoContrib: null,
};

export const listagemRegraTribSlice = createSlice({
  name: "listagem_regra_trib_slice",
  initialState: initialState,
  reducers: {
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setPista: (state, action) => {
      state.pista = action.payload;
    },
    setFinalidade: (state, action) => {
      state.finalidade = action.payload;
    },
    setIdProduto: (state, action) => {
      state.idProduto = action.payload;
    },
    setCfop: (state, action) => {
      state.cfop = action.payload;
    },
    setModelo: (state, action) => {
      state.modelo = action.payload;
    },
    setUfDestino: (state, action) => {
      state.ufDestino = action.payload;
    },
    setTipoContrib: (state, action) => {
      state.tipoContrib = action.payload;
    },
  },
});

export const {
  setup,
  setPista,
  setFinalidade,
  setIdProduto,
  setCfop,
  setModelo,
  setUfDestino,
  setTipoContrib,
} = listagemRegraTribSlice.actions;

export default listagemRegraTribSlice.reducer;
