import React from "react";
import { ModalBase, Table } from "../../../../../components";
import { formatNumber } from "../../../../../coreUtils";

export const columns = [
  {
    dataField: "modelo",
    text: "Modelo",
    align: "center",
  },
  {
    dataField: "numero_nf",
    text: "Número",
    align: "center",
  },
  {
    dataField: "serie_nf",
    text: "Série",
    align: "center",
  },
  {
    dataField: "nome_dest",
    text: "Destinatário",
    align: "left",
  },
  {
    dataField: "chave_acesso",
    text: "Chave de Acesso",
    align: "Center",
  },
  {
    dataField: "data_emissao",
    text: "Emissão",
    align: "Center",
  },
  {
    dataField: "cfop",
    text: "CFOP",
    align: "Center",
  },
  {
    dataField: "ncm",
    text: "NCM",
    align: "Center",
  },
  {
    dataField: "total_nf",
    text: "Valor NF-e",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
  {
    dataField: "vlr_prod",
    text: "Valor Produto",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2, true),
  },
];

export const ModalDetalhesNotas = ({ isOpen, toggle, notasProduto }) => {
  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="xl"
        title={`Notas Fiscais do Produto`}
        number="5043_1"
      >
        <Table
          data={notasProduto}
          columns={columns}
          paginated={false}
          pageSize={25}
        />
      </ModalBase>
    </>
  );
};
