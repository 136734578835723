import React from "react";
import { Table, TableCheck } from "../../../../../components";
import {
  formatNumber,
  parseRound,
  roundNumber,
} from "../../../../../coreUtils";
import { MdOutlineArrowDownward, MdOutlineArrowUpward } from "react-icons/md";
import { LuEqual } from "react-icons/lu";
import unidecode from "unidecode";
import { UncontrolledTooltip } from "reactstrap";

const corUnidadeMedida = (row) => {
  const unidadeNf = unidecode(row.unid_prod_nf ?? "").toUpperCase();
  const unidadeCadProd = unidecode(row.unid_med_prod ?? "").toUpperCase();

  return unidadeCadProd &&
    unidadeNf !== unidadeCadProd &&
    [0, null, undefined].includes(parseFloat(row.fator_conv ?? 0))
    ? "indigo"
    : undefined;
};

export const ProdutosEntradaNfXmlGrid = ({
  usaPrecoAtacado,
  dados,
  onSelect,
  handleEditItem,
  atualizarValoresItens,
  marcarDesmarcarItem,
}) => {
  const columns = [
    {
      dataField: "id_produto",
      text: "Prod",
      align: "center",
    },
    {
      dataField: "nome_produto",
      text: "Descrição do Produto",
      align: "left",
      formatter: (c, row) => {
        if (row.ligacao_encontrada) {
          const idItem =
            "prod-monofasico-" + Math.floor(Math.random() * Date.now());
          return (
            <>
              {c}
              {row.prod_monofasico && (
                <>
                  <span
                    style={{
                      padding: "0px 6px",
                      marginLeft: "5px",
                      background: "#ecb22e",
                      borderRadius: "14px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                    id={idItem}
                  >
                    MF
                  </span>
                  <UncontrolledTooltip target={idItem}>
                    Produto Monofásico
                  </UncontrolledTooltip>
                </>
              )}
            </>
          );
        }
        return (
          <span style={{ color: "blueviolet" }}>
            Ligação Não Encontrada - {row.desc_prod_nf}
          </span>
        );
      },
    },
    {
      dataField: "unid_prod_nf",
      text: "Dados NF",
      align: "center",
      formatter: (c, row) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ color: corUnidadeMedida(row) }}>
            {`${formatNumber(row.qtd_prod_nf, true, 2, true)} ${c}`}
          </div>
          <div>{`R$ ${formatNumber(row.vlr_unit_prod_nf, true, 2, true)}`}</div>
        </div>
      ),
    },
    {
      dataField: "vlr_desc_prod_unit",
      text: "Desconto",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_unit_prod",
      text: "Unitário",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_icms_st_unit",
      text: "ICMS ST",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_ipi_unit",
      text: "IPI",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_frete_unit",
      text: "Frete",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_outros_fora_nf_unit",
      text: "Outras",
      align: "right",
      formatter: (c, row) =>
        (row.vlr_outros_fora_nf ?? "0") > 0 ? (
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <span style={{ color: "#bbb" }}>
              {formatNumber(row.vlr_outros_fora_nf ?? "0", true, 2)}
            </span>
            {formatNumber(c ?? "0", true, 2)}
          </div>
        ) : (
          formatNumber(c ?? "0", true, 2)
        ),
    },
    {
      dataField: "unid_med_prod",
      text: "UN",
      align: "center",
      style: (c, row) => ({ color: corUnidadeMedida(row) }),
    },
    {
      dataField: "qtd_prod_entrada",
      text: "Qtd",
      align: "center",
      formatter: (c) => formatNumber(c, true, 6, true),
    },
    {
      dataField: "vlr_custo_unit",
      text: "Custo Unit",
      align: "right",
      formatter: (c, row) => {
        const custoAtual = parseRound(row.custo_ue_prod);
        const custoNovo = parseFloat(c);

        if (!row.ligacao_encontrada) {
          return formatNumber(c, true, 2);
        } else {
          let Icone;

          if (roundNumber(Math.abs(custoNovo - custoAtual)) <= 0.01) {
            Icone = LuEqual;
          } else if (custoNovo < custoAtual) {
            Icone = MdOutlineArrowDownward;
          } else {
            Icone = MdOutlineArrowUpward;
          }

          return (
            <>
              <Icone size={12} /> {formatNumber(c, true, 2)}
            </>
          );
        }
      },
      style: (c, row) => {
        const custoAtual = parseRound(row.custo_ue_prod);
        const custoNovo = parseFloat(c);

        let style = {};
        if (
          !row.ligacao_encontrada ||
          roundNumber(Math.abs(custoNovo - custoAtual)) <= 0.01
        ) {
          style.color = undefined;
        } else if (custoNovo < custoAtual) {
          style.color = "green";
        } else {
          style.color = "red";
        }
        return style;
      },
    },
    {
      dataField: "perc_lucro",
      text: "% Lucro",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
      onChange: handleEditItem,
      onBlur: atualizarValoresItens,
      editable: (c, row) => row.ligacao_encontrada,
      editorType: "number",
      alwaysShowEditor: true,
      isPercentage: true,
    },
    {
      dataField: "preco_venda",
      text: "Preço Venda",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      onChange: handleEditItem,
      onBlur: atualizarValoresItens,
      editable: (c, row) => row.ligacao_encontrada,
      editorType: "number",
      alwaysShowEditor: true,
    },
    {
      dataField: "perc_lucro_atacado",
      text: "% Lucro Atac",
      align: "center",
      formatter: (c) => formatNumber(c, true, 2),
      onChange: handleEditItem,
      onBlur: atualizarValoresItens,
      editable: (c, row) => row.ligacao_encontrada,
      editorType: "number",
      alwaysShowEditor: true,
      isPercentage: true,
      hidden: !usaPrecoAtacado,
    },
    {
      dataField: "preco_atacado",
      text: "Preço Atac",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
      onChange: handleEditItem,
      onBlur: atualizarValoresItens,
      editable: (c, row) => row.ligacao_encontrada,
      editorType: "number",
      alwaysShowEditor: true,
      hidden: !usaPrecoAtacado,
    },
    {
      dataField: "atualiza_preco_venda",
      text: "Atu",
      align: "center",
      headerAlign: "center",
      selectOnClick: false,
      cellContentTag: null,
      onClick: (row) => marcarDesmarcarItem(row),
      formatter: (c, row) => (
        <>
          <TableCheck value={c} onClick={() => marcarDesmarcarItem(row)} />{" "}
          {c ? "Sim" : "Não"}
        </>
      ),
    },
  ];
  return (
    <Table
      keyField="uuid"
      data={dados}
      columns={columns}
      onSelect={onSelect}
      pageSize={10}
      paginated={false}
      clearSelectionOnUpdate
    />
  );
};
