import React from "react";
import { Row } from "reactstrap";
import { ComboBox, FormCheckbox, TextInput } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  setZeraQtdeProdBalanco,
  setUsaEstoqueVirtual,
  setCaminImpressoraEtiqueta,
  setLayoutEtiqueta,
  setLinguagemImpressoraEtiqueta,
  setUtilizaEstoqueRealAdmProntaEntrega,
  setBloqueiaLctoResultNegativoEstRealAdm,
  setPermiteManipularDadosIncMovManual,
} from "../store/estoque_slice";

const layoutEtiquetaOptions = [
  {
    label:
      "01 = Impressora ZEBRA - 3 colunas (3,40x2,30cm) - Cód.Barras, Nome produto, Preço de venda",
    value: 1,
  },
  {
    label:
      "02 = Impressora ELGIN - Gondola (7,50x3,50cm)   - Cód.Barras, Nome do produto, Localização",
    value: 2,
  },
  {
    label:
      "03 = Impressora ELGIN - 2 colunas (5,00x2,50cm) - Cód.Barras, Id produto, Nome do Produto",
    value: 3,
  },
  {
    label:
      "04 = Impressora ELGIN - Gondola (7,50x3,50cm)   - Cód.Barras, Nome do produto, Valor atacado, Valor venda",
    value: 4,
  },
  {
    label:
      "05 = Folha A4 Adesiva - 2 Colunas, 8 Linhas     - Nome, UN, Cód. Barras, Preço de Venda",
    value: 5,
  },
  {
    label:
      "06 = Folha A4 Adesiva - 3 Colunas, 10 Linhas    - Nome, UN, Cód. Barras, Referência, Nro",
    value: 6,
  },
  {
    label:
      "07 = Folha A4 Adesiva - 3 Colunas, 11 Linhas    - Nome, UN, Cód. Barras, Preço de Venda",
    value: 7,
  },
  {
    label:
      "08 = Folha A4 Adesiva - 4 Colunas (4X1,5 cm)    - Nome, Cód. Barras, Referência",
    value: 8,
  },
  {
    label:
      "09 = Folha A4 Adesiva - 4 Colunas               - Nome, Cód. Barras, Preço de Venda",
    value: 9,
  },
  {
    label:
      "10 = Folha A4 Adesiva - 5 Colunas; 13 Linhas    - Cód. Barras, Nome, Referência, Preço de Venda",
    value: 10,
  },
  {
    label:
      "11 = Folha A4 Adesiva - 2 Colunas, 11 Linhas    - Nome, Preço de venda",
    value: 11,
  },
  {
    label:
      "12 = Folha A4 Adesiva - 3 Colunas, 10 Linhas    - UN, Cód. Barras, Preço de Venda",
    value: 12,
  },
  {
    label:
      "13 = Folha A4 Adesiva - 5 Colunas, 13 Linhas    - Nome, UN, Cód. Barras, Preço de Venda",
    value: 13,
  },
  {
    label:
      "14 = Impressora ARGOX - Gondola                 - Nome do Produto e Preço de Venda Centralizado",
    value: 14,
  },
  {
    label:
      "15 = Impressora ZEBRA - 3 colunas (3,00x2,50cm) - Cód.Barras, Nome produto, Preço de venda)",
    value: 15,
  },
  {
    label:
      "16 = Impressora ARGOX - 3 Colunas (3,30x2,00cm) - Cód Barras, Nome e ID Produto, Preço de venda e Tamanho se houver)",
    value: 16,
  },
  {
    label:
      "17 = Impressora ARGOX - 2 colunas (5,00x2,50cm) - Nome Produto, Preço, Código de Barras, Código do Produto",
    value: 17,
  },
  {
    label:
      "18 = Folha A4 Adesiva - 3 colunas, 10 linhas    - Nome, UN, Código de Barras, Código Produto, Preço de Venda",
    value: 18,
  },
  {
    label: "19 = Impressora ZEBRA - 2 Colunas - Furada com preço destacável ",
    value: 19,
  },
  {
    label:
      "20 = Impressora Elgin L42 Pro - Gondola (9,00x3,00cm) - Cód.Barras, Nome do produto, Preço de Venda",
    value: 20,
  },
  {
    label:
      "21 = Folha A4 Adesiva - 3 colunas, 10 linhas  - Nome, UN, Codigo de barras, Codigo do produto, preco venda em 90 graus",
    value: 21,
  },
  {
    label:
      "22 = Impressora Elgin L-42 - Gondola (9,00x3,00cm) - Cód.Barras, Nome do produto, Preço de Venda",
    value: 22,
  },
  {
    label:
      "23 = Impressora Elgin L42 Pro - 2 Colunas (4,00x2,50cm) - Cód.Barras, Cód.Produto Nome do produto, Preço de Venda",
    value: 23,
  },
  {
    label:
      "24 = Folha A4 Adesiva - 3 colunas, 10 linhas  - Nome, UN, Codigo de barras, Codigo do produto, preco venda, tamanho, cor em 90 graus",
    value: 24,
  },
  {
    label:
      "25 = Folha A4 Adesiva - 5 Colunas; 13 Linhas    - Nome, Tam, Cor, Cód. Barras, cod interno, Preço de Venda",
    value: 25,
  },
  {
    label:
      "26 = Impressora ELGIN L42 PRO - 2 Colunas - Furada com preço destacável ",
    value: 26,
  },
  {
    label:
      "27 = Impressora ELGIN L42 - 3 colunas - Nome do produto, Cód.Barras, Preço de Venda",
    value: 27,
  },
  {
    label:
      "28 = Impressora ARGOX - 2 colunas (3,90x4,00cm) - Nome Produto, Fabricante, Referência, Preço, Código de Barras, Código do Produto",
    value: 28,
  },
  {
    label:
      "29 = Impressora ZEBRA - EPL2 - GONDOLA - [Nome, Código de Barras, Preço]",
    value: 29,
  },
  {
    label: "30 = Impressora L42 PRO - 2 Colunas - [QRCode, ID]",
    value: 30,
  },
  // {
  //   label:
  //     "31 = Impressora Elgin L42 - Gondola (9,00x3,00cm) - Cód.Barras, Nome Completo do produto, Preço de Venda",
  //   value: 31,
  // },
  {
    label:
      "32 = Impressora Elgin L42 Pro - 2 Colunas (5,00x2,50cm) - Cód.Barras, Cód.Produto Nome do produto, Referência",
    value: 32,
  },
  {
    label:
      "33 = Impressora Elgin L42 Pro - Gondola (9,00x3,00cm) - Cód.Barras, Cód.Produto Nome do produto, Referência - ZPL",
    value: 33,
  },
  {
    label:
      "34 = Folha A4 Adesiva - 5 Colunas, 13 Linhas - Nome, UN, Tamanho, Cód. Barras, Preço de Venda",
    value: 34,
  },
  {
    label:
      "35 = Impressora Zebra - 1 Coluna (4,00x6,00) - Nome, Cód. Barras, Preço de Venda, Nome Empresa",
    value: 35,
  },
  {
    label:
      "36 = Impressora Zetex Z50X - 1 Coluna (10,00x15,00) - Dados da Empresa, Nome do Produto, Frases de Perigo/Precaução",
    value: 36,
  },
  {
    label:
      "37 = Folha A4 Adesiva - 5 Colunas, 13 Linhas - Nome, Referência, UN, Cód. Barras",
    value: 37,
  },
  {
    label: "38 = Folha A4 Adesiva - 2 Colunas, 5 Linhas - Dados da Nota Fiscal",
    value: 38,
  },
  {
    label: "40 = Folha A4 Adesiva - 2 Colunas, 5 Linhas - Dados da Nota Fiscal",
    value: 40,
  },
];

const linguagemImpressoraEtiquetaOptions = [
  { label: "etqEpl2", value: "EPL2" },
  { label: "etqNenhum", value: "NENHUM" },
  { label: "etqPpla", value: "PPLA" },
  { label: "etqPplb", value: "PPLB" },
  { label: "etqZPLII", value: "ZPL2" },
];

export const PersonEstoque = () => {
  const store = useSelector((state) => state.estoque);
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <FormCheckbox
          padded={false}
          label="Zerar Estoque de Produtos Não Considerados no Balanço"
          checked={store.zera_qtde_prod_balanco}
          onChange={() =>
            dispatch(setZeraQtdeProdBalanco(!store.zera_qtde_prod_balanco))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Utilizar Estoque Virtual"
          checked={store.usa_estoque_virtual}
          onChange={() =>
            dispatch(setUsaEstoqueVirtual(!store.usa_estoque_virtual))
          }
        />
      </Row>
      <Row>
        <ComboBox
          md={10}
          label="Layout de Impressão de Etiquetas"
          options={layoutEtiquetaOptions}
          defaultValue={store.layout_etiqueta}
          onChange={(s) => dispatch(setLayoutEtiqueta(s?.value))}
        />
      </Row>
      <Row>
        <TextInput
          md={5}
          label="Nome da Impressora de Etiquetas"
          value={store.camin_impressora_etiqueta}
          onChange={(e, v) => dispatch(setCaminImpressoraEtiqueta(v))}
          maxLength={255}
          forceUppercase={false}
        />
      </Row>
      <Row>
        <ComboBox
          md={4}
          label="Linguagem da Impressora de Etiquetas"
          options={linguagemImpressoraEtiquetaOptions}
          defaultValue={store.linguagem_impressora_etiqueta}
          onChange={(s) =>
            dispatch(setLinguagemImpressoraEtiqueta(s?.value ?? null))
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Pronta Entrega - Utiliza estoque paralelo, Estoque Real ADM"
          checked={store.utiliza_estoque_real_adm_pronta_entrega}
          onChange={() =>
            dispatch(
              setUtilizaEstoqueRealAdmProntaEntrega(
                !store.utiliza_estoque_real_adm_pronta_entrega
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Bloquear Lançamento ao Estoque Real ADM se o Resultado for Negativo"
          checked={store.bloqueia_lcto_result_negativo_est_real_adm}
          onChange={() =>
            dispatch(
              setBloqueiaLctoResultNegativoEstRealAdm(
                !store.bloqueia_lcto_result_negativo_est_real_adm
              )
            )
          }
        />
      </Row>
      <Row>
        <FormCheckbox
          padded={false}
          label="Permitir Manipular Dados em Lançamentos Manuais de Estoque"
          checked={store.permite_manipular_dados_inc_mov_manual}
          onChange={() =>
            dispatch(
              setPermiteManipularDadosIncMovManual(
                !store.permite_manipular_dados_inc_mov_manual
              )
            )
          }
        />
      </Row>
    </>
  );
};
