import React from "react";
import { IconButton, Table } from "../../../../../../components";
import { MdOutlineSwapVert } from "react-icons/md";

export const RoteirizacaoGrid = ({
  data,
  selected,
  handleSelect,
  handleSelectAll,
  alterarOrdem,
}) => {
  const columns = [
    {
      dataField: "ordem",
      text: "Ordem",
      align: "center",
      sort: true,
      formatter: (c, row) => (
        <>
          {c}
          <IconButton
            icon={MdOutlineSwapVert}
            onClick={() => alterarOrdem(row.id)}
            color="#dba400"
            hint="Alterar Ordem de Atendimento da Roteirização"
            className="ml-2"
          />
        </>
      ),
    },
    {
      dataField: "id_cliente",
      text: "Cliente",
      align: "center",
      sort: true,
    },
    {
      dataField: "id_cliente__nome",
      text: "Nome",
      align: "left",
      formatter: (c, row) =>
        row.bloqueado ? (
          <>
            {c} <span style={{ color: "red" }}>Cliente bloqueado!</span>
          </>
        ) : (
          c
        ),
    },
    {
      dataField: "id_cliente__nome_fant",
      text: "Nome Fant.",
      align: "left",
    },
    {
      dataField: "id_cliente__cidade__nome",
      text: "Cidade",
      align: "left",
    },
    {
      dataField: "id_cliente__endereco",
      text: "Endereço",
      align: "left",
      formatter: (_, row) =>
        `${row.id_cliente__endereco}, ${row.id_cliente__numero}`,
    },
    {
      dataField: "id_cliente__bairro",
      text: "Bairro",
      align: "left",
    },
    {
      dataField: "id_cliente__fone1",
      text: "Telefone",
      align: "center",
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      selected={selected}
      paginated={false}
      multiselect
      onSelect={handleSelect}
      onSelectAll={handleSelectAll}
      clearSelectionOnUpdate
    />
  );
};
