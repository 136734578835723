import React, { useEffect, useState } from "react";
import {
  Divider,
  FixedField,
  FormButton,
  PageContainer,
  RadioGroup,
  Table,
  TableDelete,
} from "../../../../components";
import { Card, Col, Row } from "reactstrap";
import { ProgressoEntradaNfXml } from "../components/ProgressoEntradaNfXml";
import {
  dateFromLocaleString,
  formatarValor,
  formatDateISO,
  formatDateLocal,
  formatNumber,
  formatValueFromAPI,
  roundFloat,
} from "../../../../coreUtils";
import { RadioItem } from "../../../../components/RadioGroup";
import EntradaNfXmlService from "../../../../services/compras/EntradaNfXmlService";
import { useHistory } from "react-router-dom";
import { entradaNfXmlRoute } from "../../../../routes/modules/compras";
import moment from "moment";
import { showWarning } from "../../../../components/AlertaModal";
import { Edit2 } from "react-feather";

export const FinanceiroEntradaNfXml = ({ location }) => {
  const selected = location?.state?.selected;
  const [numero, setNumero] = useState(null);
  const [serie, setSerie] = useState(null);
  const [dataEmi, setDataEmi] = useState("");
  const [chaveAcesso, setChaveAcesso] = useState(null);
  const [fornecedorCadastrado, setFornecedorCadastrado] = useState(false);
  const [idFornecedor, setIdFornecedor] = useState("");
  const [nomeFornecedor, setNomeFornecedor] = useState("");
  const [vlrTotNf, setVlrTotNf] = useState(0);
  const [faturas, setFaturas] = useState([]);
  const [gerarTitulos, setGerarTitulos] = useState("S");

  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const history = useHistory();

  const carregarDados = async () => {
    const [ok, ret] = await EntradaNfXmlService.buscarDadosNf(selected);
    if (ok) {
      setNumero(ret.numero);
      setSerie(ret.serie);
      setDataEmi(ret.data_emi);
      setChaveAcesso(ret.chave_acesso);
      setFornecedorCadastrado(ret.fornecedor_cadastrado);
      setIdFornecedor(ret.id_fornecedor);
      setNomeFornecedor(ret.nome_fornecedor);
      setVlrTotNf(parseFloat(ret.vlr_tot_nf));
      setFaturas(
        ret.faturas.map((e) => ({ ...e, valor: parseFloat(e.valor) }))
      );
    }
    return ok;
  };

  const handleEditFatura = (coluna, novoValor, row) => {
    if (coluna === "valor") {
      row.valor = parseFloat(novoValor);
    } else if (coluna === "data_vcto") {
      if (moment.isMoment(novoValor)) {
        row.data_vcto = formatDateLocal(novoValor);
      } else {
        return false;
      }
    }

    setFaturas(faturas.map((e) => (e.parcela === row.parcela ? row : e)));
  };

  const addFatura = () => {
    // Calcula valor de cada parcela baseado na nova quantidade de faturas
    let vlrParcela = roundFloat(vlrTotNf / (faturas.length + 1), 2);

    // Cria a fatura
    let fatura = {
      parcela: 1,
      numero: numero,
      data_vcto: formatDateLocal(new Date()),
      valor: vlrParcela,
    };

    if (faturas.length > 0) {
      fatura = {
        ...fatura,
        // Atualiza número da parcela
        parcela: Math.max(...faturas.map((e) => e.parcela)) + 1,
      };

      // Atualiza parcelas já existentes, com novo valor
      let novasFaturas = faturas.map((e) => ({
        ...e,
        valor: vlrParcela,
      }));

      // Adiciona nova fatura à lista de faturas
      novasFaturas.push(fatura);

      // Verifica valores para identificar se há alguma diferença e corrigir
      let somaFaturas = 0;
      novasFaturas.forEach((e) => {
        somaFaturas += e.valor;
      });
      let valorDiferenca = roundFloat(vlrTotNf - somaFaturas, 2);
      novasFaturas[novasFaturas.length - 1].valor = roundFloat(
        novasFaturas[novasFaturas.length - 1].valor + valorDiferenca,
        2
      );

      setFaturas([...novasFaturas]);
    } else {
      setFaturas([fatura]);
    }
  };

  const deleteFatura = (parc) => {
    // Remove a parcela da lista de faturas
    let novasFaturas = faturas.filter((e) => e.parcela !== parc);

    if (novasFaturas.length > 0) {
      // Recalcula o valor das parcelas
      let vlrParcela = roundFloat(vlrTotNf / novasFaturas.length, 2);
      // Atualiza parcelas com o novo valor e reorganiza o número das parcelas
      novasFaturas = novasFaturas.map((e, index) => ({
        ...e,
        parcela: index + 1,
        valor: vlrParcela,
      }));
    }

    // Verifica valores para identificar se há alguma diferença e corrigir
    let somaFaturas = 0;
    novasFaturas.forEach((e) => {
      somaFaturas += e.valor;
    });
    let valorDiferenca = roundFloat(vlrTotNf - somaFaturas, 2);
    novasFaturas[novasFaturas.length - 1].valor = roundFloat(
      novasFaturas[novasFaturas.length - 1].valor + valorDiferenca,
      2
    );
    setFaturas([...novasFaturas]);
  };

  const handleSubmit = async () => {
    const gerarContasPagar = gerarTitulos === "S";
    if (gerarContasPagar && faturas.length === 0) {
      showWarning(
        "Não foram informadas faturas para geração do contas a pagar. " +
          "Por favor, identifique as parcelas ou desmarque a opção " +
          "Gerar Contas a Pagar"
      );
      return;
    }

    const payload = {
      id_nf_entrada_xml: selected,
      gerar_contas_pagar: gerarContasPagar,
      faturas: faturas.map((e) => ({
        parcela: e.parcela,
        numero: e.numero,
        data_vcto: formatDateISO(dateFromLocaleString(e.data_vcto)),
        valor: parseFloat(e.valor),
      })),
    };

    setLoadingSubmit(true);
    const [ok] = await EntradaNfXmlService.importar(payload);
    if (ok) {
      history.replace(entradaNfXmlRoute.path);
    }
    setLoadingSubmit(false);
  };

  const iniciarTela = async () => {
    // if (!(await buscarParams())) {
    //   history.goBack();
    // }

    if (!(await carregarDados())) {
      history.goBack();
    }
    setLoading(false);
  };

  const columnsFaturas = [
    { dataField: "numero", text: "Número", align: "center" },
    {
      dataField: "parcela",
      text: "P",
      align: "center",
      fixedColWidth: true,
      colWidth: "10%",
    },
    {
      dataField: "data_vcto",
      text: "Vencimento",
      align: "center",
      fixedColWidth: true,
      colWidth: "25%",
      editable: true,
      editorType: "date",
      // alwaysShowEditor: true,
      onChange: handleEditFatura,
      formatter: (c) => (
        <>
          {c}
          <Edit2 size={12} className="ml-1" style={{ marginBottom: "3px" }} />
        </>
      ),
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatarValor(c, 2),
      fixedColWidth: true,
      colWidth: "25%",
      onChange: handleEditFatura,
      editable: true,
      editorType: "number",
      alwaysShowEditor: true,
    },
    {
      dataField: "del",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => deleteFatura(row.parcela)} />
      ),
    },
  ];

  const onActivate = () => {
    iniciarTela();
  };

  useEffect(onActivate, []);

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      handleSubmit();
    }
  };

  return (
    <PageContainer
      title="Entrada de Nota Fiscal via XML"
      number="0101_2"
      loading={loading}
      onKeyDown={onKeyDown}
    >
      <Card body>
        <Row>
          <Col md={8}>
            <Divider>Informações da Nota Fiscal</Divider>
            <Row className="mb-2">
              <FixedField horizontal label="Número" value={numero} />
              <FixedField horizontal label="Série" value={serie} />
              <FixedField horizontal label="Emissão" value={dataEmi} />
            </Row>
            <Row className="mb-2">
              <FixedField
                horizontal
                label="Fornecedor"
                value={
                  fornecedorCadastrado
                    ? formatValueFromAPI(nomeFornecedor, idFornecedor)
                    : nomeFornecedor
                }
              />
            </Row>
            <Row>
              <FixedField horizontal label="Chave Acesso" value={chaveAcesso} />
            </Row>
          </Col>
          <ProgressoEntradaNfXml activeStep={2} md={4} />
        </Row>
      </Card>
      <Card body>
        <Divider>Informações Financeiras</Divider>
        <Row className="mb-2">
          <FixedField
            label="Valor Total da Nota"
            value={formatNumber(vlrTotNf, true, 2)}
            horizontal
          />
        </Row>
        <Row>
          <Col md={5}>
            <Row>
              <RadioGroup
                label="Gerar Títulos a Pagar"
                value={gerarTitulos}
                onChange={setGerarTitulos}
              >
                <RadioItem label="Sim" value="S" />
                <RadioItem label="Não" value="N" />
              </RadioGroup>

              {gerarTitulos === "S" && (
                <FormButton
                  color="success"
                  onClick={addFatura}
                  divClassName="ml-auto pr-0"
                >
                  + 1 Parcela
                </FormButton>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          {gerarTitulos === "S" ? (
            <Col md={5}>
              <Table
                data={faturas}
                columns={columnsFaturas}
                pageSize={10}
                keyField="parcela"
                showRegisterCount={false}
                paginated={false}
                fixedSize={false}
              />
            </Col>
          ) : (
            <Col>
              <h4
                style={{
                  textAlign: "center",
                  background: "#F4FFA2",
                  paddingBlock: "1rem",
                  fontWeight: "bold",
                }}
              >
                NÃO Serão Gerados Títulos a Pagar Para Esta Nota Fiscal
              </h4>
            </Col>
          )}
        </Row>
        <Row>
          <FormButton
            padded={false}
            color="success"
            divClassName="ml-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            F9 - Concluir Processo
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
