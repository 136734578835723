import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";

import imagem1 from "../../../../assets/img/layouts_carne/1.png";
import imagem2 from "../../../../assets/img/layouts_carne/2.png";
import imagem3 from "../../../../assets/img/layouts_carne/3.png";
import imagem4 from "../../../../assets/img/layouts_carne/4.png";
import { Divider } from "../../../../components";

const layouts = [
  {
    id: 1,
    imagem: imagem1,
    descricao: (
      <>
        <ul>
          <li>Duas Colunas</li>
          <li>Código de Barras para identificação da Parcela</li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    imagem: imagem2,
    descricao: (
      <>
        <ul>
          <li>Duas Colunas</li>
          <li>Dados da Empresa</li>
          <li>Dados do Cliente</li>
        </ul>
      </>
    ),
  },
  {
    id: 3,
    imagem: imagem3,
    descricao: (
      <>
        <ul>
          <li>Uma Coluna</li>
          <li>Código de Barras para identificação da Parcela</li>
          <li>Dados da Empresa</li>
          <li>Dados do Cliente</li>
        </ul>
      </>
    ),
  },
  {
    id: 4,
    imagem: imagem4,
    descricao: (
      <>
        <ul>
          <li>Similar ao Layout 1</li>
          <li>Nome da Empresa</li>
          <li>Identificação de cada Parcela</li>
        </ul>
      </>
    ),
  },
];

const PrintDisplay = ({ layoutsList, openImageViewer }) => (
  <div className="print-display">
    {layoutsList.map((layout) => (
      <div className="print-item">
        <img
          src={layout.imagem}
          className="print-thumb"
          onClick={() => openImageViewer(layout.id)}
          alt=""
        />
        <div style={{ height: "min-content", marginLeft: "0.75rem" }}>
          <h5 className="mb-1">Layout {layout.id}</h5>
          <span>{layout.descricao}</span>
        </div>
      </div>
    ))}
  </div>
);

export const ImpressaoCarne = () => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [idImagem, setIndexImagem] = useState(0);

  const openImageViewer = (index) => {
    setIndexImagem(index);
    setIsViewerOpen(true);
  };

  const onCloseImageViewer = () => {
    setIndexImagem(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <Divider className="mt-2">Folha A4</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [1, 2, 4].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      <Divider className="mt-2">Bobina 80mm</Divider>
      <PrintDisplay
        layoutsList={layouts.filter((item) => [3].includes(item.id))}
        openImageViewer={openImageViewer}
      />
      {isViewerOpen && (
        <ImageViewer
          src={[layouts.find((layout) => layout.id === idImagem)?.imagem]}
          currentIndex={0}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={onCloseImageViewer}
          backgroundStyle={{ backgroundColor: "#00000085" }}
        />
      )}
    </>
  );
};
