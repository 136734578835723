import { Truck } from "react-feather";
import { IncluirAlterarPedido } from "../../pages/pedidos/pedidos_externos/central_ped_externo/incluir_alterar/IncluirAlterarPedido";
import { CentralPedExterno } from "../../pages/pedidos/pedidos_externos/central_ped_externo/CentralPedExterno";
import { EntregarPedido } from "../../pages/pedidos/pedidos_externos/central_ped_externo/entregar_pedido/EntregarPedido";
import { ConferenciaDinheiro } from "../../pages/pedidos/pedidos_externos/conferencia_dinheiro/ConferenciaDinheiro";
import { RomaneiosRealizados } from "../../pages/pedidos/romaneio/realizados/RomaneiosRealizados";
import { Romaneio } from "../../pages/pedidos/romaneio/Romaneio";
import { PedEcommerce } from "../../pages/pedidos/ped_ecommerce/PedEcommerce";
import { CentralRomaneio } from "../../pages/pedidos/romaneio/central_de_romaneios/CentralRomaneio";
import { LiberacaoDeClientes } from "../../pages/pedidos/ped_ecommerce/liberacao_clientes/LiberacaoDeClientes";
import { PedidosExternos } from "../../pages/pedidos/pedidos_externos/PedidosExternos";
import { PedidosInternos } from "../../pages/pedidos/pedidos_internos/PedidosInternos";
import { FlexRepresentExt } from "../../pages/pedidos/pedidos_externos/flex_pedido_externo/FlexRepresentExt";
import { CentralPedInterno } from "../../pages/pedidos/pedidos_internos/central_ped_interno/CentralPedInterno";
import { PedidosPendentesEntrega } from "../../pages/relatorios/pedidos/pedidos_pendentes_entrega/PedidosPendentesEntrega";

export const ecommerceLiberacaoClientesRoute = {
  path: "/pedidos/pedido_ecommerce/liberacao_de_clientes",
  name: "Liberação de Clientes",
  id: "AcSkWeb0035",
  nroTela: "0035",
  component: LiberacaoDeClientes,
};

export const pedidosEcommerceRoutes = [ecommerceLiberacaoClientesRoute];

export const centralPedInternoRoute = {
  path: "/pedidos/pedidos_internos/central",
  name: "Pedidos Internos Pendentes de Entrega",
  id: "AcSkWeb0100",
  nroTela: "0100",
  component: CentralPedInterno,
};

export const pedidosInternosRoutes = [centralPedInternoRoute];

export const entregarPedidoRoute = {
  path: "/pedidos/pedidos_externos/central_de_pedidos/entregar",
  name: "Entregar Pedido Externo",
  component: EntregarPedido,
};

export const incluirAlterarPedidoRoute = {
  path: "/pedidos/pedidos_externos/central_de_pedidos/pedido",
  name: "Pedido Externo",
  component: IncluirAlterarPedido,
};

export const centralRomaneioRoute = {
  path: "/pedidos/romaneio/central_romaneios",
  name: "Central de Romaneios",
  id: "AcSkWeb0048",
  nroTela: "0048",
  component: CentralRomaneio,
};

export const romaneioRealizadosRoute = {
  path: "/pedidos/romaneio/romaneios_realizados",
  name: "Romaneios Realizados",
  id: "AcSkWeb0071",
  nroTela: "0071",
  component: RomaneiosRealizados,
};

export const romaneioRoutes = [centralRomaneioRoute, romaneioRealizadosRoute];

export const centralPedidosRoute = {
  path: "/pedidos/pedidos_externos/central_de_pedidos",
  name: "Central de Pedidos Externos",
  id: "AcSkWeb0034",
  nroTela: "0034",
  component: CentralPedExterno,
};

export const pedidosConferenciaValoresRecebidosEmMaosRoute = {
  path: "/pedidos/pedidos_externos/conferencia_valores_recebidos_em_mao",
  name: "Conferência de Valores Recebidos em Mãos",
  id: "AcSkWeb0054",
  nroTela: "0054",
  component: ConferenciaDinheiro,
};

export const pedidosFlexRepresentantesRoute = {
  path: "/pedidos/pedidos_externos/flex_represent_ext",
  name: "Flex de Representantes",
  id: "AcSkWeb0072",
  nroTela: "0072",
  component: FlexRepresentExt,
};

export const pedidosExternosRoutes = [
  centralPedidosRoute,
  pedidosConferenciaValoresRecebidosEmMaosRoute,
  pedidosFlexRepresentantesRoute,
];

const pedidosRoutes = {
  path: "/pedidos",
  name: "Pedidos",
  icon: Truck,
  children: [
    {
      path: "/pedidos/pedidos_externos",
      name: "Pedidos Externos",
      component: PedidosExternos,
      canGoBack: false,
    },
    {
      path: "/pedidos/pedido_ecommerce",
      name: "Pedidos E-Commerce",
      component: PedEcommerce,
      canGoBack: false,
    },
    {
      path: "/pedidos/pedidos_internos",
      name: "Pedidos Internos",
      component: PedidosInternos,
      canGoBack: false,
    },
    {
      path: "/pedidos/romaneio",
      name: "Romaneio",
      component: Romaneio,
      canGoBack: false,
    },
  ],
};

export default pedidosRoutes;
