import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";
import CamposPesqCliente from "./CamposPesqCliente";
import ModalPesqCliente from "./ModalPesqCliente";
import CadastroClienteService from "../../../services/cadastro/CadastroClienteService";

const PesqCliente = forwardRef(
  (
    {
      onConfirm,
      selectNextField,
      md = 6,
      mdIdent,
      onChangeDescricao,
      autoFocus,
      sistAutomotivo,
      idCliente,
      nomeCliente,
      disabled,
      showShortcut,
      label = "Cliente",
      divClassName,
    },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pistaPesq, setPistaPesq] = useState("");
    const [cliCuringa, setCliCuringa] = useState(false);
    const refNome = useRef();
    const refId = useRef();

    const buscarDadosCliente = async (_, pista) => {
      if (pista) {
        setLoading(true);
        const [ok, ret] = await CadastroClienteService.pesquisa(pista);

        if (!ok || ret.length === 0) {
          onConfirm(null, "Não Encontrado", false, "", "");
          setCliCuringa(false);
        } else if (ret.length > 1) {
          setPistaPesq(pista);
          setModalOpen(true);
        } else {
          setCliCuringa(ret[0].curinga);
          onConfirm(
            ret[0].id,
            ret[0].nome,
            ret[0].curinga,
            ret[0].cpf_cnpj,
            ret[0].whatsapp
          );
          if (!ret[0].curinga && selectNextField) {
            selectNextField();
          }
          setLoading(false);
          return true;
        }
        setLoading(false);
      } else {
        onConfirm(null, "", false, "", "");
        setCliCuringa(false);
      }
      return false;
    };

    const focusIdentif = () => {
      if (cliCuringa) {
        if (refNome.current) {
          refNome.current.focus();
        }
      } else {
        if (refId.current) {
          refId.current.focus();
        }
      }
    };

    useImperativeHandle(ref, () => ({
      setDescricao: (v) => refNome.current.setValue(v),
      setId: (v) => {
        if (refId.current) {
          refId.current.setValue(v);
        }
      },
      setValueByID: (v) => buscarDadosCliente(null, v),
      focus: focusIdentif,
      clear: () => {
        refNome.current.setValue("");
        if (refId.current) {
          refId.current.setValue("");
        }
        setCliCuringa(false);
      },
    }));

    useEffect(() => {
      if (cliCuringa) {
        refNome.current.focus();
      }
    }, [cliCuringa]);

    const handleOpenModal = (pista) => {
      setPistaPesq(pista);
      setModalOpen(true);
    };

    return (
      <>
        <CamposPesqCliente
          md={md}
          mdIdent={mdIdent}
          onClickPesq={() => setModalOpen(true)}
          onExitIdentificador={buscarDadosCliente}
          refId={refId}
          refNome={refNome}
          loadingPesq={loading}
          disabledDescricao={!cliCuringa}
          openModal={handleOpenModal}
          onChangeDescricao={onChangeDescricao}
          autoFocus={autoFocus}
          idCliente={idCliente}
          nomeCliente={nomeCliente}
          disabledIdPesq={disabled}
          showShortcut={!disabled && showShortcut}
          label={label}
          divClassName={divClassName}
          pistaPesq={pistaPesq}
          setPistaPesq={setPistaPesq}
        />
        <ModalPesqCliente
          pistaPadrao={pistaPesq}
          isOpen={modalOpen}
          toggle={(selecionouCli) => {
            setModalOpen(!modalOpen);
            if (!modalOpen) {
              setPistaPesq("");
            } else {
              if (selecionouCli) {
                if (selectNextField) selectNextField();
              } else {
                focusIdentif();
              }
            }
          }}
          selectCli={(id, nome, curinga, cpfCnpj, whatsapp) => {
            setCliCuringa(curinga);
            onConfirm(id, nome, curinga, cpfCnpj, whatsapp);
          }}
          sistAutomotivo={sistAutomotivo}
        />
      </>
    );
  }
);

export default PesqCliente;
