import React, { useState } from "react";
import { FixedField, ModalBase, Table } from "../../../../../components";
import GerencChequesClientesService from "../../../../../services/financeiro/GerencChequesClientesService";
import { Row } from "reactstrap";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";

const rowClassName = "mb-2";
const rowStyle = { justifyContent: "space-between" };

const columnsLog = [
  { dataField: "data_hora_log", text: "Data/Hora", align: "center" },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
  },
  { dataField: "log", text: "Log", align: "left" },
];

export const DetalhesChequeClienteModal = ({ isOpen, toggle, selected }) => {
  const [dadosCheque, setDadosCheque] = useState({});
  const [logs, setLogs] = useState([]);

  const limparDados = () => {
    setDadosCheque({});
    setLogs([]);
  };

  const carregarDados = async () => {
    const [ok1, ret1] = await GerencChequesClientesService.buscar(selected);
    if (!ok1) {
      toggle();
      return;
    }

    const [ok2, ret2] = await GerencChequesClientesService.buscarLogs(selected);
    if (!ok2) {
      toggle();
      return;
    }

    setDadosCheque(ret1);
    setLogs(ret2);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Detalhes do Cheque de Cliente"
      number="0131_3"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <DetalhesChequeClienteForm dadosCheque={dadosCheque} />
      <Table data={logs} columns={columnsLog} pageSize={5} paginated={false} />
    </ModalBase>
  );
};

export const DetalhesChequeClienteForm = ({
  dadosCheque,
  mostrarCmc7 = true,
}) => {
  return (
    <>
      <Row className={rowClassName} style={rowStyle}>
        <FixedField
          label="N° Cheque"
          value={dadosCheque.nro_cheque}
          horizontal
        />
        <FixedField
          label="Situação Atual"
          value={dadosCheque.desc_status}
          horizontal
        />
      </Row>
      <Row className={rowClassName} style={rowStyle}>
        <FixedField
          label="Banco"
          value={formatValueFromAPI(
            dadosCheque.nome_banco,
            dadosCheque.id_banco
          )}
          horizontal
        />
        <FixedField label="Agencia" value={dadosCheque.agencia} horizontal />
        <FixedField
          label="Nro Conta"
          value={dadosCheque.nro_conta}
          horizontal
        />
      </Row>
      <Row className={rowClassName} style={rowStyle}>
        <FixedField
          label="Cliente"
          value={formatValueFromAPI(
            dadosCheque.nome_cliente,
            dadosCheque.id_cliente
          )}
          horizontal
        />
        <FixedField
          label="CPF/CNPJ"
          value={dadosCheque.cpf_cnpj_cliente}
          horizontal
        />
      </Row>
      <Row className={rowClassName} style={rowStyle}>
        <FixedField
          label="Origem"
          value={
            dadosCheque.desc_origem +
            (![0, null, undefined].includes(dadosCheque.id_origem)
              ? ` ${dadosCheque.id_origem}`
              : "")
          }
          horizontal
        />
        <FixedField
          label="Nº Caixa Loja"
          value={dadosCheque.id_caixa_loja}
          horizontal
        />
      </Row>
      <Row className={rowClassName} style={rowStyle}>
        <FixedField label="Emissão" value={dadosCheque.emissao} horizontal />
        {dadosCheque.cheque_avi && (
          <span style={{ fontStyle: "italic", textDecoration: "underline" }}>
            Cheque à Vista
          </span>
        )}
        <FixedField label="Bom Para" value={dadosCheque.bom_para} horizontal />
        <FixedField
          label="Valor"
          value={formatNumber(dadosCheque.valor, true, 2)}
          horizontal
        />
      </Row>
      {mostrarCmc7 && (
        <Row className={rowClassName} style={rowStyle}>
          <FixedField label="CMC7" value={dadosCheque.cmc7} horizontal />
        </Row>
      )}
      <Row className={rowClassName} style={rowStyle}>
        <FixedField label="Observação" value={dadosCheque.observ} horizontal />
      </Row>
      <Row className={rowClassName} style={rowStyle}>
        <FixedField
          label="Incluído por"
          value={
            formatValueFromAPI(
              dadosCheque.nome_colaborador_inc,
              dadosCheque.id_colaborador_inc
            ) +
            " - " +
            dadosCheque.inclusao
          }
          horizontal
        />
      </Row>
    </>
  );
};
