import { apiDeleteV2, apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/pagar/duplic_pag";
const URL_BASE_GERENCIAMENTO = `${URL_BASE}/gerenciamento`;
const URL_BASE_LANCAMENTOS = `${URL_BASE_GERENCIAMENTO}/lancamentos`;

const GerencContasPagarService = {
  alterar: (payload) => apiPutV2(`${URL_BASE_GERENCIAMENTO}/alterar/`, payload),
  alterarMovPlanoConta: (payload) =>
    apiPutV2(`${URL_BASE_GERENCIAMENTO}/alterar_mov_plano_conta/`, payload),
  buscar: (idTitulo) =>
    apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar/${idTitulo}/`),
  buscarStatus: (idTitulo) =>
    apiGetV2(`${URL_BASE_GERENCIAMENTO}/buscar_status/${idTitulo}/`),
  excluir: (payload) => apiPutV2(`${URL_BASE_GERENCIAMENTO}/excluir/`, payload),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  lancamentos: {
    incluir: (payload) =>
      apiPostV2(`${URL_BASE_LANCAMENTOS}/incluir/`, payload),
    excluir: (idTransacao) =>
      apiDeleteV2(`${URL_BASE_LANCAMENTOS}/excluir/${idTransacao}/`),
  },
  listar: (params) => apiGetV2(`${URL_BASE_GERENCIAMENTO}/listar/`, params),
  liquidar: (payload) => apiPostV2(`${URL_BASE}/liquidar/`, payload),
};

export default GerencContasPagarService;
