import React, { useState } from "react";
import {
  BotaoPesquisar,
  ComboBox,
  FormButton,
  PageContainer,
} from "../../../components";
import { Card, Col, Row } from "reactstrap";
import { urlRelatorioProdutosBalanca } from "../../relatorios/cadastrais/produtos_balanca/ProdutosBalanca";
import { apiGetV2 } from "../../../apiV2";
import { gerarToledoMVG5 } from "./layouts/ToledoMVG5";
import { ExportacaoBalancaGrid } from "./components/ExportacaoBalancaGrid";
import { showError } from "../../../components/AlertaModal";
import { gerarFilizola } from "./layouts/Filizola";

const layoutOptions = [
  {
    label: "Balanças Toledo - Layout MGV5 - Arquivo TXITENS.TXT",
    value: "TMVG5",
  },
  {
    label: "Balanças Filizola - Layout Padrão - Arquivo CADTXT.TXT",
    value: "FILIZ",
  },
];

export const ExportacaoBalanca = () => {
  const [produtos, setProdutos] = useState([]);
  const [layout, setLayout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingExportar, setLoadingExportar] = useState(false);

  const buscarDados = () => apiGetV2(urlRelatorioProdutosBalanca);

  const carregarDados = async () => {
    setLoading(true);
    const [ok, ret] = await buscarDados();
    setProdutos(ok ? ret : []);
    setLoading(false);
  };

  const exportar = async () => {
    setLoadingExportar(true);
    const [ok, ret] = await buscarDados();
    if (!ok) {
      return;
    }

    const gerarArquivo = () => {
      switch (layout) {
        case "TMVG5":
          return gerarToledoMVG5(ret);
        case "FILIZ":
          return gerarFilizola(ret);
        default:
          return [false, `Layout ${layout} não implementado`];
      }
    };

    const [okArq, retArq] = gerarArquivo();
    if (!okArq) {
      showError(retArq);
    }

    setLoadingExportar(false);
  };

  return (
    <PageContainer title="Exportação de Produtos para Balança" number="0111">
      <Card body>
        <Row>
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <ComboBox
            options={layoutOptions}
            md={5}
            label="Layout"
            onChange={(s) => setLayout(s?.value)}
            defaultValue={layout}
          />
          <FormButton
            color="primary"
            disabled={[0, null, undefined].includes(layout)}
            disabledHint="Selecione o Layout para Geração do Arquivo"
            loading={loadingExportar}
            onClick={exportar}
          >
            Gerar Arquivo
          </FormButton>
        </Row>
      </Card>
      <Card body>
        <ExportacaoBalancaGrid dados={produtos} />
        <Row>
          <Col>
            <h5>Legenda</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <span style={{ fontWeight: "bold" }}>
              Produtos identificados pela cor AZUL serão pesados pela balança no
              PDV.
              <br />
              Para um produto ser identificado como de balança ele precisar ter
              as seguintes características:
              <ul>
                <li>A unidade cadastrada como KG (Kilogramas)</li>
                <li>O código de balança informado no cadastro</li>
              </ul>
            </span>
          </Col>
        </Row>
      </Card>
    </PageContainer>
  );
};
