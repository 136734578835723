import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AsyncComboBox,
  DatePicker,
  FixedField,
  FormCheckbox,
  IntegerFormInput,
  ModalBase,
  TextInput,
  Table,
} from "../../../../../components";
import moment from "moment";
import {
  dateFromLocaleString,
  formatDateISO,
  formatNumber,
  formatValueFromAPI,
} from "../../../../../coreUtils";
import GerencContasPagarService from "../../../../../services/financeiro/GerencContasPagarService";
import { iconeAlterar } from "../../../../../components/cadastro";
import { AlterarPlanoCtaTituloPagarModal } from "./AlterarPlanoCtaTituloPagarModal";
import { showWarning } from "../../../../../components/AlertaModal";

export const AlterarTituloPagarModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [params, setParams] = useState({});
  const [idOrganizacao, setIdOrganizacao] = useState(null);
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [dataEmi, setDataEmi] = useState(null);
  const [dataCompetencia, setDataCompetencia] = useState(null);
  const [dataVcto, setDataVcto] = useState(null);
  const [carteira, setCarteira] = useState(false);
  const [numero, setNumero] = useState("");
  const [parcela, setParcela] = useState(null);
  const [origem, setOrigem] = useState("");
  const [descOrigem, setDescOrigem] = useState("");
  const [observacao, setObservacao] = useState("");
  const [vlrOrigAntes, setVlrOrigAntes] = useState(0);
  const [vlrPagarAntes, setVlrPagarAntes] = useState(0);
  const [vlrOrigDepois, setVlrOrigDepois] = useState(0);
  const [vlrPagarDepois, setVlrPagarDepois] = useState(0);
  const [planosContas, setPlanosContas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [planoContaAlterar, setPlanoContaAlterar] = useState({});
  const [alterarPlanoContaOpen, setAlterarPlanoContaOpen] = useState(false);
  const utilizaCentroCusto = params?.utiliza_centro_custo;
  const utilizaOrganizacao = params?.utiliza_organizacao;

  const origemTituloNaoAlteraPlanoConta = !["PRO", "IMT", "TER"].includes(
    origem
  );

  const pagParcial =
    (planosContas ?? []).findIndex((e) => e.operacao === "P") > -1;

  const limparDados = () => {
    setParams({});
    setIdOrganizacao(null);
    setIdFornecedor(null);
    setDataEmi(null);
    setDataVcto(null);
    setDataCompetencia(null);
    setCarteira(false);
    setNumero("");
    setParcela(null);
    setOrigem("");
    setDescOrigem("");
    setObservacao("");
    setVlrOrigAntes(0);
    setVlrPagarAntes(0);
    setVlrOrigDepois(0);
    setVlrPagarDepois(0);
    setPlanosContas([]);
  };

  const carregarDados = async (par) => {
    setParams(par);
    const [ok, ret] = await GerencContasPagarService.buscar(selected);
    if (ok) {
      setIdOrganizacao(ret.id_organizacao);
      setIdFornecedor(ret.id_fornecedor);
      setDataEmi(dateFromLocaleString(ret.emissao));
      setDataVcto(dateFromLocaleString(ret.vencimento));
      setDataCompetencia(
        ret.competencia ? dateFromLocaleString(ret.competencia) : null
      );
      setCarteira(ret.carteira);
      setNumero(ret.numero);
      setParcela(ret.sequenc);
      setObservacao(ret.observ);
      setOrigem(ret.id_cab__origem);
      setDescOrigem(ret.desc_origem);
      setVlrOrigAntes(parseFloat(ret.vlr_orig));
      setVlrPagarAntes(parseFloat(ret.vlr_pagar));
      setVlrOrigDepois(parseFloat(ret.vlr_orig));
      setVlrPagarDepois(parseFloat(ret.vlr_pagar));
      setPlanosContas(ret.movs_plano_conta);
    } else {
      toggle();
    }
  };

  const notifyAlterarPlanoConta = async () => {
    const [ok, ret] = await GerencContasPagarService.buscar(selected);
    if (ok) {
      setVlrOrigDepois(parseFloat(ret.vlr_orig));
      setVlrPagarDepois(parseFloat(ret.vlr_pagar));
      setPlanosContas(ret.movs_plano_conta);
    }
    notifyEvent();
  };

  const toggleAlterarPlanoConta = () =>
    setAlterarPlanoContaOpen(!alterarPlanoContaOpen);

  const verifPodeAlterarPlanoConta = (mov) => {
    if (pagParcial) {
      return [
        false,
        `Este título já sofreu pagamento parcial e suas informações
        de origem não podem ser alteradas.`,
      ];
    }

    if (mov.operacao !== "I") {
      return [
        false,
        `Somente Planos de Contas com os quais o título
      foi incluído podem ser alterados.\nOutras alterações (Desconto ou Acréscimo)
      deverão ser realizadas pela ferramenta de Transações.`,
      ];
    }

    return [true, ""];
  };

  const alterarPlanoConta = (id, row) => {
    const [ok, msg] = verifPodeAlterarPlanoConta(row);
    if (!ok) {
      showWarning(msg);
      return;
    }

    setPlanoContaAlterar(row);
    setTimeout(() => {
      toggleAlterarPlanoConta();
    }, 1);
  };

  const handleSubmit = async () => {
    if (utilizaOrganizacao && [0, null, undefined].includes(idOrganizacao)) {
      showWarning("Por favor, informe a Organização");
      return;
    }

    if ([0, null, undefined].includes(idFornecedor)) {
      showWarning("Por favor, informe o Fornecedor");
      return;
    }

    if (!(dataEmi instanceof Date)) {
      showWarning(
        "A Data de Emissão é inválda. Por favor, revise-a e tente novamente"
      );
      return;
    }

    if (!(dataVcto instanceof Date)) {
      showWarning(
        "A Data de Vencimento é inválda. Por favor, revise-a e tente novamente"
      );
      return;
    }

    if (!(dataCompetencia instanceof Date)) {
      showWarning(
        "A Data de Competência é inválda. Por favor, revise-a e tente novamente"
      );
      return;
    }

    if (["", null, undefined].includes(numero)) {
      showWarning("Por favor, informe o Número do Título");
      return;
    }

    if ([null, undefined].includes(parcela)) {
      showWarning("Por favor, informe o número da Parcela");
      return;
    }

    const payload = {
      id_titulo: selected,
      id_organizacao: utilizaOrganizacao ? idOrganizacao : null,
      id_fornecedor: idFornecedor,
      data_emi: formatDateISO(dataEmi),
      data_vcto: formatDateISO(dataVcto),
      data_competencia: formatDateISO(dataCompetencia),
      carteira: carteira,
      numero: numero,
      parcela: parcela,
      observacao: observacao,
    };
    setLoading(true);
    const [ok] = await GerencContasPagarService.alterar(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const columnsPlanosContas = [
    { dataField: "id", text: "#", align: "center" },
    { dataField: "desc_operacao", text: "Operação", align: "center" },
    {
      dataField: "nome_plano_conta",
      text: "Plano de Contas",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_plano_conta),
    },
    {
      dataField: "nome_centro_custo",
      text: "Centro de Custo",
      align: "left",
      formatter: (c, row) =>
        row.id_centro_custo
          ? formatValueFromAPI(c, row.id_centro_custo)
          : "Não Especificado",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    iconeAlterar(alterarPlanoConta, {
      disabled: (c, row) => {
        const [ok] = verifPodeAlterarPlanoConta(row);
        return !ok;
      },
      disabledHint: (c, row) => {
        const [ok, msg] = verifPodeAlterarPlanoConta(row);
        return !ok ? msg : undefined;
      },
    }),
  ];

  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Alteração de Título a Pagar"
        number="0091_4"
        paramsName="inc_titulo_pagar"
        onClosed={limparDados}
        onBeforeOpen={carregarDados}
        onConfirm={handleSubmit}
        loadingConfirm={loading}
      >
        {utilizaOrganizacao && (
          <Row>
            <AsyncComboBox
              md={6}
              label="Organização"
              isConcatField
              concatModelName="organizacao"
              defaultValue={idOrganizacao}
              onChange={(s) => setIdOrganizacao(s?.value)}
              defaultOptions
            />
          </Row>
        )}
        <Row>
          <AsyncComboBox
            md={8}
            label="Fornecedor"
            isConcatField
            concatModelName="fornecedor"
            defaultValue={idFornecedor}
            onChange={(s) => setIdFornecedor(s?.value)}
          />
          <FixedField
            label="Origem"
            value={descOrigem}
            horizontal
            divClassName="mt-4"
          />
        </Row>
        <Row>
          <TextInput
            md={3}
            label="Número"
            value={numero}
            onChange={(e, v) => setNumero(v)}
            maxLength={12}
          />
          <IntegerFormInput
            md={1}
            label="Parcela"
            defaultValue={parcela}
            onChange={setParcela}
          />
          <DatePicker
            md={2}
            label="Emissão"
            value={dataEmi}
            onChange={(v) => setDataEmi(moment.isMoment(v) ? v.toDate() : v)}
          />
          <DatePicker
            md={2}
            label="Competência"
            value={dataCompetencia}
            onChange={(v) =>
              setDataCompetencia(moment.isMoment(v) ? v.toDate() : v)
            }
          />
          <DatePicker
            md={2}
            label="Vencimento"
            value={dataVcto}
            onChange={(v) => setDataVcto(moment.isMoment(v) ? v.toDate() : v)}
          />
          <FormCheckbox
            label="Em Carteira"
            checked={carteira}
            onChange={() => setCarteira(!carteira)}
          />
        </Row>
        <Row className="mb-2">
          <TextInput
            md={12}
            label="Observação"
            value={observacao}
            onChange={(e, v) => setObservacao(v)}
            maxLength={100}
          />
        </Row>
        <Row className="mb-2" style={{ justifyContent: "space-between" }}>
          <FixedField
            label="Valor Original Atual"
            value={formatNumber(vlrOrigAntes, true, 2)}
            horizontal
          />
          <FixedField
            label="Novo Valor Original"
            value={formatNumber(vlrOrigDepois, true, 2)}
            horizontal
          />
        </Row>
        <Row className="mb-2" style={{ justifyContent: "space-between" }}>
          <FixedField
            label="Valor A Pagar Atual"
            value={formatNumber(vlrPagarAntes, true, 2)}
            horizontal
          />
          <FixedField
            label="Novo Valor A Pagar"
            value={formatNumber(vlrPagarDepois, true, 2)}
            horizontal
          />
        </Row>
        <Table
          data={planosContas}
          columns={columnsPlanosContas}
          showRegisterCount={false}
          pageSize={5}
          paginated={false}
          onRowDoubleClick={alterarPlanoConta}
        />
      </ModalBase>
      <AlterarPlanoCtaTituloPagarModal
        isOpen={alterarPlanoContaOpen}
        toggle={toggleAlterarPlanoConta}
        utilizaCentroCusto={utilizaCentroCusto}
        idTitulo={selected}
        origemTituloNaoAlteraPlanoConta={origemTituloNaoAlteraPlanoConta}
        planoContaAlterar={planoContaAlterar}
        notifyEvent={notifyAlterarPlanoConta}
      />
    </>
  );
};
