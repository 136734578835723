import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  codigo: "",
  descricao: "",
  cfopNf: "",
  sitTribIcms: "",
  modBcIcms: "",
  percIcms: 0,
  tipoRedBaseIcms: "",
  percRedBaseIcmsEm: 0,
  percRedBaseIcmsPara: 0,
  sitTribIcmsNfce: "",
  modBcIcmsNfce: "",
  percIcmsNfce: 0,
  percRedBaseIcmsNfce: 0,
  modBcIcmsSt: "",
  margValorAdicIcmsSt: 0,
  percIcmsSt: 0,
  percReduIcmsSt: 0,
  sitTribIpi: "",
  percIpi: 0,
  enquadIpi: "",
  somaVlrIpiBcIcmsSt: false,
  sitTribPis: "",
  percPis: 0,
  percRetenPis: 0,
  sitTribCofins: "",
  percCofins: 0,
  percRetenCofins: 0,
  geraCredIcms: false,
  aliqCredIcms: 0,
  percDesonerIcms: 0,
  motivoDesonerIcms: "",
  percRetenCsll: 0,
  percRetenIrpj: 0,
  cobrarNaFatura: true,
  mensagAdicNfe: "",
  percIcmsDifer: 0,
  deducVlrIcmsBcPisCofins: false,
  cBenef: "",
  somaVlrIpiBcIcms: false,
  somaVlrOutrosBcIcms: false,
  somaVlrFreteBcIpi: false,
  somaVlrFreteBcIcms: false,
  aliqAdRem: 0,
  ativo: true,
  cstsIcms: [],
  camposAtivosIcms: {
    mod_bc_icms_ativo: true,
    aliq_icms_ativo: true,
    tipo_red_bc_icms_ativo: true,
    perc_red_em_bc_icms_ativo: true,
    perc_red_para_bc_icms_ativo: true,
    perc_desoner_icms_ativo: true,
    motivo_desoner_icms_ativo: true,
    mod_bc_icms_st_ativo: true,
    perc_red_bc_icms_st_ativo: true,
    perc_mva_bc_icms_st_ativo: true,
    aliq_icms_st_ativo: true,
    credito_icms_simples_ativo: true,
  },
  camposAtivosIcmsNfce: {
    mod_bc_icms_nfce_ativo: true,
    aliq_icms_nfce_ativo: true,
    perc_red_base_icms_nfce_ativo: true,
  },
};

export const cadCfopSlice = createSlice({
  name: "cad_cfop",
  initialState: initialState,
  reducers: {
    init: () => initialState,
    setup: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
      return state;
    },
    setCamposAtivosIcms: (state, action) => {
      state.camposAtivosIcms = action.payload;
    },
    setCamposAtivosIcmsNfce: (state, action) => {
      state.camposAtivosIcmsNfce = action.payload;
    },
    setCodigo: (state, action) => {
      state.codigo = action.payload;
    },
    setDescricao: (state, action) => {
      state.descricao = action.payload;
    },
    setCfopNf: (state, action) => {
      state.cfopNf = action.payload;
    },
    setSitTribIcms: (state, action) => {
      state.sitTribIcms = action.payload;
    },
    setModBcIcms: (state, action) => {
      state.modBcIcms = action.payload;
    },
    setPercIcms: (state, action) => {
      state.percIcms = action.payload;
    },
    setTipoRedBaseIcms: (state, action) => {
      state.tipoRedBaseIcms = action.payload;
    },
    setPercRedBaseIcmsEm: (state, action) => {
      state.percRedBaseIcmsEm = action.payload;
    },
    setPercRedBaseIcmsPara: (state, action) => {
      state.percRedBaseIcmsPara = action.payload;
    },
    setSitTribIcmsNfce: (state, action) => {
      state.sitTribIcmsNfce = action.payload;
    },
    setModBcIcmsNfce: (state, action) => {
      state.modBcIcmsNfce = action.payload;
    },
    setPercIcmsNfce: (state, action) => {
      state.percIcmsNfce = action.payload;
    },
    setPercRedBaseIcmsNfce: (state, action) => {
      state.percRedBaseIcmsNfce = action.payload;
    },
    setModBcIcmsSt: (state, action) => {
      state.modBcIcmsSt = action.payload;
    },
    setMargValorAdicIcmsSt: (state, action) => {
      state.margValorAdicIcmsSt = action.payload;
    },
    setPercIcmsSt: (state, action) => {
      state.percIcmsSt = action.payload;
    },
    setPercReduIcmsSt: (state, action) => {
      state.percReduIcmsSt = action.payload;
    },
    setSitTribIpi: (state, action) => {
      state.sitTribIpi = action.payload;
    },
    setPercIpi: (state, action) => {
      state.percIpi = action.payload;
    },
    setEnquadIpi: (state, action) => {
      state.enquadIpi = action.payload;
    },
    setSomaVlrIpiBcIcmsSt: (state, action) => {
      state.somaVlrIpiBcIcmsSt = action.payload;
    },
    setSitTribPis: (state, action) => {
      state.sitTribPis = action.payload;
    },
    setPercPis: (state, action) => {
      state.percPis = action.payload;
    },
    setPercRetenPis: (state, action) => {
      state.percRetenPis = action.payload;
    },
    setSitTribCofins: (state, action) => {
      state.sitTribCofins = action.payload;
    },
    setPercCofins: (state, action) => {
      state.percCofins = action.payload;
    },
    setPercRetenCofins: (state, action) => {
      state.percRetenCofins = action.payload;
    },
    setGeraCredIcms: (state, action) => {
      state.geraCredIcms = action.payload;
    },
    setAliqCredIcms: (state, action) => {
      state.aliqCredIcms = action.payload;
    },
    setPercDesonerIcms: (state, action) => {
      state.percDesonerIcms = action.payload;
    },
    setMotivoDesonerIcms: (state, action) => {
      state.motivoDesonerIcms = action.payload;
    },
    setPercRetenCsll: (state, action) => {
      state.percRetenCsll = action.payload;
    },
    setPercRetenIrpj: (state, action) => {
      state.percRetenIrpj = action.payload;
    },
    setCobrarNaFatura: (state, action) => {
      state.cobrarNaFatura = action.payload;
    },
    setMensagAdicNfe: (state, action) => {
      state.mensagAdicNfe = action.payload;
    },
    setPercIcmsDifer: (state, action) => {
      state.percIcmsDifer = action.payload;
    },
    setDeducVlrIcmsBcPisCofins: (state, action) => {
      state.deducVlrIcmsBcPisCofins = action.payload;
    },
    setCBenef: (state, action) => {
      state.cBenef = action.payload;
    },
    setSomaVlrIpiBcIcms: (state, action) => {
      state.somaVlrIpiBcIcms = action.payload;
    },
    setSomaVlrOutrosBcIcms: (state, action) => {
      state.somaVlrOutrosBcIcms = action.payload;
    },
    setSomaVlrFreteBcIpi: (state, action) => {
      state.somaVlrFreteBcIpi = action.payload;
    },
    setSomaVlrFreteBcIcms: (state, action) => {
      state.somaVlrFreteBcIcms = action.payload;
    },
    setAliqAdRem: (state, action) => {
      state.aliqAdRem = action.payload;
    },
    setAtivo: (state, action) => {
      state.ativo = action.payload;
    },
    setCstsIcms: (state, action) => {
      state.cstsIcms = action.payload;
    },
  },
});

export const {
  init,
  setup,
  setCamposAtivosIcms,
  setCamposAtivosIcmsNfce,
  setCodigo,
  setDescricao,
  setCfopNf,
  setSitTribIcms,
  setModBcIcms,
  setPercIcms,
  setTipoRedBaseIcms,
  setPercRedBaseIcmsEm,
  setPercRedBaseIcmsPara,
  setSitTribIcmsNfce,
  setModBcIcmsNfce,
  setPercIcmsNfce,
  setPercRedBaseIcmsNfce,
  setModBcIcmsSt,
  setMargValorAdicIcmsSt,
  setPercIcmsSt,
  setPercReduIcmsSt,
  setSitTribIpi,
  setPercIpi,
  setEnquadIpi,
  setSomaVlrIpiBcIcmsSt,
  setSitTribPis,
  setPercPis,
  setPercRetenPis,
  setSitTribCofins,
  setPercCofins,
  setPercRetenCofins,
  setGeraCredIcms,
  setAliqCredIcms,
  setPercDesonerIcms,
  setMotivoDesonerIcms,
  setPercRetenCsll,
  setPercRetenIrpj,
  setCobrarNaFatura,
  setMensagAdicNfe,
  setPercIcmsDifer,
  setDeducVlrIcmsBcPisCofins,
  setCBenef,
  setSomaVlrIpiBcIcms,
  setSomaVlrOutrosBcIcms,
  setSomaVlrFreteBcIpi,
  setSomaVlrFreteBcIcms,
  setAliqAdRem,
  setAtivo,
  setCstsIcms,
} = cadCfopSlice.actions;

export default cadCfopSlice.reducer;
