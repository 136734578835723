import React, { useState } from "react";
import { LabelButton, ModalBase, RadioGroup } from "../../../../components";
import { Row } from "reactstrap";
import { toastr } from "react-redux-toastr";
import { RadioItem } from "../../../../components/RadioGroup";

export const ExportarDetEntradasModal = ({
  exportarDominio,
  exportarTexto,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modoExportar, setModoExportar] = useState(null);
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setModoExportar(null);
  };

  const onConfirm = async () => {
    if (["", null, undefined].includes(modoExportar)) {
      toastr.warning("Atenção", "Selecione a forma de exportação");
      return false;
    }
    setLoading(true);
    let res = false;
    if (modoExportar === "DOM") {
      res = exportarDominio();
    } else if (modoExportar === "TXT") {
      res = exportarTexto();
    }

    setLoading(false);
    if (res) toggle();
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <LabelButton divClassName="pt-3" onClick={toggle}>
        Exportar
      </LabelButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Exportar Informações"
        number="0115_1"
        onClosed={limparDados}
        onConfirm={onConfirm}
        loadingConfirm={loading}
      >
        <Row>
          <RadioGroup value={modoExportar} onChange={setModoExportar}>
            <RadioItem label="Sistema Domínio" value="DOM" />
            <RadioItem label="Arquivo Texto" value="TXT" />
          </RadioGroup>
        </Row>
      </ModalBase>
    </>
  );
};
