const settings = {
  AUTENTICADOR: "https://auth.skillsoft.com.br",
  AUTENTICADOR_DEV: "https://auth.skillsoft.com.br",
  API_ADDRESS: "http://localhost:5001",
  WS_TEF: "wss://ws.tef.skillsoft.com.br",
  WS_TEF_DEV: "wss://ws.tef.skillsoft.com.br",
  WHATSAPP_API: "https://whats-api.skillsoft.com.br",
  WHATSAPP_API_DEV: "http://localhost:3333",
  WS_WHATSAPP_API: "wss://ws-whats-api.skillsoft.com.br",
  WS_WHATSAPP_API_DEV: "ws://localhost:3334",
  DB_NAME_DEV: "tres_gessos_guilherme",
  AMBIENTE_DEV: false,
  API_PLACAS: "https://api-placas.skillsoft.com.br",
};

export default settings;
