import unidecode from "unidecode";
import {
  downloadFileFromBlob,
  formatValueFromAPI,
  limparNumero,
} from "../../../../coreUtils";

export const gerarToledoMVG5 = (produtos) => {
  try {
    for (const prod of produtos) {
      if (formatarCodBalanca(prod.cod_balanca) === "".padStart(6, "0")) {
        const msg =
          "O Produto: " +
          formatValueFromAPI(prod.nome, prod.id) +
          "Está com o código de balança zerado e não pode ser" +
          "considerado no arquivo de integração. " +
          "Por favor, revise este produto.";

        return [false, msg];
      }
    }

    const content = produtos.map((prod) => formatarProduto(prod)).join("");

    downloadFileFromBlob(
      new Blob([content], { type: "text/plain" }),
      "TXITENS.TXT"
    );
  } catch (err) {
    const msg = `Falha ao gerar exportação Toledo MVG5: ${err.message}`;
    return [false, msg];
  }

  return [true, null];
};

const formatarCodBalanca = (v) => v.padStart(6, "0");

const formatarProduto = (prod) => {
  const unidMed = prod.unidade === "KG" ? "0" : "1";

  const precoVenda = limparNumero(
    parseFloat(prod.preco_venda ?? 0).toFixed(2)
  ).padStart(6, "0");

  const diasValidade = parseInt(prod.dias_validade ?? 0)
    .toString()
    .padStart(3, "0");

  const nome = unidecode(prod.nome).substring(0, 25).padEnd(25, " ");

  return [
    "01", // Código do Departamento
    "00", // CARACTERES DE TIPO DE ETIQUETA
    unidMed, // Unidade
    formatarCodBalanca(prod.cod_balanca), // Código do Produto
    precoVenda, // Preço de Venda
    diasValidade, // Dias de Validade do Produto
    nome, // Descritivo do Item – Primeira Linha
    "".padEnd(25, " "), // Descritivo do Item – Segunda Linha
    "\n",
  ].join("");
};
