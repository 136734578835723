import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  DatePicker,
  FixedField,
  FormButton,
  FormCheckbox,
  ModalBase,
  NumberInput,
  PesqPlanoCtaCentroCusto,
  RadioGroup,
  Table,
  TableDelete,
  TextInput,
} from "../../../../../components";
import {
  dateFromLocaleString,
  formatDateISO,
  formatNumber,
  formatValueFromAPI,
} from "../../../../../coreUtils";
import { RadioItem } from "../../../../../components/RadioGroup";
import { showWarning } from "../../../../../components/AlertaModal";
import GerencContasReceberService from "../../../../../services/financeiro/GerencContasReceberService";
import moment from "moment";

const headerRowStyle = {
  justifyContent: "space-between",
  marginBottom: "0.5rem",
  marginRight: "-15px",
};

export const AlterarTituloReceberModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [dadosTitulo, setDadosTitulo] = useState({});
  const [numeroTitulo, setNumeroTitulo] = useState(null);
  const [carteiraTitulo, setCarteiraTitulo] = useState(false);
  const [dataVctoTitulo, setDataVctoTitulo] = useState(null);
  const [observTitulo, setObservTitulo] = useState("");
  const [params, setParams] = useState({});
  const [natureza, setNatureza] = useState("+");
  const [idPlanoContas, setIdPlanoContas] = useState(null);
  const [historico, setHistorico] = useState("");
  const [valor, setValor] = useState(0);
  const [loadingAddLancamento, setLoadingAddLancamento] = useState(false);
  const [loadingGravarAlteracoes, setLoadingGravarAlteracoes] = useState(false);

  const [alterouDadosTitulo, setAlterouDadosTitulo] = useState(false);

  const utilizaOrganizacao = params?.utiliza_organizacao ?? false;

  const limparDadosLancamento = () => {
    setNatureza("+");
    setIdPlanoContas(null);
    setHistorico("");
    setValor(0);
  };

  const limparDados = () => {
    setDadosTitulo({});
    setParams({});
    setNumeroTitulo(null);
    setCarteiraTitulo(false);
    setDataVctoTitulo(null);
    setObservTitulo("");
    limparDadosLancamento();
  };

  const carregarDados = async () => {
    const [ok, ret] = await GerencContasReceberService.buscar(selected);
    if (ok) {
      setDadosTitulo(ret);
      setNumeroTitulo(ret.numero);
      setCarteiraTitulo(ret.carteira);
      setDataVctoTitulo(dateFromLocaleString(ret.vencimento));
      setObservTitulo(ret.observ);
    }
    return ok;
  };

  const gravarAlteracoes = async () => {
    setLoadingGravarAlteracoes(true);
    try {
      if (!(dataVctoTitulo instanceof Date)) {
        showWarning("Por favor, revise a data de vencimento do título.");
        return;
      }

      const payload = {
        id_titulo: selected,
        numero: numeroTitulo ?? "",
        data_vcto: formatDateISO(dataVctoTitulo),
        carteira: carteiraTitulo,
        obs: observTitulo ?? "",
      };

      const [ok] = await GerencContasReceberService.alterar(payload);
      if (ok) {
        await carregarDados();
        setAlterouDadosTitulo(true);
      }
    } finally {
      setLoadingGravarAlteracoes(false);
    }
  };

  const onBeforeOpen = async (params) => {
    setParams(params);
    setAlterouDadosTitulo(false);
    const ok = await carregarDados();
    if (!ok) {
      toggle();
      return;
    }
  };

  const handleAddLancamento = async () => {
    if ([0, null, undefined].includes(idPlanoContas)) {
      showWarning("Por favor, informe o Plano de Contas do Lançamento");
      return false;
    }
    if ([0, null, undefined].includes(valor)) {
      showWarning("Por favor, informe o Valor do Lançamento");
      return false;
    }

    const payload = {
      id_titulo: selected,
      natureza: natureza,
      id_plano_contas: idPlanoContas,
      historico: historico,
      valor: valor,
    };

    setLoadingAddLancamento(true);
    const [ok] = await GerencContasReceberService.lancamentos.incluir(payload);
    if (ok) {
      limparDadosLancamento();
      await carregarDados();
      setAlterouDadosTitulo(true);
    }
    setLoadingAddLancamento(false);
  };

  const handleDeleteLancamento = async (id) => {
    const [ok] = await GerencContasReceberService.lancamentos.excluir(id);
    if (ok) {
      carregarDados();
      setAlterouDadosTitulo(true);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      handleAddLancamento();
    }
  };

  const onBeforeClose = () => {
    if (alterouDadosTitulo) {
      notifyEvent();
    }
    setAlterouDadosTitulo(false);
  };

  const columnsLancamentos = [
    { dataField: "id", text: "Seq", align: "center" },
    {
      dataField: "transacao",
      text: "Cod",
      align: "center",
    },
    {
      dataField: "desc_transac",
      text: "Transação",
      align: "left",
    },
    {
      dataField: "histor",
      text: "Histórico",
      align: "left",
    },
    {
      dataField: "usuario",
      text: "Usuário",
      align: "center",
    },
    {
      dataField: "data_hora_lanc",
      text: "Data/Hora",
      align: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "del",
      dummy: true,
      align: "center",
      formatter: (c, row) => (
        <TableDelete onClick={() => handleDeleteLancamento(row.id)} />
      ),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Alterar do Título a Receber"
      number="0120_3"
      onBeforeOpen={onBeforeOpen}
      onBeforeClose={onBeforeClose}
      onClosed={limparDados}
      paramsName="inc_titulo_receber"
      onKeyDown={onKeyDown}
    >
      <Row style={headerRowStyle}>
        {utilizaOrganizacao && (
          <FixedField
            label="Organização"
            value={
              dadosTitulo?.id_organizacao
                ? formatValueFromAPI(
                    dadosTitulo?.nome_organizacao,
                    dadosTitulo?.id_organizacao
                  )
                : "Não Informada"
            }
            horizontal
          />
        )}
        <FixedField label="Sequência" value={selected} horizontal />
        <FixedField
          label="Status"
          value={dadosTitulo?.recebido ? "Liquidado" : "Em Aberto"}
          textStyle={{ color: dadosTitulo?.recebido ? "green" : "red" }}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Cliente"
          value={formatValueFromAPI(
            dadosTitulo?.nome_cliente,
            dadosTitulo?.id_cliente
          )}
          horizontal
        />
        <FixedField
          label="Parcela"
          value={`${dadosTitulo?.parcela}`}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Origem"
          value={dadosTitulo?.desc_origem}
          horizontal
        />
        <FixedField
          label="Nro. Origem"
          value={dadosTitulo?.nro_origem}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField label="Emissão" value={dadosTitulo?.emissao} horizontal />
        {dadosTitulo?.recebido && (
          <FixedField
            label="Recebimento"
            value={dadosTitulo?.recebimento}
            horizontal
          />
        )}
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Juros (+)"
          value={formatNumber(dadosTitulo?.vlr_juro, true, 2)}
          horizontal
        />
        <FixedField
          label="Multa (+)"
          value={formatNumber(dadosTitulo?.vlr_multa, true, 2)}
          horizontal
        />
        <FixedField
          label="Desconto (-)"
          value={formatNumber(dadosTitulo?.vlr_desc, true, 2)}
          horizontal
        />
        <FixedField
          label="Acréscimos (+)"
          value={formatNumber(dadosTitulo?.vlr_acre, true, 2)}
          horizontal
        />
      </Row>
      <Row style={headerRowStyle}>
        <FixedField
          label="Valor Original"
          value={formatNumber(dadosTitulo?.vlr_orig, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor a Receber"
          value={formatNumber(dadosTitulo?.vlr_receber, true, 2)}
          horizontal
        />
        <FixedField
          label="Valor Recebido"
          value={formatNumber(dadosTitulo?.vlr_recebido, true, 2)}
          horizontal
        />
      </Row>
      <hr />
      <Row>
        <TextInput
          md={3}
          label="Número"
          value={numeroTitulo}
          onChange={(e, v) => setNumeroTitulo(v)}
        />
        <FormCheckbox
          label="Carteira"
          checked={carteiraTitulo}
          onChange={() => setCarteiraTitulo(!carteiraTitulo)}
        />
        <DatePicker
          md={2}
          label="Vencimento"
          value={dataVctoTitulo}
          onChange={(v) =>
            setDataVctoTitulo(moment.isMoment(v) ? v.toDate() : v)
          }
        />
      </Row>
      <Row>
        <TextInput
          md={null}
          label="Observação"
          value={observTitulo}
          onChange={(e, v) => setObservTitulo(v)}
        />
        <FormButton
          onClick={gravarAlteracoes}
          color="success"
          loading={loadingGravarAlteracoes}
        >
          Gravar Alterações
        </FormButton>
      </Row>
      <hr />
      <Row>
        <RadioGroup label="Natureza" value={natureza} onChange={setNatureza}>
          <RadioItem label="Acréscimo" value="+" />
          <RadioItem label="Decréscimo" value="-" />
        </RadioGroup>
      </Row>
      <Row>
        <PesqPlanoCtaCentroCusto
          md={8}
          label="Plano Contas"
          mode="plano_contas"
          value={idPlanoContas}
          onChange={setIdPlanoContas}
          hideShortcut={false}
        />
      </Row>
      <Row>
        <TextInput
          md={12}
          label="Histórico"
          value={historico}
          onChange={(e, v) => setHistorico(v)}
        />
      </Row>
      <Row>
        <NumberInput md={2} label="Valor" value={valor} onChange={setValor} />
        <FormButton
          md="auto"
          color="success"
          onClick={handleAddLancamento}
          loading={loadingAddLancamento}
        >
          F9 - Confirmar
        </FormButton>
      </Row>
      <hr />
      <Table
        data={dadosTitulo?.lancamentos ?? []}
        columns={columnsLancamentos}
        showRegisterCount={false}
        pageSize={5}
        paginated={false}
      />
    </ModalBase>
  );
};
