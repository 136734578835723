import React, { useState } from "react";
import { ModalDetalhes } from "../../../../../components/ModalDetalhes";
import { formatNumber, naturalSort } from "../../../../../coreUtils";
import PedidosPendentesEntregaService from "../../../../../services/relatorios/pedido_venda/PedidosPendentesService";
import { ProdServIcone } from "../../../../../components/ProdServIcone";

const columns = [
  {
    dataField: "tipo_item",
    text: "Tipo",
    align: "center",
    headerAlign: "center",
    formatter: (c) => <ProdServIcone value={c} />,
    sortable: true,
  },
  {
    dataField: "item",
    text: "Item",
    align: "center",
    headerAlign: "center",
    sortable: true,
  },
  {
    dataField: "id_item",
    text: "#",
    align: "center",
    headerAlign: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "descricao",
    text: "Descrição",
    align: "left",
    headerAlign: "left",
    sortable: true,
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    headerAlign: "center",
    formatter: (v) => formatNumber(v, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "qt_entregue",
    text: "Entregue",
    align: "center",
    headerAlign: "center",
    formatter: (v) => formatNumber(v, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "qt_saldo_dis",
    text: "Pendente",
    align: "center",
    headerAlign: "center",
    formatter: (v) => formatNumber(v, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_final",
    text: "Valor Item",
    align: "right",
    headerAlign: "right",
    formatter: (v) => formatNumber(v, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "vlr_total",
    text: "Total",
    align: "right",
    headerAlign: "right",
    formatter: (v) => formatNumber(v, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const DetalhesConsultaModal = ({ idPedido, tipo }) => {
  const [dados, setDados] = useState([]);

  const buscarDados = async () => {
    const [ok, ret] = await PedidosPendentesEntregaService.buscarItensPedido(
      idPedido,
      tipo
    );
    setDados(
      ok ? ret.map((e, idx) => ({ ...e, item: e.item ?? idx + 1 })) : []
    );
  };

  return (
    <ModalDetalhes
      id={idPedido}
      title={`Itens do Pedido Nº ${idPedido}`}
      data={dados}
      columns={columns}
      size="lg"
      onOpen={buscarDados}
      onClose={() => setDados([])}
    />
  );
};
