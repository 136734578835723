import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  ComboBox,
  DatePicker,
  Divider,
  FormButton,
  FormCheckbox,
  IntegerFormInput,
  Loader,
  MaskedInput,
  NumberInput,
  PageContainer,
  RadioGroup,
  TextInput,
} from "../../../../components";
import {
  MODAL_ACTIONS,
  formatDateISO,
  formatarCpfCnpj,
  useStateWithRef,
  validarCPFCNPJ,
} from "../../../../coreUtils";
import { TomadoresMDFe } from "./components/TomadoresMDFe";
import { DocumentosMDFe } from "./components/DocumentosMDFe";
import { Card, Row } from "reactstrap";
import moment from "moment";
import ManifEletronMDFeService from "../../../../services/faturamento/ManifEletronMDFeService";
import { RadioItem } from "../../../../components/RadioGroup";
import { Redirect } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { manifestoEletronicoMDFeRoute } from "../../../../routes/modules/docsEletron";
import { InclusaoRapidaDocsMDFeModal } from "./components/InclusaoRapidaDocsMDFeModal";

export const tiposCargaMdfe = [
  { label: "Granel sólido", value: 1 },
  { label: "Granel líquido", value: 2 },
  { label: "Frigorificada", value: 3 },
  { label: "Conteinerizada", value: 4 },
  { label: "Carga Geral", value: 5 },
  { label: "Neogranel", value: 6 },
  { label: "Perigosa (granel sólido)", value: 7 },
  { label: "Perigosa (granel líquido)", value: 8 },
  { label: "Perigosa (carga frigorificada)", value: 9 },
  { label: "Perigosa (conteinerizada)", value: 10 },
  { label: "Perigosa (carga geral)", value: 11 },
];

export const IncluirAlterarMDFe = ({ location }) => {
  const selected = location.state?.id;
  const action = location.state?.action ?? MODAL_ACTIONS.ADD;
  const [redirect, setRedirect] = useState(false);
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // Dados Gerais
  const [numero, setNumero] = useState(null);
  const [chaveAcesso, setChaveAcesso] = useState("");
  const [dataEmissao, setDataEmissao] = useState(null);
  const [dataIniViagem, setDataIniViagem] = useState(null);
  const [horaIniViagem, setHoraIniViagem] = useState("");
  const [idCidadeCarrega, setIdCidadeCarrega] = useState(0);
  const [idPercurso, setIdPercurso] = useState(null);
  const [tomadores, setTomadores, tomadoresRef] = useStateWithRef([]);
  // Rodoviário
  const [idCondutor, setIdCondutor] = useState(0);
  const [cpfCondutor, setCpfCondutor] = useState("");
  const [idVeiculo, setIdVeiculo] = useState(0);
  const [dadosVeiculo, setDadosVeiculo] = useState({});
  const [ciot, setCiot] = useState("");
  const [cnpjCpfRespCiot, setCnpjCpfRespCiot] = useState("");
  // Documentos
  const [documentos, setDocumentos, documentosRef] = useStateWithRef([]);
  // Totalizadores
  const [vlrTotCarga, setVlrTotCarga] = useState(0);
  const [pesoBrutoCarga, setPesoBrutoCarga] = useState(0);
  const [unidMedCarga, setUnidMedCarga] = useState("KG");
  // Informações da Carga
  const [tipoCargaProdPred, setTipoCargaProdPred] = useState(0);
  const [descrProdPred, setDescrProdPred] = useState("");
  const [eanProdPred, setEanProdPred] = useState("");
  const [semEanProdPred, setSemEanProdPed] = useState(false);
  const [ncmProdPred, setNcmProdPred] = useState("");
  const [cepCarrega, setCepCarrega] = useState("");
  const [cepDescarrega, setCepDescarrega] = useState("");
  // Informações adicionais
  const [infoAdic, setInfoAdic] = useState("");

  const [modalIncRapidaDocsOpen, setModalIncRapidaDocsOpen] = useState(false);

  const toggleModalIncRapidaDocsOpen = () => {
    setModalIncRapidaDocsOpen(!modalIncRapidaDocsOpen);
  };

  const handleConfirmModalIncRapidaDocsOpen = (docs) => {
    const novosDocs = [...documentos];
    const novosTomadores = [...tomadores];
    let novoVlrTotalCarga = parseFloat(vlrTotCarga);

    docs.forEach((e) => {
      if (!novosDocs.find((d) => d.chave_acesso === e.chave_acesso)) {
        novosDocs.push({
          tp_documento: e.tipo_doc,
          chave_acesso: e.chave_acesso,
          cidade_dest: e.nome_cidade_doc,
          id_cidade_dest: e.cidade_doc,
        });

        novoVlrTotalCarga += parseFloat(e.vlr_tot_carga);
      }
      if (e.id_tomador && !novosTomadores.find((t) => t.id === e.id_tomador)) {
        novosTomadores.push({
          id: e.id_tomador,
          nome: e.nome_tomador,
          cpf_cnpj: e.cpf_cnpj_tomador,
        });
      }
    });

    setDocumentos(novosDocs);
    setTomadores(novosTomadores);
    setVlrTotCarga(novoVlrTotalCarga);
  };

  const carregarDados = async () => {
    setLoadingFetch(true);
    const [ok, ret] = await ManifEletronMDFeService.buscar(selected);

    if (ok) {
      setNumero(ret.numero);
      setChaveAcesso(ret.chave_acesso);
      setDataEmissao(moment(ret.data_emissao).toDate());
      setDataIniViagem(moment(ret.data_inicio_viagem, "DD/MM/YYYY").toDate());
      setHoraIniViagem(ret.hora_inicio_viagem);
      setTomadores(ret.tomadores);
      setIdCidadeCarrega(ret.id_cidade_carrega);
      setIdPercurso(ret.id_percurso ?? null);
      setIdVeiculo(ret.id_veiculo);
      setIdCondutor(ret.id_condutor ?? 0);
      setCpfCondutor(ret.cpf_condutor);
      setCiot(ret.ciot ?? "");
      setCnpjCpfRespCiot(ret.cnpj_cpf_resp_ciot ?? "");
      setDocumentos(ret.documentos);
      setVlrTotCarga(parseFloat(ret.vlr_total_carga));
      setPesoBrutoCarga(parseFloat(ret.peso_bruto_carga));
      setUnidMedCarga(ret.unid_med_carga);
      setTipoCargaProdPred(ret.tipo_carga_prod_pred);
      setEanProdPred(ret.ean_prod_pred ?? "");
      setNcmProdPred(ret.ncm_prod_pred ?? "");
      setCepCarrega(ret.cep_local_carrega ?? "");
      setCepDescarrega(ret.cep_local_descarrega ?? "");
      setDescrProdPred(ret.descr_prod_pred ?? "");
      setInfoAdic(ret.info_adic ?? "");
      setSemEanProdPed(ret.ean_prod_pred === "SEM GTIN");
    }

    setLoadingFetch(false);
  };

  const addDoc = (payload) => {
    setDocumentos([...documentosRef.current, payload]);
  };

  const deleteDoc = (chave) => {
    setDocumentos(
      documentosRef.current.filter((e) => e.chave_acesso !== chave)
    );
  };

  const addTomador = (payload) => {
    setTomadores([...tomadoresRef.current, payload]);
  };

  const deleteTomador = (id) => {
    setTomadores(tomadoresRef.current.filter((e) => e.id !== id));
  };

  const handleSetVeiculo = (s) => {
    setIdVeiculo(s?.value);
    setDadosVeiculo(s);
  };

  const handleSetIdCondutor = (s) => {
    setIdCondutor(s?.value);
    setCpfCondutor(s?.cpf_condutor ?? "");
  };

  const handleSubmit = async () => {
    if (!dataIniViagem) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a data do início da viagem."
      );
      return false;
    } else if (!(dataIniViagem instanceof Date)) {
      toastr.warning("Atenção", "A data de início da viagem é inválida.");
      return false;
    }

    if (["", null].includes(horaIniViagem)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a hora do início da viagem."
      );
      return false;
    } else if (!moment(horaIniViagem, "hh:mm").isValid()) {
      toastr.warning("Atenção", "A hora de início da viagem é inválida.");
      return false;
    }

    if ([0, null, undefined].includes(idCidadeCarrega)) {
      toastr.warning("Atenção", "Por favor, informe o Local de Carregamento");
      return false;
    }

    if ([0, null, undefined].includes(idVeiculo)) {
      toastr.warning("Atenção", "Por favor, informe o Veículo do MDFe");
      return false;
    }

    if ([0, null, undefined].includes(idCondutor)) {
      toastr.warning("Atenção", "Por favor, informe o Condutor do MDFe");
      return false;
    }

    if (
      (cnpjCpfRespCiot ?? "").replaceAll(/\D/g, "").length > 0 &&
      !validarCPFCNPJ(cnpjCpfRespCiot)
    ) {
      toastr.warning(
        "Atenção",
        "O CPF/CNPJ do Responsavel pelo CIOT é inválido."
      );
      return false;
    }

    if ([0, null, undefined].includes(tipoCargaProdPred)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe o Tipo da Carga Predominante"
      );
      return false;
    }

    if (["", null, undefined].includes(descrProdPred)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a Descrição do Produto Predominante da Carga."
      );
      return false;
    }

    if (!semEanProdPred && ["", null, undefined].includes(eanProdPred)) {
      toastr.warning(
        "Atenção",
        "Caso o produto predominante não possua código EAN, " +
          "marque a opção SEM GTIN e tente novamente."
      );
      return false;
    }

    if (ncmProdPred !== null) {
      const ncmVal = ncmProdPred.replace(/\D/g, "");

      if (ncmVal.length > 0 && ncmVal.length !== 8) {
        toastr.warning("Atenção", "A NCM da Carga Predominante é inválida");
        return false;
      }
    }

    const payload = {
      tomadores: tomadores.map((e) => ({ id: e.id })),
      documentos: documentos.map((e) => ({
        tipo: e.tp_documento,
        chave_acesso: e.chave_acesso,
        id_cidade: e.id_cidade_dest,
      })),
      id_cidade_carrega: idCidadeCarrega,
      cep_carrega: cepCarrega,
      cep_descarrega: cepDescarrega,
      data_ini_viagem: formatDateISO(dataIniViagem),
      hora_ini_viagem: horaIniViagem,
      id_condutor: idCondutor,
      id_veiculo: idVeiculo,
      id_percurso: idPercurso,
      ciot: ciot,
      cnpj_cpf_resp_ciot: cnpjCpfRespCiot,
      info_adic: infoAdic,
      vlr_tot_carga: vlrTotCarga,
      peso_bruto_carga: pesoBrutoCarga,
      unid_med_carga: unidMedCarga,
      tipo_carga_prod_pred: tipoCargaProdPred,
      descr_prod_pred: descrProdPred,
      ean_prod_pred: eanProdPred,
      ncm_prod_pred: ncmProdPred,
    };

    setLoadingSubmit(true);

    const [ok] =
      action === MODAL_ACTIONS.ADD
        ? await ManifEletronMDFeService.incluir(payload)
        : await ManifEletronMDFeService.alterar({ id: selected, ...payload });

    if (ok) {
      setRedirect(true);
    }
    setLoadingSubmit(false);
  };

  const onKeyDown = (e) => {
    if (!e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey) {
      if (e.key === "F9") handleSubmit();
    }
  };

  const handleSetSemEanProdPed = () => {
    if (!semEanProdPred) {
      setEanProdPred("SEM GTIN");
    } else {
      setEanProdPred("");
    }
    setSemEanProdPed(!semEanProdPred);
  };

  useEffect(() => {
    if (action === MODAL_ACTIONS.EDIT) {
      carregarDados();
    }
  }, []);

  return loadingFetch ? (
    <Loader />
  ) : redirect ? (
    <Redirect
      to={{
        pathname: manifestoEletronicoMDFeRoute.path,
        state: { refresh: true },
      }}
    />
  ) : (
    <PageContainer
      title={
        (action === MODAL_ACTIONS.ADD ? "Inclusão" : "Alteração") + " de MDFe"
      }
      number="0042_1"
      canGoBack
      onKeyDown={onKeyDown}
      className="px-0 pt-0 pb-7"
    >
      <Card body>
        {action === MODAL_ACTIONS.EDIT && (
          <Row>
            <IntegerFormInput
              md={2}
              label="Número"
              defaultValue={numero}
              disabled
            />
            <TextInput
              md={4}
              label="Chave de Acesso"
              value={chaveAcesso}
              disabled
            />
            <DatePicker label="Emissão" md={2} value={dataEmissao} disabled />
          </Row>
        )}
        <Row>
          <DatePicker
            autoFocus
            label="Início da Viagem"
            md={2}
            value={dataIniViagem}
            onChange={(v) =>
              setDataIniViagem(moment.isMoment(v) ? v.toDate() : v)
            }
          />
          <TextInput
            type="time"
            label="Hora"
            md={2}
            value={horaIniViagem}
            onChange={(e, v) => setHoraIniViagem(v)}
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="cidade"
            defaultOptions
            label="Local de Carregamento"
            isSearchable
            isClearable
            onChange={(s) => setIdCidadeCarrega(s?.value ?? 0)}
            defaultValue={idCidadeCarrega}
          />
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="percurso_mdfe"
            label="Percurso"
            isSearchable
            isClearable
            defaultOptions
            onChange={(s) => setIdPercurso(s?.value ?? null)}
            defaultValue={idPercurso}
          />
        </Row>
        <TomadoresMDFe
          tomadores={tomadores}
          addTomador={addTomador}
          deleteTomador={deleteTomador}
        />
      </Card>
      <Card body>
        <Divider className="mt-0">Informações dos Documentos</Divider>
        <Row className="mb-2 mt-2">
          <FormButton padded={false} onClick={toggleModalIncRapidaDocsOpen}>
            Inclusão Rápida de Documentos
          </FormButton>
          <InclusaoRapidaDocsMDFeModal
            isOpen={modalIncRapidaDocsOpen}
            toggle={toggleModalIncRapidaDocsOpen}
            onConfirm={handleConfirmModalIncRapidaDocsOpen}
            docsJaIncluidos={documentos}
          />
        </Row>
        <DocumentosMDFe
          documentos={documentos}
          addDoc={addDoc}
          deleteDoc={deleteDoc}
        />
      </Card>
      <Card body>
        <Divider className="mt-0">Informações - Rodoviário</Divider>
        <Row>
          <AsyncComboBox
            md={3}
            isConcatField
            concatModelName="veiculo_empresa"
            defaultOptions
            label="Veículo"
            isSearchable
            onChange={handleSetVeiculo}
            defaultValue={idVeiculo}
          />
          {dadosVeiculo?.value && (
            <>
              <TextInput
                md={2}
                label="RNTRC"
                value={dadosVeiculo?.rntrc}
                disabled
              />
              <TextInput md={1} label="UF" value={dadosVeiculo?.uf} disabled />
              <TextInput
                md={2}
                label="Carroceria"
                value={dadosVeiculo?.carroceria}
                disabled
              />
              <NumberInput
                md={1}
                label="Tara (Kg)"
                value={parseFloat(dadosVeiculo?.tara)}
                disabled
              />
              {dadosVeiculo?.pertence_terceiro && (
                <TextInput
                  md={3}
                  label="Proprietário"
                  value={`${dadosVeiculo?.nome_propriet} - ${dadosVeiculo?.cpf_cnpj_propriet}`}
                  disabled
                />
              )}
            </>
          )}
        </Row>
        <Row>
          <AsyncComboBox
            md={4}
            isConcatField
            concatModelName="condutor_mdfe"
            label="Condutor"
            isSearchable
            isClearable
            defaultOptions
            onChange={handleSetIdCondutor}
            defaultValue={idCondutor}
          />
          <TextInput md={2} label="CPF" value={cpfCondutor} disabled />
          <MaskedInput
            mask="999999999999999"
            maskChar={null}
            md={2}
            label="CIOT"
            value={ciot}
            onChange={(e, v) => setCiot(v)}
          />
          <TextInput
            md={2}
            label="CNPJ/CPF Resp. CIOT"
            value={cnpjCpfRespCiot}
            onChange={(e, v) => setCnpjCpfRespCiot(v)}
            onBlur={(e, v) => setCnpjCpfRespCiot(formatarCpfCnpj(v))}
            maxLength={18}
          />
        </Row>
      </Card>
      <Card body>
        <Divider className="mt-0">Totalizadores do Manifesto</Divider>
        <Row>
          <NumberInput
            label="Valor Total da Carga"
            md={2}
            value={vlrTotCarga}
            onChange={setVlrTotCarga}
          />
          <NumberInput
            label="Peso Bruto"
            md={2}
            decimalPlaces={4}
            onChange={setPesoBrutoCarga}
            value={pesoBrutoCarga}
          />
          <RadioGroup
            label="Unidade"
            value={unidMedCarga}
            onChange={setUnidMedCarga}
          >
            <RadioItem label="Kg" value="KG" />
            <RadioItem label="Ton." value="TON" />
          </RadioGroup>
        </Row>
      </Card>
      <Card body>
        <Divider className="mt-0">Dados da Carga</Divider>
        <Row>
          <ComboBox
            md={3}
            label="Tipo"
            options={tiposCargaMdfe}
            onChange={(s) => setTipoCargaProdPred(s?.value)}
            defaultValue={tipoCargaProdPred}
          />
          <TextInput
            label="GTIN/EAN"
            md={2}
            value={eanProdPred}
            onChange={(e, v) => setEanProdPred(v)}
            maxLength={14}
            disabled={semEanProdPred}
          />
          <FormCheckbox
            label="SEM GTIN"
            md={1}
            name="sem_ean_prod_pred"
            checked={semEanProdPred}
            onChange={handleSetSemEanProdPed}
            divClassName="px-0"
          />
          <MaskedInput
            mask="99999999"
            label="NCM"
            md={2}
            value={ncmProdPred}
            onChange={(e, v) => setNcmProdPred(v)}
          />
          <MaskedInput
            mask="99999-999"
            label="CEP Carrega"
            md={2}
            value={cepCarrega}
            onChange={(e, v) => setCepCarrega(v)}
          />
          <MaskedInput
            mask="99999-999"
            label="CEP Descarrega"
            md={2}
            value={cepDescarrega}
            onChange={(e, v) => setCepDescarrega(v)}
          />
        </Row>
        <Row>
          <TextInput
            label="Produto Predominante"
            md={7}
            value={descrProdPred}
            onChange={(e, v) => setDescrProdPred(v)}
            maxLength={120}
          />
        </Row>
      </Card>
      <Card body>
        <Row>
          <TextInput
            label="Informações Adicionais"
            type="textarea"
            inputStyle={{ resize: "none" }}
            rows={4}
            md={8}
            value={infoAdic}
            onChange={(e, v) => setInfoAdic(v)}
          />
          <FormButton
            md="auto"
            color="success"
            divClassName="ml-auto mt-auto"
            onClick={handleSubmit}
            loading={loadingSubmit}
          >
            {action === MODAL_ACTIONS.ADD ? "Gerar" : "Confirmar"} MDFe
          </FormButton>
        </Row>
      </Card>
    </PageContainer>
  );
};
