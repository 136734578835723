import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import {
  fichaTecnicaRoute,
  ordemProducaoRoute,
  produtoIntermediarioRoute,
  programacaoProducaoRoute,
} from "../../../routes/modules/industria";
import { LiaClipboardListSolid } from "react-icons/lia";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { PiFactory, PiGear } from "react-icons/pi";

export const Industria = () => {
  return (
    <PageContainer title="Indústria">
      <MenuGroup>
        <MenuGroupButton
          label="Ficha Técnica"
          icon={LiaClipboardListSolid}
          pathname={fichaTecnicaRoute.path}
          nroTela={fichaTecnicaRoute.nroTela}
        />
        <MenuGroupButton
          label="Produto Intermediário"
          icon={HiOutlineClipboardDocumentList}
          pathname={produtoIntermediarioRoute.path}
          nroTela={produtoIntermediarioRoute.nroTela}
        />
        <MenuGroupButton
          label="Programação de Produção"
          icon={PiGear}
          pathname={programacaoProducaoRoute.path}
          nroTela={programacaoProducaoRoute.nroTela}
        />
        <MenuGroupButton
          label="Ordem de Produção"
          icon={PiFactory}
          pathname={ordemProducaoRoute.path}
          nroTela={ordemProducaoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
