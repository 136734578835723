import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  CardTotais,
  CardTotaisItem,
  ComboBox,
  FiltroPeriodoDatas,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import GerencChequesClientesService from "../../../../services/financeiro/GerencChequesClientesService";
import { GerencChequesClientesGrid } from "./components/GerencChequesClientesGrid";
import { showConfirmation } from "../../../../components/ConfirmationModal";
import { DetalhesChequeClienteModal } from "./components/DetalhesChequeClienteModal";
import { IncluirAlterarChequeClienteModal } from "./components/IncluirAlterarChequeClienteModal";
import { MODAL_ACTIONS } from "../../../../coreUtils";
import { BotaoIncluir } from "../../../../components/cadastro";
import { DepositarChequeClienteModal } from "./components/DepositarChequeClienteModal";
import { ExcluirChequeClienteModal } from "./components/ExcluirChequeClienteModal";

const periodoOptions = [
  { label: "Bom Para", value: "BOM" },
  { label: "Emissão", value: "EMI" },
  { label: "Devolução", value: "DEV" },
  { label: "Depósito", value: "DEP" },
  { label: "Repasse", value: "REP" },
];

const mostrarStatusOptions = [
  { label: "Todos os Cheques", value: "ALL" },
  { label: "A Receber", value: "A" },
  { label: "Devolvido - Sem Fundo", value: "D" },
  { label: "Depositado em Conta Bancária", value: "E" },
  { label: "Repassado", value: "R" },
  { label: "Enviado para Custódia", value: "C" },
  { label: "Trocado por Dinheiro", value: "T" },
  { label: "Estornado via Devol.", value: "M" },
];

export const GerencChequesClientes = () => {
  const [idCliente, setIdCliente] = useState(null);
  const [idBanco, setIdBanco] = useState(null);
  const [nroCheque, setNroCheque] = useState(null);
  const [cpfCnpjCliente, setCpfCnpjCliente] = useState("");
  const [periodo, setPeriodo] = useState(periodoOptions[0].value);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [mostrarStatus, setMostrarStatus] = useState(
    mostrarStatusOptions[1].value
  );
  const [dados, setDados] = useState([]);
  const [totais, setTotais] = useState({});
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [incluirAlterarOpen, setIncluirAlterarOpen] = useState(false);
  const [action, setAction] = useState(MODAL_ACTIONS.ADD);
  const [detalhesOpen, setDetalhesOpen] = useState(false);
  const [excluirOpen, setExcluirOpen] = useState(false);

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_cliente: idCliente,
      id_banco: idBanco,
      nro_cheque: nroCheque,
      cpf_cnpj_cliente: cpfCnpjCliente,
      periodo: periodo,
      data_ini: dataIni,
      data_fim: dataFim,
      mostrar_status: mostrarStatus,
    };

    setLoading(true);
    const [ok, ret] = await GerencChequesClientesService.listar(params);
    if (ok) {
      setTotais(ret.totais);
      setDados(ret.cheques);
    } else {
      setDados([]);
      setTotais({});
    }
    setLoading(false);
  };

  const handleSelect = (id, is, row) => {
    setSelected(id);
    setSelectedStatus(is ? row?.status : null);
  };

  const toggleIncluirAlterar = () => setIncluirAlterarOpen(!incluirAlterarOpen);

  const toggleDetalhes = () => setDetalhesOpen(!detalhesOpen);

  const toggleExcluir = () => setExcluirOpen(!excluirOpen);

  const mostrarDetalhes = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleDetalhes();
    }, 1);
  };

  const trocarPorDinheiro = (id) => {
    const onConfirm = async () => {
      const payload = { id_cheque_rec: id };
      const [ok] = await GerencChequesClientesService.trocarPorDinheiro(
        payload
      );
      if (ok) {
        carregarDados();
      }
    };

    showConfirmation(
      <>
        Tem certeza que deseja trocar o cheque por dinheiro?
        <br />
        Obs.: O valor do Cheque será creditado no saldo do Caixa Administrativo.
      </>,
      () => onConfirm()
    );
  };

  const enviarCustodia = async (id) => {
    const payload = { id_cheque_rec: id };
    const [ok] = await GerencChequesClientesService.enviarCustodia(payload);
    if (ok) {
      carregarDados();
    }
  };

  const retirarCustodia = async (id) => {
    const payload = { id_cheque_rec: id };
    const [ok] = await GerencChequesClientesService.retirarCustodia(payload);
    if (ok) {
      carregarDados();
    }
  };

  const incluir = () => {
    setAction(MODAL_ACTIONS.ADD);
    setTimeout(() => {
      toggleIncluirAlterar();
    }, 1);
  };

  const alterar = (id) => {
    setSelected(id);
    setAction(MODAL_ACTIONS.EDIT);
    setTimeout(() => {
      toggleIncluirAlterar();
    }, 1);
  };

  const excluir = (id) => {
    setSelected(id);
    setTimeout(() => {
      toggleExcluir();
    }, 1);
  };

  return (
    <PageContainer title="Gerenciamento de Cheques de Clientes" number="0131">
      <Card body>
        <Row>
          <TextInput
            label="Nº Cheque"
            md={2}
            value={nroCheque}
            onChange={(e, v) => setNroCheque(v)}
          />
          <AsyncComboBox
            md={4}
            label="Cliente"
            concatModelName="cliente"
            defaultValue={idCliente}
            onChange={(s) => setIdCliente(s?.value)}
          />
          <TextInput
            label="CPF/CNPJ"
            md={2}
            value={cpfCnpjCliente}
            onChange={(e, v) => setCpfCnpjCliente(v)}
          />
          <AsyncComboBox
            md={4}
            label="Banco"
            concatModelName="banco"
            defaultValue={idBanco}
            onChange={(s) => setIdBanco(s?.value)}
            hideShortcut
            defaultOptions
          />
        </Row>
        <Row>
          <ComboBox
            options={periodoOptions}
            md={2}
            label="Período"
            onChange={(s) => setPeriodo(s?.value)}
            defaultValue={periodo}
          />
          <FiltroPeriodoDatas
            defaultOption={null}
            defaultStart={null}
            defaultEnd={true}
            onChange={handleDate}
          />
          <ComboBox
            options={mostrarStatusOptions}
            md={2}
            label="Mostrar"
            onChange={(s) => setMostrarStatus(s?.value)}
            defaultValue={mostrarStatus}
          />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
          <BotaoIncluir toggle={incluir} divClassName="ml-auto" />
          <DetalhesChequeClienteModal
            isOpen={detalhesOpen}
            toggle={toggleDetalhes}
            selected={selected}
          />
          <IncluirAlterarChequeClienteModal
            isOpen={incluirAlterarOpen}
            toggle={toggleIncluirAlterar}
            action={action}
            selected={selected}
            notifyEvent={carregarDados}
          />
          <DepositarChequeClienteModal
            selected={selected}
            selectedStatus={selectedStatus}
            notifyEvent={carregarDados}
          />
          <ExcluirChequeClienteModal
            isOpen={excluirOpen}
            toggle={toggleExcluir}
            selected={selected}
            notifyEvent={carregarDados}
          />
        </Row>
      </Card>
      <CardTotais>
        <CardTotaisItem
          label="Quantidade"
          value={totais?.quantidade}
          checkFloat={true}
          className="col-md-2 ml-auto"
        />
        <CardTotaisItem
          label="Valor Total"
          value={totais?.vlr_total}
          className="col-md-2"
        />
      </CardTotais>
      <Card body>
        <GerencChequesClientesGrid
          dados={dados}
          onSelect={handleSelect}
          mostrarDetalhes={mostrarDetalhes}
          trocarPorDinheiro={trocarPorDinheiro}
          enviarCustodia={enviarCustodia}
          retirarCustodia={retirarCustodia}
          alterar={alterar}
          excluir={excluir}
        />
      </Card>
    </PageContainer>
  );
};
