import { apiGetV2 } from "../../../apiV2";

const URL_BASE = "/relatorios/pedido_venda";
const URL_BASE_PENDENTE_ENTREGA = `${URL_BASE}/pendente_entrega`;

const PedidosPendentesEntregaService = {
  buscarDados: (params) => apiGetV2(`${URL_BASE_PENDENTE_ENTREGA}/`, params),
  buscarItensPedido: (idPedido, tipo) =>
    apiGetV2(`${URL_BASE_PENDENTE_ENTREGA}/itens/${idPedido}/${tipo}/`),
  buscarAgrupadoProduto: (params) =>
    apiGetV2(`${URL_BASE}/produtos_pendentes_entrega/`, params),
  buscarAgrupadoCliente: (params) =>
    apiGetV2(`${URL_BASE}/produtos_pendentes_entrega_por_cli/`, params),
};

export default PedidosPendentesEntregaService;
