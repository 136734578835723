import React from "react";
import { IconButton, Table } from "../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../coreUtils";
import { GoArrowRight } from "react-icons/go";

export const ConfirmacaoPrecosGrid = ({ dados, prosseguirConfirmacaoNf }) => {
  const columns = [
    { dataField: "id", text: "ID", align: "center" },
    {
      dataField: "nome_fornecedor",
      text: "Fornecedor",
      align: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_fornecedor),
    },
    {
      dataField: "numero",
      text: "Número",
      align: "center",
    },
    { dataField: "serie", text: "Série", align: "center" },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
    },
    {
      dataField: "vlr_tot_prod",
      text: "Produtos",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_tot_icms_st",
      text: "ICMS ST",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_tot_ipi",
      text: "IPI",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_frete",
      text: "Frete",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "act",
      text: "",
      align: "center",
      formatter: (c, row) => (
        <IconButton
          icon={GoArrowRight}
          onClick={() => prosseguirConfirmacaoNf(row.id)}
        />
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onRowDoubleClick={(id) => prosseguirConfirmacaoNf(id)}
      paginated={false}
    />
  );
};
