import React from "react";
import { Table } from "../../../../components";
import { formatNumber, naturalSort } from "../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "#",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "nome",
    text: "Nome",
    align: "left",
    sortable: true,
    style: (c, row) => (row.pesa_no_pdv === true ? { color: "#5b7dff" } : {}),
  },
  {
    dataField: "cod_balanca",
    text: "Cód. Balança",
    align: "center",
    sortable: true,
  },
  {
    dataField: "unidade",
    text: "UN",
    align: "center",
    sortable: true,
    style: (c, row) => (row.pesa_no_pdv === true ? { color: "#5b7dff" } : {}),
  },
  {
    dataField: "preco_venda",
    text: "Preço de Venda",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "dias_validade",
    text: "Dias de Validade",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const ExportacaoBalancaGrid = ({ dados }) => {
  return <Table data={dados} columns={columns} paginated={false} />;
};
