import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  BotaoImprimir,
  LabelButton,
  ModalBase,
  Table,
} from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import { docPrintReport } from "../../../../pdf";
import { showWarning } from "../../../../components/AlertaModal";
import SugestaoCompraService from "../../../../services/compras/SugestaoCompraService";

export const ProdutosAbaixoEstMinModal = forwardRef(
  (
    {
      ignorarEstNegativo,
      considerarProdInativos,
      formatarMultifilter,
      atualizarContagemAoIniciar,
      handleSubmit,
      disabled,
      disabledHint,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [produtos, setProdutos] = useState([]);
    const [qtdProdutos, setQtdProdutos] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingImprimir, setLoadingImprimir] = useState(false);

    const limparDados = () => {
      setProdutos([]);
    };

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    const gerarParametros = () => ({
      ignorar_est_negativo: ignorarEstNegativo,
      considerar_prod_inativos: considerarProdInativos,
      multifilter: formatarMultifilter ? formatarMultifilter() : undefined,
    });

    const buscarDados = async () => {
      return SugestaoCompraService.listarProdutosEstMin(gerarParametros());
    };

    const onChangeQtdCompra = (coluna, novoValor, row) => {
      setProdutos(
        produtos.map((e) =>
          e.id_produto === row.id_produto
            ? { ...row, qtd_compra: parseFloat(novoValor) }
            : e
        )
      );
    };

    const carregarDados = async () => {
      const [ok, ret] = await buscarDados();

      if (!ok) {
        toggle();
        return;
      }
      setProdutos(ok ? ret.map((e) => ({ ...e, qtd_compra: 0 })) : []);
    };

    const buscarQtdProdutosEstMin = async () => {
      const [ok, ret] = await SugestaoCompraService.buscarQtdProdutosEstMin(
        gerarParametros()
      );
      setQtdProdutos(ok ? ret.qtd_produtos : 0);
    };

    const imprimir = async () => {
      setLoadingImprimir(true);
      const [ok, ret] = await buscarDados();
      if (ok) {
        await docPrintReport(
          "/compras/produtos_abaixo_est_min/",
          { dados: ret },
          "0016_4"
        );
      }
      setLoadingImprimir(false);
    };

    const handleSubmitInternal = async () => {
      const payload = produtos.filter((e) => e.qtd_compra > 0);
      if (payload.length === 0) {
        showWarning(
          "Por favor, informe a quantidade de compra para ao menos um produto"
        );
        return;
      }

      setLoading(true);
      if (await handleSubmit(payload)) {
        toggle();
      }
      setLoading(false);
    };

    useImperativeHandle(ref, () => ({
      atualizarContagem: buscarQtdProdutosEstMin,
    }));

    const onChangeAtualizarContagemAoIniciar = () => {
      if (atualizarContagemAoIniciar) {
        buscarQtdProdutosEstMin();
      }
    };

    useEffect(onChangeAtualizarContagemAoIniciar, [atualizarContagemAoIniciar]);

    const columns = [
      { dataField: "id_produto", text: "Produto", align: "center" },
      {
        dataField: "nome_produto",
        text: "Nome",
        align: "left",
        fixedColWidth: true,
        colWidth: "400px",
      },
      {
        dataField: "unidade_produto",
        text: "Unidade",
        align: "center",
      },
      {
        dataField: "quantidade",
        text: "Est. Atual",
        align: "center",
        formatter: (c, row) =>
          formatNumber(c, true, row.qtd_casas_decimais_qtd, true),
      },
      {
        dataField: "est_min",
        text: "Est. Mínimo",
        align: "center",
        formatter: (c, row) =>
          formatNumber(c, true, row.qtd_casas_decimais_qtd, true),
      },
      {
        dataField: "qtd_sugerida_compra",
        text: "Qtd. Sugerida",
        align: "center",
        formatter: (c, row) =>
          formatNumber(c, true, row.qtd_casas_decimais_qtd, true),
      },
      {
        dataField: "qtd_compra",
        text: "Qtd. Compra",
        align: "center",
        editable: true,
        editorType: "number",
        onChange: onChangeQtdCompra,
        alwaysShowEditor: true,
        fixedColWidth: true,
        colWidth: "100px",
        decimalPlaces: (row) => row.qtd_casas_decimais_qtd,
      },
    ];

    return (
      <>
        {qtdProdutos > 0 && (
          <LabelButton
            divClassName="ml-auto pt-3 px-0"
            onClick={toggle}
            hint={
              formatarMultifilter | ignorarEstNegativo | considerarProdInativos
                ? "Considerando os filtros"
                : null
            }
            disabled={disabled}
            disabledHint={disabledHint}
          >
            {qtdProdutos} produtos abaixo do estoque mínimo
          </LabelButton>
        )}
        <ModalBase
          isOpen={isOpen}
          toggle={toggle}
          size="lg"
          title="Produtos Abaixo do Estoque Mínimo"
          number="0016_4"
          numberStyle={{ marginRight: "0" }}
          onBeforeOpen={carregarDados}
          onClosed={limparDados}
          onConfirm={handleSubmitInternal}
          loadingConfirm={loading}
          footerActions={
            <BotaoImprimir
              padded={false}
              onClick={imprimir}
              loading={loadingImprimir}
              divClassName="mr-auto"
            />
          }
        >
          <Table
            key="id_produto"
            data={produtos}
            columns={columns}
            paginated={false}
            growIntoPageSize
            pageSize={15}
          />
        </ModalBase>
      </>
    );
  }
);
