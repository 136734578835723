import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import { AsyncComboBox, FormCheckbox } from "../../../../components";
import { FiltroPeriodoDatas } from "../../../../components/FiltroPeriodoDatas";
import {
  userPodeVerTotaisRelVenda,
  isArray,
  formatDateISO,
} from "../../../../coreUtils";
import { ComissaoFixaGrid } from "./components/ComissaoFixaGrid";
import ComissaoFixaService from "../../../../services/relatorios/comissionamento/ComissaoFixaService";
import { BotaoPesquisar } from "../../../../components/BotaoPesquisar";
import { BotaoImprimir } from "../../../../components/BotaoImprimir";
import { PageContainer } from "../../../../components/PageContainer";
import { docPrintReport } from "../../../../pdf";

export const ComissaoFixa = () => {
  const podeVerTotais = userPodeVerTotaisRelVenda();
  const idUsuario = parseInt(localStorage.getItem("id_colaborador"));
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [vendedores, setVendedores] = useState(
    !podeVerTotais ? [idUsuario] : []
  );
  const [nomesVendedores, setNomesVendedores] = useState(
    !podeVerTotais
      ? [{ label: localStorage.getItem("id_colaborador"), value: idUsuario }]
      : []
  );
  const [imprimirDetalhamento, setImprimirDetalhamento] = useState(false);
  const [loadingPesquisar, setLoadingPesquisar] = useState(false);
  const [loadingRelatorio, setLoadingRelatorio] = useState(false);

  const limparDados = () => {
    setDados([]);
  };

  const handleSelectDate = async (_dataIni, _dataFim) => {
    setDataIni(_dataIni);
    setDataFim(_dataFim);
    limparDados();
  };

  const listarDados = async () => {
    setLoadingPesquisar(true);

    const [ok, ret] = await ComissaoFixaService.listar(
      vendedores?.join(","),
      dataIni,
      dataFim
    );
    setDados(ok ? ret : []);
    setLoadingPesquisar(false);
  };

  const gerarRelatorio = async () => {
    setLoadingRelatorio(true);
    if (imprimirDetalhamento) {
      for (const idVendedor of vendedores) {
        const [ok, ret] = await ComissaoFixaService.buscarDetalhamentoComiss(
          idVendedor,
          dataIni,
          dataFim
        );

        if (ok) {
          await docPrintReport(
            "/relatorios/comissao/fixa/",
            {
              itens: ret.itens,
              nome_vendedor:
                nomesVendedores.find((e) => e.value === idVendedor)?.label ??
                "",
              data_ini: formatDateISO(dataIni),
              data_fim: formatDateISO(dataFim),
            },
            "5021_2"
          );
        }
      }
    } else {
      const [ok, ret] = await ComissaoFixaService.listar(
        vendedores?.join(","),
        dataIni,
        dataFim
      );

      if (ok) {
        await docPrintReport(
          "/relatorios/comissao/fixa/",
          {
            itens: ret,
            geral: true,
            data_ini: formatDateISO(dataIni),
            data_fim: formatDateISO(dataFim),
          },
          "5021_2"
        );
      }
    }
    setLoadingRelatorio(false);
  };

  const handleSetVendedor = (selected) => {
    setVendedores(
      isArray(selected)
        ? selected?.map((e) => e.value)
        : selected
        ? [selected?.value]
        : []
    );
    setNomesVendedores(
      isArray(selected) ? selected : selected ? [selected] : []
    );
    limparDados();
  };

  return (
    <PageContainer title="Comissão Fixa" number="5021" canGoBack>
      <Card body>
        <Row>
          <FiltroPeriodoDatas onChange={handleSelectDate} />
        </Row>
        <Row>
          <AsyncComboBox
            isClearable
            isConcatField
            isSearchable
            concatModelName="colaborador"
            defaultOptions
            md={5}
            label="Vendedor(es)"
            onChange={handleSetVendedor}
            isMulti={podeVerTotais}
            disabled={!podeVerTotais}
            defaultValue={!podeVerTotais ? vendedores[0] : null}
          />
          <BotaoPesquisar
            onClick={listarDados}
            loading={loadingPesquisar}
            disabled={vendedores?.length === 0 || vendedores === null}
            disabledHint="Selecione ao menos um vendedor"
          />
          <FormCheckbox
            label="Imprimir detalhamento de produtos por vendedor"
            name="imprimir_detalhamento"
            onChange={() => setImprimirDetalhamento(!imprimirDetalhamento)}
            checked={imprimirDetalhamento}
            padded={true}
          />
          <BotaoImprimir
            onClick={gerarRelatorio}
            loading={loadingRelatorio}
            disabled={vendedores?.length === 0 || vendedores === null}
            disabledHint="Selecione ao menos um vendedor"
          />
        </Row>
      </Card>
      <ComissaoFixaGrid data={dados} dataIni={dataIni} dataFim={dataFim} />
    </PageContainer>
  );
};
