import React, { useState } from "react";
import { Table } from "../../../../components";
import { ModalBase } from "../../../../components/ModalBase";
import NotaFiscalServicoService from "../../../../services/docs_eletron/NotaFiscalServicoService";
import { formatValueFromAPI } from "../../../../coreUtils";

const columns = [
  {
    dataField: "alerta",
    text: "Mensagem",
    align: "left",
    headerAlign: "left",
    colWidth: "60%",
  },
  {
    dataField: "data_hora_alerta",
    text: "Data / Hora",
    align: "center",
    headerAlign: "center",
    colWidth: "15%",
  },
  {
    dataField: "nome_colaborador",
    text: "Colaborador",
    align: "left",
    headerAlign: "left",
    formatter: (c, row) => formatValueFromAPI(c, row.id_colaborador),
    colWidth: "25%",
  },
];

export const AlertasNFSeModal = ({ isOpen, toggle, selected }) => {
  const [dados, setDados] = useState([]);

  const limparDados = () => setDados([]);

  const carregarDados = async () => {
    const [ok, ret] = await NotaFiscalServicoService.buscarAlertas(selected);
    setDados(ok ? ret : []);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      title="Alertas da NFSe"
      number="0046_2"
      autoFocus
      hideCancelButton
    >
      <Table data={dados} columns={columns} paginated={false} />
    </ModalBase>
  );
};
