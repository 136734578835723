import React, { useState } from "react";
import { Badge, Row } from "reactstrap";
import {
  Divider,
  FixedField,
  IconButton,
  ModalBase,
  Table,
} from "../../../../components";
import NotaFiscalService from "../../../../services/docs_eletron/NotaFiscalService";
import {
  formatNumber,
  formatValueFromAPI,
  formatarValor,
} from "../../../../coreUtils";
import CadastroClienteService from "../../../../services/cadastro/CadastroClienteService";
import { toastr } from "react-redux-toastr";
import { BiCopy } from "react-icons/bi";
import { optionsFretePorContaNF } from "../incluir_alterar/components/DadosFreteNf";

export const DetalhesNfModal = ({ isOpen, toggle, selected }) => {
  const [dadosCab, setDadosCab] = useState({});
  const [dadosDest, setDadosDest] = useState({});
  const [totaisNfe, setTotaisNfe] = useState({});
  const [itens, setItens] = useState([]);
  const [casasDecQuantidade, setCasasDecQuantidade] = useState(2);
  const [casasDecValor, setCasasDecValor] = useState(2);

  const dadosFrete = dadosCab.frete ?? {};

  const limparDados = () => {
    setDadosCab({});
    setDadosDest({});
    setTotaisNfe({});
    setItens([]);
  };

  const carregarDados = async (params) => {
    setCasasDecQuantidade(params?.num_casas_decimais_quantidade ?? 2);
    setCasasDecValor(params?.num_casas_decimais_valor ?? 2);

    const [ok1, ret1] = await NotaFiscalService.inclusao.buscarCab(selected);
    if (ok1) {
      const [ok2, ret2] = await CadastroClienteService.buscarResumo(
        ret1.id_cliente
      );

      if (!ok2) return false;

      const [ok3, ret3] = await NotaFiscalService.inclusao.buscarItens(
        selected
      );

      if (!ok3) return false;

      setDadosCab(ret1);
      setDadosDest(ret2);
      setItens(ret3.itens);
      setTotaisNfe(ret3.totais_nfe);
    }
  };

  const columns = [
    {
      dataField: "item",
      text: "#",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "50px",
    },
    {
      dataField: "id_produto",
      text: "Produto",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "70px",
    },
    {
      dataField: "descricao",
      text: "Descrição",
      align: "left",
      headerAlign: "left",
      fixedColWidth: true,
      colWidth: "320px",
    },
    {
      dataField: "ncm",
      text: "NCM",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "80px",
    },
    {
      dataField: "sit_trib_icms",
      text: "CST",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "50px",
    },
    {
      dataField: "cfop",
      text: "CFOP",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "unidade",
      text: "UN",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "55px",
    },
    {
      dataField: "quantidade",
      text: "Qtd.",
      align: "center",
      headerAlign: "center",
      fixedColWidth: true,
      colWidth: "70px",
      formatter: (c) => formatarValor(c, casasDecQuantidade, 0, true),
    },
    {
      dataField: "vlr_item",
      text: "Vlr. Item",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "80px",
      formatter: (c) => formatarValor(c, casasDecValor, casasDecValor, true),
    },
    {
      dataField: "vlr_icms",
      text: "ICMS",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "80px",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
    {
      dataField: "vlr_total",
      text: "Total",
      align: "right",
      headerAlign: "right",
      fixedColWidth: true,
      colWidth: "90px",
      formatter: (c) => formatarValor(c, 2, 2, true),
    },
  ];

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      title="Detalhes da Nota Fiscal"
      number="0055_7"
      paramsName="inc_alt_prod_nfe"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
    >
      <Row className="mb-2">
        <FixedField label="Número" value={dadosCab.numero} horizontal />
        <FixedField label="Série" value={dadosCab.serie} horizontal />
        <FixedField label="Emissão" value={dadosCab.emissao} horizontal />
        <FixedField
          label="Tipo de Documento"
          value={dadosCab.desc_tipo_doc}
          horizontal
        />
      </Row>
      <Row>
        <FixedField
          label="Chave de Acesso"
          value={
            <>
              {dadosCab.chave_nfe}{" "}
              {(dadosCab.chave_nfe ?? "").length > 0 && (
                <IconButton
                  size={12}
                  icon={BiCopy}
                  onClick={() => {
                    navigator.clipboard.writeText(dadosCab.chave_nfe);
                    toastr.info("", "Chave de Acesso copiada.");
                  }}
                />
              )}
            </>
          }
          horizontal
        />
        <FixedField
          label="Origem"
          value={
            <>
              {dadosCab.desc_origem}
              {dadosCab.id_origem > 0 && <> - {dadosCab.id_origem}</>}
              {dadosCab.venda_cancelada === true && (
                <Badge
                  color="danger"
                  className="ml-2"
                  style={{ transform: "translateY(-1px)" }}
                >
                  Venda Cancelada
                </Badge>
              )}
            </>
          }
          horizontal
        />
      </Row>
      <hr className="my-2" />
      <Row className="mb-2">
        <FixedField
          label="Cliente"
          value={formatValueFromAPI(dadosCab.nome_cli, dadosCab.id_cliente)}
          horizontal
        />
      </Row>
      <Row className="mb-2">
        <FixedField label="CNPJ" value={dadosDest.cpf_cnpj} horizontal />
        <FixedField
          label="Inscrição Estadual"
          value={dadosDest.insc_est}
          horizontal
        />
        <FixedField
          label="Endereço"
          value={dadosDest.endereco_cliente}
          horizontal
        />
      </Row>
      <Row>
        <FixedField label="CEP" value={dadosDest.cep} horizontal />
        <FixedField
          label="Cidade"
          value={
            dadosDest.cidade_cliente
              ? dadosDest.cidade_cliente + " - " + dadosDest.uf_cidade
              : ""
          }
          horizontal
        />
        <FixedField label="Fone" value={dadosDest.fone1} horizontal />
      </Row>
      <Divider className="mt-3">Totais da Nota Fiscal</Divider>
      <Row>
        <FixedField
          md={2}
          label="Base Cálc. ICMS"
          value={formatNumber(totaisNfe.base_calc_icms ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="ICMS"
          value={formatNumber(totaisNfe.vlr_tot_icms ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="B.C. ICMS ST"
          value={formatNumber(totaisNfe.base_calc_icms_st ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="ICMS ST"
          value={formatNumber(totaisNfe.vlr_tot_icms_st ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="IPI"
          value={formatNumber(totaisNfe.vlr_tot_ipi ?? 0, true, 2)}
        />
      </Row>
      <Row>
        <FixedField
          md={2}
          label="Frete"
          value={formatNumber(totaisNfe.vlr_frete ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="Seguro"
          value={formatNumber(totaisNfe.vlr_seguro ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="Outras Desp."
          value={formatNumber(totaisNfe.vlr_outros ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="Desconto"
          value={formatNumber(totaisNfe.vlr_desconto ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="Total Produtos"
          value={formatNumber(totaisNfe.vlr_tot_prod ?? 0, true, 2)}
        />
        <FixedField
          md={2}
          label="Total da Nota"
          value={formatNumber(totaisNfe.vlr_tot_nf ?? 0, true, 2)}
          labelStyle={{ color: "black" }}
          textStyle={{ fontSize: "0.9rem", fontWeight: "bold" }}
        />
      </Row>
      <Divider className="mt-2">Transportadora / Volumes Transportados</Divider>
      <Row className="mb-2">
        <FixedField
          label="Transportadora"
          value={
            dadosFrete.id_transportadora
              ? formatValueFromAPI(
                  dadosFrete.nome_transportadora,
                  dadosFrete.id_transportadora
                )
              : ""
          }
          horizontal
        />
        <FixedField
          label="Frete Por Conta"
          value={
            optionsFretePorContaNF.find(
              (e) => e.value === dadosFrete.transp_por_conta
            )?.label ?? ""
          }
          horizontal
        />
        <FixedField label="Placa" value={dadosFrete.placa} horizontal />
        <FixedField label="UF Veíc." value={dadosFrete.uf_veiculo} horizontal />
      </Row>
      <Row className="mb-2">
        <FixedField
          label="Qtd. Volume"
          defaultValue={dadosFrete.quantidade}
          horizontal
        />
        <FixedField label="Espécie" value={dadosFrete.especie} horizontal />
        <FixedField label="Marca" value={dadosFrete.marca} horizontal />
        <FixedField label="Número" value={dadosFrete.numero} horizontal />
        <FixedField
          label="Peso Bruto"
          value={formatNumber(dadosFrete.peso_bruto, true, 2)}
          horizontal
        />
        <FixedField
          label="Peso Líquido"
          value={formatNumber(dadosFrete.peso_liquido, true, 2)}
          horizontal
        />
      </Row>
      <Row className="mb-3">
        <FixedField
          label="Informações Adicionais"
          value={dadosCab.dados_adic}
          textStyle={{ whiteSpace: "pre-wrap" }}
          horizontal
        />
      </Row>
      <Table data={itens} columns={columns} pageSize={7} paginated={false} />
    </ModalBase>
  );
};
