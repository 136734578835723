import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { RiExchangeDollarLine } from "react-icons/ri";
import {
  gerencChequesClientesRoute,
  gerencContasReceberRoute,
  gerenciamentoCreditoRotativoRoute,
} from "../../../routes/modules/financeiro";
import { PiCurrencyCircleDollar } from "react-icons/pi";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

export const ContasReceber = () => {
  return (
    <PageContainer title="Contas a Receber">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento"
          icon={PiCurrencyCircleDollar}
          pathname={gerencContasReceberRoute.path}
          nroTela={gerencContasReceberRoute.nroTela}
        />
        <MenuGroupButton
          label="Cheques de Clientes"
          icon={LiaMoneyCheckAltSolid}
          pathname={gerencChequesClientesRoute.path}
          nroTela={gerencChequesClientesRoute.nroTela}
        />
        <MenuGroupButton
          label="Crédito Rotativo"
          icon={RiExchangeDollarLine}
          pathname={gerenciamentoCreditoRotativoRoute.path}
          nroTela={gerenciamentoCreditoRotativoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
