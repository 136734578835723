import React, { useState } from "react";
import {
  DragAndDropArquivos,
  ModalBase,
  TextInput,
} from "../../../../components";
import { Row } from "reactstrap";
import NotaFiscalConsumidorService from "../../../../services/docs_eletron/NotaFiscalConsumidorService";
import { showWarning } from "../../../../components/AlertaModal";

const linksSefaz = [
  {
    uf: "RS",
    link: "https://www.sefaz.rs.gov.br/dfe/Consultas/ConsultaPublicaDfe",
  },
];

export const AjustarDuplicidadeNFCeModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [chaveAcesso, setChaveAcesso] = useState("");
  const [arquivo, setArquivo] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState("");
  const [loading, setLoading] = useState(false);
  const linkSefazUfEmpresa =
    linksSefaz.find((e) => e.uf === localStorage.getItem("uf_empresa"))?.link ??
    false;

  const limparDados = () => {
    setChaveAcesso("");
    setArquivo(null);
    setNomeArquivo("");
  };

  const carregarDados = async () => {
    const [ok, ret] =
      await NotaFiscalConsumidorService.gerenciamento.buscarChaveAcessoDuplicidade(
        selected
      );
    if (!ok) return false;
    setChaveAcesso(ret.chave_nfe ?? "");
    return true;
  };

  const onDrop = (file) => {
    setArquivo(file);
    setNomeArquivo(file.name);
  };

  const onBeforeOpen = async () => {
    if (!(await carregarDados(selected))) {
      toggle();
    }
  };

  const handleSubmit = async () => {
    if (!arquivo) {
      showWarning("Por favor, selecione o Arquivo XML da Nota Fiscal.");
      return;
    }
    setLoading(true);
    let formData = new FormData();
    formData.append("file", arquivo, nomeArquivo);
    formData.append("id_nfe", selected);

    const [ok] =
      await NotaFiscalConsumidorService.gerenciamento.corrigirDuplicidade(
        formData
      );

    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      title="Ajustar Duplicidade de Nota Fiscal Consumidor"
      onBeforeOpen={onBeforeOpen}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
      onClosed={limparDados}
      number="0066_8"
    >
      <span>
        1. Para ajustar a duplicidade da NFCe, é necessário realizar o download
        do arquivo XML da Nota Fiscal Consumidor conforme a chave de acesso
        abaixo:
      </span>
      <Row className="mb-2">
        <TextInput value={chaveAcesso} disabled />
      </Row>
      {linkSefazUfEmpresa && (
        <p>
          É possível realizar o download do arquivo por{" "}
          <a href={linkSefazUfEmpresa} target="blank">
            ESTE LINK - SEFAZ ESTADUAL.
          </a>
        </p>
      )}

      <p>2. Após baixado o arquivo, indique-o abaixo:</p>
      <Row className="mb-3">
        <DragAndDropArquivos
          multiple={true}
          accept="text/xml"
          md={12}
          text="Arraste e solte o Arquivo XML da NFCe ou clique para selecioná-lo"
          onDrop={onDrop}
          style={{ backgroundColor: "#523468" }}
        />
      </Row>
      {arquivo && (
        <Row className="mb-3">
          <TextInput label="Arquivo Indicado" value={nomeArquivo} disabled />
        </Row>
      )}
      <span>3. Com o arquivo indicado, clique em Confirmar.</span>
    </ModalBase>
  );
};
