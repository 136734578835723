import React, { useState } from "react";
import {
  AsyncComboBox,
  BotaoPesquisar,
  FiltroPeriodoDatas,
  IntegerFormInput,
  PageContainer,
} from "../../../components";
import { Card, Row } from "reactstrap";
import { ConfirmacaoPrecosGrid } from "./components/ConfirmacaoPrecosGrid";
import ConfirmacaoPrecosService from "../../../services/compras/ConfirmacaoPrecosService";
import { useHistory } from "react-router-dom";
import { confirmarPrecosNfRoute } from "../../../routes/modules/compras";

export const ConfirmacaoPrecos = () => {
  const [idFornecedor, setIdFornecedor] = useState(null);
  const [numero, setNumero] = useState(null);
  const [dataIni, setDataIni] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleDate = (di, df) => {
    setDataIni(di);
    setDataFim(df);
  };

  const carregarDados = async () => {
    const params = {
      id_fornecedor: idFornecedor,
      numero: numero,
      data_ini: dataIni,
      data_fim: dataFim,
    };
    setLoading(true);
    const [ok, ret] = await ConfirmacaoPrecosService.listarNfs(params);
    setDados(ok ? ret : []);
    setLoading(false);
  };

  const prosseguirConfirmacaoNf = async (id) => {
    history.push(confirmarPrecosNfRoute.path, { selected: id });
  };

  return (
    <PageContainer title="Confirmação de Preços de Custo e Venda" number="0130">
      <Card body>
        <Row>
          <AsyncComboBox
            md={4}
            label="Fornecedor"
            isConcatField
            concatModelName="fornecedor"
            onChange={(s) => setIdFornecedor(s?.value)}
          />
          <IntegerFormInput
            md={2}
            label="Número NF-e"
            value={numero}
            onChange={setNumero}
          />
          <FiltroPeriodoDatas label="Emissão" onChange={handleDate} />
          <BotaoPesquisar onClick={carregarDados} loading={loading} />
        </Row>
      </Card>
      <Card body>
        <ConfirmacaoPrecosGrid
          dados={dados}
          prosseguirConfirmacaoNf={prosseguirConfirmacaoNf}
        />
      </Card>
    </PageContainer>
  );
};
