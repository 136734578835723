import React from "react";
import {
  ColorField,
  IconButton,
  IconLink,
  Table,
} from "../../../../components";
import { formatNumber } from "../../../../coreUtils";
import {
  gerarSugestaoCompraRoute,
  incluirOrdemCompraRoute,
} from "../../../../routes/modules/compras";
import { BsPrinter } from "react-icons/bs";
import { OrdemCompraService } from "../../../../services/compras/OrdemCompraService";
import { LuPencilLine } from "react-icons/lu";
import { MdMailOutline } from "react-icons/md";

const coresStatus = [
  { value: "SCD", color: "#0080FF", hint: "Sugestão de Compra Em Digitação" },
  { value: "OCD", color: "#7e24b7", hint: "Ordem de Compra Em Digitação" },
  { value: "OCA", color: "#FF9664", hint: "Ordem de Compra Ag. Mercadoria" },
  { value: "OCF", color: "#4bb51e", hint: "Ordem de Compra Finalizada" },
];

export const GerenciamentoComprasGrid = ({
  dados,
  setSelected,
  enviarEmailOrdemCompra,
}) => {
  const columns = [
    { dataField: "id", text: "#", align: "center" },
    { dataField: "processo", text: "Processo", align: "center", hidden: true },
    {
      dataField: "dh_inclusao",
      text: "Inclusão",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "dh_ultima_atualizacao",
      text: "Última Alteração",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "data_entrega_ordem_compra",
      text: "Data de Entrega",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "fornecedor",
      text: "Fornecedor",
      align: "left",
      formatter: (c, row) => {
        if (row.id_ordem_compra) {
          return row.fornecedor_ordem_compra;
        }
        return "";
      },
    },
    {
      dataField: "numero_nf_entrada",
      text: "NF Entrada",
      align: "center",
    },
    {
      dataField: "qtd_produtos",
      text: "Qtd. Produtos",
      align: "center",
      formatter: (c, row) => {
        if (row.id_ordem_compra) {
          return formatNumber(row.qtd_prods_ordem_compra, true, 2, true);
        }
        return "";
      },
    },
    {
      dataField: "vlr_total",
      text: "Valor Total",
      align: "right",
      formatter: (c, row) => {
        if (row.id_ordem_compra) {
          return formatNumber(row.vlr_total_ordem_compra, true, 2);
        }
        return "";
      },
    },
    {
      dataField: "status",
      text: "St",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <ColorField scheme={coresStatus} value={c} />,
      style: { verticalAlign: "middle", padding: 0 },
      colWidth: "35px",
      fixedColWidth: true,
      sortable: true,
      hideSortIcon: true,
    },
    {
      dataField: "det",
      text: "Alt",
      align: "center",
      headerAlign: "center",
      cellContentTag: null,
      formatter: (c, row) => (
        <>
          {row.status === "OCD" && (
            <IconLink
              icon={LuPencilLine}
              size={16}
              pathname={incluirOrdemCompraRoute.path}
              state={{ id_ordem_compra: row.id_ordem_compra }}
              hint="Continuar Ordem de Compra"
              tooltipPlacement="left"
              color="#dba400"
            />
          )}
          {row.status === "SCD" && (
            <IconLink
              icon={LuPencilLine}
              size={16}
              pathname={gerarSugestaoCompraRoute.path}
              state={{ id_sugestao: row.id_sugestao }}
              hint="Continuar Sugestão de Compra"
              tooltipPlacement="left"
              color="#dba400"
            />
          )}
        </>
      ),
    },
    {
      dataField: "det",
      text: "Ações",
      align: "center",
      headerAlign: "center",
      cellContentTag: null,
      formatter: (c, row) => (
        <>
          {row.id_ordem_compra && (
            <>
              <IconButton
                icon={BsPrinter}
                onClick={() => OrdemCompraService.imprimir(row.id_ordem_compra)}
                hint="Imprimir Ordem de Compra"
                tooltipPlacement="left"
              />
              <IconButton
                icon={MdMailOutline}
                onClick={() =>
                  enviarEmailOrdemCompra(
                    row.id_ordem_compra,
                    row.email_fornecedor_ordem_compra ?? ""
                  )
                }
                hint="Enviar E-Mail Ordem de Compra"
                tooltipPlacement="left"
                className="ml-2"
              />
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={setSelected}
      clearSelectionOnUpdate
    />
  );
};
