import React from "react";
import { Table } from "../../../../../components/Table";

const DocsProdutorRuralGrid = ({ docsVinculados, onSelect }) => {
  const columns = [
    {
      dataField: "nro_doc_fiscal",
      text: "Nº/Série",
      headerAlign: "center",
      align: "center",
      formatter: (_, row) => `${row.nro_doc_fiscal}/${row.serie_doc_fiscal}`,
    },
    {
      dataField: "modelo_documento",
      text: "Modelo",
      headerAlign: "left",
      align: "left",
    },
    {
      dataField: "ano_mes_ref_nf",
      text: "Ano/Mês",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "uf_emitente",
      text: "UF",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "cnpj_prod_rural",
      text: "CNPJ",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "cpf_prod_rural",
      text: "CPF",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "insc_est_prod_rural",
      text: "I.E.",
      headerAlign: "center",
      align: "center",
    },
  ];
  return (
    <Table
      key={JSON.stringify(docsVinculados)}
      columns={columns}
      data={docsVinculados}
      pageSize={5}
      paginated={false}
      onSelect={onSelect}
    />
  );
};

export default DocsProdutorRuralGrid;
