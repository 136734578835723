import React from "react";
import { Table } from "../../../../components";
import { formatNumber, naturalSort } from "../../../../coreUtils";

const columns = [
  {
    dataField: "id",
    text: "Produto",
    align: "center",
    sortable: true,
    sortFunc: naturalSort,
  },
  { dataField: "nome", text: "Nome", align: "left", sortable: true },
  {
    dataField: "referencia",
    text: "Referência",
    align: "center",
    sortable: true,
  },
  { dataField: "unidade", text: "UN", align: "center" },
  {
    dataField: "nome_grupo",
    text: "Grupo",
    align: "left",
    sortable: true,
  },
  {
    dataField: "quantidade",
    text: "Qtd",
    align: "center",
    formatter: (c) => formatNumber(c, true, 2, true),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "custo_ue",
    text: "Custo",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
  {
    dataField: "preco_venda",
    text: "Preço Venda",
    align: "right",
    formatter: (c) => formatNumber(c, true, 2),
    sortable: true,
    sortFunc: naturalSort,
  },
];

export const ManutencaoEstoqueGrid = ({ dados, setSelected }) => {
  return (
    <Table
      data={dados}
      columns={columns}
      onSelect={setSelected}
      clearSelectionOnUpdate
      paginated
    />
  );
};
