import React from "react";
import { ColorField, MoreDropdown, Table } from "../../../../../components";
import { formatNumber, formatValueFromAPI } from "../../../../../coreUtils";
import { DropdownItem } from "reactstrap";
import { iconeAlterar } from "../../../../../components/cadastro";

const coresStatus = [
  { value: "A", color: "#0080FF", hint: "A Receber" },
  { value: "D", color: "#FF0D0D", hint: "Devolvido - Sem Fundo" },
  { value: "E", color: "#FF9664", hint: "Depositado em Conta Bancária" },
  { value: "R", color: "#00AA00", hint: "Repassado - Pagamento de Contas" },
  { value: "C", color: "#80FF00", hint: "Enviado para Custódia" },
  { value: "T", color: "#DDDD00", hint: "Trocado por Dinheiro" },
  {
    value: "M",
    color: "#800080",
    hint: "Estornado via Devolução de Mercadoria",
  },
];

export const GerencChequesClientesGrid = ({
  dados,
  onSelect,
  mostrarDetalhes,
  trocarPorDinheiro,
  enviarCustodia,
  retirarCustodia,
  alterar,
  excluir,
}) => {
  const columns = [
    { dataField: "id", text: "#", align: "center", headerAlign: "center" },
    {
      dataField: "nome_banco",
      text: "Banco",
      align: "left",
      headerAlign: "left",
    },
    {
      dataField: "nro_cheque",
      text: "Nº Cheque",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "nome_cliente",
      text: "Cliente",
      align: "left",
      headerAlign: "left",
      formatter: (c, row) => formatValueFromAPI(c, row.id_cliente),
    },
    {
      dataField: "cpf_cnpj_cliente",
      text: "CPF/CNPJ",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "emissao",
      text: "Emissão",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "bom_para",
      text: "Bom Para",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "valor",
      text: "Valor",
      align: "right",
      headerAlign: "right",
      formatter: (c) => formatNumber(c, true, 2),
    },
    {
      dataField: "status",
      text: "St",
      align: "center",
      headerAlign: "center",
      formatter: (c) => <ColorField scheme={coresStatus} value={c} />,
      style: { verticalAlign: "middle", padding: 0 },
      colWidth: "35px",
      fixedColWidth: true,
      sortable: true,
      hideSortIcon: true,
    },
    iconeAlterar(alterar, {
      disabled: (id, row) => !["A", "D"].includes(row.status),
      disabledHint: "Cheques nesta situação não podem ser alterados",
    }),
    {
      dataField: "act",
      text: "",
      align: "center",
      selectOnClick: false,
      fixedColWidth: true,
      colWidth: "40px",
      formatter: (c, row) => (
        <MoreDropdown horizontal>
          <DropdownItem onClick={() => mostrarDetalhes(row.id)}>
            Detalhes
          </DropdownItem>
          {row.status === "A" && (
            <>
              <DropdownItem onClick={() => trocarPorDinheiro(row.id)}>
                Trocar por Dinheiro
              </DropdownItem>
              <DropdownItem onClick={() => enviarCustodia(row.id)}>
                Enviar para Custódia
              </DropdownItem>
            </>
          )}
          {row.status === "C" && (
            <DropdownItem onClick={() => retirarCustodia(row.id)}>
              Retirar da Custódia
            </DropdownItem>
          )}
          {row.status === "A" && (
            <>
              <DropdownItem divider />
              <DropdownItem onClick={() => excluir(row.id)}>
                Excluir
              </DropdownItem>
            </>
          )}
        </MoreDropdown>
      ),
    },
  ];

  return (
    <Table
      data={dados}
      columns={columns}
      paginated={false}
      onSelect={onSelect}
      clearSelectionOnUpdate
    />
  );
};
