import React from "react";
import { ModalBase } from "../../../../../components";

export const ConfirmarItemJaIncluidoSugestaoModal = ({
  isOpen,
  toggle,
  onConfirm,
}) => {
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Adicionar Produto à Sugestão de Compra"
      onConfirm={() => {
        toggle();
        onConfirm();
      }}
      cancelButtonText="Não"
      confirmButtonText="Sim"
    >
      <p>
        Este produto já está presente na Sugestão de Compra
        <br />
        <br />
        Deseja incluí-lo novamente?
      </p>
    </ModalBase>
  );
};
