import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const BASE_URL = "/bancario/cobranca/gerenciamento";
const BASE_URL_BOLETOS = "/bancario/cobranca/boleto";
const BASE_URL_INTEGRA_API = "/bancario/cobranca/integracao_api";

export const GerenciamentoCobrancaBancariaService = {
  listar: (params) => apiGetV2(`${BASE_URL}/listar/`, params),
  listarSkill: (params) => apiGetV2(`${BASE_URL}/listar_skill/`, params),
  buscar: (idDuplicata) => apiGetV2(`${BASE_URL}/buscar/${idDuplicata}/`),
  enviarAvisoAtrasoWhatsapp: (idDuplicata) =>
    apiPostV2(`${BASE_URL}/whats_atraso_skill/`, {
      id_dup_rec_mov: idDuplicata,
    }),
  enviarNfseBoletoViaWhats: (idDuplicata) =>
    apiPostV2(`${BASE_URL_BOLETOS}/boleto_nfse_via_whatsapp/`, {
      id_dup_rec_mov: idDuplicata,
    }),
  gerarEmitirNfseBoleto: (idDuplicata) =>
    apiPostV2(`${BASE_URL_BOLETOS}/gerar_emitir_nfse_boleto/`, {
      id_dup_rec_mov: idDuplicata,
    }),
  verificaClienteGeraNfseBoleto: (idCliente) =>
    apiGetV2(
      `${BASE_URL_BOLETOS}/verifica_cliente_gera_nfse_boleto/${idCliente}/`
    ),
  verificarStatusBoleto: (idDuplicata) =>
    apiPutV2(`${BASE_URL_INTEGRA_API}/verificar_status_boleto/`, {
      id_duplicata: idDuplicata,
    }),
  relInconsistenciasSkill: (ano, somenteComInconsistencia) =>
    apiGetV2(
      `${BASE_URL}/rel_inconsistencias_skill/?ano=${ano}&somente_com_inconsistencia=${somenteComInconsistencia}`
    ),
};
