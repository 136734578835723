import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { MdOutlineFormatAlignCenter } from "react-icons/md";
import { centralPedInternoRoute } from "../../../routes/modules/pedidos";

export const PedidosInternos = () => {
  return (
    <PageContainer title="Pedidos Internos">
      <MenuGroup>
        <MenuGroupButton
          label="Central de Pedidos Internos"
          icon={MdOutlineFormatAlignCenter}
          pathname={centralPedInternoRoute.path}
          nroTela={centralPedInternoRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
