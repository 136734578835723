import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_ACTIONS } from "../../../../../../coreUtils";
import {
  init,
  setAtivo,
  setCstsIcms,
  setup,
} from "../../store/cadRegraTributacaoSlice";
import { BlocoDadosGerais } from "./BlocoDadosGerais";
import { CamposChaves } from "./CamposChaves";
import { TabsTributacao } from "./TabsTributacao";
import {
  modalTitleRegraTrib,
  routesBaseRegraTrib,
} from "../../RegraTributacao";
import { ModalCadastroV2 } from "../../../../../../components/cadastro";
import { toastr } from "react-redux-toastr";
import TributacaoService from "../../../../../../services/cadastro/TributacaoService";

export const CadastroRegraTributacaoModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
}) => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();

  const limparDados = () => {
    dispatch(init());
  };

  const onOpen = async () => {
    const [ok, ret] = await TributacaoService.listarCsts();

    if (!ok) return false;

    dispatch(setCstsIcms(ret));
  };

  const fetchData = async (res) => {
    const payload = {
      descricao: res.descricao,
      dentroEstabelecimento: res.dentro_estabelecimento ? "DENT" : "FORA",
      finalidadeOper: res.finalidade_oper,
      ufOrigem: res.uf_emite,
      ufDestino: res.uf_destino,
      tipoContrib: res.tipo_contrib,
      modeloNfe: res.modelo_nfe,
      cfop: res.cfop,
      cobrarNaFatura: res.cobrar_na_fatura,
      mensagAdicNfe: res.mensag_adic_nfe,
      sitTribIcms: res.sit_trib_icms,
      modBcIcms: res.mod_bc_icms,
      percIcms: parseFloat(res.perc_icms),
      tipoRedBaseIcms: res.tipo_red_base_icms,
      percRedBaseIcmsEm: parseFloat(res.perc_red_base_icms_em),
      percRedBaseIcmsPara: parseFloat(res.perc_red_base_icms_para),
      somaVlrFreteBcIcms: res.soma_vlr_frete_bc_icms,
      modBcIcmsSt: res.mod_bc_icms_st,
      margValorAdicIcmsSt: parseFloat(res.marg_valor_adic_icms_st),
      percIcmsSt: parseFloat(res.perc_icms_st),
      percReduIcmsSt: parseFloat(res.perc_redu_icms_st),
      sitTribIpi: res.sit_trib_ipi,
      percIpi: parseFloat(res.perc_ipi),
      enquadIpi: res.enquad_ipi,
      somaVlrIpiBcIcmsSt: res.soma_vlr_ipi_bc_icms_st,
      somaVlrIpiBcIcms: res.soma_vlr_ipi_bc_icms,
      somaVlrFreteBcIpi: res.soma_vlr_frete_bc_ipi,
      somaVlrOutrosBcIcms: res.soma_vlr_outros_bc_icms,
      sitTribPis: res.sit_trib_pis,
      percPis: parseFloat(res.perc_pis),
      percRetenPis: parseFloat(res.perc_reten_pis),
      sitTribCofins: res.sit_trib_cofins,
      percCofins: parseFloat(res.perc_cofins),
      percRetenCofins: parseFloat(res.perc_reten_cofins),
      geraCredIcms: res.gera_cred_icms,
      aliqCredIcms: parseFloat(res.aliq_cred_icms),
      percDesonerIcms: parseFloat(res.perc_desoner_icms),
      motivoDesonerIcms: res.motivo_desoner_icms,
      percRetenCsll: parseFloat(res.perc_reten_csll),
      percRetenIrpj: parseFloat(res.perc_reten_irpj),
      percIcmsDifer: parseFloat(res.perc_icms_difer),
      deducVlrIcmsBcPisCofins: res.deduc_vlr_icms_bc_pis_cofins,
      cBenef: res.c_benef || "",
      aliqAdRem: parseFloat(res.aliq_ad_rem),
      ativo: res.ativo,
    };
    dispatch(setup(payload));
  };

  const submitPayload = (action) => {
    if (["", null, undefined].includes(store.descricao)) {
      toastr.warning("Atenção", "Por favor, informe a Descrição");
      return false;
    }

    if (["", null, undefined].includes(store.dentroEstabelecimento)) {
      toastr.warning("Atenção", "Por favor, informe o Local da Operação");
      return false;
    }

    if (["", null, undefined].includes(store.finalidadeOper)) {
      toastr.warning("Atenção", "Por favor, informe a Finalidade da Operação");
      return false;
    }

    if (["", null, undefined].includes(store.ufDestino)) {
      toastr.warning("Atenção", "Por favor, informe a UF Destino");
      return false;
    }

    if (["", null, undefined].includes(store.tipoContrib)) {
      toastr.warning("Atenção", "Por favor, informe o Tipo de Contribuinte");
      return false;
    }

    if (["", null, undefined].includes(store.modeloNfe)) {
      toastr.warning("Atenção", "Por favor, informe o Modelo da NFe");
      return false;
    }

    if (["", null, undefined].includes(store.sitTribIcms)) {
      toastr.warning(
        "Atenção",
        "Por favor, informe a Situação Tribtária do ICMS"
      );
      return false;
    }

    const payload = {
      descricao: store.descricao,
      dentro_estabelecimento: store.dentroEstabelecimento === "DENT",
      finalidade_oper: store.finalidadeOper ?? "",
      modelo_nfe: store.modeloNfe,
      uf_emite: store.ufOrigem ?? "RS",
      uf_destino: store.ufDestino ?? "",
      cfop: store.cfop,
      tipo_contrib: store.tipoContrib,
      sit_trib_icms: store.sitTribIcms,
      mod_bc_icms: store.modBcIcms ?? "",
      perc_icms: store.percIcms,
      tipo_red_base_icms: store.tipoRedBaseIcms ?? "",
      perc_red_base_icms_em: store.percRedBaseIcmsEm,
      perc_red_base_icms_para: store.percRedBaseIcmsPara,
      soma_vlr_frete_bc_icms: store.somaVlrFreteBcIcms,
      mod_bc_icms_st: store.modBcIcmsSt ?? "",
      marg_valor_adic_icms_st: store.margValorAdicIcmsSt,
      perc_icms_st: store.percIcmsSt,
      perc_redu_icms_st: store.percReduIcmsSt,
      sit_trib_ipi: store.sitTribIpi ?? "",
      perc_ipi: store.percIpi,
      enquad_ipi: store.enquadIpi ? store.enquadIpi : "",
      soma_vlr_ipi_bc_icms_st: store.somaVlrIpiBcIcmsSt,
      soma_vlr_ipi_bc_icms: store.somaVlrIpiBcIcms,
      soma_vlr_frete_bc_ipi: store.somaVlrFreteBcIpi,
      soma_vlr_outros_bc_icms: store.somaVlrOutrosBcIcms,
      sit_trib_pis: store.sitTribPis ?? "",
      perc_pis: store.percPis,
      perc_reten_pis: store.percRetenPis,
      sit_trib_cofins: store.sitTribCofins ?? "",
      perc_cofins: store.percCofins,
      perc_reten_cofins: store.percRetenCofins,
      gera_cred_icms: store.geraCredIcms,
      aliq_cred_icms: store.aliqCredIcms,
      perc_desoner_icms: store.percDesonerIcms,
      motivo_desoner_icms: store.motivoDesonerIcms ?? "",
      perc_reten_csll: store.percRetenCsll,
      perc_reten_irpj: store.percRetenIrpj,
      cobrar_na_fatura: store.cobrarNaFatura,
      mensag_adic_nfe: store.mensagAdicNfe,
      perc_icms_difer: store.percIcmsDifer,
      deduc_vlr_icms_bc_pis_cofins: store.deducVlrIcmsBcPisCofins,
      c_benef: store.cBenef,
      aliq_ad_rem: store.aliqAdRem,
      ativo: store.ativo,
    };

    return action === MODAL_ACTIONS.ADD
      ? payload
      : { ...payload, id: selected };
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleRegraTrib}
      size="lg"
      onClose={limparDados}
      fetchData={fetchData}
      submitPayload={submitPayload}
      headerCheck={{
        value: store.ativo,
        toggle: () => dispatch(setAtivo(!store.ativo)),
      }}
      routesBase={routesBaseRegraTrib}
      number="0031_1"
      selected={selected}
      notifyEvent={notifyEvent}
      onOpen={onOpen}
    >
      <CamposChaves />
      <BlocoDadosGerais />
      <TabsTributacao />
    </ModalCadastroV2>
  );
};
