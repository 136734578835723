import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FixedField,
  FormButton,
  ModalBase,
  TextInput,
} from "../../../../../components";
import { Row } from "reactstrap";
import { setInfoAdic } from "../store/cabNFSlice";
import NotaFiscalService from "../../../../../services/docs_eletron/NotaFiscalService";

export const InfoAdicNfModal = () => {
  const stateCab = useSelector((state) => state.cabNF);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [infoAdicInterno, setInfoAdicInterno] = useState("");
  const [infoAdicAuto, setInfoAdicAuto] = useState("");
  const [loadingFetch, setLoadingFetch] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const limparDados = () => {
    setInfoAdicInterno("");
    setInfoAdicAuto("");
  };

  const onConfirm = async () => {
    if (![0, null, undefined].includes(stateCab.idNfe)) {
      const payload = {
        id_cab: stateCab.idNfe,
        info_adic: infoAdicInterno,
      };
      setLoadingSubmit(true);
      const [ok] = await NotaFiscalService.inclusao.atualizarInfoAdic(payload);
      setLoadingSubmit(false);
      if (!ok) return false;
    }
    dispatch(setInfoAdic(infoAdicInterno));
    toggle();
  };

  const toggle = async () => {
    if (!isOpen) {
      if (![0, null, undefined].includes(stateCab.idNfe)) {
        setLoadingFetch(true);
        const [ok, ret] = await NotaFiscalService.inclusao.buscarInfoAdic(
          stateCab.idNfe
        );
        setLoadingFetch(false);
        if (ok) {
          setInfoAdicAuto(ret.info_adic_auto);
          setInfoAdicInterno(ret.info_adic1);
        } else {
          return false;
        }
      } else {
        setInfoAdicInterno(stateCab.infoAdic);
      }
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FormButton md="auto" onClick={toggle} loading={loadingFetch}>
        Informações Adicionais
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title="Informações Adicionais da NF"
        number="0055_12"
        onConfirm={onConfirm}
        loadingConfirm={loadingSubmit}
        onClosed={limparDados}
      >
        {infoAdicAuto !== "" && (
          <Row className="mb-3">
            <FixedField
              labelStyle={{ color: "black", fontWeight: "bold" }}
              label="Informações Adicionais Geradas pelo Sistema"
              value={
                <>
                  {infoAdicAuto.split("\n").map((e) => (
                    <>
                      {e}
                      <br />
                    </>
                  ))}
                </>
              }
            />
          </Row>
        )}
        <Row>
          <TextInput
            md={12}
            label="Informações Adicionais"
            type="textarea"
            value={infoAdicInterno}
            onChange={(e, v) => setInfoAdicInterno(v)}
            rows={3}
          />
        </Row>
      </ModalBase>
    </>
  );
};
