import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { FormButton } from "../../../../../components";
import { BoletoService } from "../../../../../services/bancario/BoletoService";

export const EmailBoletosModal = ({
  duplics,
  notifyAction,
  disabledButtons,
  label = "Enviar E-Mail",
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const send = async () => {
    setIsLoading(true);
    for (let i = 0; i < duplics.length; i++) {
      try {
        await enviarBoletoEmail(duplics[i]);
      } catch (error) {
        toastr.error("Erro!", error.message);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const enviarBoletoEmail = async (idDuplic) => {
    if (await BoletoService.enviarBoletoEmail(idDuplic)) notifyAction("email");
  };

  return (
    <FormButton
      onClick={isLoading ? null : send}
      disabled={disabledButtons}
      loading={isLoading}
      padded={false}
      color="secondary"
      md="auto"
      divClassName="pl-0"
    >
      {label}
    </FormButton>
  );
};
