import React, { useState } from "react";
import { IntegerFormInput, ModalBase } from "../../../../components";
import { Row } from "reactstrap";
import { OrdemCompraService } from "../../../../services/compras/OrdemCompraService";

export const FecharManualmenteOrdemCompraModal = ({
  isOpen,
  toggle,
  ordemCompraSelected,
  notifyEvent,
}) => {
  const [loading, setLoading] = useState(false);
  const [nroNfEntrada, setNroNfEntrada] = useState(0);

  const limparDados = () => {
    setNroNfEntrada(0);
  };

  const handleSubmit = async () => {
    const payload = {
      id_ordem_compra: ordemCompraSelected,
      nro_nf_entrada: nroNfEntrada,
    };
    setLoading(true);
    try {
      const [ok] = await OrdemCompraService.fecharManualmenteOrdemCompra(
        payload
      );
      if (ok) {
        notifyEvent();
        toggle();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      title="Fechar Ordem de Compra Manualmente"
      number="0016_8"
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <Row>
        <IntegerFormInput
          md={12}
          label="Número da NF-e"
          value={nroNfEntrada}
          onChange={setNroNfEntrada}
        />
      </Row>
    </ModalBase>
  );
};
