import React, { useEffect, useState } from "react";
import {
  AsyncComboBox,
  DatePicker,
  Divider,
  FixedField,
  FormButton,
  FormCheckbox,
  IconButton,
  ModalBase,
  NumberInput,
  TextInput,
} from "../../../../../components";
import { Col, Row } from "reactstrap";
import { BsMagic } from "react-icons/bs";
import {
  dateDiffInDays,
  dateFromLocaleString,
  formatDateISO,
  formatNumber,
  roundFloat,
  sumDataField,
} from "../../../../../coreUtils";
import moment from "moment";
import { showWarning } from "../../../../../components/AlertaModal";
import GerencContasReceberService from "../../../../../services/financeiro/GerencContasReceberService";
import { ConfirmarRectoParcialModal } from "./ConfirmarRectoParcialModal";
import { apiGetV2 } from "../../../../../apiV2";

const CampoValor = ({ label, value, onChange, preencAuto }) => (
  <Col md={4}>
    <Row>
      <NumberInput md={null} label={label} value={value} onChange={onChange} />
      <IconButton
        icon={BsMagic}
        onClick={() => preencAuto(onChange)}
        style={{
          marginTop: "27px",
        }}
      />
    </Row>
  </Col>
);

export const ReceberTitulosModal = ({ titulosSelected, notifyEvent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rectoParcialOpen, setRectoParcialOpen] = useState(false);

  // Conta Bancária
  const [idContaContaBanc, setIdContaContaBanc] = useState(null);
  const [historContaBanc, setHistorContaBanc] = useState("");
  const [dataPagContaBanc, setDataPagContaBanc] = useState(null);
  const [valorContaBanc, setValorContaBanc] = useState(0);

  // Caixa ADM
  const [historCaixaAdm, setHistorCaixaAdm] = useState("");
  const [dataPagCaixaAdm, setDataPagCaixaAdm] = useState(null);
  const [valorCaixaAdm, setValorCaixaAdm] = useState(0);

  // Cheque
  const [nroCheque, setNroCheque] = useState(null);
  const [idBancoCheque, setIdBancoCheque] = useState(null);
  const [idClienteCheque, setIdClienteCheque] = useState(null);
  const [nroContaCheque, setNroContaCheque] = useState(null);
  const [agenciaCheque, setAgenciaCheque] = useState(null);
  const [cmc7Cheque, setCmc7Cheque] = useState(null);
  const [chequeAVista, setChequeAVista] = useState(false);
  const [vencimentoCheque, setVencimentoCheque] = useState(null);
  const [obsCheque, setObsCheque] = useState(null);
  const [valorCheque, setValorCheque] = useState(0);

  // Multa e Juros
  const [percMulta, setPercMulta] = useState(0);
  const [vlrMulta, setVlrMulta] = useState(0);
  const [diasMulta, setDiasMulta] = useState(0);
  const [percJuros, setPercJuros] = useState(0);
  const [vlrJuros, setVlrJuros] = useState(0);
  const [diasJuros, setDiasJuros] = useState(0);

  const selecionouTituloRecebido = titulosSelected.some(
    (e) => e.recebido === true
  );
  const vlrReceber = roundFloat(
    sumDataField(titulosSelected, "vlr_receber") + vlrJuros + vlrMulta,
    2
  );
  const totalLancado = valorContaBanc + valorCaixaAdm + valorCheque;
  const totalLancar = roundFloat(vlrReceber - totalLancado, 2);
  const multiplosTitulos = titulosSelected.length > 1;

  const limparValores = () => {
    setValorContaBanc(0);
    setValorCaixaAdm(0);
    setValorCheque(0);
  };

  const limparDados = () => {
    limparValores();
    setPercJuros(0);
    setVlrJuros(0);
    setPercMulta(0);
    setVlrMulta(0);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const preencAuto = (funcao) => {
    limparValores();
    funcao(parseFloat(vlrReceber));
  };

  const buscarParams = async () => {
    const [ok, ret] = await apiGetV2("/tela/rec_titulo_receber/");
    if (ok) {
      if (ret.cob_multa) {
        setPercMulta(parseFloat(ret.taxa_multa));
        setDiasMulta(parseFloat(ret.dias_multa));
      }
      if (ret.cob_juros) {
        setPercJuros(parseFloat(ret.taxa_juros));
        setDiasJuros(parseFloat(ret.dias_juros));
      }
    }
  };

  const calcularDiasAtraso = (vencimento) => {
    return dateDiffInDays(vencimento, new Date());
  };

  const calcularJuros = () => {
    let vlrJurosTotal = 0;
    titulosSelected.forEach((e) => {
      let diasAtraso = calcularDiasAtraso(dateFromLocaleString(e.vencimento));
      // Desconta dias de carência
      diasAtraso -= diasJuros;

      if (diasAtraso > 0) {
        const valorJurosDia =
          (parseFloat(e.vlr_receber) * (percJuros / 100)) / 30;
        const vlrJurosTitulo = roundFloat(valorJurosDia * diasAtraso, 2);
        vlrJurosTotal += vlrJurosTitulo;

        e.vlr_juros = vlrJurosTitulo;
      }
    });

    setVlrJuros(vlrJurosTotal);
  };

  const calcularMulta = () => {
    let vlrMultaTotal = 0;
    titulosSelected.forEach((e) => {
      let diasAtraso = calcularDiasAtraso(dateFromLocaleString(e.vencimento));
      // Desconta dias de carência
      diasAtraso -= diasMulta;

      if (diasAtraso > 0) {
        const vlrMultaTitulo = roundFloat(
          parseFloat(e.vlr_receber) * (percMulta / 100),
          2
        );
        vlrMultaTotal += vlrMultaTitulo;

        e.vlr_multa = vlrMultaTitulo;
      }
    });

    setVlrMulta(vlrMultaTotal);
  };

  const onChangePercJuros = () => {
    calcularJuros();
  };

  useEffect(onChangePercJuros, [percJuros]);

  const onChangePercMulta = () => {
    calcularMulta();
  };

  useEffect(onChangePercMulta, [percMulta]);

  const verificarValoresRecursos = () => {
    if (valorContaBanc === 0) {
      setIdContaContaBanc(null);
      setHistorContaBanc("");
      setDataPagContaBanc(null);
    }

    if (valorCaixaAdm === 0) {
      setHistorCaixaAdm("");
      setDataPagCaixaAdm(null);
    }

    if (valorCheque === 0) {
      setIdBancoCheque(null);
      setIdClienteCheque(null);
      setNroCheque(null);
      setNroContaCheque(null);
      setAgenciaCheque(null);
      setCmc7Cheque(null);
      setChequeAVista(false);
      setVencimentoCheque(null);
      setObsCheque(null);
    }
  };

  const onChangeValoresRecursos = () => {
    verificarValoresRecursos();
  };

  useEffect(onChangeValoresRecursos, [
    valorCaixaAdm,
    valorContaBanc,
    valorCheque,
  ]);

  const togglePagtoParcial = () => setRectoParcialOpen(!rectoParcialOpen);

  const handleSubmit = async (verificarPagtoParcial) => {
    let recursos = [];
    let pagtoParcial = false;
    if (totalLancado === 0) {
      showWarning("Por favor, informe ao menos um Recurso de Pagamento.");
      return;
    } else if (totalLancado > vlrReceber) {
      showWarning(`A soma dos recursos utilizados para o recebimento é MAIOR
          que valor total dos títulos selecionados.\n
          Por Favor, verifique os valores informados.`);
      return;
    } else if (totalLancado < vlrReceber) {
      if (multiplosTitulos) {
        showWarning(`A soma dos recursos utilizados para o recebimento é MENOR
          que valor total dos títulos selecionados.\n
          Por Favor, verifique os valores informados.`);
        return;
      } else {
        pagtoParcial = true;
      }
    }

    if (valorContaBanc > 0) {
      if ([0, null, undefined].includes(idContaContaBanc)) {
        showWarning("Por favor, informe a Conta Bancária.");
        return;
      }
      if (!(dataPagContaBanc instanceof Date)) {
        showWarning("Por favor, revise a Data do Pagamento em Conta Bancária.");
        return;
      }

      recursos.push({
        conta_banc: {
          id_conta_banc: idContaContaBanc,
          data_rec: formatDateISO(dataPagContaBanc),
          valor: valorContaBanc,
        },
      });
    }
    if (valorCaixaAdm > 0) {
      if (!(dataPagCaixaAdm instanceof Date)) {
        showWarning(
          "Por favor, revise a Data do Pagamento em Caixa Administrativo."
        );
        return;
      }

      recursos.push({
        caixa_adm: {
          data_rec: formatDateISO(dataPagCaixaAdm),
          valor: valorCaixaAdm,
        },
      });
    }
    if (valorCheque > 0) {
      if ([0, null, undefined].includes(idBancoCheque)) {
        showWarning("Por favor, revise o Banco do Cheque.");
        return;
      }
      if ([0, null, undefined].includes(idClienteCheque)) {
        showWarning("Por favor, revise o Cliente do Cheque.");
        return;
      }
      if (["", null, undefined].includes(nroCheque)) {
        showWarning("Por favor, revise o Número do Cheque.");
        return;
      }
      if (!(vencimentoCheque instanceof Date)) {
        showWarning("Por favor, revise o Vencimento do Cheque.");
        return;
      }

      recursos.push({
        cheque_rec: {
          id_banco: idBancoCheque,
          id_cliente: idClienteCheque,
          nro_cheque: nroCheque,
          agencia: agenciaCheque ?? "",
          nro_conta: nroContaCheque ?? "",
          data_emi: formatDateISO(new Date()),
          data_vcto: formatDateISO(vencimentoCheque),
          observ: obsCheque ?? "",
          cmc7: cmc7Cheque ?? "",
          a_vista: chequeAVista,
          valor: valorCheque,
        },
      });
    }

    if (verificarPagtoParcial && pagtoParcial) {
      togglePagtoParcial();
      return;
    }

    let dataRec = new Date();
    if (valorCaixaAdm > 0) {
      dataRec = dataPagCaixaAdm;
    } else if (valorContaBanc > 0) {
      dataRec = dataPagContaBanc;
    }

    const payload = {
      titulos: titulosSelected.map((e) => ({
        id_dup_rec_mov: e.id,
        vlr_rec: multiplosTitulos
          ? parseFloat(e.vlr_receber) + (e.vlr_juros ?? 0) + (e.vlr_multa ?? 0)
          : totalLancado,
        vlr_juros: e.vlr_juros ?? 0,
        vlr_multa: e.vlr_multa ?? 0,
      })),
      recursos: recursos,
      vlr_total_rec: totalLancado,
      vlr_juros: vlrJuros,
      vlr_multa: vlrMulta,
      data_rec: formatDateISO(dataRec),
      observ_rec: "",
    };
    setLoading(true);
    const [ok] = await GerencContasReceberService.receber(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  const disabledContaBanc = valorContaBanc <= 0;
  const disabledCaixaAdm = valorCaixaAdm <= 0;
  const disabledCheque = valorCheque <= 0;

  return (
    <>
      <FormButton
        md="auto"
        divClassName="ml-auto"
        color="success"
        onClick={toggle}
        disabled={selecionouTituloRecebido || titulosSelected.length === 0}
        disabledHint={
          selecionouTituloRecebido
            ? "Selecione somete títulos abertos"
            : "Selecione ao menos um título da lista"
        }
        padded={false}
      >
        Receber Valores
      </FormButton>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        title="Recebimento de Títulos a Receber"
        number="0120_3"
        onClosed={limparDados}
        onConfirm={() => handleSubmit(true)}
        loadingConfirm={loading}
        onBeforeOpen={buscarParams}
      >
        <Row className="mb-2">
          <Col md={6}>
            <Row>
              <NumberInput
                md={4}
                label="Juros"
                value={percJuros}
                onChange={setPercJuros}
                isPercentage
              />
              <NumberInput md={5} label=" " value={vlrJuros} disabled />
            </Row>
          </Col>
          <Col md={6} className="pr-0">
            <Row style={{ justifyContent: "flex-end" }}>
              <NumberInput
                md={4}
                label="Multa"
                value={percMulta}
                onChange={setPercMulta}
                isPercentage
              />
              <NumberInput md={5} label=" " value={vlrMulta} disabled />
            </Row>
          </Col>
        </Row>
        <Row className="mb-2">
          <FixedField
            label="Total Lançado"
            value={formatNumber(totalLancado, true, 2)}
            labelStyle={{ fontSize: "0.8rem", fontWeight: "normal" }}
            textStyle={{ fontSize: "0.8rem" }}
            divStyle={{ paddingTop: "4.1px" }}
            horizontal
          />
          <FixedField
            label={multiplosTitulos ? "Total a Lançar" : "Restante a Receber"}
            value={formatNumber(totalLancar, true, 2)}
            labelStyle={{ fontSize: "0.8rem", fontWeight: "normal" }}
            textStyle={{ fontSize: "0.8rem" }}
            divStyle={{ paddingTop: "4.1px" }}
            horizontal
          />
          <FixedField
            label="Valor Total a Receber"
            value={formatNumber(vlrReceber, true, 2)}
            labelStyle={{ fontSize: "1rem" }}
            textStyle={{ fontSize: "1rem" }}
            horizontal
          />
        </Row>
        <Row>
          <CampoValor
            label="Conta Bancária"
            value={valorContaBanc}
            onChange={setValorContaBanc}
            preencAuto={preencAuto}
          />
          <CampoValor
            label="Caixa Administrativo"
            value={valorCaixaAdm}
            onChange={setValorCaixaAdm}
            preencAuto={preencAuto}
          />
          <CampoValor
            label="Cheque"
            value={valorCheque}
            onChange={setValorCheque}
            preencAuto={preencAuto}
          />
        </Row>
        <Divider textClassName={{ "text-muted": disabledContaBanc }}>
          Informações Conta Bancária
        </Divider>
        <Row>
          <AsyncComboBox
            md={9}
            label="Conta"
            isSearchable
            isConcatField
            defaultOptions
            concatModelName="conta_banc"
            defaultValue={idContaContaBanc}
            onChange={(s) => setIdContaContaBanc(s?.value ?? null)}
            disabled={disabledContaBanc}
            clearOnDisable
          />
          <DatePicker
            md={3}
            label="Data do Crédito na Conta"
            value={dataPagContaBanc}
            onChange={(v) =>
              setDataPagContaBanc(moment.isMoment(v) ? v.toDate() : v)
            }
            disabled={disabledContaBanc}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Histórico"
            value={historContaBanc}
            onChange={(e, v) => setHistorContaBanc(v)}
            disabled={disabledContaBanc}
            clearOnDisable
          />
        </Row>

        <Divider textClassName={{ "text-muted": disabledCaixaAdm }}>
          Informações Caixa Administrativo
        </Divider>
        <Row>
          <DatePicker
            md={3}
            label="Data"
            value={dataPagCaixaAdm}
            onChange={(v) =>
              setDataPagCaixaAdm(moment.isMoment(v) ? v.toDate() : v)
            }
            disabled={disabledCaixaAdm}
          />
          <TextInput
            md={9}
            label="Histórico"
            value={historCaixaAdm}
            onChange={(e, v) => setHistorCaixaAdm(v)}
            disabled={disabledCaixaAdm}
            clearOnDisable
          />
        </Row>
        <Divider textClassName={{ "text-muted": disabledCaixaAdm }}>
          Informações Cheque
        </Divider>
        <Row>
          <TextInput
            md={3}
            label="Número Cheque"
            value={nroCheque}
            onChange={(e, v) => setNroCheque(v)}
            maxLength={8}
            disabled={disabledCheque}
            clearOnDisable
          />
          <AsyncComboBox
            md={4}
            label="Banco"
            isSearchable
            isConcatField
            defaultOptions
            concatModelName="banco"
            defaultValue={idBancoCheque}
            onChange={(s) => setIdBancoCheque(s?.value)}
            disabled={disabledCheque}
            clearOnDisable
          />
          <TextInput
            md={3}
            label="C. C."
            value={nroContaCheque}
            onChange={(e, v) => setNroContaCheque(v)}
            maxLength={20}
            disabled={disabledCheque}
            clearOnDisable
          />
          <TextInput
            md={2}
            label="Agência"
            value={agenciaCheque}
            onChange={(e, v) => setAgenciaCheque(v)}
            maxLength={5}
            disabled={disabledCheque}
            clearOnDisable
          />
        </Row>
        <Row>
          <AsyncComboBox
            md={12}
            label="Cliente"
            isSearchable
            isConcatField
            concatModelName="cliente"
            defaultValue={idClienteCheque}
            onChange={(s) => setIdClienteCheque(s?.value)}
            disabled={disabledCheque}
            clearOnDisable
          />
        </Row>
        <Row>
          <TextInput
            md={7}
            label="CMC7"
            value={cmc7Cheque}
            onChange={(e, v) => setCmc7Cheque(v)}
            maxLength={35}
            disabled={disabledCheque}
            clearOnDisable
          />
          <FormCheckbox
            label="À Vista"
            checked={chequeAVista}
            onChange={() => setChequeAVista(!chequeAVista)}
            disabled={disabledCheque}
          />
          <DatePicker
            md={3}
            label="Vencimento (Bom Para)"
            value={vencimentoCheque}
            onChange={(v) =>
              setVencimentoCheque(moment.isMoment(v) ? v.toDate() : v)
            }
            disabled={disabledCheque}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="Obs - Nome do Terceiro"
            value={obsCheque}
            onChange={(e, v) => setObsCheque(v)}
            maxLength={150}
            disabled={disabledCheque}
            clearOnDisable
          />
        </Row>
      </ModalBase>
      <ConfirmarRectoParcialModal
        isOpen={rectoParcialOpen}
        toggle={togglePagtoParcial}
        vlrReceber={vlrReceber}
        totalLancado={totalLancado}
        onConfirm={() => handleSubmit(false)}
      />
    </>
  );
};
