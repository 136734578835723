import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, CustomInput, FormGroup, Label, Row } from "reactstrap";
import ComboBox from "../../../../../../components/ComboBox";
import TextInput from "../../../../../../components/TextInput";
import { ufs } from "../../../../../../coreUtils";
import {
  setDentroEstabelecimento,
  setDescricao,
  setFinalidadeOper,
  setModeloNfe,
  setTipoContrib,
  setUfDestino,
} from "../../store/cadRegraTributacaoSlice";

export const FINALIDADES_REGRA_TRIB = [
  { label: "Venda", value: "VENDA" },
  { label: "Remessa de Saída", value: "RMSAI" },
  { label: "Remessa de Entrada", value: "RMENT" },
  { label: "Bonificação", value: "BONIF" },
  { label: "Devolução", value: "DEVOL" },
  { label: "Ajuste/Estorno", value: "AJUST" },
  { label: "Complementar", value: "COMPL" },
  { label: "Transferência Entre Empresas", value: "TRFEM" },
  { label: "Venda para Entrega Futura", value: "VDAEF" },
];

export const CamposChaves = () => {
  const store = useSelector((state) => state.cadRegraTributacao);
  const dispatch = useDispatch();
  return (
    <>
      <Row className="mb-3">
        <TextInput
          label="Descrição"
          md={12}
          value={store.descricao}
          onChange={(e, v) => dispatch(setDescricao(v))}
          maxLength={80}
          autoFocus
        />
      </Row>
      <Row className="mb-3">
        <Col md="5">
          <FormGroup
            onChange={({ target }) =>
              dispatch(setDentroEstabelecimento(target.value))
            }
          >
            <Label>Local da Operação</Label>
            <div>
              <CustomInput
                type="radio"
                name="local_oper"
                id="dentro_estab_local_oper"
                label="No Estabelecimento"
                value="DENT"
                inline
                checked={store.dentroEstabelecimento === "DENT"}
              />
              <CustomInput
                type="radio"
                name="local_oper"
                id="fora_estab_local_oper"
                label="Fora do Estabelecimento"
                value="FORA"
                inline
                checked={store.dentroEstabelecimento === "FORA"}
              />
            </div>
          </FormGroup>
        </Col>
        <ComboBox
          md={3}
          hint={
            "Notas fiscais emitidas pelo sistema retarguarda, devem ter a " +
            "finalidade Venda ou Devolução, para NF-es de finalidade Normal e " +
            "Devolução, respectivamente"
          }
          label="Finalidade"
          options={FINALIDADES_REGRA_TRIB}
          defaultOptions
          onChange={(selected) => dispatch(setFinalidadeOper(selected?.value))}
          defaultValue={store.finalidadeOper}
        />
        <TextInput label="UF Origem" md={2} value={store.ufOrigem} disabled />
        <ComboBox
          options={ufs}
          md={2}
          label="UF Destino"
          defaultOptions
          onChange={(selected) => dispatch(setUfDestino(selected?.value))}
          defaultValue={store.ufDestino}
        />
      </Row>
      <Row className="mb-3">
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => {
              dispatch(setTipoContrib(target.value));
            }}
          >
            <Label>Tipo de Contribuinte</Label>
            <div>
              <CustomInput
                type="radio"
                name="tipo_contrib"
                id="contribuinte_tipo_contrib"
                label="Contribuinte"
                value="CONTRIB"
                inline
                checked={store.tipoContrib === "CONTRIB"}
              />
              <CustomInput
                type="radio"
                name="tipo_contrib"
                id="nao_contribuinte_tipo_contrib"
                label="Não Contribuinte"
                value="NAO_CONTRI"
                inline
                checked={store.tipoContrib === "NAO_CONTRI"}
              />
              <CustomInput
                type="radio"
                name="tipo_contrib"
                id="consumidor_final_tipo_contrib"
                label="Consumidor Final"
                value="CONS_FINAL"
                inline
                checked={store.tipoContrib === "CONS_FINAL"}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md="auto">
          <FormGroup
            onChange={({ target }) => {
              dispatch(setModeloNfe(target.value));
            }}
          >
            <Label>Modelo da NF</Label>
            <div>
              <CustomInput
                type="radio"
                name="modelo_nf"
                id="nfe_modelo_nf"
                label="NFe (55)"
                value="55"
                inline
                checked={store.modeloNfe === "55"}
              />
              <CustomInput
                type="radio"
                name="modelo_nf"
                id="nfce_modelo_nf"
                label="NFe Consumidor (65)"
                value="65"
                inline
                checked={store.modeloNfe === "65"}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
