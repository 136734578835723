import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { setAliqAdRem } from "../../store/cadCfopSlice";
import { cadastroProdutoRoute } from "../../../../../../routes/modules/cadastro";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Divider, NumberInput } from "../../../../../../components";

export const TabIcmsMonoCfop = () => {
  const store = useSelector((state) => state.cadCfop);
  const dispatch = useDispatch();

  return (
    <>
      <Divider className="mt-4">ICMS Monofásico</Divider>
      <Row className="mb-2">
        <NumberInput
          label="Alíquota Ad Rem"
          md={3}
          onChange={(v) => dispatch(setAliqAdRem(v))}
          value={store.aliqAdRem}
          isPercentage
          clearOnDisable
          decimalPlaces={4}
          divClassName="pr-2"
          disabled={store.sitTribIcms !== "61"}
        />
      </Row>
      <span>
        Para cálculo do ICMS Monofásico é necessário informar a quantidade
        conforme unidade de medida estabelecidada na legislação no{" "}
        <Link
          style={{ color: "#5b7dff" }}
          target="_blank"
          to={cadastroProdutoRoute.path}
        >
          Cadastro de Produto
        </Link>
      </span>
    </>
  );
};
