import React from "react";
import { ModalBase, TextInput } from "../../../../../components";
import { Row } from "reactstrap";

export const ModalMaisFiltros = ({
  isOpen,
  toggle,
  cfops,
  setCfops,
  ncms,
  setNcms,
}) => {
  return (
    <>
      <ModalBase
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        title={`Outros Filtros`}
        number="5043_2"
        onConfirm={toggle}
      >
        <Row>
          <TextInput
            md={12}
            label="CFOPs (separadas por vírgula)"
            value={cfops}
            onChange={(_, v) => setCfops(v)}
          />
        </Row>
        <Row>
          <TextInput
            md={12}
            label="NCMs (separados por vírgula)"
            value={ncms}
            onChange={(_, v) => setNcms(v)}
          />
        </Row>
      </ModalBase>
    </>
  );
};
