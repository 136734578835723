import React, { useEffect, useRef, useState } from "react";
import {
  ModalBase,
  ComboBox,
  MaskedInput,
  TextInput,
  NumberInput,
} from "../../../../components";
import { apiGet, apiPost, apiDelete } from "../../../../api";
import { ufs, meses, validarCPFCNPJ, pessoa } from "../../../../coreUtils";
import { Button, Col, Row } from "reactstrap";
import DocsProdutorRuralGrid from "./components/DocsProdutorRuralGrid";
import { toastr } from "react-redux-toastr";

const modelosDoc = [
  { label: "NF de Produtor", value: "04" },
  { label: "NF (v2.0)", value: "01" },
];

export const ProdutorRuralNFModal = ({ isOpen, toggle, selected }) => {
  const [docsVinculados, setDocsVinculados] = useState([]);
  const [docSelecionado, setDocSelecionado] = useState();

  const [ufEmitente, setUfEmitente] = useState(null);
  const [mesDocumento, setMesDocumento] = useState(
    meses[new Date().getMonth()].value
  );
  const [anoDocumento, setAnoDocumento] = useState(new Date().getFullYear());
  const [pessoaProdutor, setPessoaProdutor] = useState("F");
  const [cnpjProdutor, setCnpjProdutor] = useState("");
  const [cpfProdutor, setCpfProdutor] = useState("");
  const [inscEstProdutor, setInscEstProdutor] = useState("");
  const [modeloDoc, setModeloDoc] = useState(modelosDoc[0].value);
  const [serieDoc, setSerieDoc] = useState(null);
  const [numeroDoc, setNumeroDoc] = useState(null);
  const serieRef = useRef();
  const numeroRef = useRef();

  const limparDados = () => {
    setSerieDoc(0);
    setNumeroDoc(0);
    serieRef.current.clear();
    numeroRef.current.clear();
    setUfEmitente(null);
    setMesDocumento(meses[new Date().getMonth()].value);
    setAnoDocumento(new Date().getFullYear());
    setPessoaProdutor("F");
    setCnpjProdutor("");
    setCpfProdutor("");
    setInscEstProdutor("");
    setModeloDoc(modelosDoc[0].value);
  };

  const incluirNFVinculada = async () => {
    if (anoDocumento < 2022 || anoDocumento > new Date().getFullYear()) {
      toastr.warning("Ano inválido", "Por favor, revise o ano do documento");
      return;
    }

    if (cnpjProdutor && !validarCPFCNPJ(cnpjProdutor)) {
      toastr.warning("CNPJ inválido", "Por favor, revise o CNPJ do produtor");
      return;
    }

    if (cpfProdutor && !validarCPFCNPJ(cpfProdutor)) {
      toastr.warning("CPF inválido", "Por favor, revise o CPF do produtor");
      return;
    }

    const anoMesDoc = `${anoDocumento.toFixed(0).slice(2, 4)}${mesDocumento
      .toFixed(0)
      .padStart(2, "0")}`;

    const payload = {
      id_cab: selected,
      uf_emit: ufEmitente,
      ano_mes_ref_nf: anoMesDoc,
      cnpj_prod_rural: cnpjProdutor,
      cpf_prod_rural: cpfProdutor,
      ie_prod_rural: inscEstProdutor,
      modelo_doc_fiscal: modeloDoc,
      serie_doc_fiscal: serieDoc?.toFixed(0),
      nro_doc_fiscal: numeroDoc?.toFixed(0),
    };

    const ret = await apiPost("/nfe/web/incluir_vinc_doc_prod_rural/", payload);
    if (ret) {
      limparDados();
      carregarDados();
    }
  };
  const removerNFVinculada = async () => {
    const ret = await apiDelete(
      `/nfe/web/excluir_vinc_doc_prod_rural/${docSelecionado}/`
    );
    if (ret) {
      carregarDados();
    }
  };

  const carregarDados = async (id) => {
    const url = `/nfe/web/buscar_vinc_doc_prod_rural/${selected}/`;
    const ret = await apiGet(url, []);
    setDocsVinculados(ret);
  };

  useEffect(() => {
    setCpfProdutor("");
    setCnpjProdutor("");
  }, [pessoaProdutor]);

  return (
    <ModalBase
      title="Informações de Produtor Rural da NF-e"
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      number="0055_3"
      onClosed={limparDados}
      onBeforeOpen={carregarDados}
    >
      <Row>
        <ComboBox
          options={ufs}
          md={2}
          label="UF Emitente"
          defaultOptions
          onChange={(selected) => setUfEmitente(selected?.value)}
          defaultValue={ufEmitente}
        />
        <ComboBox
          md={2}
          options={meses}
          label="Mês do Documento"
          defaultOptions
          onChange={(selected) => setMesDocumento(selected?.value)}
          defaultValue={mesDocumento}
          isSearchable={false}
        />
        <NumberInput
          decimalPlaces={0}
          md={2}
          label="Ano do Documento"
          value={anoDocumento}
          onChange={setAnoDocumento}
        />
        <ComboBox
          md={2}
          label="Pessoa"
          options={pessoa}
          defaultOptions
          defaultValue={pessoaProdutor}
          onChange={(selected) => setPessoaProdutor(selected?.value)}
          isSearchable={false}
        />
        {pessoaProdutor === "J" && (
          <MaskedInput
            md={3}
            mask="99.999.999/9999-99"
            label="CNPJ do Produtor"
            maskChar={null}
            onChange={(_, value) => setCnpjProdutor(value)}
            value={cnpjProdutor}
          />
        )}
        {pessoaProdutor === "F" && (
          <MaskedInput
            md={2}
            mask="999.999.999-99"
            label="CPF do Produtor"
            maskChar={null}
            onChange={(_, value) => setCpfProdutor(value)}
            value={cpfProdutor}
          />
        )}
      </Row>
      <Row>
        <TextInput
          label="IE do Produtor"
          md={2}
          value={inscEstProdutor}
          onChange={(_, value) => setInscEstProdutor(value)}
        />
        <ComboBox
          options={modelosDoc}
          defaultOptions
          label="Modelo do Documento"
          md={3}
          defaultValue={modeloDoc}
          onChange={(selected) => setModeloDoc(selected?.value)}
          isSearchable={false}
        />
        <NumberInput
          decimalPlaces={0}
          md={3}
          mdInput={8}
          label="Número do Documento"
          value={numeroDoc}
          onChange={setNumeroDoc}
          ref={numeroRef}
        />
        <NumberInput
          decimalPlaces={0}
          md={2}
          mdInput={7}
          label="Série do Documento"
          value={serieDoc}
          onChange={setSerieDoc}
          ref={serieRef}
        />
      </Row>
      <Row style={{ marginBottom: "10px" }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "8px",
          }}
        >
          <Button className="mr-2" color="success" onClick={incluirNFVinculada}>
            Incluir
          </Button>
          <Button
            color="danger"
            onClick={removerNFVinculada}
            disabled={!docSelecionado}
          >
            Excluir
          </Button>
        </Col>
      </Row>
      <DocsProdutorRuralGrid
        docsVinculados={docsVinculados}
        onSelect={setDocSelecionado}
      />
    </ModalBase>
  );
};
