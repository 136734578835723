import React, { useState } from "react";
import { ModalBase, TextInput } from "../../../../../components";
import { showWarning } from "../../../../../components/AlertaModal";
import GerencChequesClientesService from "../../../../../services/financeiro/GerencChequesClientesService";
import { Row } from "reactstrap";
import { DetalhesChequeClienteForm } from "./DetalhesChequeClienteModal";

export const ExcluirChequeClienteModal = ({
  isOpen,
  toggle,
  selected,
  notifyEvent,
}) => {
  const [dadosCheque, setDadosCheque] = useState({});
  const [motivo, setMotivo] = useState("");
  const [loading, setLoading] = useState(false);

  const limparDados = () => {
    setDadosCheque({});
    setMotivo("");
  };

  const carregarDados = async () => {
    const [ok, ret] = await GerencChequesClientesService.buscar(selected);
    if (!ok) {
      toggle();
      return;
    }
    setDadosCheque(ret);
  };

  const handleSubmit = async () => {
    if (["", null, undefined].includes(motivo)) {
      showWarning("Por favor, informe o Motivo da Exclusão do Cheque");
      return;
    }

    const payload = {
      id_cheque_rec: selected,
      motivo: motivo,
    };

    setLoading(true);
    const [ok] = await GerencChequesClientesService.excluir(payload);
    if (ok) {
      notifyEvent();
      toggle();
    }
    setLoading(false);
  };

  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      title="Excluir Cheque de Cliente"
      number="0131_2"
      onBeforeOpen={carregarDados}
      onClosed={limparDados}
      onConfirm={handleSubmit}
      loadingConfirm={loading}
    >
      <DetalhesChequeClienteForm
        dadosCheque={dadosCheque}
        mostrarCmc7={false}
      />
      <hr />
      <Row>
        <TextInput
          md={12}
          label="Motivo"
          value={motivo}
          onChange={(e, v) => setMotivo(v)}
        />
      </Row>
    </ModalBase>
  );
};
