import React from "react";
import {
  MenuGroup,
  MenuGroupButton,
  PageContainer,
  Divider,
} from "../../components";
import { BsReceiptCutoff } from "react-icons/bs";
import { BiReceipt } from "react-icons/bi";
import { MdHomeRepairService } from "react-icons/md";
import {
  notaFiscalConsumidorRoute,
  notaFiscalEletronicaRoute,
  notaFiscalServicoRoute,
} from "../../routes/modules/docsEletron";
import { TbTruckDelivery } from "react-icons/tb";
import { manifestoEletronicoMDFeRoute } from "../../routes/modules/docsEletron";

export const DocumentosEletronicos = () => {
  return (
    <PageContainer title="Documentos Eletrônicos">
      <Divider className="pl-3 pr-5">Notas Fiscais</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Nota Fiscal Eletrônica NF-e"
          icon={BiReceipt}
          pathname={notaFiscalEletronicaRoute.path}
          nroTela={notaFiscalEletronicaRoute.nroTela}
        />
        <MenuGroupButton
          label="Nota Fiscal Consumidor NFC-e"
          icon={BsReceiptCutoff}
          pathname={notaFiscalConsumidorRoute.path}
          nroTela={notaFiscalConsumidorRoute.nroTela}
        />
        <MenuGroupButton
          label="Nota Fiscal de Serviço NFS-e"
          icon={MdHomeRepairService}
          pathname={notaFiscalServicoRoute.path}
          nroTela={notaFiscalServicoRoute.nroTela}
        />
      </MenuGroup>
      <Divider className="pl-3 pr-5">Transporte</Divider>
      <MenuGroup>
        <MenuGroupButton
          label="Manifesto Eletrônico"
          icon={TbTruckDelivery}
          pathname={manifestoEletronicoMDFeRoute.path}
          nroTela={manifestoEletronicoMDFeRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
