import React, { useMemo } from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { RiFolderReceivedLine } from "react-icons/ri";
import { BiBarcodeReader } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaSackDollar } from "react-icons/fa6";
import { Send } from "react-feather";
import {
  gerenciamentoCobrancaBancRoute,
  gerenciamentoCobrancaSkillRoute,
  impressaoBoletosRoute,
  remessaBancariaRoute,
  retornoCobrancaBancariaRoute,
} from "../../../routes/modules/financeiro";

export const CobrancaBanc = () => {
  const licenca = useMemo(() => parseInt(localStorage.getItem("licenca")), []);

  return (
    <PageContainer title="Cobrança Bancária">
      <MenuGroup>
        <MenuGroupButton
          label="Gerenciamento Cobrança Bancária"
          icon={FaFileInvoiceDollar}
          pathname={gerenciamentoCobrancaBancRoute.path}
          nroTela={gerenciamentoCobrancaBancRoute.nroTela}
        />
        <MenuGroupButton
          label="Impressão de Boletos"
          icon={BiBarcodeReader}
          pathname={impressaoBoletosRoute.path}
          nroTela={impressaoBoletosRoute.nroTela}
        />
        <MenuGroupButton
          label="Remessa Bancária CNAB"
          icon={Send}
          pathname={remessaBancariaRoute.path}
          nroTela={remessaBancariaRoute.nroTela}
        />
        <MenuGroupButton
          label="Retorno Bancário CNAB"
          icon={RiFolderReceivedLine}
          pathname={retornoCobrancaBancariaRoute.path}
          nroTela={retornoCobrancaBancariaRoute.nroTela}
        />
        {licenca === 0 && (
          <MenuGroupButton
            label="Gerenciamento Cobrança Skill"
            icon={FaSackDollar}
            pathname={gerenciamentoCobrancaSkillRoute.path}
            nroTela={gerenciamentoCobrancaSkillRoute.nroTela}
          />
        )}
      </MenuGroup>
    </PageContainer>
  );
};
