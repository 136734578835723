import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  MODAL_ACTIONS,
  formatarCpfCnpj,
  validarCPFCNPJ,
} from "../../../../../../coreUtils";
import { TextInput } from "../../../../../../components";
import { toastr } from "react-redux-toastr";
import { modalTitleTomadorMDFe, routesBaseTomadorMDFe } from "../Tomador";
import { ModalCadastroV2 } from "../../../../../../components/cadastro";

export const CadastroTomadorModal = ({
  isOpen,
  toggle,
  action,
  selected,
  notifyEvent,
  concatShortcut,
}) => {
  const [nome, setNome] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [emailConemb, setEmailConemb] = useState("");
  const [ativo, setAtivo] = useState(true);

  const fetchData = (data) => {
    setNome(data.nome);
    setCpfCnpj(data.cpf_cnpj);
    setEmailConemb(data.email_conemb);
    setAtivo(data.ativo);
  };

  const limparDados = () => {
    setNome("");
    setCpfCnpj("");
    setEmailConemb("");
    setAtivo(true);
  };

  const submitPayload = (action) => {
    if (!validarCPFCNPJ(cpfCnpj)) {
      toastr.warning("Atenção", "O CPF/CNPJ informado é inválido");
      return false;
    }

    let data = {
      nome: nome,
      cpf_cnpj: cpfCnpj,
      email_conemb: emailConemb,
      ativo: ativo,
    };

    return action === MODAL_ACTIONS.EDIT ? { ...data, id: selected } : data;
  };

  return (
    <ModalCadastroV2
      isOpen={isOpen}
      toggle={toggle}
      action={action}
      title={modalTitleTomadorMDFe}
      size="md"
      fetchData={fetchData}
      onClose={limparDados}
      headerCheck={{
        toggle: () => setAtivo(!ativo),
        value: ativo,
      }}
      routesBase={routesBaseTomadorMDFe}
      submitPayload={submitPayload}
      selected={selected}
      notifyEvent={notifyEvent}
      number="0042_81"
      concatShortcut={concatShortcut}
      concatModelName="tomador_mdfe"
    >
      <Row>
        <TextInput
          label="Nome"
          md={8}
          onChange={(e, v) => setNome(v)}
          value={nome}
          maxLength={60}
          autoFocus
        />
        <TextInput
          label="CPF/CNPJ"
          md={4}
          value={cpfCnpj}
          onChange={(e, v) => setCpfCnpj(v)}
          onBlur={(e, v) => setCpfCnpj(formatarCpfCnpj(v))}
          maxLength={18}
        />
      </Row>
      <Row>
        <TextInput
          label="E-Mail CONEMB"
          md={8}
          value={emailConemb}
          onChange={(e, v) => setEmailConemb(v)}
          maxLength={60}
          forceUppercase={false}
        />
      </Row>
    </ModalCadastroV2>
  );
};
