import React, { useImperativeHandle, forwardRef } from "react";
import {
  FixedField,
  FormButton,
  FormCheckbox,
  LabelButton,
  Loader,
  NumberInput,
  PageContainer,
  TextInput,
} from "../../../../components";
import { Card, Row } from "reactstrap";
import { useState } from "react";
import UteisService from "../../../../services/uteis/UteisService";
import {
  formatNumber,
  formatarCpfCnpj,
  roundFloat,
  validarCPFCNPJ,
} from "../../../../coreUtils";
import { FrenteVendaGrid } from "./components/FrenteVendaGrid";
import { ConfirmarVendaModal } from "./components/ConfirmarVendaModal";
import { OrcamentosModal } from "./components/OrcamentosModal";
import { Provider, useDispatch, useSelector } from "react-redux";
import { frenteVendaRoute } from "../../../../routes/modules/vendas";
import { storeCab } from "./store";
import {
  initialState,
  setClienteCuringa,
  setCpfCnpjCliente,
  setIdCliente,
  setIdVenda,
  setNomeCliente,
  setPontosPremiacao,
  setVendaBonificada,
  setVlrFrete,
  setVlrTotComisVenda,
  setVlrTotDescVenda,
  setVlrTotProdVenda,
  setVlrTotServVenda,
  setVlrTotVenda,
  setWhatsappCliente,
  setup,
} from "./store/vendaCabSlice";
import FrenteVendaService from "../../../../services/vendas/FrenteVendaService";
import { IncluirServicoModal } from "./components/IncluirServicoModal";
import { IncluirProdutoForm } from "./components/IncluirProdutoForm";
import { apiGetV2 } from "../../../../apiV2";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { showWarning } from "../../../../components/AlertaModal";
import {
  setAvisaIdentifPremiado,
  setIdContaBancFatBol,
  setIdentificaFormaRec,
  setIdentificaPremiado,
  setIdentificaVendedorFinal,
  setInformarPercComisFrenteVenda,
  setOperacaoPadrao,
  setPermiteFaturamentoFrenteVenda,
  setRegimeTribut,
  setTemListaPreco,
  setUtilizaAmbiente,
  setUtilizaCorProd,
  setUtilizaRegraPremiacao,
  setUtilizaRegraTributacao,
  setUtilizaTamanhoProd,
} from "./store/paramsFrenteVendaSlice";
import { useRef } from "react";
import { DescontoGeralModal } from "./components/DescontoGeralModal";
import { Prompt } from "react-router-dom/cjs/react-router-dom";
import { VendasSalvasModal } from "./components/VendasSalvasModal";
import PesqCliente from "../../../../components/form/pesq_cliente/PesqCliente";
import { ObservItemModal } from "./components/ObservItemModal";
import { ConfirmarAlterarClienteModal } from "./components/ConfirmarAlterarClienteModal";
import { TrocarOperadorModal } from "./components/TrocarOperadorModal";

const FrenteVendaContainer = forwardRef((_, ref) => {
  // Parâmetros
  const params = useSelector((state) => state.paramsFrenteVenda);
  // Dados da Venda
  const simplesNacional = params.regime_tribut === "Simples Nacional";
  const stateCab = useSelector((state) => state.vendaCab);
  const dispatch = useDispatch();
  const [itens, setItens] = useState([]);
  // Controle
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [loadingSalvarInterno, setLoadingSalvarInterno] = useState(false);
  const [erroAoIniciar, setErroAoIniciar] = useState(false);
  const [atualizarValoresItens, setAtualizarValoresItens] = useState(false);
  const [confirmarOpen, setConfirmarOpen] = useState(false);
  const [orcamentosOpen, setOrcamentosOpen] = useState(false);
  const [vendasSalvasOpen, setVendasSalvasOpen] = useState(false);
  const [trocarOperadorOpen, setTrocarOperadorOpen] = useState(false);
  const [observItemOpen, setObservItemOpen] = useState(false);
  const [selectedTipo, setSelectedTipo] = useState(null);
  const [selectedIdItem, setSelectedIdItem] = useState(null);
  const [selectedObserv, setSelectedObserv] = useState("");
  const [confirmaAlteraClienteOpen, setConfirmaAlteraClienteOpen] =
    useState(false);
  const [dadosClienteAlterar, setDadosClienteAlterar] = useState({});
  const pesqClienteRef = useRef();
  const incluirProdutoRef = useRef();
  const semCliente = [0, null, undefined].includes(stateCab.id_cliente);
  const semItens = (itens ?? []).length === 0;

  const limparDados = () => {
    dispatch(setup(initialState));
    setItens([]);
    if (pesqClienteRef.current) {
      pesqClienteRef.current.focus();
    }
  };

  const carregarParametros = async () => {
    const [ok, ret] = await apiGetV2("/tela/frente_venda/");
    if (ok) {
      dispatch(setOperacaoPadrao(ret.operacao_padrao));
      dispatch(setRegimeTribut(ret.regime_tribut));
      dispatch(setUtilizaAmbiente(ret.utiliza_ambiente));
      dispatch(setUtilizaRegraPremiacao(ret.utiliza_regra_premiacao));
      dispatch(setAvisaIdentifPremiado(ret.avisa_identif_premiado));
      dispatch(setTemListaPreco(ret.tem_lista_preco));
      dispatch(setUtilizaRegraTributacao(ret.utiliza_regra_tributacao));
      dispatch(setUtilizaCorProd(ret.utiliza_cor_prod));
      dispatch(setUtilizaTamanhoProd(ret.utiliza_tamanho_prod));
      dispatch(
        setPermiteFaturamentoFrenteVenda(ret.permite_faturamento_frente_venda)
      );
      dispatch(setIdContaBancFatBol(ret.id_conta_banc_fat_bol));
      dispatch(setIdentificaVendedorFinal(ret.identifica_vendedor_final));
      dispatch(setIdentificaPremiado(ret.identifica_premiado));
      dispatch(
        setInformarPercComisFrenteVenda(ret.informar_perc_comis_frente_venda)
      );
      dispatch(setIdentificaFormaRec(ret.identifica_forma_rec));
    } else {
      setErroAoIniciar(true);
      history.goBack();
    }
    setLoading(false);
  };

  const buscarItens = async (idVenda) => {
    const [ok, ret] = await FrenteVendaService.buscarItens(
      idVenda ?? stateCab.id_venda
    );
    if (ok) {
      setItens(ret.itens);
      const totais = ret.totais;
      dispatch(setVlrTotProdVenda(parseFloat(totais.vlr_tot_prod)));
      dispatch(setVlrTotServVenda(parseFloat(totais.vlr_tot_serv)));
      dispatch(setVlrTotDescVenda(parseFloat(totais.vlr_desconto)));
      dispatch(setVlrTotComisVenda(parseFloat(totais.vlr_tot_comis)));
      dispatch(setVlrTotVenda(parseFloat(totais.vlr_total)));
      dispatch(setPontosPremiacao(parseFloat(totais.pontos_premiacao)));
    } else {
      setItens([]);
      dispatch(setVlrTotProdVenda(0));
      dispatch(setVlrTotServVenda(0));
      dispatch(setVlrTotDescVenda(0));
      dispatch(setVlrTotComisVenda(0));
      dispatch(setVlrTotVenda(0));
      dispatch(setPontosPremiacao(0));
    }
    return ok;
  };

  const importarVendaSalva = async (idVenda) => {
    const [ok, ret] = await FrenteVendaService.vendasSalvas.buscar(idVenda);

    if (ok) {
      dispatch(setIdVenda(idVenda));
      dispatch(
        setup({
          id_cliente: ret.id_cliente,
          nome_cliente: ret.nome_cli,
          cpf_cnpj_cliente: ret.cpf_cnpj_cli,
          cliente_curinga: ret.cliente_curinga,
          vlr_frete: parseFloat(ret.vlr_frete),
          id_premiado: ret.id_premiado,
          forma_pagto: (ret.forma_pagto ?? "")?.trim() || null,
          id_cond_pag: ret.id_cond_pag || null,
        })
      );
      await buscarItens(idVenda);
      pesqClienteRef.current.setValueByID(ret.id_cliente);
      return true;
    } else {
      return false;
    }
  };

  const validarDadosCab = () => {
    if (
      (stateCab.cpf_cnpj_cliente ?? "").trim() !== "" &&
      !validarCPFCNPJ(stateCab.cpf_cnpj_cliente)
    ) {
      showWarning("Por favor, revise o CPF/CNPJ do Cliente.");
      return false;
    }
    return true;
  };

  const verificarIncluirCab = async () => {
    if (!validarDadosCab()) return null;
    if ([0, null, undefined].includes(stateCab.id_venda)) {
      const payload = {
        id_cliente: stateCab.id_cliente,
        nome_cliente: stateCab.nome_cliente ?? "", // DEVERIA DEIXAR NULL
        cpf_cnpj_cliente: stateCab.cpf_cnpj_cliente,
        vlr_frete: stateCab.vlr_frete,
        id_orcamento: stateCab.id_orcamento,
        id_premiado: stateCab.id_premiado,
        vda_bonificada: stateCab.venda_bonificada,
        forma_pag: null,
        id_cond_pag: null,
      };
      const [ok, ret] = await FrenteVendaService.incluirCab(payload);
      if (ok) {
        dispatch(setIdVenda(ret.id_frente_venda_salva_cab));
      }
      return ok ? ret.id_frente_venda_salva_cab : null;
    } else {
      return stateCab.id_venda;
    }
  };

  const handleSetIdCliente = (v, nome, curinga, cpfCnpj, whatsapp) => {
    if (v !== stateCab.id_cliente || !curinga) {
      if (
        (params.tem_lista_preco === true ||
          params.utiliza_regra_tributacao === true) &&
        ![0, null, undefined].includes(stateCab.id_cliente) &&
        ![0, null, undefined].includes(stateCab.id_venda) &&
        !simplesNacional &&
        v !== stateCab.id_cliente
      ) {
        // Caso já tenha um cliente selecionado na venda e a empresa utiliza
        // lista de preço, ele deve ser avisado que os valores do itens serão
        // recalculados
        setConfirmaAlteraClienteOpen(true);
        setDadosClienteAlterar({
          id: v ?? null,
          nome: nome ?? null,
          curinga: curinga ?? null,
          cpfCnpj: cpfCnpj ?? null,
          whatsapp_cliente: whatsapp ?? null,
        });
      } else {
        dispatch(setIdCliente(v ?? null));
        dispatch(setNomeCliente(nome ?? null));
        dispatch(setClienteCuringa(curinga ?? null));
        dispatch(setCpfCnpjCliente(cpfCnpj ?? null));
        dispatch(setWhatsappCliente(whatsapp ?? null));
      }
    }
  };

  const cancelarAlteracaoCliente = () => {
    pesqClienteRef.current.setId(stateCab.id_cliente);
  };

  const recalcVlrsItensAlteraCli = async (idClienteNovo) => {
    // Limpa o form de inclusão de produtos para evitar valores incorretos de
    // serem utilizados
    incluirProdutoRef.current.limpar();

    const payload = {
      id_cab: stateCab.id_venda,
      id_cliente: idClienteNovo,
      nome_cliente: dadosClienteAlterar.nome ?? "",
      cpf_cnpj_cliente: dadosClienteAlterar.cpfCnpj,
      vlr_frete: stateCab.vlr_frete,
      id_orcamento: stateCab.id_orcamento,
      vda_bonificada: stateCab.venda_bonificada,
    };
    const [ok] = await FrenteVendaService.salvarCab(payload, false);
    if (!ok) return;

    const itensAtualizados = [];
    for (const row of itens) {
      if (row.tipo === "P") {
        const [ok, ret] = await FrenteVendaService.buscarPrecoListaPreco(
          row.id_prod_serv,
          idClienteNovo
        );
        if (ok && ret.preco_venda) {
          row.vlr_unit = parseFloat(ret.preco_venda);
          const rowAtualizada = await recalcularValoresGrade(
            "vlr_item",
            ret.preco_venda,
            row
          );
          itensAtualizados.push(rowAtualizada);
          continue;
        }
      }

      // Sempre recalcula o valor do item. Nas empresas que utilizam regra de
      // tributação. Isso é muito importante
      if (params.utiliza_regra_tributacao === true) {
        const rowAtualizada = await recalcularValoresGrade(
          "vlr_item",
          row.vlr_item,
          row
        );
        itensAtualizados.push(rowAtualizada);
      } else {
        itensAtualizados.push({ ...row });
      }
    }

    for (const row of itensAtualizados) {
      await atualizarItem(row);
    }

    buscarItens();
  };

  const onBlurCpfCnpjCliente = (v) => {
    dispatch(setCpfCnpjCliente(formatarCpfCnpj(v)));
  };

  const incluirItem = async (payload) => {
    const idVenda = await verificarIncluirCab();
    if (idVenda) {
      const postPayload = {
        id_cab: idVenda,
        ...payload,
      };

      const [ok] = await FrenteVendaService.incluirItem(postPayload);
      if (ok) {
        buscarItens(idVenda);
      }
      return ok;
    } else {
      return false;
    }
  };

  const excluirItem = async (tipo, idItem) => {
    const [ok] = await FrenteVendaService.excluirItem(tipo, idItem);
    if (ok) {
      buscarItens();
    }
  };

  const recalcularValoresGrade = async (coluna, novoValor, row) => {
    // Se o valor não for alterado, não recalcula nada
    const alterouValorNumerico = !["descricao"].includes(coluna);
    let novoValorTest;
    let valorAntesTest;
    if (alterouValorNumerico) {
      const casasDecTest = coluna === "vlr_item" ? 4 : 2;

      novoValorTest = roundFloat(parseFloat(novoValor), casasDecTest);
      valorAntesTest = roundFloat(parseFloat(row[coluna]), casasDecTest);
    } else {
      novoValorTest = novoValor;
      valorAntesTest = row[coluna];
    }

    if (novoValorTest === valorAntesTest) {
      return row;
    }

    if (coluna === "descricao") {
      row.descricao = novoValor;
    } else if (coluna === "observ") {
      row.observ = novoValor;
    } else if (coluna === "quantidade") {
      const quantidade = parseFloat(novoValor);
      const vlrItem = parseFloat(row.vlr_item);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);

      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = quantidade * (vlrUnit - vlrItem);
        novoPercDesc =
          vlrTotBruto > 0
            ? roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2)
            : 0;
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      const vlrTotProd = roundFloat(vlrItem * quantidade, 2);
      row.quantidade = quantidade;
      row.perc_desc = novoPercDesc;
      row.vlr_desc = novoVlrDesc;
      row.vlr_acre = novoVlrAcre;
      row.vlr_tot_prod_serv = vlrTotProd;
    } else if (coluna === "vlr_item") {
      const quantidade = parseFloat(row.quantidade);
      const vlrItem = parseFloat(novoValor);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);

      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = roundFloat(quantidade * (vlrUnit - vlrItem), 2);
        novoPercDesc =
          vlrTotBruto > 0
            ? roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2)
            : 0;
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = quantidade * (vlrItem - vlrUnit);
        } else {
          novoVlrAcre = 0;
        }
      }
      const vlrTotProd = roundFloat(vlrItem * quantidade, 2);
      row.perc_desc = novoPercDesc;
      row.vlr_desc = novoVlrDesc;
      row.vlr_acre = novoVlrAcre;
      row.vlr_item = vlrItem;
      row.vlr_tot_prod_serv = vlrTotProd;
    } else if (coluna === "perc_desc") {
      const percDesc = parseFloat(novoValor);
      const quantidade = parseFloat(row.quantidade);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);

      const vlrItem = roundFloat(((100 - percDesc) / 100) * vlrUnit, 4);
      const vlrTotProd = roundFloat(vlrItem * quantidade, 2);
      const vlrDesc = roundFloat((percDesc / 100) * vlrTotBruto, 2);
      row.perc_desc = percDesc;
      row.vlr_desc = vlrDesc;
      row.vlr_acre = 0;
      row.vlr_item = vlrItem;
      row.vlr_tot_prod_serv = vlrTotProd;
    } else if (coluna === "vlr_tot_prod_serv") {
      const vlrTotProd = parseFloat(novoValor);
      const quantidade = parseFloat(row.quantidade);
      const vlrUnit = parseFloat(row.vlr_unit);
      const vlrTotBruto = roundFloat(vlrUnit * quantidade, 2);

      const vlrItem =
        quantidade > 0 ? roundFloat(vlrTotProd / quantidade, 4) : 0;
      let novoVlrDesc;
      let novoPercDesc;
      let novoVlrAcre;
      if (vlrUnit > vlrItem) {
        novoVlrDesc = roundFloat(quantidade * (vlrUnit - vlrItem), 2);
        novoPercDesc =
          vlrTotBruto > 0
            ? roundFloat((novoVlrDesc / vlrTotBruto) * 100, 2)
            : 0;
        novoVlrAcre = 0;
      } else {
        novoVlrDesc = 0;
        novoPercDesc = 0;
        if (vlrItem > vlrUnit) {
          novoVlrAcre = roundFloat(quantidade * (vlrItem - vlrUnit), 2);
        } else {
          novoVlrAcre = 0;
        }
      }
      row.perc_desc = novoPercDesc;
      row.vlr_desc = novoVlrDesc;
      row.vlr_acre = novoVlrAcre;
      row.vlr_item = vlrItem;
      row.vlr_tot_prod_serv = vlrTotProd;
    } else if (coluna === "perc_comis") {
      row.perc_comis = parseFloat(novoValor);
    }

    if (row.tipo === "P") {
      row.vlr_tot_comis = roundFloat(
        row.vlr_tot_prod_serv *
          ((parseFloat(row.perc_comis ?? "0") ?? 0) / 100),
        2
      );
    }

    if (alterouValorNumerico) {
      if (row.tipo === "P" && !simplesNacional) {
        const trib = await UteisService.calcularImpostosProduto(
          row.id_prod_serv,
          stateCab.id_cliente,
          !stateCab.venda_bonificada ? "VENDA" : "BONIF",
          55,
          true,
          "", // cfop?
          parseFloat(row.quantidade),
          parseFloat(row.vlr_tot_prod_serv),
          stateCab.vlr_frete
        );

        if (trib && Object.keys(trib).length > 0) {
          const vIcmsSt = parseFloat(trib.vlr_icms_st);
          const vIpi = parseFloat(trib.vlr_ipi);
          const pIpi = parseFloat(trib.perc_ipi);
          const vIcmsStUnit =
            row.quantidade > 0 ? roundFloat(vIcmsSt / row.quantidade, 2) : 0;
          const vTotal =
            parseFloat(trib.vlr_total_com_impostos) - stateCab.vlr_frete;
          row = {
            ...row,
            perc_ipi: pIpi,
            vlr_ipi: vIpi,
            vlr_icms_st: vIcmsSt,
            vlr_icms_st_unit: vIcmsStUnit,
            vlr_total: vTotal,
          };
        } else {
          return false;
        }
      } else {
        row.vlr_total = row.vlr_tot_prod_serv;
      }
    }

    setAtualizarValoresItens(true);
    setItens(
      itens.map((element) => {
        if (element.id === row.id) {
          return { ...row };
        } else {
          return element;
        }
      })
    );

    return row;
  };

  const atualizarItem = async (row) => {
    const payload = {
      id_item: row.id,
      tipo: row.tipo,
      id_prod_serv: row.id_prod_serv,
      descricao: row.descricao,
      unidade: row.unidade,
      id_ambiente: row.id_ambiente,
      quantidade: parseFloat(row.quantidade ?? "0") ?? 0,
      vlr_unit: parseFloat(row.vlr_unit ?? "0") ?? 0,
      vlr_item: parseFloat(row.vlr_item ?? "0") ?? 0,
      perc_desc: parseFloat(row.perc_desc ?? "0") ?? 0,
      vlr_tot_prod_serv: parseFloat(row.vlr_tot_prod_serv ?? "0") ?? 0,
      vlr_total: parseFloat(row.vlr_total ?? "0") ?? 0,
      vlr_desc: parseFloat(row.vlr_desc ?? "0") ?? 0,
      vlr_acre: parseFloat(row.vlr_acre ?? "0") ?? 0,
      perc_ipi: parseFloat(row.perc_ipi ?? "0") ?? 0,
      vlr_ipi: parseFloat(row.vlr_ipi ?? "0") ?? 0,
      vlr_icms_st: parseFloat(row.vlr_icms_st ?? "0") ?? 0,
      vlr_icms_st_unit: parseFloat(row.vlr_icms_st_unit ?? "0") ?? 0,
      perc_comis: parseFloat(row.perc_comis ?? "0") ?? 0,
      vlr_tot_comis: parseFloat(row.vlr_tot_comis ?? "0") ?? 0,
      observ: row.observ ?? "",
    };

    const [ok] = await FrenteVendaService.alterarItem(payload);
    return ok;
  };

  const atualizarDadosItemGrid = async (row) => {
    if (atualizarValoresItens) {
      await atualizarItem(row);
      buscarItens();
    }
  };

  const atualizarObservItem = async (observ) => {
    setAtualizarValoresItens(true);

    const item = itens.find(
      (e) => e.tipo === selectedTipo && e.id === selectedIdItem
    );

    if (item) {
      return await atualizarItem({ ...item, observ: observ });
    } else {
      showWarning("Não foi possível encontrar os dados do item");
      return false;
    }
  };

  const alterarObservItem = (tipo, idItem, observ) => {
    setSelectedTipo(tipo);
    setSelectedIdItem(idItem);
    setSelectedObserv(observ ?? "");
    setTimeout(() => {
      toggleObservItem();
    }, 1);
  };

  const atualizarItens = async (itensAtualizados) => {
    for (const row of itensAtualizados) {
      let [tipoItem, idItem] = row.id_item.split("-");
      idItem = parseInt(idItem);
      const item = itens.find((e) => e.tipo === tipoItem && e.id === idItem);

      if (item) {
        const payload = {
          id_item: item.id,
          tipo: item.tipo,
          id_prod_serv: item.id_prod_serv,
          descricao: item.descricao,
          unidade: item.unidade,
          id_ambiente: item.id_ambiente,
          quantidade: parseFloat(item.quantidade ?? "0") ?? 0,
          vlr_unit: parseFloat(item.vlr_unit ?? "0") ?? 0,
          vlr_item: parseFloat(row.vlr_item ?? "0") ?? 0,
          perc_desc: parseFloat(row.perc_desc ?? "0") ?? 0,
          vlr_tot_prod_serv: parseFloat(row.vlr_tot_prod_serv ?? "0") ?? 0,
          vlr_total: parseFloat(row.vlr_total ?? "0") ?? 0,
          vlr_desc: parseFloat(row.vlr_desc ?? "0") ?? 0,
          vlr_acre: parseFloat(row.vlr_acre ?? "0") ?? 0,
          perc_ipi: parseFloat(row.perc_ipi ?? "0") ?? 0,
          vlr_ipi: parseFloat(row.vlr_ipi ?? "0") ?? 0,
          vlr_icms_st: parseFloat(row.vlr_icms_st ?? "0") ?? 0,
          vlr_icms_st_unit: parseFloat(row.vlr_icms_st_unit ?? "0") ?? 0,
          perc_comis: parseFloat(row.perc_comis ?? "0") ?? 0,
          vlr_tot_comis: parseFloat(row.vlr_tot_comis ?? "0") ?? 0,
          observ: item.observ,
        };

        await FrenteVendaService.alterarItem(payload);
      }
    }
    buscarItens();
  };

  const salvarVenda = async (successMessage) => {
    if (loadingSalvarInterno) return false;
    if (semCliente) {
      showWarning("Por favor, informe o Cliente da Venda");
      return false;
    }

    if (!validarDadosCab()) {
      return false;
    }

    setLoadingSalvarInterno(true);
    const payload = {
      id_cab: stateCab.id_venda,
      id_cliente: stateCab.id_cliente,
      nome_cliente: stateCab.nome_cliente ?? "", // DEVERIA DEIXAR NULL
      cpf_cnpj_cliente: stateCab.cpf_cnpj_cliente,
      vlr_frete: stateCab.vlr_frete,
      id_orcamento: stateCab.id_orcamento,
      vda_bonificada: stateCab.venda_bonificada,
    };
    const [ok] = await FrenteVendaService.salvarCab(payload, successMessage);
    setLoadingSalvarInterno(false);
    return ok;
  };

  const handleSalvarVenda = async () => {
    setLoadingSalvar(true);
    try {
      if (!(await salvarVenda(true))) {
        setLoadingSalvar(false);
        return false;
      }
    } finally {
      setLoadingSalvar(false);
    }
    limparDados();
  };

  const toggleConfirmar = () => setConfirmarOpen(!confirmarOpen);

  const toggleOrcamentos = () => setOrcamentosOpen(!orcamentosOpen);

  const toggleVendasSalvas = () => setVendasSalvasOpen(!vendasSalvasOpen);

  const toggleObservItem = () => setObservItemOpen(!observItemOpen);

  const toggleTrocarOperador = () => setTrocarOperadorOpen(!trocarOperadorOpen);

  const handleConfirmar = async () => {
    if (await salvarVenda(false)) {
      toggleConfirmar();
    }
  };

  const verificarVenda = () =>
    ![0, null, undefined].includes(stateCab.id_venda);

  const onKeyDown = (e) => {
    if (e.key === "F9") {
      if (loadingSalvarInterno) return;
      if (semItens) {
        showWarning("Por favor, inclua ao menos um item.");
        return false;
      }
      handleConfirmar();
    }

    if (e.key === "F8") {
      e.preventDefault();
      if (loadingSalvarInterno) return;
      if (!orcamentosOpen) {
        toggleOrcamentos();
      }
    }

    if (e.key === "F7") {
      e.preventDefault();
      if (loadingSalvarInterno) return;
      if (!vendasSalvasOpen) {
        toggleVendasSalvas();
      }
    }
  };

  useEffect(() => {
    carregarParametros();
    // A função abaixo é executada quando o componente é desmontado
    // (onComponentUnmount).
    return () => {
      dispatch(setup(initialState));
    };
  }, []);

  const verificarErroAoIniciar = () => erroAoIniciar;

  window.onbeforeunload = function (e) {
    if (window.location.pathname !== frenteVendaRoute.path) {
      return;
    }

    if (verificarErroAoIniciar()) {
      return;
    }

    if (verificarVenda()) {
      return;
    }
    var dialogText = "Tem certeza de que deseja sair da frente de venda?";
    e.returnValue = dialogText;
    return dialogText;
  };

  const disabledSalvarFinalizar = semItens || semCliente;
  const hintSalvarFinalizar = semItens
    ? "Inclua ao menos um item"
    : semCliente && "Informe o Cliente da Venda";

  useImperativeHandle(ref, () => ({
    onKeyDown: onKeyDown,
  }));

  const handleSetVendaBonificada = async () => {
    setLoadingSalvarInterno(true);
    try {
      const vdaBonificada = !stateCab.venda_bonificada;
      dispatch(setVendaBonificada(vdaBonificada));

      if (!semItens && !semCliente) {
        const payload = {
          id_cab: stateCab.id_venda,
          id_cliente: stateCab.id_cliente,
          nome_cliente: stateCab.nome_cliente ?? "", // DEVERIA DEIXAR NULL
          cpf_cnpj_cliente: stateCab.cpf_cnpj_cliente,
          vlr_frete: stateCab.vlr_frete,
          id_orcamento: stateCab.id_orcamento,
          vda_bonificada: vdaBonificada,
        };
        const [ok] = await FrenteVendaService.salvarCab(payload, false);
        if (!ok) return;

        const itensAtualizados = [];
        for (const row of itens) {
          if (row.tipo === "P" && !simplesNacional) {
            const trib = await UteisService.calcularImpostosProduto(
              row.id_prod_serv,
              stateCab.id_cliente,
              !vdaBonificada ? "VENDA" : "BONIF",
              55,
              true,
              "", // cfop?
              parseFloat(row.quantidade),
              parseFloat(row.vlr_tot_prod_serv),
              stateCab.vlr_frete
            );

            if (trib && Object.keys(trib).length > 0) {
              const vIcmsSt = parseFloat(trib.vlr_icms_st);
              const vIpi = parseFloat(trib.vlr_ipi);
              const pIpi = parseFloat(trib.perc_ipi);
              const vIcmsStUnit =
                row.quantidade > 0
                  ? roundFloat(vIcmsSt / row.quantidade, 2)
                  : 0;
              const vTotal = parseFloat(trib.vlr_total_com_impostos);

              itensAtualizados.push({
                ...row,
                perc_ipi: pIpi,
                vlr_ipi: vIpi,
                vlr_icms_st: vIcmsSt,
                vlr_icms_st_unit: vIcmsStUnit,
                vlr_total: vTotal,
              });
            } else {
              return false;
            }
          }
        }
        setItens([...itensAtualizados]);
        for (const row of itensAtualizados) {
          await atualizarItem(row);
        }

        buscarItens();
      }
    } finally {
      setLoadingSalvarInterno(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Prompt
        when={!semItens}
        message={(location) =>
          "Tem certeza de que deseja sair? Dados que não foram salvos serão perdidos!"
        }
      />
      <Card body>
        <Row>
          <PesqCliente
            onConfirm={handleSetIdCliente}
            selectNextField={() => {
              if (incluirProdutoRef.current) {
                incluirProdutoRef.current.focus();
              }
            }}
            onChangeDescricao={(v) => dispatch(setNomeCliente(v))}
            idCliente={stateCab.id_cliente}
            nomeCliente={stateCab.nome_cliente}
            ref={pesqClienteRef}
            autoFocus
            showShortcut
          />
          <TextInput
            md={2}
            label="CPF/CNPJ"
            value={stateCab.cpf_cnpj_cliente}
            onChange={(e, v) => dispatch(setCpfCnpjCliente(v))}
            onBlur={(e, v) => onBlurCpfCnpjCliente(v)}
            disabled={!stateCab.cliente_curinga}
            inputStyle={{ textAlign: "center" }}
          />
          {stateCab.whatsapp_cliente && (
            <FixedField
              label="Whatsapp"
              value={stateCab.whatsapp_cliente}
              horizontal
              verticallyCentered
              divStyle={{ paddingTop: "15px" }}
            />
          )}
        </Row>
        <IncluirProdutoForm
          incluirItem={incluirItem}
          semCliente={semCliente}
          ref={incluirProdutoRef}
        />
        <Row>
          <LabelButton onClick={toggleOrcamentos} divClassName="mt-2 mb-0">
            F8 - Orçamentos
          </LabelButton>
          <OrcamentosModal
            isOpen={orcamentosOpen}
            toggle={toggleOrcamentos}
            setItens={setItens}
            simplesNacional={simplesNacional}
            buscarItens={buscarItens}
          />
          <IncluirServicoModal incluirItem={incluirItem} />
          <DescontoGeralModal
            simplesNacional={simplesNacional}
            itens={itens.filter((e) => e.lista_preco !== true)}
            updateItens={atualizarItens}
          />
          <LabelButton onClick={toggleVendasSalvas} divClassName="mt-2 mb-0">
            F7 - Vendas Salvas
          </LabelButton>
          <VendasSalvasModal
            isOpen={vendasSalvasOpen}
            toggle={toggleVendasSalvas}
            importarVendaSalva={importarVendaSalva}
          />
          <ObservItemModal
            isOpen={observItemOpen}
            toggle={toggleObservItem}
            selectedObserv={selectedObserv}
            handleSubmit={atualizarObservItem}
            notifyEvent={() => buscarItens()}
          />
          <LabelButton
            onClick={toggleTrocarOperador}
            divClassName="ml-auto mt-2 mb-0"
          >
            Trocar Operador
          </LabelButton>
          <TrocarOperadorModal
            isOpen={trocarOperadorOpen}
            toggle={toggleTrocarOperador}
          />
        </Row>
      </Card>
      <Card body>
        <ConfirmarVendaModal
          isOpen={confirmarOpen}
          toggle={toggleConfirmar}
          notifyFinalizar={limparDados}
        />
        <FrenteVendaGrid
          dados={itens}
          excluirItem={excluirItem}
          recalcularValoresGrade={recalcularValoresGrade}
          atualizarDadosItem={atualizarDadosItemGrid}
          alterarObservItem={alterarObservItem}
          idOrcamentoAlterando={stateCab.id_orcamento}
        />
      </Card>
      <Card body style={{ position: "sticky", bottom: 0 }}>
        <Row className="mb-2">
          <FixedField
            label="Serviços"
            value={formatNumber(stateCab.vlr_tot_serv_venda, true, 2)}
            horizontal
            verticallyCentered
          />
          <FixedField
            label="Desconto"
            value={formatNumber(stateCab.vlr_tot_desc_venda, true, 2)}
            horizontal
            verticallyCentered
          />
          <FixedField
            label="Comissão"
            value={formatNumber(stateCab.vlr_tot_comis_venda, true, 2)}
            horizontal
            verticallyCentered
          />
          <NumberInput
            md={1}
            inline
            label="Frete"
            value={stateCab.vlr_frete}
            onChange={(v) => {
              dispatch(setVlrFrete(v));
              dispatch(
                setVlrTotVenda(
                  stateCab.vlr_tot_prod_venda + stateCab.vlr_tot_serv_venda + v
                )
              );
            }}
            disabled={!verificarVenda()}
          />
          {params.utiliza_regra_tributacao && (
            <FormCheckbox
              padded={false}
              label="Venda Bonificada"
              checked={stateCab.venda_bonificada}
              onChange={handleSetVendaBonificada}
              divClassName="ml-4"
              disabled={loadingSalvarInterno}
              hint="Altera a tributação de todos os produtos para a natureza de operação Bonificação"
            />
          )}
          <FixedField
            divClassName="ml-auto pr-0"
            labelStyle={{ fontSize: "1.2rem", fontWeight: "bold" }}
            label="Valor Total"
            value={formatNumber(stateCab.vlr_tot_venda, true, 2)}
            labelClassName="mb-0 mt-1"
            horizontal
            verticallyCentered
            boldValue
          />
        </Row>
        <Row>
          <FixedField
            label="Produtos"
            value={formatNumber(stateCab.vlr_tot_prod_venda, true, 2)}
            horizontal
            verticallyCentered
          />
          <FormButton
            md="auto"
            divClassName="ml-auto"
            color="info"
            onClick={handleSalvarVenda}
            loading={loadingSalvar}
            disabled={disabledSalvarFinalizar}
            disabledHint={hintSalvarFinalizar}
            padded={false}
          >
            Salvar
          </FormButton>
          <FormButton
            md="auto"
            color="success"
            onClick={handleConfirmar}
            disabled={disabledSalvarFinalizar}
            disabledHint={hintSalvarFinalizar}
            padded={false}
            divClassName="pr-0"
          >
            F9 - Continuar
          </FormButton>
        </Row>
      </Card>
      <ConfirmarAlterarClienteModal
        isOpen={confirmaAlteraClienteOpen}
        toggle={() => {
          setConfirmaAlteraClienteOpen(!confirmaAlteraClienteOpen);
          setDadosClienteAlterar({});
        }}
        dadosClienteAlterar={dadosClienteAlterar}
        cancelarAlteracao={cancelarAlteracaoCliente}
        recalcularValoresItens={recalcVlrsItensAlteraCli}
      />
    </>
  );
});

export const FrenteVenda = () => {
  const refContainer = useRef();

  return (
    <PageContainer
      title="Frente de Venda"
      number="0030 - Beta"
      onKeyDown={(e) => {
        if (refContainer.current) {
          refContainer.current.onKeyDown(e);
        }
      }}
      formClassName="pb-0"
    >
      <Provider store={storeCab}>
        <FrenteVendaContainer ref={refContainer} />
      </Provider>
    </PageContainer>
  );
};
