import React from "react";
import { MenuGroup, MenuGroupButton, PageContainer } from "../../../components";
import { MdLocalShipping } from "react-icons/md";
import { pedPendentesEntregaRoute } from "../../../routes/modules/relatorios";

export const Pedidos = () => {
  return (
    <PageContainer title="Pedidos">
      <MenuGroup>
        <MenuGroupButton
          label="Pedidos Pendentes de Entrega"
          icon={MdLocalShipping}
          pathname={pedPendentesEntregaRoute.path}
          nroTela={pedPendentesEntregaRoute.nroTela}
        />
      </MenuGroup>
    </PageContainer>
  );
};
