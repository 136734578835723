import React from "react";
import { Table } from "../../../../../components";
import {
  dropdownAcoes,
  iconeAlterar,
} from "../../../../../components/cadastro";
import { naturalSort } from "../../../../../coreUtils";

export const FabricanteGrid = ({ data, setSelected, alterar, excluir }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      align: "center",
      headerAlign: "center",
      sortable: true,
      sortFunc: naturalSort,
    },
    {
      dataField: "nome",
      text: "Nome",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "id_cidade__nome",
      text: "Cidade",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "telefone",
      text: "Telefone",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    {
      dataField: "email",
      text: "E-Mail",
      align: "left",
      headerAlign: "left",
      sortable: true,
    },
    iconeAlterar(alterar),
    dropdownAcoes({ excluir: excluir }),
  ];

  return (
    <Table
      data={data}
      columns={columns}
      onSelect={setSelected}
      onRowDoubleClick={alterar}
    />
  );
};
