import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";

const URL_BASE = "/receber/cheque_rec/gerenciamento";

const GerencChequesClientesService = {
  alterar: (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  buscarLogs: (idChequeRec) =>
    apiGetV2(`${URL_BASE}/buscar_logs/${idChequeRec}/`),
  buscar: (idChequeRec) => apiGetV2(`${URL_BASE}/buscar/${idChequeRec}/`),
  depositar: (payload) => apiPostV2(`${URL_BASE}/depositar/`, payload),
  enviarCustodia: (payload) =>
    apiPutV2(`${URL_BASE}/enviar_custodia/`, payload),
  excluir: (payload) => apiPostV2(`${URL_BASE}/excluir/`, payload),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  retirarCustodia: (payload) =>
    apiPutV2(`${URL_BASE}/retirar_custodia/`, payload),
  trocarPorDinheiro: (payload) =>
    apiPostV2(`${URL_BASE}/trocar_por_dinheiro/`, payload),
};

export default GerencChequesClientesService;
