import { apiGetV2, apiPostV2, apiPutV2 } from "../../apiV2";
import { docPrintReport } from "../../pdf";

const URL_BASE = "/mdfe";
const ManifEletronMDFeService = {
  imprimir: async (id) => {
    const [ok, dados] = await apiGetV2(`${URL_BASE}/dados_impressao/${id}/`);
    if (ok) {
      await docPrintReport(
        "/docs_eletron/transporte/mdfe/",
        { dados: dados },
        "0042_2"
      );
    }
  },
  listar: (params) => apiGetV2(`${URL_BASE}/listar/`, params),
  listarDocsInclusaoRapida: (params) =>
    apiGetV2(`${URL_BASE}/listar_docs_inclusao_rapida/`, params),
  buscar: (id) => apiGetV2(`${URL_BASE}/buscar/${id}/`),
  buscarChaveDuplicidade: (id) =>
    apiGetV2(`${URL_BASE}/duplicidade/buscar_chave_acesso/${id}/`),
  cancelar: (id) => apiPutV2(`${URL_BASE}/cancelar/`, { id_mdfe: id }),
  corrigirDuplicidade: (formData) =>
    apiPostV2(`${URL_BASE}/duplicidade/corrigir_duplicidade/`, formData),
  incluir: (payload) => apiPostV2(`${URL_BASE}/incluir/`, payload),
  alterar: (payload) => apiPutV2(`${URL_BASE}/alterar/`, payload),
  emitir: (id) => apiPutV2(`${URL_BASE}/emitir/`, { id_mdfe: id }),
  encerrar: (id) => apiPutV2(`${URL_BASE}/encerrar/`, { id_mdfe: id }),
  buscarEventosAlertas: (id) =>
    apiGetV2(`${URL_BASE}/buscar_eventos_alertas/${id}/`),
  buscarDadosConemb: (mdfes) =>
    apiGetV2(`${URL_BASE}/buscar_dados_conemb/`, { id_mdfe: mdfes }),
  enviarEmailConemb: (idMdfe, payload) =>
    apiPostV2(`${URL_BASE}/enviar_email_conemb/${idMdfe}/`, payload),
  vincularNfeManualmente: (payload) =>
    apiPostV2("/pronta_entrega/mdfe/vincular_nfe_manualmente/", payload),
};

export default ManifEletronMDFeService;
